import { http, httpOnline, } from "../../config";
import { getNetworkState } from "../../config/offline";
import { getStoredOtpConfig, logoutOffline } from "../../config/otp";

const authRootUrl = "/auth";
const userRootUrl = "/user";

const logout = async (oktaAuth) => {
	const networkStatus = getNetworkState();
	await logoutOffline();
	if (networkStatus === "online") {
		oktaAuth.signOut();
	} else {
		window.location.assign("/");
	}
};

const setupCsrfProtection = async () => httpOnline.get("/auth/csrf", { ignoreOfflineAuth: true });

const getCurrentUser = async () => httpOnline.get(`${userRootUrl}/current`, { ignoreOfflineAuth: true });

const getTotpConfig = async () => http.get(`${authRootUrl}/otp/config`, {
	handleOfflineResponse: getStoredOtpConfig,
	ignoreOfflineAuth: true
});

const getTotpCode = async () => httpOnline.get(`${authRootUrl}/otp/code`);

export { logout, getCurrentUser, setupCsrfProtection, getTotpConfig, getTotpCode };
