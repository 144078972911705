import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { buildTrainProcessBinderDetail, SprContext } from "../../../../../shared";
import { getTrainByTechId, getTrainAssociatedBinders } from "../../../train.services";
import { getProcessByTechId } from "../../../../process/process.services";
import SlSuggestedSheetSidebar from "../../../../../shared/components/sl-suggested-sheet-sidebar/sl-suggested-sheet-sidebar";
import RailwayBinderList from "../railway-binder-list";
import RailWaySubheader from "../../../../../shared/components/railway-subheader/railway-subheader";

import "./train-binder-list.scss";

/**
 * Render the Train binder list page
 * @return {JSX.Element}
 * @constructor
 */
const TrainBinderList = ({ disableLineSwitch }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { trainTechId, processId } = useParams();
	const [ train, setTrain ] = useState({});
	const [ binderList, setBinderList ] = useState([]);
	const { setCurrentBinder, side } = useContext(SprContext);
	const [ sheetTechId, setSheetTechId ] = useState("");

	const fetchTrainBinders = useCallback(
		async (processTrainId) => {
			const { data = [] } = await getTrainAssociatedBinders(processTrainId);
			if (data.length === 1) {
				const link = buildTrainProcessBinderDetail({ processId, binderTechnicalId: data[0].tech_id }, "railway");
				history.replace(link);
			} else {
				setBinderList(data);
				const [ binderInfo = {} ] = data;
				const headerBinder = {
					type: binderInfo.type,
					materialLabel: binderInfo.material_label,
					color: binderInfo.color,
				};
				setCurrentBinder(headerBinder);
			}
		},
		[ history, processId, setCurrentBinder ],
	);

	const fetchTrain = (processTrainId, options = {}) => {
		getTrainByTechId(processTrainId, { ...options, extendMaterial: true }).then(response => {
			setTrain(response?.data);
		});
	};

	const initPage = () => {
		if (processId) {
			// get train and binder info from process
			getProcessByTechId(processId, { extendOwner: false }).then(response => {
				const newTrainId = response?.data?.train_tech_id;
				if (newTrainId){
					fetchTrainBinders(newTrainId);
					fetchTrain(newTrainId, { is_active: true });
				}
				setSheetTechId(response?.data?.sheet_tech_id);
			});
		} else {
			fetchTrainBinders(trainTechId);
			fetchTrain(trainTechId, { is_active: true });
		}
	};

	useEffect(initPage, [ trainTechId, processId, setCurrentBinder, fetchTrainBinders ]);

	const subHeader = <RailWaySubheader
		trainId={train?.id}
		title={train?.material_label}
		linkTo={"/railway"}
		returnLabel={t("train:railway-binder-list.header.back-train")}
	/>;

	const sideBar = processId && sheetTechId && <SlSuggestedSheetSidebar />;


	return 	<RailwayBinderList
		binders={binderList}
		subHeader={subHeader}
		processId={processId}
		sideBar={sideBar}
		allowLineSwitch={side === "train"}
		disabledLineSwitch={disableLineSwitch}
	/>;
};

const TrainBinderListWrapper = (props) => <TrainBinderList {...props}/>;

export default TrainBinderListWrapper;
