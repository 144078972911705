import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { checkFormError } from "../../../../shared";
import { ErrorMessage, PopupConfirm, ColorChooser, Editor, Dropdown } from "../../../../theme";
import { resolveSheetColor, sheetColorList, sheetColorTrainList } from "../../";
import validateEditSheetTitleForm from "./validate-edit-sheet-title-form";
import { getBinderThemes } from "../../../binder/binder-theme.services";
import { listAllBinderSignal } from "../../../binder/binder-signal.services";
import { fetchSheetByBinder, updateSheet } from "../../sheet.services";
import signalOptionToString from "./utils/signal-option-to-string";

import "./popup-edit-sheet-title.scss";

/**
 * Render the popup to edit a sheet
 * @param {object} props
 * @param {function} props.onSubmitSuccess
 * @param {object} props.sheet
 * @param {object} props.binder
 * @param {object} props.popupControl
 * @param {boolean} props.isTrainBinder
 * @returns {JSX.Element}
 */
const PopupEditSheetTitle = (props) => {
	const { onSubmitSuccess, sheet = {}, binder, popupControl, isTrainBinder } = props;
	const { title: sheetTitle, number: sheetNumber, theme_tech_id: themeTechId } = sheet;
	const { t } = useTranslation();

	const [ signalList, setSignalList ] = useState([]);
	const [ sheetList, setSheetList ] = useState([]);
	const [ sheetListLoading, setSheetListLoading ] = useState(false);
	const [ form, setForm ] = useState({ title: sheetTitle });

	const formError = useMemo(
		() => validateEditSheetTitleForm(form, { binderType: binder?.type, sheetNumber, sheetList }),
		[ sheetList, form, binder?.type, sheetNumber ]
	);

	const [ networkError, setNetworkError ] = useState(false);
	const [ themeList, setThemeList ] = useState();

	const editorToolbarConfig = {
		options: [ "inline", "list", "colorPicker" ],
		inline: { options: [ "bold", "italic" ] },
		list: { options: [ "unordered" ] },
		colorPicker: { colors: [ "black", "red", "transparent" ] }
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
	};

	const isFormReady = !sheetListLoading && !checkFormError(formError);

	const handleConfirm = () => {
		if (isFormReady) {
			const update = {
				title: form.title,
				color: form.color,
				signalTechId: form.sheetSignal,
				themeTechId: form.themeTechId
			};

			updateSheet(sheet?.binder_tech_id, sheet?.sheet_id, update)
				.then(response => {
					onSubmitSuccess(response?.data);

					// Reset form after data's submission
					setNetworkError(false);
					popupControl.hide();
				})
				.catch((error) => {
					console.error(error);
					setNetworkError(true);
				});
		}
	};

	const renderThemeOptions = (theme) => (
		<option key={theme?.tech_id} value={theme?.tech_id} disabled={sheet?.theme_tech_id === theme?.tech_id}>
			{theme?.title}
		</option>
	);

	const renderSignalOption = signal => {
		const { tech_id: techId } = signal;
		return (
			<option key={techId} value={techId} disabled={sheet?.signal_tech_id === techId}>
				{signalOptionToString(signal)}
			</option>
		);
	};

	const getThemes = () => {
		if (binder?.tech_id) {
			getBinderThemes(binder.tech_id).then(response => {
				setThemeList(response.data);
			});
		}
	};

	const getSignalList = () => {
		if (binder?.tech_id) {
			listAllBinderSignal(binder?.tech_id).then(response => {
				setSignalList(response?.data);
			});
		}
	};

	const handleUpdateSheetListBySignal = () => {
		if (form.sheetSignal) {
			setSheetListLoading(true);
			fetchSheetByBinder(binder?.tech_id, { signalTechId: form.sheetSignal })
				.then(response => setSheetList(response?.data))
				.finally(() => setSheetListLoading(false));
		}
	};

	useEffect(getSignalList, [ binder?.tech_id ]);
	useEffect(getThemes, [ binder?.tech_id ]);
	useEffect(handleUpdateSheetListBySignal, [ form.sheetSignal, binder?.tech_id ]);

	const resetPopup = () => {
		setForm({ title: sheetTitle });
		setSheetList([]);
		setSheetListLoading(false);
	};

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t("sheet:popup.edit-title.title")}
			className="popup-edit-sheet-title__wrapper"
			onConfirm={handleConfirm}
			onClose={resetPopup}
			onCancel={resetPopup}
			ctaConfirmLabel={t("sheet:popup.edit-title.cta-confirm-label")}
			ctaConfirmDisabled={!isFormReady}
		>
			<form className="popup-binder-edit-title">
				<span className="popup-binder-edit-title__sheet-number">{sheet?.number_search}</span>
				<Editor
					name="title"
					label={t("sheet:popup.edit-title.field.title.label")}
					value={sheet?.title}
					toolbarConfig={editorToolbarConfig}
					onChange={handleChange}
				/>
				{sheet?.theme_tech_id &&
				<Dropdown
					name="themeTechId"
					value={form.themeTechId || themeTechId}
					onChange={handleChange}
					placeholder={t("sheet:popup.edit-title.field.theme.label")}>
					{themeList?.map(renderThemeOptions)}
				</Dropdown>
				}
				{binder?.type === "man" &&
				<Dropdown className="form__signal" value={form.sheetSignal} name="sheetSignal" onChange={handleChange}
				          placeholder={t("sheet:popup.edit-title.signal-dropdown-placeholder")}>
					{signalList.map(renderSignalOption)}
				</Dropdown>
				}
				{binder?.type !== "man" &&
				<ColorChooser
					name="color"
					label={t("sheet:popup.create.field.color.label")}
					value={form.color || resolveSheetColor(sheet.color, binder.color, sheet.theme_color)}
					onChange={handleChange}
					colorList={isTrainBinder ? sheetColorTrainList : sheetColorList}
				/>
				}
				{formError.sheetSignal.includes("invalid") &&
				<ErrorMessage>{t("sheet:popup.edit-title.sheet-exist")}</ErrorMessage>}
				{networkError &&
				<ErrorMessage className="popup-binder-edit-title__network-error">{t("error.default")}</ErrorMessage>}
			</form>
		</PopupConfirm>
	);
};

PopupEditSheetTitle.propTypes = {
	binder: PropTypes.object.isRequired,
	sheet: PropTypes.object.isRequired,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired
	}).isRequired,
	onSubmitSuccess: PropTypes.func,
	isTrainBinder: PropTypes.bool
};

export default PopupEditSheetTitle;
