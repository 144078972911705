const detectNumberInStringWithPaddingRegex = /(0*)([0-9]+)/g;

/**
 * Pads all detected number with \"0\" until the resulting number string length reaches at least minNumberLength. The padding is applied from the start of the detected number.
 * @param {string} str The string to analyse
 * @param {number} [minNumberLength=3] The min pad length prefixing all detected number in the result
 * @returns {string}
 * @example
 * const string = "EET n°000010, EET N°011, EET N°12"
 * const result = padStartNumberInside(string)
 * console.log(result)
 * // output: EET n°00000010, EET N°00000011, EET N°00000012
 */
const padStartNumberInside = (str, minNumberLength = 8) => str.replace(
	detectNumberInStringWithPaddingRegex,
	(match, padding, number) => {
		const padLength = Math.max(0, minNumberLength - (padding.length + number.length));
		return `${"0".repeat(padLength)}${padding}${number}`;
	}
);

/**
 * Remove the leading \"0\" prefixing all detected number in the provided string
 * @param {string} str The string to analyse
 * @returns {string}
 * @example
 * const string = "EET n°000010, EET N°11"
 * const result = unPadStartNumberInside(string)
 * console.log(result)
 * // output: EET n°10, EET N°11
 */
const unpadStartNumberInside = (str = "") => str.replace(
	detectNumberInStringWithPaddingRegex,
	(match, _padding, number) => number
);

const sheetListUnpadStartNumberInside = sheet => ({ ...sheet, number: unpadStartNumberInside(sheet.number) });

export { padStartNumberInside, unpadStartNumberInside, sheetListUnpadStartNumberInside };
