import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../../../../../theme";
import { IconPlus } from "../../../../../../assets";

import "./create-sheet-button.scss";

/**
 * CreateSheetButton component
 * @param {object} props
 * @param {function} props.onClick
 * @returns {JSX.Element}
 */
const CreateSheetButton = props => {
	const { onClick } = props;
	const { t } = useTranslation();

	return <Button 
		variant="primary"
		type="button"
		icon={IconPlus}
		label={t("binder:binder-detail.sheet-list.create-cta-label")}
		onClick={onClick}
	/>;
};

CreateSheetButton.propTypes = {
	onClick: PropTypes.func.isRequired
};

export default CreateSheetButton;
