import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IconWrapper } from "../../../../../../../../../../theme";
import { IconArrowRight } from "../../../../../../../../../../theme/assets/img";
import { useSheetContentContext } from "../../../../../../../../context/sheet-content-context";

import "./simple-link-step-view.scss";


const SimpleLinkStepView = (props) => {
	const { content, visible } = props;
	const { nodeList } = useSheetContentContext();
	const [ stepNumber, setStepNumber ] = useState("");

	const { t } = useTranslation();

	const getPhaseNumber = () => {
		if (content?.step) {
			const nodeStep = nodeList.find((value) => value.id === content.step);
			setStepNumber(nodeStep?.content?.step);
		}
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getPhaseNumber, [ content?.step ]);

	return (
		<div className={classNames("simple-link-phase-view__content", { "simple-link-phase-view__content--visible": visible })}>
			<IconWrapper className="simple-link-template-view__icon" Component={IconArrowRight}/>
			<span className="simple-link-phase-view__text">
				{`${t("sheet:sheet-details.template.simpleLink.step")} ${stepNumber}`}
			</span>
		</div>
	);
};

export default SimpleLinkStepView;
