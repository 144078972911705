import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSheetContentContext } from "../../../../../context/sheet-content-context";
import { Dropdown } from "../../../../../../../theme";
import { hasNoNextTemplatesInBothYesAndNo, getTemplateCopiedInLocalStorage } from "../../../utils/handle-copy-paste-template";
import getComponentConfig from "../../../utils/get-component-config";

/**
 * @param {{scope: 'train'|'pcc'}} props
 * @returns {JSX.Element}
 */
const DropdownGenerator = (props) => {
	const { scope } = props;
	const {
		nodeList,
		getCurrentActive,
		setCurrentNodeType,
		isLastTemplate,
		setCopiedTemplate,
		activeLocation,
		getTemplateByLocation,
		sheetData
	} = useSheetContentContext();

	const { t } = useTranslation();
	const sheetType = sheetData?.type;

	const [ dropdownValue, setDropdownValue ] = useState();
	const [ placeholderValue, setPlaceholderValue ] = useState();
	const [ dropdownOptions, setDropdownOptions ] = useState([]);
	const [ templateCopied, setTemplateCopied ] = useState();

	const activeNode = useMemo(getCurrentActive, [ getCurrentActive ]);

	const terminateTemplate = [ "termination", "multipleLink", "simpleLink" ];
	const templateCopiedType = "templateCopied";

	const handleDropdownChange = (e) => {
		const { value } = e.target;
		if (value === templateCopiedType) {
			setCopiedTemplate(templateCopied, nodeList);
		} else {
			setCurrentNodeType(value);
		}
		setDropdownValue(value);
	};

	const removeTerminateTemplate = (type) => !terminateTemplate.includes(type);

	const generateDropdownOptions = () => {
		const isLast = isLastTemplate(activeLocation);
		let templateTypes = Object.keys(getComponentConfig(scope, { sheetType }));
		let newPlaceholderValue = "new";
		if (activeLocation.parentId) {
			const activeParent = getTemplateByLocation({ id: activeLocation.parentId });
			const { type: parentType, content: activeParentContent } = activeParent;
			const { questionEnd, questionSide } = activeLocation;

			// Case question as parent
			if (parentType=== "question" && !questionEnd) {
				// For items
				templateTypes = Object.keys(getComponentConfig(scope,{ context: "question-list", sheetType }));

			} else if (parentType === "question" && questionEnd){
				// For end-item
				newPlaceholderValue = "next";
				templateTypes = Object.keys(getComponentConfig(scope, { context: "question-end", sheetType }));

				const otherSide = questionSide === "yes" ? "no" : "yes";
				const siblingEndType = activeParentContent?.[otherSide]?.["end-item"]?.type;
				if (siblingEndType !== "next" && !isLast) {
					// Force next template when parent not last and other side already a end
					setDropdownValue("disabled");
					templateTypes = [ "next" ];
				}
			} else if (parentType === "multipleChoice"){
				// Case multipleChoice as parent
				templateTypes = Object.keys(getComponentConfig(scope, { context : "multiple-choice", sheetType }));
			}
		} else if (!isLast){
			// Remove termination & multipleLink option if template select is not the last one
			templateTypes = templateTypes.filter(removeTerminateTemplate);
		}

		setPlaceholderValue(newPlaceholderValue);

		const templateCopiedFromStorage = getTemplateCopiedInLocalStorage();
		setTemplateCopied(templateCopiedFromStorage);

		// Remove template-copied from dropdownMenu if not exist
		if (!templateCopiedFromStorage) {
			templateTypes = templateTypes.filter(templateType => templateType !== templateCopiedType);
		}

		// Remove template-copied from dropdownMenu if copied template is question or multiple choice and position of active template is in a question
		const templateAvoided = [ "question", "multipleChoice" ];
		if (activeLocation.parentId && templateAvoided.includes(templateCopiedFromStorage?.type)) {
			templateTypes = templateTypes.filter(templateType => templateType !== templateCopiedType);
		}

		const options = templateTypes.map((type) =>
			(
				<option
					//if template active is not the last AND there are 2 terminations in question => option is disabled
					disabled={!isLast && type === templateCopiedType && hasNoNextTemplatesInBothYesAndNo(templateCopiedFromStorage)}
					value={type}
					key={type}>
					{t(`sheet:sheet-details.template-dropdown.${type}`)}
				</option>
			)
		);
		setDropdownOptions(options);
	};

	useEffect(() => {
		if (activeNode?.type) {
			setDropdownValue(activeNode.type);
		}
	}, [ activeNode ]);

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(generateDropdownOptions, [ activeNode ]);

	return (
		<Dropdown
			className="gabarit-panel__dropdown-type"
			disabled={!activeNode || dropdownValue === "disabled"}
			onChange={handleDropdownChange}
			value={dropdownValue}
			placeholder={t("sheet:sheet-details.template-dropdown.placeholder")}
			placeholderValue={placeholderValue}
			label={t("sheet:sheet-details.template-dropdown.title")}>
			{dropdownOptions}
		</Dropdown>
	);
};


DropdownGenerator.propTypes = {
	scope: PropTypes.oneOf([ "train", "pcc" ])
};

export default DropdownGenerator;
