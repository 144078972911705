import React from "react";
import { createPortal } from "react-dom";
import "./popup-wrapper.scss";

const PopupWrapper = ({ children, visible }) => {
	if (visible) {
		return createPortal(
			<div className="popup-wrapper">{children}</div>,
			document.getElementById("modal-root")
		);
	}
	return null;
};

export default PopupWrapper;
