import React from "react";
import { useTranslation } from "react-i18next";
import ResumeFormStepOneLineB from "../resume-form-step-one-line-B/resume-form-step-one-line-B";
import ResumeFormStepTwoLineB from "../resume-form-step-two-line-B/resume-form-step-two-line-B";
import ResumeFormStepThreeLineB from "../resume-form-step-three-line-B/resume-form-step-three-line-B";
import { Button } from "../../../../../../../../theme";

import "./resume-form-line-B.scss";

const ResumeFormLineB = (props) => {
	const { resumeStep, handleChange, handleReset, handleSubmit, resumeForm, backToSubstep, handleBackClick,
		stepNumber, hasEmergencyLightOn, trainComposition } = props;

	const { t } = useTranslation();

	const handleSubmitResumeForm = () => {
		const updatedResumeForm = { ...resumeForm, step_completed: true };
		handleSubmit(updatedResumeForm);
	};

	const propsObject = {
		resumeStep: resumeStep,
		stepNumber: stepNumber,
		handleChange: handleChange,
		handleReset: handleReset,
		handleSubmit: handleSubmit,
		resumeForm: resumeForm,
		backToSubstep: backToSubstep,
		handleBackClick: handleBackClick,
		hasEmergencyLightOn: hasEmergencyLightOn,
		trainComposition: trainComposition
	};

	return (
		<>
			{resumeStep === 0 && <ResumeFormStepOneLineB {...propsObject}/>}
			{resumeStep >= 1 && <ResumeFormStepTwoLineB {...propsObject}/>}
			{resumeStep >= 2 && <ResumeFormStepThreeLineB {...propsObject}/>}
			{resumeStep >= 3 && <div className="resume-rer__recap__button-wrapper">
				<Button
					variant="primary"
					type="submit"
					onClick={handleSubmitResumeForm}
					label={t("process:process-rer-resume.footer.confirm-resume")}
					className="resume-rer__recap__button-wrapper__button"
				/>
			</div>}
		</>
	);
};

export default ResumeFormLineB;
