import { useContext } from "react";
import { unpadStartNumberInside } from "../../../shared";
import { SheetContentContext } from "../context/sheet-content-context";

/**
 * reload data and content in context
 * @callback setSheetDataAndContentCallback
 * @param {object} sheet
 */

/**
 * reload sheet data in sheet content context
 * @callback reloadSheetData
 * @param {{binderId: string, sheetNumber?: string, sheetId?: string}} params
 * @param {boolean} [updateContent = true]
 */

/**
 * hook to reload sheet data and content in sheet content context
 * @returns {{reloadSheetData: reloadSheetDataCallback, refreshSheetData: setSheetDataAndContentCallback}}
 */
export function useSheetReload() {
	const { initSheetContent, setSheetData } = useContext(SheetContentContext);
	
	/** @type {setSheetDataAndContentCallback} */
	const setSheetDataAndContent = (sheet, updateContent = true) => {
		const { content = [], ...restSheet } = sheet;
		const newSheetData = { ...restSheet, binder_id: undefined };
		if (updateContent) {
			initSheetContent(content);
		}
		setSheetData(newSheetData);
	};		

	/** @type {reloadSheetDataCallback} */
	const reloadSheetData = (sheet = {}, updateContent = true) => {
		sheet.number = unpadStartNumberInside(sheet.number);
		setSheetDataAndContent(sheet, updateContent);
	};

	return { reloadSheetData, refreshSheetData: setSheetDataAndContent };
}


