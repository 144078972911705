import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SubwayLineNumber } from "../../../index";
import "./filter-associated-line-label.scss";

/**
 * Render the AssociatedLineDropdownOption components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.line
 * @returns {JSX.Element}
 */
const FilterAssociatedLineLabel = props => {
	const { className, line } = props;

	const { t } = useTranslation();

	return (
		<label className={classnames(className, "filter-associated-line-label")} htmlFor={line}>
			<SubwayLineNumber className="filter-associated-line-label__line" lineNumber={line}/>
			<span className="filter-associated-line-label__label">
				{`${t("binder:binder-list.filter.filter-list.line")} ${line}`}
			</span>
		</label>
	);
};

FilterAssociatedLineLabel.propTypes = {
	className: PropTypes.string,
	line: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired
};

export default FilterAssociatedLineLabel;
