import { useState } from "react";

const useDropdownMenu = () => {
	const [ visible, setVisible ] = useState(false);
	const show = () => setVisible(true);
	const hide = () => setVisible(false);

	return {
		visible,
		setVisible,
		show,
		hide,
		itemConfig: {
			showMenu: show,
			hideMenu: hide
		}
	};
};

export default useDropdownMenu;
