import { useCallback, useEffect, useState } from "react";
import { csrf } from "../../config";
import { setupCsrfProtection } from "../auth/auth.services";
import { useHistory } from "react-router-dom";

/**
 * Setup csrf configuration when not loading
 * @param online the network state
 * @param loading The parent loading state
 * @return {{loading: boolean}}
 */
const useCSRF = (online, loading) => {
	const history = useHistory();
	const [ csrfTokenReady, setCsrfTokenReady ] = useState(false);

	const setupCSRF = useCallback(async () => {
		if (online) {
			try {
				const response = await setupCsrfProtection();
				const csrfToken = response.headers["csrf-token"];
				csrf.saveCSRFToken(csrfToken);
			} catch {
				csrf.removeCSRFToken();
			}
		}
	}, [ online ]);

	const csrfSetupEffect = useCallback(() => {
		const isAuthenticated = !loading && online;
		if (isAuthenticated) {
			setCsrfTokenReady(false);
			setupCSRF()
				.catch(() => history.push("/error-access"))
				.finally(() => {
					setCsrfTokenReady(true);
				});
		}
	}, [ history, loading, online, setupCSRF ]);

	useEffect(csrfSetupEffect, [ csrfSetupEffect ]);

	return { loading: online && !csrfTokenReady };
};

export default useCSRF;
