import { groupBy } from "../../../shared";

/**
 * groupBinderByAbbrev reducer
 * @param groupedBinderList The grouped binder list
 * @param binder the current binder
 */
const groupBinderByAbbrevReducer = (groupedBinderList, binder) => {
	const existingBinderEntryPosition = groupedBinderList.findIndex(binderEntry =>
		binderEntry.abbreviation.toLowerCase() === binder.abbreviation.toLowerCase());

	const result = [ ...groupedBinderList ];

	if (existingBinderEntryPosition === -1) {
		result.push({ ...binder, count: 1 });
	} else {
		result[existingBinderEntryPosition].count += 1;
	}

	return result;
};

/**
 * Group binder by abbrev. keep first found binder info, keep binder list order
 * @param binderList
 * @returns {*[]}
 */
const groupBinderByAbbrev = (binderList) => Array.isArray(binderList) ? binderList.reduce(groupBinderByAbbrevReducer, []) : [];

/**
 * Count the amount of binders per material label
 * @param binderList
 * @returns {Array<[string,object[]]>}
 */
const sortAndGroupBinderByMaterial = (binderList) => {
	if (Array.isArray(binderList)){
		const sortedBinderList = [ ...binderList ];
		sortedBinderList.sort(({ material_label: materialLabelA }, { material_label: materialLabelB }) => materialLabelA.localeCompare(materialLabelB));

		const bindersGroupedByMaterial = groupBy(sortedBinderList, "material_tech_id");
		const result =  Object.entries(bindersGroupedByMaterial);
		return result;
	}
};

export { groupBinderByAbbrev, groupBinderByAbbrevReducer, sortAndGroupBinderByMaterial };
