import React from "react";
import classNames from "classnames";
import { IconWrapper } from "../../../";
import "./dropdown-menu-item.scss";

const DropdownMenuItem = props => {
	const {
		className, icon, label, onClick = () => {}, href, target = "_self", hideMenu
	} = props;

	const handleClick = () => {
		onClick();
		hideMenu();
	};

	const content = (
		<>
			{icon && <IconWrapper className="dropdown-menu-item__icon" Component={icon}/>}
			<span className="dropdown-menu-item__label">{label}</span>
		</>
	);

	return (
		<li className="dropdown-menu-item__wrapper">
			{href && (
				<a className={classNames("dropdown-menu-item", className)} href={href} target={target}
				   rel="noopener noreferrer">
					{content}
				</a>
			)}
			{!href && (
				<button className={classNames("dropdown-menu-item", className)} type="button"
				        onClick={handleClick}>
					{content}
				</button>
			)}
		</li>
	);
};

export default DropdownMenuItem;
