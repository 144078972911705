import React from "react";
import { useTranslation } from "react-i18next";
import { IconWrapper } from "../../index";
import OfflineHeader from "../../../domains/user/components/offline-header/offline-header";
import { ImgUnfinishedProcess } from "../../../domains/process/assets";
import "./no-cache-available.scss";

/**
 * Render The no cache available error page
 * @return {JSX.Element}
 * @constructor
 */
const NoCacheAvailable = () => {
	const { t } = useTranslation();
	
	return (
		<main className="no-cache-available">
			<OfflineHeader />
			<div className="no-cache-available__content">
				<IconWrapper className="no-cache-available__image" Component={ImgUnfinishedProcess} />
				<p className="no-cache-available__text">{t("user:no-cache-available.text")}</p>
			</div>
		</main>
	);
};

export default NoCacheAvailable;
