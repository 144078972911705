import { http } from "../../config";
import { getEntity, getEntityList } from "../../shared/cache-access/entity/entity-utils";

const trainRootUrl = "/train";

/**
 * @typedef Train
 * @prop {String} id
 * @prop {Number} line
 * @prop {String} material_tech_id
 * @prop {String} brake_system
 * @prop {boolean} brake_isolation
 * @prop {boolean} engine_system
 * @prop {Number} car_number
 * @prop {String} binder_auto
 * @prop {String} binder_driver
 * @prop {String} binder_officer
 */
/**
 * Create a new train
 * @param {Train} train The train's data
 * @return {Promise<import("axios").AxiosResponse<Train>>}
 */
const createTrain = async (train) => http.post(trainRootUrl, train);

/**
 * Copy an existing train
 * @param {string} trainId The new train's id
 * @param {string} copyTrainTechId the tech id of the original train to copy
 * @return {Promise<import("axios").AxiosResponse<Train>>}
 */
const copyTrain = async (trainId, copyTrainTechId) => http.post(trainRootUrl, { id: trainId },
	{ params: { trainTechId: copyTrainTechId } });

/**
 * Get list of trains
 * @param {string} searchQuery search query by train id
 * @param {{
 * 	line: string
 * 	is_active: boolean
 * }} params train of specific line
 * @return {Promise<import("axios").AxiosResponse<[]Train>>}
 */
const fetchTrain = async (searchQuery, params = {}) => {
	const { line } = params;
	if (line){
		return http.get(trainRootUrl, {
			params: { ...params, q: searchQuery },
			handleOfflineResponse: async () => {
				const trainList = await getEntityList("train", { line, is_active: true });
				const result = trainList
					.filter((cacheEntry) => searchQuery.length === 0 || cacheEntry.data.id.match(new RegExp(`.*${searchQuery}.*`, "g")))
					.sort((lhs, rhs) => lhs.data.id > rhs.data.id);
				return result.map(cacheEntry => cacheEntry.data);
			}
		});
	}
};

/**
 * Update an existing train
 * @param {String} trainId The train's tech id
 * @param {Train} trainData The train's data
 * @return {Promise<import("axios").AxiosResponse<Train>>}
 */
const updateTrain = async (trainId, trainData) => http.put(`${trainRootUrl}/${trainId}`, trainData);

/**
 * delete train by tech id
 * @param {string} trainTechId train technical id
 * @return {Promise<>} train The train data
 */
const deleteTrainByTechId = async (trainTechId) => http.delete(`${trainRootUrl}/${trainTechId}`);

/**
 * get train by tech id
 * @param {string} trainTechId train technical id
 * @param {object} [options]
 * @param {boolean} [options.extendMaterial=false] set to true to get train material details
 * @return {Promise<import("axios").AxiosResponse<Train>>}
 */
const getTrainByTechId = async (trainTechId, options = {}) => {
	const { extendMaterial = false } = options;
	return http.get(`${trainRootUrl}/${trainTechId}`, {
		params: { extendMaterial },
		handleOfflineResponse: async () => {
			const trainCacheEntry = await getEntity("train", trainTechId);
			return trainCacheEntry.data;
		}
	});
};

/**
 * get binder associated with provided train
 * @param {string} trainTechId train technical id
 * @return {Promise<import("axios").AxiosResponse<object>>} binder list
 */
const getTrainAssociatedBinders = async (trainTechId) => http.get(`${trainRootUrl}/${trainTechId}/binders`, {
	handleOfflineResponse: async () => {
		const storedBinders = await getEntityList("binder", { trainRef: trainTechId });
		return storedBinders.map(cacheEntry => cacheEntry.data);
	}
});

export {
	createTrain,
	copyTrain,
	updateTrain,
	fetchTrain,
	deleteTrainByTechId,
	getTrainByTechId,
	getTrainAssociatedBinders
};
