import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Editor as DraftJSEditor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import "./editor.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Editor = props => {
	const {
		value, onChange = content => {}, onBlur, name, label, toolbarConfig, disabled, className = "", focus
	} = props;

	const [ editorState, setEditorState ] = useState();

	const initWrapperClassName = (baseClassName) => classNames(baseClassName , className, { [`${baseClassName}--disabled`]: disabled });

	const handleChange = newEditorState => {
		setEditorState(newEditorState);
		const currentRawContent = convertToRaw(newEditorState.getCurrentContent());
		const plainTextLength = currentRawContent.blocks[0]?.text.length;
		if (plainTextLength !== 0) {
			onChange({ target: { name, value: currentRawContent } });
		} else {
			onChange({ target: { name, value: null } });
		}
	};

	const initEditorContent = () => {
		// initialize editor content
		let newEditorState = EditorState.createEmpty();
		if (value) {
			const initialEditorState = convertFromRaw(value);
			newEditorState = EditorState.createWithContent(initialEditorState);
		}
		// handle cursor position on focus
		if (focus) {
			setEditorState(EditorState.moveFocusToEnd(newEditorState));
		} else {
			setEditorState(newEditorState);
		}
	};

	const editorConfig = {
		editorState,
		toolbar: toolbarConfig,
		wrapperClassName: initWrapperClassName("editor__wrapper"),
		editorClassName: "editor",
		toolbarClassName: "editor__toolbar",
		localization: {
			locale: "fr"
		},
		onEditorStateChange: handleChange,
		onBlur
	};

	useEffect(initEditorContent, [ value, focus, disabled ]);

	return <DraftJSEditor {...editorConfig} readOnly={disabled} stripPastedStyles={true} placeholder={label}/>;
};

export default Editor;
