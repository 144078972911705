import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SubwayLineNumber } from "../../../index";
import "./filter-label-line.scss";

/**
 * Render the FilterTagLine components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.line
 * @returns {JSX.Element}
 */
const FilterLabelLine = props => {
	const { className, line } = props;
	const { t } = useTranslation();

	return (
		<span className={classnames(className, "filter-label-line")}>
			<SubwayLineNumber className="filter-label-line__icon" lineNumber={line}/>
			{t("theme:filter-tag.line", { line })}
		</span>
	);
};

FilterLabelLine.propTypes = {
	className: PropTypes.string,
	line: PropTypes.string.isRequired
};

export default FilterLabelLine;
