import React from "react";
import { useTranslation } from "react-i18next";
import TrainTroubleFormRadioButton from "../train-trouble-form-radio-button/train-trouble-form-radio-button";

import "./train-trouble-form-radio-button-with-input.scss";

const TrainTroubleFormRadioButtonWithInput = (props) => {
	const { title = "", step = "", substep = "", group = "", options = [], warning = false, onChange, resumeForm = {}, checkedValue } = props;
	const { t } = useTranslation();

	const speedValue = resumeForm?.[group]?.[substep];

	const clearInput = () => {
		document.getElementById("input-field").value = "";
	};

	const handleChange = async (event) => {
		clearInput();
		onChange(event);
	};

	return (
		<div className={"train-trouble-form-radio-button-input__container"}>
			<TrainTroubleFormRadioButton
				title={title}
				step={step}
				substep={substep}
				options={options}
				onChange={handleChange}
				warning={warning}
				checkedValue={checkedValue}/>
			<div>
				<input className={"train-trouble-form-radio-button-input__option-text"}
					   id="input-field"
					   aria-label="Enter character"
					   type="text"
					   value={options.includes(speedValue) ? "" : speedValue}
					   name={substep}
					   maxLength={6}
					   onChange={onChange}
					   placeholder={t(`process:process-rer-resume.${step}.other-restriction`)}/>
			</div>
		</div>
	);
};

export default TrainTroubleFormRadioButtonWithInput;
