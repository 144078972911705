import React,{ useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { resultsSheetTableLineA,resultsSheetTableLineB } from "../../../utils";
import classNames from "classnames";
import { IconWarningOutline } from "../../../../../../../theme/assets/img";
import TrainTroubleFormTrainElements
	from "../../../shared/train-trouble-form-train-elements/train-trouble-form-train-elements";

import "./results-sheet-table.scss";

const ResultsSheetTable = (props) => {
	const { formDataDamageLocation, line, resultsSheetFormData, field, handleChange, currentGroup, displayTitle = true, readOnly = false } = props;
	const { name: fieldName, key } = field;
	const { affected_equipment: affectedEquipment } = resultsSheetFormData?.valve_maneuver_group;
	const { t } = useTranslation();
	const [ resultsSheetTable, setResultsSheetTable ] = useState([]);
	const [ displayWarningFirstElement, setDisplayWarningFirstElement ] = useState(false);
	const [ displayWarningSecondElement, setDisplayWarningSecondElement ] = useState(false);
	const { direction, train_composition: trainComposition } = formDataDamageLocation || {};

	const isMultipleTrainComposition = trainComposition === "UM";
	// The following specific EMD items contain FI pulls, the 3 first and last EMD cars of each train elements are not concerned only for line B
	const fiPullsItemFirstElement = [ "first-element9EMD right", "first-element6EMD right", "first-element8EMD left", "first-element5EMD left" ];
	const fiPullsItemSecondElement = [ "second-element8EMD left", "second-element5EMD left", "second-element9EMD right", "second-element6EMD right" ];

	const initDividerClassname = () => classNames(
		"results-sheet__table-form__table__elements__element__divider",
		{
			"results-sheet__table-form__table__elements__element__divider--line-B": line === "B",
			"results-sheet__table-form__table__elements__element__divider--line-A": line === "A"
		}
	);

	const initCellClassname = (selectedCell, isEmdItem) => classNames(
		"results-sheet__table-form__table__elements__element__item",
		{
			"results-sheet__table-form__table__elements__element__item--emd-selected": selectedCell && isEmdItem,
			"results-sheet__table-form__table__elements__element__item--selected": selectedCell && !isEmdItem,
			"results-sheet__table-form__table__elements__element__item--item": !isEmdItem,
			"results-sheet__table-form__table__elements__element__item--emd": isEmdItem,
		}
	);

	const initItemWrapperClassname = (hasWhiteSpace) => classNames(
		"results-sheet__table-form__table__elements__element__item__wrapper",
		{
			"results-sheet__table-form__table__elements__element__item__wrapper--white-space": hasWhiteSpace,
		}
	);

	// if a cell is selected verify if the cell has to be added or deleted
	const handleClick = async (value) => {

		// verify if the cell has to be added or deleted to the affected equipment
		const isValueExists = affectedEquipment?.includes(value);

		// if the cell selected doesn't exist in the form add it
		if (!isValueExists) {
			const updatedAffectedEquipment = [ ...affectedEquipment, value ];
			handleChange({ target: { group: currentGroup, name: fieldName, value: updatedAffectedEquipment } });
		} else {
			// if the cell selected already exists in the form delete it from the car content
			const updatedAffectedEquipment = affectedEquipment.filter((selectedCell) => selectedCell !== value);
			handleChange({ target: { group: currentGroup, name: fieldName, value: updatedAffectedEquipment } });
		}
	};

	const generateCells = (item, indexCell, elementKey, isDivider) => {
		const hasWhiteSpace = /\s/g.test(item);
		const isEmdItem = item === "EMD";

		// give the element number, the cell index and the value as ID to make a difference between the same values for the entire element
		const cellId = `${elementKey}${indexCell}${item}`;

		// give the index cell to the value to be able to identify which item is selected
		const value = `${elementKey}${indexCell}${item}`;

		// find if the value exists in formData and if the train number has been completed, if not don't select the item
		const selected = affectedEquipment?.includes(value);

		return (
			<>
				<li className={initCellClassname(selected, isEmdItem)}>
					<label className={initItemWrapperClassname(hasWhiteSpace)} htmlFor={cellId}>
						<input
							className="results-sheet__table-form__table__elements__element__item__wrapper__input-field"
							id={cellId}
							name={fieldName}
							type="table"
							checked={selected}
							defaultValue={value}
							onClick={!readOnly ? () => handleClick(value) : () => {}}
						/>
						<span className={classNames("results-sheet__table-form__table__elements__element__item__wrapper__text", { "results-sheet__table-form__table__elements__element__item__wrapper__text--bold": isEmdItem })}>{item}</span>
					</label>
				</li>
				{isDivider && <div className={initDividerClassname()} /> }
			</>
		);
	};

	const generateTable = (element) => {
		const { element1, element2 } = element || {};
		const elementToDisplay = element1 || element2;
		const { key: elementKey, content = [] } = elementToDisplay || {};

		const contentToDisplay = direction === "right" ? [ ...content ].reverse() : content;

		return (
			<ul className="results-sheet__table-form__table__elements__element">
				{contentToDisplay?.map((item, itemIndex) => {
					// if there are two CP or CG CP next to each other, it means we have a new car beginning, then we add a divider to visualise it
					const nextValue = contentToDisplay[itemIndex + 1];
					const isDivider = item !== "EMD" && nextValue === item;
					return generateCells(item, itemIndex, elementKey, isDivider);
				})}
			</ul>
		);
	};

	useEffect(() => {
		if (line === "A"){
			setResultsSheetTable(resultsSheetTableLineA);
		} else if (line === "B") {
			setResultsSheetTable(resultsSheetTableLineB);
		}
	}, [ line, formDataDamageLocation ]);

	const isDisplayWarningInfo = () => {
		const warningInfoFirstElementLeftDirection = affectedEquipment.some(r=> fiPullsItemFirstElement.includes(`${r} left`));
		const warningInfoFirstElementRightDirection = affectedEquipment.some(r=> fiPullsItemFirstElement.includes(`${r} right`));
		const warningInfoSecondElementRightDirection = affectedEquipment.some(r=> fiPullsItemSecondElement.includes(`${r} right`));
		const warningInfoSecondElementLeftDirection = affectedEquipment.some(r=> fiPullsItemSecondElement.includes(`${r} left`));
		const isLineB = line === "B";
		setDisplayWarningFirstElement(isLineB ? (direction === "right" ? warningInfoFirstElementRightDirection : warningInfoFirstElementLeftDirection) : false);
		setDisplayWarningSecondElement(isLineB ? (direction === "right" ? warningInfoSecondElementLeftDirection : warningInfoSecondElementRightDirection) : false);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(isDisplayWarningInfo, [ resultsSheetFormData, affectedEquipment ]);

	const generateElements = () => {

		return (
			<div className="results-sheet__table-form__table__elements">
				<TrainTroubleFormTrainElements direction={direction} trainCompositionForm={trainComposition} readOnly={readOnly} group={"valve-maneuver"}>
					<div className="results-sheet__table-form__table__elements__element-wrapper">
						{generateTable(resultsSheetTable[0])}
						{displayWarningFirstElement && <div className="results-sheet__table-form__table__elements__element-wrapper__warning">
							<IconWarningOutline role="img" aria-hidden="true" focusable="false"/>
							<span className="results-sheet__table-form__table__elements__element-wrapper__warning__title">{t("process:process-rer-resume.results-sheet.warning-info")}</span>
						</div>}
					</div>
					{isMultipleTrainComposition &&
					<div className="results-sheet__table-form__table__elements__element-wrapper">
						<span className="results-sheet__table-form__table__elements__element-wrapper__second-title">{t("process:process-rer-resume.train-elements.second-element")}</span>
						{generateTable(resultsSheetTable[1])}
						{displayWarningSecondElement && <div className="results-sheet__table-form__table__elements__element-wrapper__warning">
							<IconWarningOutline role="img" aria-hidden="true" focusable="false"/>
							<span className="results-sheet__table-form__table__elements__element-wrapper__warning__title">{t("process:process-rer-resume.results-sheet.warning-info")}</span>
						</div>}
					</div>
					}
				</TrainTroubleFormTrainElements>
			</div>
		);
	};

	return (
		<div className="results-sheet__table-form">
			{displayTitle && <div className="process-results-sheet__table-form__title-wrapper">
        	    <span className="process-results-sheet__table-form_title-wrapper__title">
           		    {t(`process:process-rer-resume.results-sheet.${key}`)}
				</span>
			</div>}
			{generateElements()}
		</div>
	);
};

export default ResultsSheetTable;
