import React from "react";
import classNames from "classnames";
import "./dropdown-menu.scss";
import { ReactComponent as IconProfile } from "../../assets/img/icon-profile.svg";
import { Button } from "../../index";

const DropdownMenu = props => {
	const { className, items = [], right, visible, setVisible } = props;

	const initClassName = (baseClassName) => classNames(baseClassName, className, { [`${baseClassName}--open`]: visible });

	return (
		<>
			<div className={initClassName("dropdown-menu")}>
				<Button
					variant="minimal"
					className="dropdown-menu__button"
					icon={IconProfile}
					onClick={() => setVisible(!visible)}
				/>
				<ul className={classNames("dropdown-menu__list", { "dropdown-menu__list--right": right })}>
					{items}
				</ul>
			</div>
			{visible && <div className="dropdown-menu--overlay" onClick={() => setVisible(false)}/>}
		</>
	);
};

export default DropdownMenu;
