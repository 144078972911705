import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { PopupConfirm, ErrorMessage } from "../../../../../theme";
import { checkFormError } from "../../../../../shared";
import { fetchBinderListById, updateBinder } from "../../../binder.services";
import FormBinder from "../form-binder/form-binder";
import {
	getFormBinderFieldMessageKey,
	validatePopupEditBinderForm
} from "../../../utils/validate-popup-binder-form";
import "./popup-binder-edit-title.scss";

/**
 * Render the PopupBinderEditTitle component
 * @param {object} props
 * @param {function} props.onSubmitSuccess Callback called after successful submit
 * @param {object} props.binder The current binder information
 * @param {object} props.popupControl
 * @param {object} props.binderTrain Boolean to indicate if current binder is type of train
 * @returns {JSX.Element}
 */
const PopupBinderEditTitle = props => {
	const { onSubmitSuccess, binder, popupControl, binderTrain } = props;
	const { t } = useTranslation();

	const [ formData, setFormData ] = useState({});
	const [ existingBinderList, setExistingBinderList ] = useState([]);
	const [ networkError, setNetworkError ] = useState(false);

	const getCurrentBinder = useCallback((binderList) => binderList.find(({ tech_id }) => tech_id === binder.tech_id) || {}, [ binder.tech_id ]);

	const formError = validatePopupEditBinderForm(formData, getCurrentBinder(existingBinderList), existingBinderList);
	const formErrorMessage = getFormBinderFieldMessageKey(formError);

	const fetchExistingBinderById = useCallback(() => {
		fetchBinderListById(binder.id).then(response => {
			setNetworkError(false);
			const binderList = response?.data || [];
			setExistingBinderList(binderList);

			const currentBinder = getCurrentBinder(binderList);

			// Setup current binder from list
			setFormData(oldFormData => ({
				...oldFormData,
				id: currentBinder.id,
				subtitle: currentBinder.subtitle,
				title: currentBinder.title,
				color: currentBinder.color,
				abbreviation: currentBinder.abbreviation,
				type: currentBinder.type,
				// enable station and stationType field
				lineNumber: currentBinder?.associated_line || [],
				stationType: currentBinder.station_type,
				station: currentBinder.station_tech_id
			}));

		}).catch((error) => {
			console.error(error);
			setNetworkError(true);
		});
	}, [ binder.id, getCurrentBinder ]);

	const getBinderList = () => {
		if (popupControl.visible) {
			fetchExistingBinderById();
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleConfirm = () => {
		if (!checkFormError(formError)) {
			const { station: station_tech_id, stationType: station_type, lineNumber: associated_line, ...binderData } = formData;
			updateBinder(binder?.tech_id, { ...binderData, station_tech_id, station_type, associated_line }).then(response => {
				setNetworkError(false);
				onSubmitSuccess(response?.data);
			}).catch((error) => {
				console.error(error);
				setNetworkError(true);
			});
		}

		// Reset form after data's submission
		setFormData({});
	};

	const handleCancel = () => {
		setFormData({});
	};

	useEffect(getBinderList, [ binder.id, popupControl.visible, fetchExistingBinderById ]);

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={binderTrain ? t("binder:popup.edit-binder-train.title") : t("binder:popup.edit-title.title")}
			className="popup-binder-edit-title__wrapper"
			onConfirm={handleConfirm}
			onClose={handleCancel}
			onCancel={handleCancel}
			ctaConfirmLabel={binderTrain ? "binder:popup.edit-binder-train.cta-confirm-label" : "binder:popup.edit-title.cta-confirm-label"}
			ctaConfirmDisabled={checkFormError(formError)}>
			<form className="popup-binder-edit-title">
				<FormBinder form={{ ...formData, id: binder?.id }} validation={formError} errorMessage={formErrorMessage}
				            onChange={handleChange} update train={binderTrain} />
				{networkError &&
				<ErrorMessage className="popup-binder-edit-title__network-error">{t("error.default")}</ErrorMessage>}
			</form>
		</PopupConfirm>
	);
};

PopupBinderEditTitle.propTypes = {
	onSubmitSuccess: PropTypes.func,
	binder: PropTypes.shape({
		id: PropTypes.string,
		tech_id: PropTypes.string,
	}),
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool
	}),
	binderTrain: PropTypes.bool
};

export default PopupBinderEditTitle;
