import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PopupConfirm, SquareColorIcon } from "../../../../../theme";
import { deleteBinderThemeByTechId } from "../../../binder-theme.services";
import resolveThemeColor from "../../../utils/resolve-theme-color";
import "./popup-delete-theme.scss";

/**
 * Render the popup to delete theme of binder
 * @param {object} props
 * @param {object} props.popupControl
 * @param {object} props.binder
 * @param {object} props.theme
 * @param {function} props.handleClose
 * @returns {JSX.Element}
 */
const PopupDeleteTheme = props => {
	const { popupControl, binder, theme = {}, handleClose } = props;
	const { tech_id: themeTechId, title, color } = theme;

	const { t } = useTranslation();

	const handleDelete = () => {
		if (binder?.tech_id && themeTechId) {
			deleteBinderThemeByTechId(binder.tech_id, themeTechId).then(() => {
				handleClose();
				popupControl.hide();
			});
		}
	};

	return (
		<PopupConfirm
			className="popup-delete-theme__wrapper"
			popupControl={popupControl}
			onConfirm={handleDelete}
			title={t("binder:popup.delete-theme.title")}
			ctaConfirmLabel="binder:popup.delete-theme.cta-confirm-label"
		>
			<div className="popup-delete-theme">
				<h1 className="popup-delete-theme__title">{t("binder:popup.delete-theme.text.title")}</h1>
				<p className="popup-delete-theme__question">{t("binder:popup.delete-theme.text.question")}</p>
				<div className="popup-delete-theme__theme">
					<SquareColorIcon color={resolveThemeColor(color, binder?.color)}/>
					<span>{title}</span>
				</div>
				<p className="popup-delete-theme__note">{t("binder:popup.delete-theme.text.note")}</p>
			</div>
		</PopupConfirm>

	);
};

PopupDeleteTheme.propTypes = {
	binder: PropTypes.object.isRequired,
	theme: PropTypes.shape({
		tech_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
	}).isRequired,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired
	}).isRequired,
	handleClose: PropTypes.func
};

export default PopupDeleteTheme;
