import React from "react";
import { useTranslation } from "react-i18next";
import { PopupConfirm } from "../../../../../../theme";
import "./popup-abort-process.scss";

const PopupAbortProcess = props => {
	const { popupControl, onConfirm } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t("process:popup.abort-process.title")}
			onConfirm={onConfirm}
		>
			<div className="popup-abort-process">
				<p className="popup-abort-process__text">{t("process:popup.abort-process.text")}</p>
			</div>
		</PopupConfirm>
	);
};

export default PopupAbortProcess;
