/**
 * Parse the provided content as JSON string if it's a string, else return the provided content without change
 *
 * Return false on JSON parse error
 * @param {string | any } stringJSON The content to parse
 * @returns {any|boolean}
 */
const parseJsonString = (stringJSON) => {
	try {
		return typeof stringJSON === "string" ? JSON.parse(stringJSON) : stringJSON;
	} catch {
		return false;
	}
};

export default parseJsonString;
