import { requiredFieldValidator, hasFieldError } from "../../../shared";

/**
 * @typedef ProcessTrainFormData
 * @property {string} position_type
 * @property {string} position_info
 * @property {string} track
 * @property {string} description
 * @property {string} train_tech_id
 * @property {string} binder_tech_id
 * @property {string} sheet_tech_id
 */

/**
 * @typedef ProcessTrainFormError
 * @property {string[]} position_type
 * @property {string[]} position_info
 * @property {string[]} track
 * @property {string[]} description
 * @property {string[]} train_tech_id
 * @property {string[]} binder_tech_id
 */

/**
 * Validate PopupCreateTrain form data
 * @param {ProcessTrainFormData} form form data to validate
 * @param {trainList[]} trainList list of existing trains
 * @returns {ProcessTrainFormError}
 */
const validatePopupCreateProcessTrainForm = (form, trainList) => {
	const {
		position_type, position_info, track, description, train_tech_id, binder_tech_id
	} = form;

	// check mandatory or optional track field
	const trackValidation = position_type === "other" ? [] : requiredFieldValidator(track);

	// check track length under 10 characters
	if (track?.length > 10) {
		trackValidation.push("length");
	}

	// check description length under 50 characters
	const descriptionValidation = (description?.length > 50) ? [ "length" ] : [];

	return {
		position_type: requiredFieldValidator(position_type),
		position_info: requiredFieldValidator(position_info),
		track: trackValidation,
		description: descriptionValidation,
		train_tech_id: requiredFieldValidator(train_tech_id),
		binder_tech_id: requiredFieldValidator(binder_tech_id),
	};
};

const getFormProcessTrainFieldMessageKey = (formError) => ({
	position_type: hasFieldError("existing", formError?.id) ? "train:popup.form.error.id-existing" : "",
	position_info: "",
	track: "",
	description: "",
	train_tech_id: "",
	binder_tech_id: "",
	sheet_tech_id: ""
});

const validatePopupEditProcessTrain = (form) => {
	const { binder_tech_id, sheet_tech_id } = form;

	return {
		train_tech_id: [],
		binder_tech_id: requiredFieldValidator(binder_tech_id),
		sheet_tech_id: sheet_tech_id ? requiredFieldValidator(sheet_tech_id) : [],
		description: [],
		position_type: [],
		position_info: [],
		track: [],
	};
};

export {
	validatePopupCreateProcessTrainForm,
	getFormProcessTrainFieldMessageKey,
	validatePopupEditProcessTrain
};
