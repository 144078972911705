import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
import { useDebouncedAction } from "../../../../../../../../shared";
import { useSheetContentContext } from "../../../../../../context/sheet-content-context";
import { ToggleSwitch } from "../../../../../../../../theme";
import { fetchSheetByBinder, fetchSheetById } from "../../../../../../sheet.services";
import { addSheetsTitleRaw } from "../../../../../../utils/add-sheet-title-raw-utils/add-sheet-title-raw-utils";
import MultiLinkBlock from "./components/multi-link-block/multi-link-block";
import "./multi-link-template-form.scss";


/**
 * Render the MultiLinkTemplateForm component
 * @param {object} props
 * @param {object} props.content
 * @param {any[]} props.content.items
 * @param {object} props.binderData The current binder information
 * @param {string} props.binderData.id
 * @param {string} props.binderData.type
 * @param {string} props.isTrainBinder
 * @returns {JSX.Element}
 */
const MultiLinkTemplateForm = (props) => {
	const { content, binderData, isTrainBinder, activeLocation = {} } = props;
	const { binderId, sheetId } = useParams();

	const isInGabaritParent = !!activeLocation?.parentId;

	const { t } = useTranslation();

	const [ binderSheets, setBinderSheets ] = useState();
	const [ currentSheet, setCurrentSheet ] = useState({});
	const { setCurrentNodeContent, isAtEndHistory, updateDelay } = useSheetContentContext();
	const debouncedSetCurrentNodeContent = useDebouncedAction(setCurrentNodeContent, updateDelay);

	const [ redirectBlocks, setRedirectBlocks ] = useState([]);
	const [ hasImage, setHasImage ] = useState(false);

	const firstInputRef = useRef();

	const getCurrentSheet = () => {
		fetchSheetById(binderId, sheetId).then(response => {
			setCurrentSheet(response?.data);
		});
	};

	const createNewBlock = () => ({ text: "", sheetNumber: "", sheetNumberSelected: false, id: uuid() });

	// Get sheet from same binder for autocompletion
	const initBinderSheets = () => {
		if (currentSheet.sheet_id) {
			const param = {
				"-type": [ "appendix" ]
			};
			if (binderData?.type === "man") {
				param.signalTechId = currentSheet.signal_tech_id;
				param["-sheet_id"] = currentSheet.sheet_id;
			}
			fetchSheetByBinder(binderId, param)
				.then((response) => {
					if (response?.data) {
						const sheetWithPlainTitle = addSheetsTitleRaw(response.data);
						setBinderSheets(sheetWithPlainTitle);
						return sheetWithPlainTitle;
					}
				});
		}
	};

	// send data to context
	const setNodeContent = (newRedirectBlocks, setHistory) => {
		setRedirectBlocks(newRedirectBlocks);
		debouncedSetCurrentNodeContent({ items: newRedirectBlocks, hasImage: hasImage }, setHistory);
	};

	const handleChange = (position) => (blockContent, setHistory) => {
		redirectBlocks[position] = { ...blockContent, binder_id: binderData?.id };
		setNodeContent([ ...redirectBlocks ], setHistory);
	};

	const handleHasImageChange = (e) => {
		const { checked: isChecked } = e.target;
		setHasImage(isChecked);
		const itemsReset = [ createNewBlock(), createNewBlock() ];
		setRedirectBlocks(itemsReset);
		debouncedSetCurrentNodeContent({ items: itemsReset, hasImage: isChecked }, true);
	};

	const handleDeleteBlock = (index) => () => {
		redirectBlocks.splice(index, 1);
		setNodeContent([ ...redirectBlocks ], false);
	};

	const generateRedirectBlock = (redirectBlock, index) => {
		return (
			<MultiLinkBlock
				key={redirectBlock.id}
				sheetList={binderSheets}
				hasFocus={index === 0 && isAtEndHistory ? firstInputRef : undefined}
				block={redirectBlock}
				position={index}
				onDelete={handleDeleteBlock(index)}
				focusFirstField={index === 0}
				isAtEndHistory={isAtEndHistory}
				onChange={handleChange(index)}
				showDelete={redirectBlocks.length > 2}
				hasImage={hasImage}
				isTrainBinder
			/>
		);
	};

	useEffect(initBinderSheets, [ binderId, sheetId, binderData?.type, currentSheet ]);

	const reloadForm = () => {
		if (!content?.items){
			const initContent = [ createNewBlock(), createNewBlock() ];
			// Initialize empty node content
			setRedirectBlocks(initContent);
			setNodeContent(initContent, false);
		} else {
			setHasImage(content.hasImage);
			setRedirectBlocks(content.items);
		}
	};

	// Ignore setNodeContent dependency to avoid infinite render loop
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(reloadForm, [ content ]);

	useEffect(getCurrentSheet, [ binderId, sheetId ]);

	return (
		<div className="multi-link-template-form">
			{(isTrainBinder && !isInGabaritParent) &&
			<div className={"multi-link-template-form__image"}>
				<div className="multi-link-template-form__image__title">
					{t("sheet:sheet-details.template.multipleLink.image-title")}
				</div>
				<div className="multi-link-template-form__image__switch">
					{t("sheet:sheet-details.template.multipleLink.image-switch")}
				</div>
				<ToggleSwitch isChecked={hasImage} handleOnChange={handleHasImageChange}/>
			</div>
			}
			{binderSheets && Array.isArray(redirectBlocks) && redirectBlocks.length > 0 && redirectBlocks.map(generateRedirectBlock)}
			<button className="multi-link-template-form__add-node" type="button"
			        onClick={() => setNodeContent([ ...redirectBlocks, createNewBlock() ], true)}>
				{t("sheet:sheet-details.template.multipleLink.submit")}
			</button>
		</div>
	);
};

MultiLinkTemplateForm.propTypes = {
	content: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.any) }),
	binderData: PropTypes.shape({ id: PropTypes.string, type: PropTypes.string.isRequired }).isRequired,
	isTrainBinder: PropTypes.bool.isRequired
};

export default MultiLinkTemplateForm;
