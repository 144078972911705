import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { IconWrapper } from "../../../../../../theme";
import { ReactComponent as IconCheck } from "../../../../assets/icon-check.svg";
import "./signal-title-selector.scss";

/**
 * Render Signal title selector for binder man signal detail page
 * @param {object} props
 * @param {string} props.className
 * @param {string[]} props.signalTitleList
 * @param {string} props.value
 * @param {string} props.name
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const SignalTitleSelector = (props) => {
	const { className, name, signalTitleList = [], value = {}, onChange } = props;

	const renderSignalTitleChoice = (signal) => {
		const { tech_id: techId, title } = signal;
		const key = `${techId}${title}`;
		const checked = value.title === title;

		return (
			<li key={key}
			    className={classnames("signal-title-selector__choice", { "signal-title-selector__choice--checked": checked })}>
				<label htmlFor={key} className="choice__wrapper">
					<input className="choice__input" id={key} name={name} type="radio" checked={value.title === title}
					       onChange={() => onChange(signal)}/>
					<span className="choice__label">{title}</span>
					{checked && <IconWrapper className="choice__check" Component={IconCheck}/>}
				</label>
			</li>
		);
	};

	return (
		<ul className={classnames("signal-title-selector", className)}>
			{signalTitleList.map(renderSignalTitleChoice)}
		</ul>
	);
};

SignalTitleSelector.propTypes = {
	className: PropTypes.string,
	signalTitleList: PropTypes.arrayOf(PropTypes.shape({
		tech_id: PropTypes.string,
		title: PropTypes.string
	})).isRequired,
	value: PropTypes.shape({
		tech_id: PropTypes.string,
		title: PropTypes.string
	}),
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired
};

export default SignalTitleSelector;
