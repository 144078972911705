import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useDebouncedAction } from "../../../../../../../../shared";
import { useSheetContentContext } from "../../../../../../context/sheet-content-context";
import { Editor } from "../../../../../../../../theme";
import { defaultEditorToolbarConfig } from "../../../../../..";
import { stringifyWithoutKey } from "../../../../../../context/sheet-content-context-utils";
import ImageForm from "../../image-form/image-form";
import "./question-template-form.scss";

const QuestionTemplateForm = (props) => {
	const { content } = props;
	const { setCurrentNodeContent, activeLocation, updateDelay } = useSheetContentContext();
	const initQuestionSide = () => ({
		items: [],
		"end-item": {
			type: "next",
			id: uuid()
		}
	});
	const { t } = useTranslation();

	const [ form, setForm ] = useState(null);
	const [ initForm, setInitForm ] = useState({ text: "", note: "" });
	const [ autoFocus, setAutoFocus ] = useState(true);

	const isContentChange = (initContent = {}, newContent = {}) => {
		// Handle draftJS content or null (for empty content)
		const isTextChange = (newContent?.text || newContent?.text === null) && (stringifyWithoutKey(newContent.text) !== stringifyWithoutKey(initContent.text));
		const isNoteChange = (newContent?.note || newContent?.note === null) && (stringifyWithoutKey(newContent.note) !== stringifyWithoutKey(initContent.note));
		return isTextChange || isNoteChange;
	};

	const setNodeContent = useDebouncedAction((newContent = {}, setHistory) => {
		const mergedContent = content || {};
		if (!content?.yes) mergedContent.yes = initQuestionSide();
		if (!content?.no) mergedContent.no = initQuestionSide();

		if (newContent?.text || newContent?.text === null) mergedContent.text = newContent.text;
		if (newContent?.note || newContent?.note === null) mergedContent.note = newContent.note;
		const isChange = isContentChange(initForm, newContent);

		if (isChange){
			const { text, note, yes, no, images } = mergedContent;
			setCurrentNodeContent({ text, note, yes, no, images }, setHistory);
			setForm({ text, note, yes, no, images });
		}
	}, updateDelay);

	const handleChange = (event) => {
		let { value, name } = event.target;
		if (value) {
			value = JSON.stringify(value);
		}
		setNodeContent({ [name]: value }, false);
	};

	const reloadForm = () => {
		setNodeContent({}, false);
		const { text, note } = content || {};
		if (text || note){
			setForm({ text, note });
			setInitForm((previousInitForm) => {
				const newIniForm = { ...previousInitForm };
				if (!initForm.text){
					newIniForm.text = JSON.parse(text || null);
				}
				if (!initForm.note){
					newIniForm.note = JSON.parse(note || null);
				}

				return newIniForm;
			});
			setAutoFocus(false);
		}
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(reloadForm, [ content, activeLocation.id ]);

	return (
		<div className="question-template-panel">
			<span className="question-template-panel__label">{t("sheet:sheet-details.template.question.text")}</span>
			<Editor
				className="question-template-panel__editor"
				name="text"
				value={initForm?.text || null}
				focus={autoFocus}
				toolbarConfig={defaultEditorToolbarConfig}
				onChange={handleChange}
				onBlur={() => setNodeContent(form, true)}/>
			<span className="question-template-panel__label">{t("sheet:sheet-details.template.question.note")}</span>
			<Editor
				className="question-template-panel__editor"
				name="note"
				value={initForm?.note || null}
				toolbarConfig={defaultEditorToolbarConfig}
				onChange={handleChange}
				onBlur={() => setNodeContent(form, true)}/>
			<ImageForm content={content}/>
		</div>
	);
};

export default QuestionTemplateForm;
