import React from "react";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import DeleteTemplateButton from "../../delete-template-button/delete-template-button";
import { useSheetModeContext } from "../../../../../../context/sheet-mode-context";
import "./new-template-view.scss";

const NewTemplateView = (props) => {
	const { template, location } = props;

	const { readOnly } = useSheetModeContext();

	if (readOnly) {
		return null;
	}

	return (
		<TemplateWrapper
			id={template.id}
			template={template}
			location={location}
			className="new-template">
			<DeleteTemplateButton template={template} location={location}/>
		</TemplateWrapper>
	);
};

export default NewTemplateView;

