import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SprPage } from "../../../../theme";
import ProcessTrainSubheader from "../../components/process-train-subheader/process-train-subheader";
import ProcessTrainBreadcrumbSubheader from "../../components/process-train-breadcrumb-subheader/process-train-breadcrumb-subheader";
import { searchBinder, fetchBinderByTechId } from "../../../binder/binder.services";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSheetReadContext } from "../../../sheet/pages/railway-sheet-view/components/sheet-read-context/sheet-read-context";
import { binderTrainTypeList } from "../../../binder/utils/binder-type";
import { useHistory } from "react-router";
import { buildTrainProcessBinderDetail } from "../../../../shared";
import TrainBinder from "../../../train/pages/train-list/components/train-list-content/components/train-binder/train-binder";

import "./process-binder-list.scss";

/**
 * Render Process's Train Binder List
 * @returns {JSX.Element}
 */
const ProcessBinderList = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { processId } = useParams();
	const { pathname } = useLocation();
	const [ binderList, setBinderList ] = useState([]);
	const [ loading, setLoading ] = useState(true);
	const { currentProcess, currentTrain } = useSheetReadContext();
	// processes created directly with a train-tech-id
	const initBindersWithTrainTechId = async () => {
		const params = {
			id: [ currentTrain.binder_auto, currentTrain.binder_driver, currentTrain.binder_officer ].filter(n => !!n),
			status: [ "published" ],
			type: binderTrainTypeList,
		};
		const { data: bindersFound = [] } = (await searchBinder(params)) || {};
		return bindersFound;
	};

	// process created via "i don't know my train" => no train tech id
	const initBinderWithoutTrainTechID = async (binderTechId) => {
		const { data: binder = {} } = (await fetchBinderByTechId(binderTechId)) || {};
		return binder;
	};

	const loadBinders = async (processBinderTechId) => {
		let binderRedirectTechId = "";
		// redirect to process binder detail directly in case of single binder or train process without traintechid
		let redirect = false;
		try {
			if (currentTrain?.id){
				const binders = await initBindersWithTrainTechId();
				redirect = binders?.length === 1;
				if (redirect) {
					binderRedirectTechId = binders?.[0]?.tech_id;
				} else {
					setBinderList(binders);
				}
			} else if (processBinderTechId) {
				const binder = await initBinderWithoutTrainTechID(processBinderTechId);
				redirect = true;
				binderRedirectTechId = binder?.tech_id;
			}

			if (redirect) {
				const link = buildTrainProcessBinderDetail({ processId, binderTechnicalId: binderRedirectTechId }, "spr", true);
				history.replace(link);
			}
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const hasTrainAndTrainTechId = currentProcess?.train_tech_id && currentTrain?.id;
		const isMaterialOnly = currentProcess?.tech_id && !currentProcess?.train_tech_id;
		if (currentProcess?.binder_tech_id && (isMaterialOnly || hasTrainAndTrainTechId)){
			loadBinders(currentProcess?.binder_tech_id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ currentProcess?.tech_id, currentTrain?.id ]);

	const renderBinderLink = (binder) => {
		const { id, title, tech_id: binderTechnicalId, type } = binder;
		const uri = buildTrainProcessBinderDetail({ processId, binderTechnicalId }, "spr", pathname.includes("read"));

		return (
			<li key={binderTechnicalId} className="process-binder-list__content__list__binder">
				<Link className="binder__link" to={uri}>
					<>
						{(currentTrain?.binder_auto === id || type === "train-auto") &&
							<TrainBinder label={t("process:process-binder-list.binder-auto")} type={"auto"} className="binder__train-type"/>}
						{(currentTrain?.binder_driver === id || type === "train-driver") &&
							<TrainBinder label={t("process:process-binder-list.binder-driver")} type={"driver"} className="binder__train-type"/>}
						{(currentTrain?.binder_officer === id || type === "train-officer") &&
							<TrainBinder label={t("process:process-binder-list.binder-officer")} type={"officer"} className="binder__train-type"/>}
						<span className="binder__train-id">{title}</span>
						<span className="binder__title">{id}</span>
					</>

				</Link>
			</li>
		);
	};

	const SubHeader = <>
		<ProcessTrainSubheader/>
		<ProcessTrainBreadcrumbSubheader/>
	</>;

	return (
		<SprPage className="process-binder-list" subheader={SubHeader}>
			{!loading &&
				<div className="process-binder-list__content">
					<div className="process-binder-list__content__title">
						{t("process:process-binder-list.title")}
					</div>
					<ul className="process-binder-list__content__list">
						{binderList.map(renderBinderLink)}
					</ul>
				</div>
			}
		</SprPage>
	);
};



export default ProcessBinderList;
