import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import TrainElementTable from "../../../damage-location/components/train-element-table/train-element-table";

const DamageLocationSummary = (props) => {
	const { line, damageLocation, tableField, isProcessRecap = false } = props;
	const { mission_code: missionCode } = damageLocation || {};

	const { t } = useTranslation();

	return (
		<>
			<div className={"rer-resume-summary__substep"}>
				<TrainElementTable fieldName={tableField?.name} damageFormData={damageLocation} setDamageFormData={() => {}} line={line} areParentsSelected={true} readOnly={true}/>
			</div>
			{missionCode && <div className={"rer-resume-summary__substep--secondary"}>
				<span className={classNames("rer-resume-summary__substep__title--secondary", { "rer-resume-summary__substep__title--secondary--lighter": isProcessRecap })}>
					{t("process:process-rer-resume.damage-location.mission-code")}
				</span>
				<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
					{missionCode}
				</span>
			</div>}
		</>
	);
};

export default DamageLocationSummary;
