import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../../theme";
import { IconCheckWithCircle, IconBackArrow } from "../../../../../../../theme/assets/img";

import "./results-sheet-card.scss";

const ResultsSheetCard = (props) => {
	const { setResultsSheetStep, associatedStep, name } = props;
	const { t } = useTranslation();

	const handleBackClick = () => {
		setResultsSheetStep(associatedStep);
	};

	return (
		<button className="results-sheet__card" onClick={handleBackClick}>
			<div className="results-sheet__card__wrapper">
				<Button
					type="button"
					variant="minimal"
					onClick={handleBackClick}
					icon={IconBackArrow}
					iconBig={true}
					className="results-sheet__card__wrapper__button"
				/>
				<span className="results-sheet__card__wrapper__title">{t(`process:process-rer-resume.results-sheet.${name}`)}</span>
			</div>
			<IconCheckWithCircle role="img" aria-hidden="true" focusable="false" class="results-sheet__card__wrapper__icon"/>
		</button>
	);
};

export default ResultsSheetCard;
