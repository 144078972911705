import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { buildUserName } from "../../index";
import { offlineUser } from "../../../config/offline";
import "./user-name-label.scss";

/**
 * Render the formatted user name
 * @param {object} props
 * @param {string} [props.className]
 * @param {{ firstname: string, lastname: string, tech_id: string|null, id: string }} [props.user]
 * @return {JSX.Element}
 * @constructor
 */
const UserNameLabel = (props) => {
	const { className = "", user = {} } = props;
	const { t } = useTranslation();

	const renderUserName = () => {
		const { firstname, lastname, tech_id: techId, id } = user;
		if (techId === offlineUser.tech_id){
			return t("user:user-offline");
		}
		else if (!id) {
			return t("user:user-deleted");
		}
		return buildUserName(firstname, lastname, id);
	};

	return <span className={classNames("user-name-label", className)}>{renderUserName()}</span>;
};

UserNameLabel.propTypes = {
	className: PropTypes.string,
	user: PropTypes.shape({
		firstname: PropTypes.string,
		lastname: PropTypes.string,
		id: PropTypes.string,
		tech_id: PropTypes.string,
	})
};

export default UserNameLabel;
