import { useState, useEffect } from "react";

/**
 * Custom hook to handle filter and sort saved in localstorage
 * @param {string} storageName The localstorage Key where to store data
 * @param {{ role: string[], associatedLine: string[], search: string}} defaultFilter The default filter value
 * @param {{ sortBy: string, sortOrder: "asc" | "desc"}} defaultSort The default sort value
 * @returns {{filter: {}, resetFilter: resetFilter, updateFilter: updateFilter, sort: {}, updateSort: updateSort}}
 */
const useStoredFilter = (storageName, defaultFilter = {}, defaultSort = {}) => {
	const [ filter, setFilter ] = useState();
	const [ sort, setSort ] = useState();

	const initializeFromStorage = () => {
		const storedState = localStorage.getItem(storageName);
		const parsedStoredState = JSON.parse(storedState || "{}");
		const { sort: storedSort = defaultSort, filter: storedFilter = defaultFilter } = parsedStoredState;
		setFilter(storedFilter);
		setSort(storedSort);
	};

	/**
	 * If current is deeply equal to previous get previous, else current
	 * @param previous
	 * @param current
	 */
	const getUpdatedObject = (previous, current) => {
		const previousStr = JSON.stringify(previous);
		const currentStr = JSON.stringify(current);
		return previousStr === currentStr ? previous : current;
	};

	const updateFilter = (name, value) => {
		setFilter((previousFilter = defaultFilter) => {
			const newFilter = { ...previousFilter, [name]: value };
			return getUpdatedObject(previousFilter, newFilter);
		});
	};

	const updateSort = (sortBy, sortOrder) => {
		setSort((previousSort = defaultSort) => {
			const newSort = { sortBy, sortOrder };
			return getUpdatedObject(previousSort, newSort);
		});
	};

	const resetFilter = () => {
		setFilter(defaultFilter);
		setSort(defaultSort);
	};

	useEffect(() => {
		// Save filter and sort on change
		if (filter || sort) {
			localStorage.setItem(storageName, JSON.stringify({ filter, sort }));
		}
	}, [ filter, sort, storageName ]);

	useEffect(initializeFromStorage, [ storageName, defaultSort, defaultFilter ]);

	return { filter: filter || defaultFilter, sort: sort || defaultSort, updateFilter, updateSort, resetFilter };
};

export default useStoredFilter;
