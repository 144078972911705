import TextTemplateForm from "../components/template/template-form/text-template-form/text-template-form";
import StepTemplateForm from "../components/template/template-form/step-template-form/step-template-form";
import QuestionTemplateForm from "../components/template/template-form/question-template-form/question-template-form";
import TerminationTemplateForm from "../components/template/template-form/termination-template-form/termination-template-form";
import ResumeTemplateForm from "../components/template/template-form/resume-template-form/resume-template-form";
import MultiLinkTemplateForm from "../components/template/template-form/multi-link-template-form/multi-link-template-form";
import SimpleLinkTemplateForm from "../components/template/template-form/simple-link-template-form/simple-link-template-form";
import MultipleChoiceTemplateForm from "../components/template/template-form/multiple-choice-template-form/multiple-choice-template-form";
import TableTemplateForm from "../components/template/template-form/table-template-form/table-template-form";
import PccInstructionTemplateForm from "../components/template/template-form/pcc-instruction-template-form/pcc-instruction-template-form";
import OfficerInterventionTemplateForm from "../components/template/template-form/officer-intervention-template-form/officer-intervention-template-form";
import MRFInterventionTemplateForm from "../components/template/template-form/mrf-intervention-template-form/mrf-intervention-template-form";

/**
 * Generate dropdown available template config for provided scope
 * @param {"pcc"|"train"} scope
 * @example
 * Each entry describe template availability :
 *  When list = true -> template available in main template list
 *  When questionList = true -> template available as question sub template
 *  When questionEnd = true -> template available as question end sub template
 *  When multipleChoiceList = true -> template available as multipleChoice sub template
 */
const generateConfigByScope = (scope) => {
	const showPcc = scope === "pcc";
	const showTrain = scope === "train";

	const showAll = showPcc || showTrain;
	return {
		note: {
			list: showAll,
			questionList: showAll,
			questionEnd: false,
			multipleChoiceList: showAll,
			formComponent: TextTemplateForm
		},
		action: {
			list: showAll,
			questionList: showAll,
			questionEnd: false,
			multipleChoiceList: showAll,
			formComponent: TextTemplateForm
		},
		call: {
			list: showAll,
			questionList: showAll,
			questionEnd: false,
			multipleChoiceList: showAll,
			formComponent: TextTemplateForm
		},
		systemEffect: {
			list: showPcc,
			questionList: showPcc,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: TextTemplateForm
		},
		pccInstruction: {
			list: showTrain,
			questionList: showTrain,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: PccInstructionTemplateForm
		},
		officerIntervention: {
			list: showTrain,
			questionList: showTrain,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: OfficerInterventionTemplateForm
		},
		mrfIntervention: {
			list: showTrain,
			questionList: showTrain,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: MRFInterventionTemplateForm
		},
		step: {
			list: showPcc,
			questionList: false,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: StepTemplateForm
		},
		question: {
			list: showAll,
			questionList: false,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: QuestionTemplateForm
		},
		resume: {
			list: showAll,
			questionList: showAll,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: ResumeTemplateForm
		},
		multipleChoice: {
			list: showPcc,
			questionList: false,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: MultipleChoiceTemplateForm
		},
		table: {
			list: showPcc,
			questionList: false,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: TableTemplateForm
		},
		termination: {
			list: showAll,
			questionList: false,
			questionEnd: showAll,
			multipleChoiceList: false,
			formComponent: TerminationTemplateForm
		},
		multipleLink: {
			list: showAll,
			questionList: false,
			questionEnd: showAll,
			multipleChoiceList: false,
			formComponent: MultiLinkTemplateForm
		},
		simpleLink: {
			list: showAll,
			questionList: false,
			questionEnd: showAll,
			multipleChoiceList: false,
			formComponent: SimpleLinkTemplateForm
		},
		pageBreak: {
			list: showAll,
			questionList: false,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: false
		},
		space: {
			list: showAll,
			questionList: showAll,
			questionEnd: false,
			multipleChoiceList: showAll,
			formComponent: false
		},
		new: {
			list: false,
			questionList: false,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: false
		},
		next: {
			list: false,
			questionList: false,
			questionEnd: false,
			multipleChoiceList: false,
			formComponent: false
		},
		templateCopied: {
			list: showAll,
			questionList: showAll,
			questionEnd: false,
			multipleChoiceList: showAll,
			formComponent: false
		}
	};
};

/**
 * Get available Template from context for provided scope
 * @param {"pcc"|"train"} scope
 * @param [params={}]
 */
const getComponentConfig = (scope, params = {}) => {
	const templateConfigList = Object.entries(generateConfigByScope(scope));
	const { context, sheetType } = params;
	let result;
	switch (context) {
	case "question-list":
		result = templateConfigList.filter(([ _key, value ]) => value.questionList);
		break;
	case "question-end":
		result = templateConfigList.filter(([ _key, value ]) => value.questionEnd);
		break;
	case "multiple-choice":
		result = templateConfigList.filter(([ _key, value ]) => value.multipleChoiceList);
		break;
	default:
		result = templateConfigList.filter(([ key, value ]) => value.list);
		break;
	}

	if (sheetType === "appendix") {
		result = result.filter(([ _key, value ]) => ![ "multipleLink", "simpleLink" ].includes(_key));
	}

	return Object.fromEntries(result.map(([ key, value ]) => [ key, value.formComponent ]));
};

export default getComponentConfig;
