const dateFormatTime = "HH'h'mm";
const dateFormatDate = "dd/MM/yy";
const dateFormatDateMonth = "MM-yy";
const dateFormatDayMonth = "dd MMMM";
const dateFormatDateAndTime = `${dateFormatDate} ${dateFormatTime}`;
const dateFormatFullDateMonth = "MMMM yyyy";
const dateFormatFullDate = "dd MMMM yyyy";
const dateFormatFull = "dd MMMM yyyy HH'h'mm";

export {
	dateFormatTime,
	dateFormatDate,
	dateFormatDateMonth,
	dateFormatDayMonth,
	dateFormatFullDateMonth,
	dateFormatDateAndTime,
	dateFormatFull,
	dateFormatFullDate
};
