import { draftJSContentToText, parseJsonString } from "../../../../theme/components/editor-rendering/utils";

/**
 * Extract Plain text from sheet's title and save it as an attribut
 * used on list for title's manipulation
 * Ex: Search by title
 * @param sheets
 * @returns {*[]}
 */
const addSheetsTitleRaw = (sheets = []) => {
	return sheets.map((sheet) => {
		const { title } = sheet;
		const parsedTitle = parseJsonString(title);
		const draftJSContent = draftJSContentToText(parsedTitle).join(" ");
		sheet.titleRaw = draftJSContent;
		return sheet;
	});
};

export { addSheetsTitleRaw };

