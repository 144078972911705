import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../../../../../../theme";
import { IconBack } from "../../../../../../theme/assets/img";
import BackToCurrentStep from "../../../../../../shared/components/back-to-current-step/back-to-current-step";

import "./process-history-header.scss";

const ProcessHistoryHeader = props => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const { process = {} } = props;
	const { status, tech_id } = process;

	const processIsClosed = status === "closed" || status === "aborted";
	const prefixLink = pathname.startsWith("/spr") ? "spr" : "railway";

	const backToProcessHistoryList = () => {
		if (processIsClosed) {
			return (
				<Link className="process-list__content__return-binders" to={`/${prefixLink}/process`}>
					<Button 
						variant="primary"
						small
						icon={IconBack}
					/>
					<span className="return-binders__text">{t("process:process-list.return-process-list")}</span>
				</Link>
			);
		} else {
			return <BackToCurrentStep className="process-history-header__back" processId={tech_id}/>;
		}
	};

	return (
		<div className="process-history-header">
			<div className="process-history-header__wrapper">
				<h1 className="process-history-header__title">
					{processIsClosed ? t("process:history-view.title-report") : t("process:history-view.title")}
				</h1>
			</div>
			<div className="process-history-header__wrapper-bottom">
				{backToProcessHistoryList()}
				<div className="process-history-header__print">
					{processIsClosed &&
					<Button
						variant="primary"
						label={t("process:history-view.print")}
						className="print__button"
						onClick={() => window.print()}
					/>
					
					}
				</div>
			</div>
		</div>
	);
};

export default ProcessHistoryHeader;
