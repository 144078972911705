import Button from "./components/form/button/button";
import ColorChooser from "./components/form/color-chooser/color-chooser";
import CustomDropdown from "./components/form/custom-dropdown/custom-dropdown";
import Dropdown from "./components/form/dropdown/dropdown";
import DropdownMenu from "./components/dropdown-menu/dropdown-menu";
import DropdownMenuItem from "./components/dropdown-menu/dropdown-menu-item/dropdown-menu-item";
import Editor from "./components/editor/editor";
import EditorRendering from "./components/editor-rendering/editor-rendering";
import ErrorMessage from "./components/error-message/error-message";
import IconWrapper from "./components/icon-wrapper/icon-wrapper";
import CdrPage from "./components/page/cdr-page/cdr-page";
import SprPage from "./components/page/spr-page/spr-page";
import PopupDefault from "./components/popup/popup-default/popup-default";
import PopupConfirm from "./components/popup/popup-confirm/popup-confirm";
import SubwayLineNumber from "./components/subway-line-number/subway-line-number";
import Textarea from "./components/form/textarea/textarea";
import TextField from "./components/form/text-field/text-field";
import TextfieldAutocomplete from "./components/form/text-field-autocomplete/textfield-autocomplete";
import DateChooser from "./components/form/date-chooser/date-chooser";
import TimeChooser from "./components/form/time-chooser/time-chooser";
import Drawer from "./components/drawer/drawer";
import SearchBar from "./components/search-bar/search-bar";
import TabsNav from "./components/tabs/tabs-nav/tabs-nav";
import TabsNavItem from "./components/tabs/tabs-nav-item/tabs-nav-item";
import TabsPanel from "./components/tabs/tabs-nav-panel/tabs-nav-panel";
import BurgerMenu from "./components/burger-menu/burger-menu";
import ToggleSwitch from "./components/toggle-switch/toggle-switch";
import Spinner from "./components/spinner/spinner";
import DigiprocLoader from "./components/digiproc-loader/digiproc-loader";
import FilterDropDown from "./components/form/filter-dropdown/filter-dropdown";
import FilterAssociatedLineLabel from "./components/filter/filter-associated-line-label/filter-associated-line-label";
import FilterTags from "./components/filter/filter-tags/filter-tags";
import FilterLabelLine from "./components/filter/filter-label-line/filter-label-line";
import FilterMaterialOption from "./components/filter/filter-material-option/filter-material-option";
import FilterDocTypeOption from "./components/filter/filter-doc-type-option/filter-doc-type-option";
import ImageListView from "./components/image-list-view/image-list-view";
import SquareColorIcon from "./components/square-color-icon/square-color-icon";
import LineSelector from "./components/form/line-selector/line-selector";
import ListContentTemplate from "./components/list-content-template/list-content-template";
import LogoDigiproc from "./components/logo-digiproc/logo-digiproc";
import DottedCard from "./components/dotted-card/dotted-card";
import EyeTriangle from "./components/eye-triangle/eye-triangle";
import BorderOverlay from "./components/border-overlay/border-overlay";

import i18next from "../config/i18next";
import i18nTheme from "./i18n/fr.json";

export { default as NoCacheAvailable } from "./pages/no-cache-available/no-cache-available";
export { default as LoadingPage } from "./pages/loading-page/loading-page";

i18next.addResourceBundle("fr", "theme", i18nTheme);

export {
	Button,
	BurgerMenu,
	ColorChooser,
	CustomDropdown,
	Dropdown,
	DropdownMenu,
	DropdownMenuItem,
	Editor,
	EditorRendering,
	ErrorMessage,
	IconWrapper,
	ImageListView,
	CdrPage,
	SprPage,
	Spinner,
	DigiprocLoader,
	PopupDefault,
	PopupConfirm,
	SubwayLineNumber,
	Textarea,
	TextField,
	TextfieldAutocomplete,
	DateChooser,
	TimeChooser,
	Drawer,
	SearchBar,
	TabsNav,
	TabsNavItem,
	TabsPanel,
	ToggleSwitch,
	FilterDropDown,
	FilterAssociatedLineLabel,
	FilterTags,
	FilterLabelLine,
	FilterMaterialOption,
	FilterDocTypeOption,
	SquareColorIcon,
	LineSelector,
	ListContentTemplate,
	LogoDigiproc,
	DottedCard,
	EyeTriangle,
	BorderOverlay
};
