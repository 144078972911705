import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getOfflineUser } from "..";
import { checkOfflineAuth } from "../../domains/user/utils/auth-offline-utils";
import { getCurrentUser } from "../auth/auth.services";
import { checkIsCachePresent } from "../cache-access/entity/entity-utils";
import { getSprStorageContent } from "../context/spr-context/spr-context-localstorage-utils";
import { OktaContext } from "../context/okta-context/okta-context";

const redirectOnMissingCache = async (side) => {
	const hasCache = await checkIsCachePresent();
	if (!hasCache && side === "train"){
		window.location = "/no-cache";
	}
	return hasCache;
};

/**
 * Setup current user when network ready
 * @param {boolean} online
 * @param {boolean} networkStatusLoading
 * @return {{loading: boolean, user: {}}}
 */
const useAuth = (online, networkStatusLoading) => {
	const [ user, setUser ] = useState();
	const [ loading, setLoading ] = useState(true);
	const location = useLocation();
	const onTotpCodePage = location.pathname.includes("/totp/code");

	const { hasAuth } = useContext(OktaContext) || {};
	const { side = "" } = getSprStorageContent();

	const fetchCurrentUser = useCallback(async (auth) => {
		/**
		 * if offline auto redirect to totp code
		 * when no valid offline jwt present
		 * else return a stansard offline user
		 */
		const handleOfflineModeSetup = async () => {
			if (!online){
				const hasCache = await redirectOnMissingCache(side);
				if (hasCache) {
					const isOfflineUserValidated = await checkOfflineAuth();
					if (!onTotpCodePage && isOfflineUserValidated){
						return getOfflineUser();
					} else if (!onTotpCodePage) {
						window.location = "/totp/code";
					}
				}
			}
		};

		try {
			if (online && auth){
				const response = await getCurrentUser();
				if (response?.data) {
					return response.data;
				}
			} else {
				return await handleOfflineModeSetup();
			}
		} catch {
			return await handleOfflineModeSetup();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ online, onTotpCodePage ]);

	const fetchUserEffect = useCallback( () => {
		if (!networkStatusLoading){
			if (!onTotpCodePage){
				setLoading(true);
			}
			fetchCurrentUser(hasAuth)
				.then(newUser => setUser(newUser))
				.finally(() =>  setLoading(false));
		}
	}, [ fetchCurrentUser, networkStatusLoading, onTotpCodePage, hasAuth ]);

	useEffect(fetchUserEffect, [ fetchUserEffect ]);

	return { user: user || {}, loading, setUser };
};

export default useAuth;
