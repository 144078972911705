import React from "react";
import { IconWrapper } from "../../../../../../../theme";
import { IconAdd } from "../../../../../../../theme/assets/img";
import { useSheetContentContext } from "../../../../../context/sheet-content-context";
import "./add-new-template.scss";

const AddNewNode = (props) => {
	const { position, location } = props;
	const { insertEmptyNodeAt } = useSheetContentContext();

	const addNewTemplate = () => {
		insertEmptyNodeAt(position, location);
	};

	return (
		<div className="add-new-node">
			<button type="button" className="add-new-node__button" onClick={addNewTemplate}>
				<IconWrapper className="add-new-node__icon" Component={IconAdd}/>
			</button>
		</div>
	);
};

export default AddNewNode;
