import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./filter-doc-type-option.scss";

/**
 * Render the FilterDocTypeOptionDropdownOption components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.docTypeList
 * @returns {JSX.Element}
 */
const FilterDocTypeOption = props => {
	const { className, docTypeList } = props;

	const { t } = useTranslation();

	return (
		<label className={classnames(className, "filter-type-option-label")} htmlFor={docTypeList}>
			<span className="filter-type-option__label">{t(`binder:binder-type.${docTypeList}`)}</span>
		</label>
	);
};

FilterDocTypeOption.propTypes = {
	className: PropTypes.string,
	docTypeList: PropTypes.string.isRequired
};

export default FilterDocTypeOption;
