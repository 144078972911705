import { useEffect, useState } from "react";
import { useAppSetupContext } from "../context/app-setup-context/app-setup-context";

/**
 *  Custom hooks to block offline to online network status change
 * @returns {{
 *      allowOnlineReturn: (value: (((prevState: boolean) => boolean) | boolean)) => void},
 *      onlineReturnAllowed: boolean
 *  }
 */
const useRailwayOnlineReturn = (defaultAllowOnlineReturn = false) => {
	const { updateNetworkStatus, networkStatus } = useAppSetupContext();

	const [ onlineReturnAllowed, setOnlineReturnAllowed ] = useState(defaultAllowOnlineReturn);

	const updateNetworkState = () => {
		if (networkStatus === "online" && onlineReturnAllowed){
			updateNetworkStatus(true);
		} else if (networkStatus === "offline") {
			updateNetworkStatus(false);
		}
	};

	useEffect(updateNetworkState, [ networkStatus, onlineReturnAllowed, updateNetworkStatus ]);

	return { allowOnlineReturn: setOnlineReturnAllowed, onlineReturnAllowed };
};

export default useRailwayOnlineReturn;
