import React, { useRef, useMemo } from "react";
import classNames from "classnames";
import IconWrapper from "../../icon-wrapper/icon-wrapper";
import PropTypes from "prop-types";
import "./text-field.scss";

/**
 * TextField component
 * @param {object} props
 * @param {string} props.value
 * @param {boolean} props.readOnly
 * @param {function} props.handleFocus
 * @param {function} props.handleBlur
 * @param {string} props.inputClassName
 * @param {string} props.label
 * @param {object} props.icon
 * @param {function} props.onChange
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {string} props.className
 * @param {boolean} props.invalid
 * @param {boolean} props.disabled
 * @param {string} props.helperText
 * @returns {JSX.Element}
 */
const TextField = (props) => {
	const {
		value = "", readOnly, handleFocus, handleBlur, inputClassName,
		label, icon, onChange, name, placeholder, className, invalid, disabled, helperText
	} = props;
	const inputRef = useRef();

	const handleLabelFocus = () => {
		inputRef.current.focus();
	};


	const containerClassName = useMemo(() => classNames(className, "text-field", {
		"text-field--invalid" : invalid,
		"text-field--disabled": disabled
	}), [ className, invalid, disabled ]);


	return (
		<div className={containerClassName}>
			<input
				ref={inputRef}
				className={classNames("text-field__input", inputClassName, { "text-field__input--valid": value })}
				type="text"
				name={name}
				onFocus={handleFocus}
				onBlur={handleBlur}
				value={value}
				readOnly={readOnly}
				onChange={onChange}
				placeholder={placeholder}
				disabled={disabled}
			/>
			<label onClick={handleLabelFocus} className="text-field__label">{label}</label>
			{icon && <IconWrapper className="text-field__icon" Component={icon}/>}
			{helperText && <span className="text-field__helper-text">{helperText}</span>}
			{props.children}
		</div>
	);
};

TextField.propTypes = {
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	handleFocus: PropTypes.func,
	handleBlur: PropTypes.func,
	inputClassName: PropTypes.string,
	label: PropTypes.string,
	icon: PropTypes.oneOfType([ PropTypes.node, PropTypes.elementType, PropTypes.object ]),
	onChange: PropTypes.func,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	invalid: PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
	disabled: PropTypes.bool,
	helperText: PropTypes.string,
};

export default TextField;
