import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ToggleSwitch } from "../../../../theme";
import { fetchBinderByTechId } from "../../binder.services";
import { fetchSheetByBinder } from "../../../sheet/sheet.services";
import SprBinderSheetList from "./components/spr-binder-sheet-list/spr-binder-sheet-list";
import SprPage from "../../../../theme/components/page/spr-page/spr-page";
import SprBinderDetailSubheader from "./components/spr-binder-detail-subheader/spr-binder-detail-subheader";
import SprBinderThemeSheetList from "./components/spr-binder-theme-sheet-list/spr-binder-theme-sheet-list";
import BinderSignalChoice from "./components/binder-signal-choice/binder-signal-choice";
import { useRunProcessContext, ProcessProvider } from "../../../process/components/context-run-process/context-run-process";
import { useSprContext } from "../../../../shared/context/spr-context/spr-context";
import "./spr-binder-detail.scss";

/**
 * Binder Detail page SPR side
 * @returns {JSX.Element}
 */
const SprBinderDetail = () => {
	const { t } = useTranslation();
	const { id: binderId } = useParams();
	const [ binder, setBinder ] = useState({});
	const [ sheetList, setSheetList ] = useState([]);
	const [ filterCheck, setFilterCheck ] = useState(false);
	const [ loading, setLoading ] = useState(true);

	const { setCurrentBinder, associatedLine } = useSprContext();
	const { createNewProcess } = useRunProcessContext();
	const history = useHistory();

	// Handle redirect on unknown or wrong format's id
	const handleError = useCallback((error) => {
		if (error?.response?.status === 400 || error?.response?.status === 404) {
			history.push("/404");
		} else {
			throw error;
		}
	}, [ history ]);

	const retrieveBinder = () => {
		fetchBinderByTechId(binderId).then(response => {
			setBinder(response?.data);
		}).catch((error) => {
			setBinder({});
			handleError(error);
		});
	};

	const getSheetList = async (additionalType = [], signalTechId = "") => {
		const type = [ "parent", "determination", ...additionalType ];
		const params = { type };
		if (signalTechId) {
			params.signalTechId = signalTechId;
		}

		return fetchSheetByBinder(binderId, params);
	};

	const updateSheetList = (additionalType, signalTechId) => {
		if (binder?.type && binder.type !== "man") {
			getSheetList(additionalType, signalTechId)
				.then(response => {
					setSheetList(response?.data);
				})
				.catch(error => {
					handleError(error);
				})
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
		}
	};


	const syncCurrentBinder = () => {
		setCurrentBinder({ color: binder.color, abbreviation: binder.abbreviation }, true);
	};

	const handleChangeToggle = (checked) => {
		const type = [ "parent" ];

		if (checked) {
			type.push("child");
		}

		if (binder?.type === "theme") {
			type.push("determination");
		}

		updateSheetList(type);
		setFilterCheck(checked);
	};

	const renderToggleSwitchChildSheet = () => {
		return (
			<div className="spr-binder-detail-page__title__filter-toggle">
				<span className="spr-binder-detail-page__title__filter-toggle__label">
					{t("binder:spr-binder-detail.filter-toggle-label.show")}
				</span>
				<ToggleSwitch isChecked={filterCheck} handleOnChange={() => handleChangeToggle(!filterCheck)}/>
			</div>
		);
	};

	const handleSignalChoice = async (signal) => {
		const { data = [] } = await getSheetList([ "child" ], signal?.tech_id);
		const [ firstSheet ] = data;
		const options = { signalTechId: signal?.tech_id || firstSheet.signal_tech_id, line: associatedLine };
		createNewProcess(firstSheet.number, binder?.id, binder?.tech_id, options);
	};

	// TODO remove eslint
	// retrieve only parent and determination sheets at first
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => updateSheetList([ "parent", "determination" ]), [ binderId, history ]);
	useEffect(retrieveBinder, [ binderId, history, handleError ]);

	// ignore getSheetList dependency to avoid infinite render
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => updateSheetList(), [ binder.type, history ]);
	useEffect(syncCurrentBinder, [ binder, setCurrentBinder ]);

	const renderSheetListByBinderType = (binderParam, sheetListParams) => {
		const { type } = binderParam;
		if (type === "sheet") {
			return <SprBinderSheetList binder={binderParam} sheetList={sheetListParams} redirectTypeDetermination/>;
		} else if (type === "theme") {
			return <SprBinderThemeSheetList binder={binderParam} sheetList={sheetListParams}/>;
		}
	};

	const subheader = binder.title ? <SprBinderDetailSubheader
		binderType={binder?.type}
		binderTitle={binder.title}
		binderZoneLink={binder?.zone_link}/> : null;

	return (
		<SprPage className="spr-binder-detail-page" subheader={subheader} classNameHeader="spr-binder-detail-page__header">
			{!loading &&
			<div className="spr-binder-detail-page__content">
				{
					binder?.type !== "man" && <div className="spr-binder-detail-page__instruction">{t("binder:spr-binder-detail.notice-select-sheet")}</div>
				}
				{binder?.type !== "man" && <>
					<div className="filter-toggle">
						{renderToggleSwitchChildSheet()}
					</div>
					{renderSheetListByBinderType(binder, sheetList)}
					<div className="spr-binder-detail-page__wrapper">
						{filterCheck && renderToggleSwitchChildSheet()}
					</div>
				</>}
				{binder?.type === "man" && <BinderSignalChoice binder={binder} onConfirm={handleSignalChoice}/>}
			</div>
			}
		</SprPage>
	);
};

const SprBinderDetailWrapper = () => (
	<ProcessProvider>
		<SprBinderDetail/>
	</ProcessProvider>
);

export default SprBinderDetailWrapper;
