import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStepTemplate from "../../../../utils/use-step-template";
import "./step-template-form.scss";

const StepTemplateForm = (props) => {
	const { content = {} } = props;

	const { t } = useTranslation();
	const { updateStepContent } = useStepTemplate();

	const updateStepContentEffect = () => {
		//avoid update at selection
		if (!content.step) {
			updateStepContent();
		}
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(updateStepContentEffect, [ content ]);

	return (
		<div className="step-template-form">
			<span className="step-template-form__step">
				{`${t("sheet:sheet-details.template.step.name")} ${content?.step}`}
			</span>
			<span className="step-template-form__text">_ _h_ _</span>
		</div>
	);
};

export default StepTemplateForm;
