import React from "react";
import classnames from "classnames";
import EyeTriangle from "../eye-triangle/eye-triangle";
import ReturnTriangle from "../return-triangle/return-triangle";



import "./border-overlay.scss";
import { useTranslation } from "react-i18next";

const BorderOverlay = ({ height, top, types = [] }) => {
	const { t } = useTranslation();
	const modifier = {
		"border-overlay--canceled" : types.includes("canceled"),
		"border-overlay--read" : types.includes("read")
	 };
	return <div className={classnames("border-overlay", modifier)} style={{
		top,
		height,
	}}>
		{types.includes("canceled") && <span className="border-overlay__return-message">{t("process:spr-run-process.return-message")}</span>}
		{types.includes("read") && <EyeTriangle/>}
		{types.includes("canceled") && <ReturnTriangle/>}
	</div>;
};

export default BorderOverlay;
