import React from "react";
import { useTranslation } from "react-i18next";
import "./officer-intervention-template-form.scss";

/**
 * Render the OfficerInterventionTemplateForm component
 * @returns {JSX.Element}
 */
const OfficerInterventionTemplateForm = () => {

	const { t } = useTranslation();

	return (
		<div className="officer-intervention-template-panel">
			<div className="officer-intervention-template-panel__title">
				{t("sheet:sheet-details.template.officerIntervention.label")}
			</div>
		</div>
	);
};

export default OfficerInterventionTemplateForm;
