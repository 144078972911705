import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { IconWrapper } from "../../";
import { iconLines } from "../../../domains/user/utils/associated-line-icon-utils/associated-line-icon.utils";
import "./subway-line-number.scss";

/**
 * Render the SubwayLineNumber components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.lineNumber
 * @returns {JSX.Element}
 */
const SubwayLineNumber = (props) => {
	const { className, lineNumber } = props;
	const classes = classnames(className, "subway-line-number");
	return <IconWrapper className={classes} Component={iconLines[lineNumber]}/>;

};

SubwayLineNumber.propTypes = {
	className: PropTypes.string,
	lineNumber: PropTypes.string
};

export default SubwayLineNumber;
