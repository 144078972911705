import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PopupConfirm } from "../../../../../theme";
import "./popup-cache-sync.scss";

/**
 * Render the PopupCacheSync component
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const PopupCacheSync = (props) => {
	const { popupControl, line, onConfirm } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm className="popup-cache-sync" onConfirm={onConfirm} popupControl={popupControl} title={t("theme:spr-page.popup-cache-sync.title")}>
			<h2 className="popup-cache-sync__title">{t("theme:spr-page.popup-cache-sync.text.0", { line })}</h2>
			<p className="popup-cache-sync__text">{t("theme:spr-page.popup-cache-sync.text.1", { line })}</p>
			<p className="popup-cache-sync__text">{t("theme:spr-page.popup-cache-sync.text.2", { line })}</p>
			<p className="popup-cache-sync__info">{t("theme:spr-page.popup-cache-sync.text.3")}</p>
		</PopupConfirm>
	);
};

PopupCacheSync.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool
	}),
	onConfirm: PropTypes.func.isRequired,
	line: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]).isRequired
};

export default PopupCacheSync;
