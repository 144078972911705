import { checkNetwork, getNetworkState } from "../offline";
import { adapter as defaultAdapter } from "axios/lib/defaults";
import { getEntity } from "../../shared/cache-access/entity/entity-utils";
import { isSomeTruthyProperty, replaceAllPropertyValue } from "../../shared/cache-access/api/api-utils";

/**
 * Axios interceptor for ping requests (requests to /status).
 * If there's no internet connection or server not reachable, the request times out after 1000ms
 * Interceptor uses CancellationToken to manually cancel axios request
 * @param requestConfig
 */
const offlineProxyInterceptor = requestConfig => {
	const interceptedMethods = [ "get", "put", "post" ];

	// if online -> send request | else -> send ping and return cache data
	const isOffline = getNetworkState() !== "online";

	const offlineAdapter = async (config) => {
		const { method: configMethod, entity, ref, line, handleCacheSync } = requestConfig;

		if ( (requestConfig.method === "put" || requestConfig.method === "post") && handleCacheSync ) {
			// Keep stored method to avoid erasing post call
			const requestData = requestConfig.data ? JSON.parse(requestConfig.data) : null;

			const cacheEntity = await getEntity(entity, ref, line).catch(() => null);
			const method = cacheEntity ? cacheEntity.metadata.method : configMethod;
			const previousPendingState = cacheEntity?.metadata?.pendingState || {};
			const pendingState = replaceAllPropertyValue(requestData, true);
			const newPendingState = { ...previousPendingState, ...pendingState };

			const isPending = isSomeTruthyProperty(newPendingState);

			const metadata = { isPending, pendingState: newPendingState, entity, ref, line, method };
			await handleCacheSync(requestConfig, requestData, metadata);
		}

		if (config.handleOfflineResponse){
			// send ping to check network status (no await for quick cache answer)
			checkNetwork();
			const cacheData = await config.handleOfflineResponse(config);
			return {
				status: 200,
				statusText: "OK",
				headers: [],
				config: config,
				request: {},
				data: cacheData,
				fromCache: true
			};
		} else {
			return defaultAdapter(config);
		}
	};

	if (interceptedMethods.includes(requestConfig.method) && isOffline) {
		requestConfig.adapter = offlineAdapter;
	}

	return requestConfig;
};

export default offlineProxyInterceptor;
