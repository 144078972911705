import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IconPCC, IconTrain } from "../../../../domains/binder/assets";
import { IconWrapper } from "../../../";
import SignalTitle from "../../../../domains/binder/pages/binder-detail/components/binder-sheet-list/components/signal-title/signal-title";
import "./buger-menu-process-item.scss";

/**
 * Render the burger menu current process
 * @param {Props} props
 * @param {ProcessItem[]} props.processItem
 * @returns {JSX.Element}
 */

const BurgerMenuProcessItem = (props) => {
	const { processItem = {} } = props;
	const {
		binderTitle,
		binderAbbreviation,
		type = "",
		binderSignalDestination,
		binderSignalOrigin,
		binderType = "",
		processTechId = "",
		stationLabel = "",
		positionInfo,
		label,
		link = "#"
	} = processItem;

	const generateIcon = processItemType => {
		const icon = processItemType === "pcc" ? IconPCC : IconTrain;
		return (
			<IconWrapper className="burger-menu__process-list__icon" Component={icon}/>
		);
	};

	const generateLink = () => {
		return <Link className="burger-menu__process-list__label" to={link}>
			{type && generateIcon(type)}
			{generateLinkContent()}
		</Link>;
	};

	const generateLinkContent = () => {
		return binderType === "man" ? 
			<span className="burger-menu__process-list__label--signal">
				{binderTitle}
				<SignalTitle className="signal__item" title={stationLabel} origin={binderSignalOrigin} destination={binderSignalDestination} />
			</span>
			: <span>{binderTitle || positionInfo || binderAbbreviation || label}</span>;
	};

	return <li key={processTechId} className="burger-menu__process-list__item">
		{generateLink()}
	</li>;
};

export default BurgerMenuProcessItem;

BurgerMenuProcessItem.propTypes = {
	processItem: PropTypes.shape({
		label: PropTypes.string,
		type: PropTypes.string,
		tech_id: PropTypes.string
	}),
};
