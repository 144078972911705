import React from "react";
import { useTranslation } from "react-i18next";
import { PopupConfirm } from "../../../../../../theme";
import { hasNoNextTemplatesInBothYesAndNo, hasNextTemplatesInBothYesAndNo } from "../../utils/handle-copy-paste-template";
import "./popup-copy-template.scss";

const PopupCopyTemplate = (props) => {
	const { template, popupControl } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t("sheet:popup.copy-template.title")}
			ctaConfirmLabel="sheet:popup.copy-template.cta-confirm-label"
			className="popup-copy-template"
		>
			<div className="popup-copy-template__message">
				<span className="popup-copy-template__message__text">{t("sheet:popup.copy-template.text.basic")}</span>
				<span className="popup-copy-template__message__text">
					{t("sheet:popup.copy-template.text.no-image-copied")}
				</span>
				{hasNoNextTemplatesInBothYesAndNo(template) && (
					<span className="popup-copy-template__message__text">
						{t("sheet:popup.copy-template.text.question-terminations")}
					</span>
				)}
				{template?.type === "question" && !hasNextTemplatesInBothYesAndNo(template) && (
					<span className="popup-copy-template__message__text">
						{t("sheet:popup.copy-template.text.question")}
					</span>
				)}
			</div>
		</PopupConfirm>
	);
};

export default PopupCopyTemplate;
