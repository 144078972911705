/**
 * Generate get utils for LocalForage instance
 * @param {LocalForage} localForageInstance
 * @return {function(key: string): Promise<*>}
 */
const getItemGenerator = localForageInstance => async (key) => {
	try {
		return await localForageInstance.getItem(key);
	} catch (error) {
		console.error(error);
	}
};

/**
 * Generate set utils for LocalForage instance
 * @param {LocalForage} localForageInstance
 * @return {function(key: string, value: any): Promise<*>}
 */
const setItemGenerator = localForageInstance => async (key, value) => {
	try {
		return await localForageInstance.setItem(key, value);
	} catch (error) {
		console.error(error);
	}
};

/**
 * Generate remove utils for LocalForage instance
 * @param {LocalForage} localForageInstance
 * @return {function(key: string): Promise<*>}
 */
const removeItemGenerator = localForageInstance => async (key) => {
	try {
		return await localForageInstance.removeItem(key);
	} catch (error) {
		console.error(error);
	}
};

/**
 * Generate keys utils for LocalForage instance
 * @param {LocalForage} localForageInstance
 * @return {function(void): Promise<*>}
 */
const getKeysGenerator = localForageInstance => async () => {
	try {
		return await localForageInstance.keys();
	} catch (error) {
		console.error(error);
	}
};

export { getItemGenerator, setItemGenerator, removeItemGenerator, getKeysGenerator };
