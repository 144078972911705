import React from "react";
import PropTypes from "prop-types";
import NextButton from "../next-button/next-button";
import "./table-task-view.scss";

/**
 * View of a table task with navigation button to next task
 * @param {object} props
 * @returns {JSX.Element}
 */
const TableTaskView = (props) => {
	const { task = {}, onClickNext } = props;
	const { content } = task;

	const handleNextButton = () => {
		onClickNext();
	};

	const renderTableHeader = (header) => {
		return (
			<tr className="table-template-view__table-tr">
				<th className="table-task-view__table-th">{header.columnOne}</th>
				<th className="table-task-view__table-th">{header.columnTwo}</th>
			</tr>
		);
	};

	const renderTableLine = (line) => {
		return (
			<tr className="table-task-view__table-tr">
				<td className="table-task-view__table-td">{line.columnOne}</td>
				<td className="table-task-view__table-td">{line.columnTwo}</td>
			</tr>
		);
	};

	return (
		<div id={task?.id} className="table-task-view">
			<table className="table-task-view__table">
				<thead className="table-task-view__table-head">
					{content?.header && renderTableHeader(content.header)}
				</thead>
				<tbody className="table-task-view__table-body">
					{content?.lines?.map(renderTableLine)}
				</tbody>
			</table>
			<div className="table-task-view__next-button">
				<NextButton onClick={handleNextButton}/>
			</div>
		</div>
	);
};

TableTaskView.propTypes = {
	task: PropTypes.shape({
		id: PropTypes.string,
		content: PropTypes.shape({
			header: PropTypes.shape({
				columnOne: PropTypes.string,
				columnTwo: PropTypes.string
			}),
			lines: PropTypes.arrayOf(
				PropTypes.shape({
					columnOne: PropTypes.string,
					columnTwo: PropTypes.string
				})
			)
		})
	}),
	onClickNext: PropTypes.func.isRequired,
};

export default TableTaskView;
