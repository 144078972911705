import React from "react";
import { useTranslation } from "react-i18next";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import "./termination-template-view.scss";

const TerminationTemplateView = props => {
	const { template, currentPosition, location } = props;

	const { t } = useTranslation();

	return (
		<TemplateWrapper
			id={template.id}
			className="termination-template"
			template={template}
			location={location}
			currentPosition={currentPosition}
			allowInsertAfter={false}>
			<div className="termination-template__title">
				{t("sheet:sheet-details.template.termination.title")}
			</div>
		</TemplateWrapper>
	);
};

export default TerminationTemplateView;
