const networkStatusKey = "network-status";
const appNetworkStatusKey = "app-network-status";

const ServerReachableEvent = new CustomEvent("server-reachable");
const ServerUnreachableEvent = new CustomEvent("server-unreachable");
const ServerErrorEvent = new CustomEvent("server-error");

const getStoredNetworkStatus = () => localStorage.getItem(networkStatusKey);
const setStoredNetworkStatus = (isOnline) => localStorage.setItem(networkStatusKey, isOnline);
const setAppStoredNetworkStatus = (isOnline) => localStorage.setItem(appNetworkStatusKey, isOnline);
const getAppStoredNetworkStatus = () => localStorage.getItem(appNetworkStatusKey);

/**
 * Get the network state from automatic network status and app level network status. Both have to be `online` to consider the app online
 *
 * @returns {"online"|"offline"}
 */
const getNetworkState = () => {
	// Default stored status are online;

	// Automatic network status change automatically according to api request call
	const storedNetworkStatus = getStoredNetworkStatus() ?? "online";
	// App level network status change according to RailwayOfflineContext
	const storedAppNetworkStatus = getAppStoredNetworkStatus() ?? "online";

	return storedNetworkStatus === "online" && storedAppNetworkStatus === "online" ? "online" : "offline";
};

export {
	getNetworkState,
	getStoredNetworkStatus,
	setStoredNetworkStatus,
	setAppStoredNetworkStatus,
	getAppStoredNetworkStatus,
	networkStatusKey,
	appNetworkStatusKey,
	ServerReachableEvent,
	ServerUnreachableEvent,
	ServerErrorEvent
};
