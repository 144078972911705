import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import "./train-trouble-form-radio-button.scss";

const TrainTroubleFormRadioButton = (props) => {
	const { title = "", step = "", substep = "", options = [], isFirstQuestion = false, onChange, checkedValue, warning = "" } = props;
	const { t } = useTranslation();

	return (
		<div className={"train-trouble-form-radio-button"}>
			<span className={classNames("train-trouble-form-radio-button__title", { "train-trouble-form-radio-button__title--wrapped": isFirstQuestion })}>
				{title}
			</span>
			{warning && <div className={"train-trouble-form-radio-button__warning"}>
				{t(`process:process-rer-resume.${step}.warning`)}
			</div>}
			<div className={"train-trouble-form-radio-button__options-container"}>
				{options.map((option, index) => {
					return (
						<div className={"train-trouble-form-radio-button__option"} key={index}>
							<input
								id={index+substep}
								type="radio"
								value={option}
								name={substep}
								checked={checkedValue === option}
								onClick={onChange}
								onChange={() => {}}
							/>
							<label htmlFor={index+substep} className={"train-trouble-form-radio-button__option__label"}>
								{t(`process:process-rer-resume.${step}.${option}`)}
							</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default TrainTroubleFormRadioButton;
