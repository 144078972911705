/**
 * @typedef Signal
 * @property {string} tech_id
 * @property {number} title
 * @property {string} origin
 * @property {string} destination
 * @property {string} binder_tech_id
 */

/**
 * Create signal info with conditional displaying of destination if a signal title is duplicate
 * @param {Signal[]} signalList An array of signals of a binder or line
 * @param {string} signalTechId The signal tech id from a sheet
 * @return {Object} the signal info containing signal title and destination
 */
const getSignalInfo = (signalList = [], signalTechId) => {
	const currentSignal = signalList.find(s => s.tech_id === signalTechId);
	const isDuplicateSignalInBinder = signalList.some(s => s?.title?.toUpperCase() === currentSignal?.title?.toUpperCase() && s.tech_id !== currentSignal?.tech_id);
	return {
		title: currentSignal?.title,
		destination: isDuplicateSignalInBinder ? currentSignal?.destination : null
	};
};

export default getSignalInfo;
