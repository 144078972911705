import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { analyseBinderSheetsPdf, getBinderPagesAnalyseStatus } from "../../../../../binder.services";
import { Button } from "../../../../../../../theme";
import { formatJsonDate, LoadWrapper } from "../../../../../../../shared";
import { dateFormatDate } from "../../../../../../process/utils";
import BinderDetailCardTemplate from "../binder-detail-card-template/binder-detail-card-template";
import "./binder-search-publish-type-card.scss";

/**
 * BinderSearchPublishTypeCard component
 * @param {object} props
 * @param {object} props.binder
 * @param {function} props.reloadBinder
 * @param {function} props.showPopupTypeSuggestion
 * @param {function} props.setNetworkError
 * @returns {JSX.Element}
 */
const BinderSearchPublishTypeCard = props => {
	const {
		binder = {}, reloadBinder = () => {}, showPopupTypeSuggestion = () => {}, setNetworkError = () => {}
	} = props;
	const {
		type_suggestion_status: typeSuggestionStatus,
		type_suggestion: typeSuggestion,
		type_suggestion_date: typeSuggestionDate
	} = binder;

	const { t } = useTranslation();
	const [ isSearchingType, setIsSearchingType ] = useState();
	const [ isSearchingTypeLoading, setSearchingTypeLoading ] = useState(false);
	const [ openSearchingTypeResultWhenDone, setOpenSearchingTypeResultWhenDone ] = useState(false);

	const handleStartBinderPublishTypeCheck = () => {
		setSearchingTypeLoading(true);
		setOpenSearchingTypeResultWhenDone(true);
		analyseBinderSheetsPdf(binder.tech_id)
			.then(response => {
				const { data: latestBinderDetail } = response;
				reloadBinder(latestBinderDetail);

				if (latestBinderDetail?.type_suggestion_status === "done") {
					showPopupTypeSuggestion();
				}
			})
			.finally(() => setSearchingTypeLoading(false));
	};

	useEffect(() => {
		if (isSearchingType && (typeSuggestionStatus === "done" || typeSuggestionStatus === "error") && openSearchingTypeResultWhenDone) {
			showPopupTypeSuggestion();
		}
		if (isSearchingType && typeSuggestionStatus !== "in-progress") {
			clearInterval(isSearchingType);
			setIsSearchingType(false);
		}
		if (!isSearchingType && typeSuggestionStatus === "in-progress") {
			const intervalId = setInterval(() => {
				getBinderPagesAnalyseStatus(binder.tech_id).then(response => {
					const { data: latestBinderDetail } = response;
					reloadBinder(latestBinderDetail);
				}).catch(() => {
					setNetworkError(true);
				});
			}, 1000);
			setIsSearchingType(intervalId);
		}
	}, [ binder, isSearchingType, openSearchingTypeResultWhenDone, reloadBinder, setNetworkError, showPopupTypeSuggestion, typeSuggestionStatus ]);

	const generatePublishTypeContent = () => {
		const verifyCta = (
			<Button
				variant="minimal"
				className="binder-search-publish-card__verify"
				onClick={handleStartBinderPublishTypeCheck}
				disabled={[ "revoke", "published", "cancelled" ].includes(binder.status)}
				label={t("binder:binder-detail.specification.type-content-button")}
			/>
		);

		if (isSearchingType || isSearchingTypeLoading) {
			// add loader spinner
			return (
				<>
					<span>{t("binder:binder-detail.specification.search-type-1")}</span>
					<span>{t("binder:binder-detail.specification.search-type-2")}</span>
					<br/>
					<span>{t("binder:binder-detail.specification.search-type-3")}</span>
					<LoadWrapper className="binder-search-publish-card__spinner" loading />
				</>
			);
		} else if (typeSuggestion === "rectification" || typeSuggestion === "edition") {
			return (
				<>
					<span>
						{`${t("binder:binder-detail.specification.type-date")} ${formatJsonDate(typeSuggestionDate, dateFormatDate)}, ${t("binder:binder-detail.specification.type-text")}`}
						<span className="binder-search-publish-card__short-result">
							{t(`binder:binder-detail.specification.type-${typeSuggestion}`)}
						</span>
					</span>
					<button className="binder-search-publish-card__show-result"
					        onClick={showPopupTypeSuggestion}>{t("binder:binder-detail.specification.type-result-button")}</button>
					{verifyCta}
				</>
			);
		} else {
			return (
				<>
					<span>{t("binder:binder-detail.specification.no-type")}</span>
					{verifyCta}
				</>
			);
		}
	};

	return (
		<BinderDetailCardTemplate title={t("binder:binder-detail.specification.type-title")} className="binder-search-publish-card">
			{generatePublishTypeContent()}
		</BinderDetailCardTemplate>
	);
};

BinderSearchPublishTypeCard.propTypes = {
	binder: PropTypes.shape({
		tech_id: PropTypes.string,
		status: PropTypes.string
	}),
	reloadBinder: PropTypes.func,
	showPopupTypeSuggestion: PropTypes.func,
	setNetworkError: PropTypes.func,
};


export default BinderSearchPublishTypeCard;
