import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDndSheetReference } from "../../../../../../../../shared";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import "./mrf-intervention-template-view.scss";

/**
 * MRFIntervention template view
 * @param {object} props
 * @param {object} props
 * @param {object} props.template
 * @param {boolean} props.allowInsertAfter
 * @param {function} props.moveCard
 * @param {boolean} [props.isSubNode = false]
 * @param {number} props.currentPosition
 * @param {object} props.location
 * @param {object} props.processCurrentTemplate
 * @returns {JSX.Element}
 */
const MRFInterventionTemplateView = props => {
	const { template, allowInsertAfter, moveCard, isSubNode = false, currentPosition, location, processCurrentTemplate } = props;

	const { t } = useTranslation();
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref, isSubNode);

	return (
		<TemplateWrapper
			id={template.id}
			className="mrf-intervention-template"
			reference={ref}
			isDragging={isDragging}
			dragRef={drag}
			template={template}
			location={location}
			allowInsertAfter={allowInsertAfter}
			currentPosition={currentPosition}
			processCurrentTemplate={processCurrentTemplate}
		>
			<div className="mrf-intervention-template__title">
				{t("sheet:sheet-details.template.mrfIntervention.label")}
			</div>
		</TemplateWrapper>
	);
};

MRFInterventionTemplateView.propTypes = {
	template: PropTypes.object,
	currentPosition: PropTypes.number,
	allowInsertAfter: PropTypes.bool,
	moveCard: PropTypes.any,
	location: PropTypes.object,
	isSubNode: PropTypes.bool,
	processCurrentTemplate: PropTypes.object,
};

export default MRFInterventionTemplateView;
