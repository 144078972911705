import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PopupConfirm, IconWrapper } from "../../../../theme";
import { ReactComponent as IconWarning } from "../../../../theme/assets/img/icon-warning.svg";
import "./popup-warning-return.scss";

/**
 * Render the popup warning when doing a return on process
 * @param {object} props
 * @param {function} props.onConfirm
 * @param {object} props.popupControl
 * @return {JSX.Element}
 * @constructor
 */
const PopupWarningReturn = props => {
	const { popupControl, onConfirm } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm title={t("process:popup.warning-return.title")} error popupControl={popupControl}
		              onConfirm={onConfirm}>
			<div className="popup-warning-return">
				<div className="popup-warning-return__wrapper">
					<IconWrapper className="popup-warning-return__icon" Component={IconWarning}/>
					<span className="popup-warning-return__text">{t("process:popup.warning-return.warning")}</span>
				</div>
				<span className="popup-warning-return__info">{t("process:popup.warning-return.info")}</span>
			</div>
		</PopupConfirm>
	);
};

PopupWarningReturn.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired,
		show: PropTypes.func.isRequired,
		visible: PropTypes.bool.isRequired
	}).isRequired,
	onConfirm: PropTypes.func.isRequired
};

export default PopupWarningReturn;
