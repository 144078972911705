import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ReactComponent as IconCheck } from "../../../assets/img/icon-check.svg";
import IconWrapper from "../../icon-wrapper/icon-wrapper";
import "./color-chooser-option.scss";

/**
 * Render a ColorChooserOption components
 * @param {object} props
 * @param {string} props.value
 * @param {boolean} props.checked
 * @param {function} props.onChange
 * @param {boolean} props.read
 * @returns {JSX.Element}
 */
const ColorChooserOption = (props) => {
	const { className, value, checked, onChange, read } = props;

	const handleClick = () => {
		if (!read) {
			onChange(value);
		}
	};

	const getComponentClassName = () => {
		const modifier = {
			"color-chooser-option--checked": checked,
			"color-chooser-option--read": read
		};
		return classnames("color-chooser-option", className, modifier);
	};
	useEffect(() => {}, [ value ]);
	return (
		<button
			type="button"
			onClick={handleClick}
			className={getComponentClassName()}
			style={{ "--option-color": value }}
		>
			{checked && <IconWrapper className="color-chooser-option__icon" Component={IconCheck}/>}
		</button>
	);
};


ColorChooserOption.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	read: PropTypes.bool
};

export default ColorChooserOption;
