import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { PopupWrapper } from "../../../../shared";
import { ReactComponent as Close } from "../../../assets/img/close.svg";
import { IconWrapper } from "../../..";

import "./popup-default.scss";

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 */

/**
 * PopupDefault component
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.title
 * @param {function} props.onClose
 * @param {object} props.children
 * @param {string} props.error
 * @param {PopupControl} props.popupControl
 */
const PopupDefault = props => {
	const {
		className,
		error,
		popupControl,
		onClose = () => {},
		title,
		children,
	} = props;

	const handleClose = () => {
		onClose();
		popupControl.hide();
	};

	return (
		<PopupWrapper visible={popupControl.visible}>
			<div className={classnames("popup-default", className)}>
				<div className={classnames("popup-default__header", { "popup-default__header--error": error })}>
					<h1 className="popup-default__title">{title}</h1>
					<button type="button" className="popup-default__close" onClick={handleClose}>
						<IconWrapper Component={Close}/>
					</button>
				</div>
				<div className="popup-default__body">{children}</div>
			</div>
		</PopupWrapper>
	);
};

PopupDefault.propTypes = {
	className: PropTypes.string,
	popupControl: PropTypes.shape({
		visible: PropTypes.bool,
		setVisible: PropTypes.func,
		show: PropTypes.func,
		hide: PropTypes.func
	}),
	title: PropTypes.string,
	onClose: PropTypes.func,
	children: PropTypes.node,
	error: PropTypes.string
};

export default PopupDefault;
