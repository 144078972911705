import React from "react";
import PropTypes from "prop-types";
import { IconWrapper } from "../../../../../index";
import "./associated-lines-checkbox.scss";

/**
 * Associated line icon checkbox
 * @param {object} props
 * @returns {JSX.Element}
 */
const AssociatedLinesCheckbox = (props) => {
	const { line, checked, icon, iconDisabled, handleCheckboxChange, disabled = false } = props;

	return (
		<div className="associated-line-checkbox">
			<input id={`line-${line}`} type="checkbox" name={`line-${line}`}
			       className="associated-line-checkbox__input"
			       checked={checked}
			       disabled={disabled}
			       value={line}
			       onChange={handleCheckboxChange}/>
			<label htmlFor={`line-${line}`}
			       className="associated-line-checkbox__label">
				{icon && <IconWrapper className="associated-line-checkbox__label__icon"
				                      Component={checked ? icon : iconDisabled}/>}
			</label>
		</div>
	);
};


AssociatedLinesCheckbox.propTypes = {
	line: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	icon: PropTypes.elementType,
	iconDisabled: PropTypes.elementType,
	handleCheckboxChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};


export default AssociatedLinesCheckbox;
