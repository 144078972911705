import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ProcessTrainSubheader from "../../components/process-train-subheader/process-train-subheader";
import ProcessTrainBreadcrumbSubheader from "../../components/process-train-breadcrumb-subheader/process-train-breadcrumb-subheader";
import { SearchBar, SprPage } from "../../../../theme";
import SprBinderSheetList from "../../../binder/pages/spr-binder-detail/components/spr-binder-sheet-list/spr-binder-sheet-list";
import { fetchBinderByTechId } from "../../../binder/binder.services";
import { fetchSheetByBinder } from "../../../sheet/sheet.services";
import { addSheetsTitleRaw } from "../../../sheet/utils/add-sheet-title-raw-utils/add-sheet-title-raw-utils";
import { useSheetReadContext } from "../../../sheet/pages/railway-sheet-view/components/sheet-read-context/sheet-read-context";

import "./process-binder-detail.scss";

/**
 * Render Process's Train Binder detail
 * @returns {JSX.Element}
 */
const 	ProcessBinderDetail = () => {
	const { t } = useTranslation();
	const { binderId } = useParams();
	const [ sheetList, setSheetList ] = useState([]);
	const [ filteredSheetList, setFilteredSheetList ] = useState([]);
	const [ sheetSearch, setSheetSearch ] = useState("");
	const { currentTrain: train = {}, setBinder, currentBinder: binder } = useSheetReadContext();


	const loadBinder = () => {
		// TODO API refacto / addition
		fetchBinderByTechId(binderId)
			.then(({ data: binderFound= {} }) => {
				setBinder(binderFound);
			});
	};

	const loadSheetList = () => {
		// TODO API refacto / addition
		if (binder.tech_id){
			fetchSheetByBinder(binder.tech_id, {}).then(response => {
				const sheetListWithTitleRaw = addSheetsTitleRaw(response.data);
				setSheetList(sheetListWithTitleRaw);
				setFilteredSheetList(response?.data);
			});
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(loadBinder, [ binderId ]);
	useEffect(loadSheetList, [ binder.tech_id ]);

	const searchSheets = () => {
		const sheetListSpread = [ ...sheetList ];
		if (sheetSearch) {
			const sheetSearchLC = sheetSearch.toLowerCase();
			const sheetListFiltered = sheetListSpread.filter((sheet) =>
				(sheet.number_search?.toLowerCase().includes(sheetSearchLC) || sheet.titleRaw?.toLowerCase().includes(sheetSearchLC)));
			setFilteredSheetList(sheetListFiltered);
		} else {
			setFilteredSheetList(sheetListSpread);
		}
	};

	useEffect(searchSheets, [ sheetList, sheetSearch ]);


	const SubHeader = <>
		<ProcessTrainSubheader  />
		<ProcessTrainBreadcrumbSubheader train={train}/>
	</>;


	return (<SprPage className="process-binder-detail" subheader={SubHeader}>
		<div className="process-binder-detail__content">
			<div className="process-binder-detail__content__head">
				<span className="process-binder-detail__content__title">{t("process:process-binder-detail.title")}</span>
				<div className="process-binder-detail__content__search">
					<SearchBar
						value={sheetSearch}
						onChange={(e) => setSheetSearch(e.target.value)}
						placeholder={t("binder:binder-detail.sheet-list.search-bar-placeholder")}/>
				</div>
			</div>
			<ul className="process-binder-detail__content__list">
				<SprBinderSheetList binder={binder} sheetList={filteredSheetList} isProcessRedirect={false}/>
			</ul>
		</div>
	</SprPage>);
};



export default ProcessBinderDetail;
