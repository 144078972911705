import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ColorChooserOption from "../color-chooser-option/color-chooser-option";
import "./color-chooser.scss";

/**
 * Render ColorChooser Component
 * @returns {JSX.Element}
 */
const ColorChooser = (props) => {
	const {
		className, name, colorList = [], value, onChange = () => {}, label, large
	} = props;

	
	const getComponentClassName = () => {
		const modifier = {
			"color-chooser--large": large
		};
		return classnames("color-chooser", className, modifier);
	};
	
	
	const handleChange = (color) => {
		onChange({ target: { name, value: color } });
	};
	
	const generateOption = () => {
		return colorList.map((optionColor) => <ColorChooserOption
			key={optionColor}
			value={optionColor}
			onChange={handleChange}
			name={name}
			checked={value === optionColor}
		/>);
	};
	
	return (
		<div className={getComponentClassName()}>
			<span className="color-chooser__label">{label}</span>
			<ColorChooserOption className="color-chooser__current-value" read value={value}/>
			<div className="color-chooser__options">
				{generateOption()}
			</div>
		</div>
	);
};

ColorChooser.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	colorList: PropTypes.arrayOf(PropTypes.string),
	value: PropTypes.string,
	onChange: PropTypes.func,
	label: PropTypes.string,
	large: PropTypes.bool
};

export default ColorChooser;
