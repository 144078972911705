import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PopupDefault, Button, ErrorMessage } from "../../../../../../theme";
import { checkFormError } from "../../../../../../shared";
import { createTrain } from "../../../../train.services";
import { getFormTrainFieldMessageKey, validatePopupCreateTrainForm } from "../../../../utils/validate-popup-train-form";
import { createTrainFormOnChange } from "../../../../utils/train-object-utils";
import FormTrain from "../form-train/form-train";

import "./popup-create-train.scss";

/**
 * Render the PopupCreateTrain component
 * @param {function} props.onSubmitSuccess Callback called after successful submit
 * @param {object} props.popupControl
 * @param {string} props.line the line of the current train-list parent page
 * @param {import("../../../../train.services").Train[]} props.trainList the train list of the train-list parent page
 * @returns {JSX.Element}
 */
const PopupCreateTrain = props => {
	const {
		onSubmitSuccess = () => {}, popupControl, line, trainList
	} = props;
	const { t } = useTranslation();

	const lineEntity = [ "A", "B" ].includes(line) ? "rer" : "mts";
	const formDataDefault = lineEntity ===  "rer" ? { line, brake_isolation: true, engine_system: true, brake_system: "bogie" } : { line };

	/**@type {[import("../../../../train.services").Train, React.Dispatch<React.SetStateAction<Train>>]} */
	const [ formData, setFormData ] = useState(formDataDefault);
	const [ networkError, setNetworkError ] = useState(false);

	const formError = validatePopupCreateTrainForm(formData, trainList);
	const formErrorMessage = getFormTrainFieldMessageKey(formError);
	const reInitForm = () => {
		setFormData(formDataDefault);
		setNetworkError(false);
	};

	const handleChange = (event) => {
		setFormData(createTrainFormOnChange(formData, event));
	};

	const handleSubmit = event => {
		event.preventDefault();
		if (!checkFormError(formError)) {
			const newTrain = { ...formData };
			reInitForm();
			createTrain(newTrain)
				.then(onSubmitSuccess)
				.then(popupControl.hide)
				.catch(() => setNetworkError(true));
		}
	};


	return (
		<PopupDefault
			className="popup-create-train__wrapper"
			popupControl={popupControl}
			title={t("train:popup.create-train.title")}
			onClose={reInitForm}
		>
			<form className="popup-create-train__form" onSubmit={handleSubmit}>
				<div className="popup-create-train__form-wrapper">
					<FormTrain
						form={formData}
						validation={formError}
						errorMessage={formErrorMessage}
						onChange={handleChange}
						extendedBinderStatus
						create
						line={line}/>
				</div>
				<div className="popup-create-train__controller">
					<Button
						variant="primary"
						className="popup-create-train__submit"
						type="submit"
						disabled={checkFormError(formError)}
						label={t("train:popup.form.submit")}
					/>
				</div>
				{networkError &&
				<ErrorMessage className="popup-create-train__network-error">{t("error.default")}</ErrorMessage>}
			</form>
		</PopupDefault>
	);
};

PopupCreateTrain.propTypes = {
	onSubmitSuccess: PropTypes.func,
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool
	}),
	line: PropTypes.string.isRequired,
	trainList: PropTypes.arrayOf(PropTypes.object)
};

export default PopupCreateTrain;
