import { http } from "../../config";

/**
 * @typedef {Object} Theme
 * @property {String} color
 * @property {String} title
 * @property {String} binder_tech_id
 */

/**
 * Get all themes contained in the specified binder
 * @param {string} binderTechId The theme's binder technical id
 * @return {Promise<import("axios").AxiosResponse<Theme>>}
 */
const getBinderThemes = async (binderTechId) => http.get(`/binder/${binderTechId}/theme`);

/**
 * Create a new theme in a binder
 * @param {string} binderTechId
 * @param {Promise<import("axios").AxiosResponse<Theme>>} theme
 */
const createBinderTheme = async (binderTechId, theme) => http.post(`/binder/${binderTechId}/theme`, theme);

/**
 * Update an existing theme
 * @param {string} binderTechId
 * @param {string} binderThemeTechId
 * @param {Promise<import("axios").AxiosResponse<Theme>>} theme
 */
const updateBinderTheme = async (binderTechId, binderThemeTechId, theme) => http.put(`/binder/${binderTechId}/theme/${binderThemeTechId}`, theme);
/**
 * Delete a theme of a binder
 * @param {string} binderTechId
 * @param {string} themeTechId
 */
const deleteBinderThemeByTechId = async (binderTechId, themeTechId) => http.delete(`binder/${binderTechId}/theme/${themeTechId}`);

export {
	getBinderThemes,
	createBinderTheme,
	updateBinderTheme,
	deleteBinderThemeByTechId
};
