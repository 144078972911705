import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LoginCallback, SecureRoute } from "@okta/okta-react";
import { SprContextProvider, SprNetworkWrapper, CdrNetworkWrapper, RailwayOfflineProvider } from "./shared";
import { NoCacheAvailable } from "./theme";
import { SheetDetail, SprSheetDetail, RailwaySheetView  } from "./domains/sheet";
import {
	ProcessHistoryView,
	SprRunProcess,
	ProcessList,
	ProcessBinderDetail,
	ProcessBinderList,
	ProcessResume,
	ProcessRerResumePage
} from "./domains/process";
import {
	BinderDetail,
	BinderList,
	SlBinderDetail,
	SprBinderDetail,
	SprBinderGroup,
	SprBinderList,
	BinderByMaterialList
} from "./domains/binder";
import { DocumentWaiting } from "./domains/document";
import { TrainList, SlTrainSearch, TrainAssociatedBinderList, MaterialAssociatedBinderList } from "./domains/train";
import {
	AdminUserList,
	UserHomePage,
	LegalsPage,
	GenerateOfflinePassword,
	RoleGuardedRoute,
	OfflineAuth,
	userRoleMap,
	binderManagementAccesRoles,
	sprAccessRoles,
	railwayAccessRoles,
	adminAccessRoles
} from "./domains/user";
import {
	ErrorBoundary,
	UnauthorizedPage,
	ErrorNetworkPage,
	NotFoundPage,
	ErrorDownPage,
	ProcessUnfinishedPage
} from "./domains/error";
import {
	SheetReadContextProvider
} from "./domains/sheet/pages/railway-sheet-view/components/sheet-read-context/sheet-read-context";
import { SheetContentProvider } from "./domains/sheet/context/sheet-content-context";
import { ProcessProvider } from "./domains/process/components/context-run-process/context-run-process";
import AuthContextRoute from "./config/router/AuthContextRoute";
import OktaWrapper from "./shared/auth/okta-wrapper";
import { OktaProvider } from "./shared/context/okta-context/okta-context";
import "./app.scss";

const App = () => {
	const regexUUID = "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";
	const contextRouterList = [
		{
			path: "/admin",
			component: AdminUserList,
			context: CdrNetworkWrapper,
			authorizedRoles: adminAccessRoles
		},
		{
			path: "/legals",
			component: LegalsPage,
			context: CdrNetworkWrapper
		},
		{
			path: "/binders/:binderId/sheet/:sheetId/edit",
			component: SheetDetail,
			context: CdrNetworkWrapper,
			authorizedRoles: binderManagementAccesRoles
		},
		{
			path: "/binders/:binderId/sheet/:sheetId",
			component: SheetDetail,
			params: { readOnly: true },
			context: CdrNetworkWrapper,
			authorizedRoles: binderManagementAccesRoles
		},
		{
			path: "/binders/:id",
			component: BinderDetail,
			context: CdrNetworkWrapper,
			authorizedRoles: binderManagementAccesRoles
		},
		{
			path: "/binders",
			component: BinderList,
			context: CdrNetworkWrapper,
			authorizedRoles: binderManagementAccesRoles
		},
		{
			path: "/document/:id/binder",
			component: DocumentWaiting,
			context: CdrNetworkWrapper
		},
		{
			path: "/document/:id/sheet",
			component: DocumentWaiting,
			context: CdrNetworkWrapper,
			authorizedRoles: binderManagementAccesRoles
		},
		{
			path: "/spr/referentiel-train",
			component: TrainList,
			context: [ SprNetworkWrapper, SprContextProvider ],
			authorizedRoles: [ userRoleMap.administrator, userRoleMap["admin-line"], userRoleMap["line-operator-supervisor"], userRoleMap["admin-rer"] ]
		},
		{
			path: "/spr/process",
			component: ProcessList,
			context: [ SprNetworkWrapper, SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/process/dead-end",
			component: ProcessUnfinishedPage,
			context: [ SprNetworkWrapper, SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/process/run",
			component: SprRunProcess,
			context: [ SprNetworkWrapper, SprContextProvider, ProcessProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: `/spr/process/:processId(${regexUUID})`,
			component: ProcessHistoryView,
			context: [ SprNetworkWrapper, SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/read/process/:processId/binders",
			component: ProcessBinderList,
			context: [ SprNetworkWrapper, SheetReadContextProvider,SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/read/process/:processId/binders/:binderId",
			component: ProcessBinderDetail,
			context: [ SprNetworkWrapper, SheetReadContextProvider,SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/read/binders/:binderId/sheet/:sheetId/process/:processId",
			component: SprSheetDetail,
			context: [ SprNetworkWrapper, SheetContentProvider , SheetReadContextProvider, SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/read/process/run",
			component: SprRunProcess,
			context: [ SprNetworkWrapper, SheetReadContextProvider, SprContextProvider, ProcessProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: `/spr/read/process/:processId(${regexUUID})`,
			component: ProcessHistoryView,
			context: [ SprNetworkWrapper, SheetReadContextProvider, SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/process/:processId/binders/:binderId/sheet/:sheetNumber",
			component: RailwaySheetView,
			context: [ SprNetworkWrapper, SprContextProvider, SheetContentProvider, SheetReadContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/binders/:binderId/sheet/:sheetId/process/:processId",
			component: SprSheetDetail,
			context: [ SprNetworkWrapper, SprContextProvider, SheetContentProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/binders/:binderId/process/:processId/sheet",
			component: SprSheetDetail,
			context: [ SprNetworkWrapper, SprContextProvider, SheetContentProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/binders/group/:abbreviation",
			component: SprBinderGroup,
			context: [ SprNetworkWrapper, SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/binders/:id",
			component: SprBinderDetail,
			context: [ SprNetworkWrapper, SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/spr/binders",
			component: SprBinderList,
			context: [ SprNetworkWrapper, SprContextProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/railway/process/:processId/binders",
			component: TrainAssociatedBinderList,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/process/:processId/binders/:binderId",
			component: SlBinderDetail,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/process/run",
			component: SprRunProcess,
			context: [ SprContextProvider, ProcessProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/binders",
			component: BinderByMaterialList,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/train/:trainTechId/binder/:binderId",
			component: SlBinderDetail,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/train/:trainTechId/binders",
			component: TrainAssociatedBinderList,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/material/:materialTechId/binder/:binderId",
			component: SlBinderDetail,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/material/:materialTechId/binders",
			component: MaterialAssociatedBinderList,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/binders/:binderId/sheet/:sheetId/process/:processId",
			component: SprSheetDetail,
			context: [ SprContextProvider, RailwayOfflineProvider, SheetContentProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/binders/:binderId/process/:processId/sheet",
			component: SprSheetDetail,
			context: [ SprContextProvider, RailwayOfflineProvider, SheetContentProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/process/dead-end",
			component: ProcessUnfinishedPage,
			context: SprContextProvider,
			authorizedRoles: railwayAccessRoles
		},
		{
			path: `/railway/process/:processId(${regexUUID})`,
			component: ProcessHistoryView,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/process/:processId/resume",
			component: ProcessResume,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/process/:processId/process-rer-resume",
			component: ProcessRerResumePage,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles,
			params: { disableLineSwitch: true }
		},
		{
			path: "/railway/process",
			component: ProcessList,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles
		},
		{
			path: "/railway",
			component: SlTrainSearch,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: railwayAccessRoles
		},
		{
			path: "/totp/password",
			component: GenerateOfflinePassword,
			context: [ SprContextProvider, RailwayOfflineProvider ],
			authorizedRoles: sprAccessRoles
		},
		{
			path: "/totp/code",
			component: OfflineAuth,
			context: [],
			authorizedRoles: railwayAccessRoles
		},
		{
			path: "/",
			component: UserHomePage,
			context: SprContextProvider
		},
	];

	const buildNestedContext = (contextList = []) => {
		if (contextList.length === 0){
			return ({ children }) => children;
		} else if (contextList.length === 1) {
			const [ ContextName ] = contextList;
			return ({ children }) => <ContextName>{children}</ContextName>;
		} else {
			const [ ContextName, ...innerContextList ] = contextList;
			const InnerContext = buildNestedContext(innerContextList);
			return ({ children }) => <ContextName><InnerContext>{children}</InnerContext></ContextName>;
		}
	};

	// Router with Auth Context and Default Context Added
	const renderContextRoute = (router) => {
		const { component: ComponentName, context: Context, path, params = {}, authorizedRoles } = router;
		if (Context){
			const ContextName = Array.isArray(Context) ? buildNestedContext(Context) : Context;
			return (
				<AuthContextRoute key={path} exact path={path}>
					<RoleGuardedRoute authorizedRoles={authorizedRoles}>
						<ContextName>
							<ComponentName {...params}/>
						</ContextName>
					</RoleGuardedRoute>
				</AuthContextRoute>
			);
		}
		return (
			<AuthContextRoute key={path} exact path={path}>
				<RoleGuardedRoute authorizedRoles={authorizedRoles}>
					<ComponentName {...params}/>
				</RoleGuardedRoute>
			</AuthContextRoute>
		);
	};

	return (
		<Router>
			<OktaWrapper>
				<ErrorBoundary>
					<div className="digiproc-app">
						<Switch element={<SecureRoute/>}>
							<Route path="/login/callback" component={LoginCallback}/>
							<Route path="/legale" component={LegalsPage}/>
							<Route exact path="/error-access" component={UnauthorizedPage}/>

							<OktaProvider>
								<Route exact path="/404">
									<NotFoundPage/>
								</Route>

								<Route exact path="/error-network">
									<ErrorNetworkPage/>
								</Route>

								<Route exact path="/error-down">
									<ErrorDownPage/>
								</Route>

								<Route exact path="/no-cache">
									<NoCacheAvailable/>
								</Route>

								{contextRouterList.map(renderContextRoute)}
							</OktaProvider>
						</Switch>
					</div>
				</ErrorBoundary>
			</OktaWrapper>
		</Router>
	);
};

export default App;
