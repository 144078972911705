import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SimpleLinkStepForm from "./components/simple-link-step-form/simple-link-step-form";
import SimpleLinkSheetForm from "./components/simple-link-sheet-form/simple-link-sheet-form";
import SimpleLinkOtherSheetForm from "./components/simple-link-other-sheet-form/simple-link-other-sheet-form";
import { useSheetContentContext } from "../../../../../../context/sheet-content-context";
import { fetchSheetById } from "../../../../../../sheet.services";
import "./simple-link-template-form.scss";

function SimpleLinkTemplateForm(props) {
	const { content = {}, binderData, isTrainBinder } = props;
	const { binderId, sheetId } = useParams();
	const { t } = useTranslation();

	const { setCurrentNodeContent } = useSheetContentContext();

	const [ simpleLinkContent, setSimpleLinkContent ] = useState(null);
	const [ currentSheet, setCurrentSheet ] = useState({});

	const [ templateSelected, setTemplateSelected ] = useState(content.type || "");

	const getSimpleLinkTypes = (binderType) => {
		const typeList = [ "sheet" ];
		if (binderType !== "man") {
			typeList.push("other-sheet");
		}
		if (!isTrainBinder){
			typeList.push("step");
		}
		return typeList;
	};

	const simpleLinkTypes = getSimpleLinkTypes(binderData?.type);
	const getCurrentSheet = () => {
		fetchSheetById(binderId, sheetId).then(response => {
			setCurrentSheet(response.data);
		});
	};

	// send data to context
	const setNodeContent = () => {
		if (simpleLinkContent){
			setCurrentNodeContent({ ...simpleLinkContent }, true);
		}
	};


	const handleRadioChange = (e) => {
		setTemplateSelected(e.target.value);
	};

	const updateSimpleLinkContent = (params) => {

		setSimpleLinkContent(previousContent => {
			const { step, sheetNumber, sheetId: sheetIdParam, sheetTitle, binder, actionId, actionPosition, sheetColor, binderColor, sheetType } = params;
			const newSimpleListContent = {
				type: templateSelected,
				step,
				sheetNumber,
				sheetTitle,
				sheetColor,
				binderColor,
				sheetType,
				sheetId: sheetIdParam,
				actionId,
				actionPosition,
				binder
			};
			const strNewContent = JSON.stringify(newSimpleListContent);
			const staleContent = JSON.stringify(previousContent);
			if (staleContent !== strNewContent){
				return newSimpleListContent;
			} else {
				return previousContent;
			}
		});
	};

	// handle update step link value
	const handleDropdownChange = (e) => {
		const params = { step: e.target.value, sheetId };
		updateSimpleLinkContent(params);
	};

	// handle update same sheet link value
	const handleSelectedValue = (suggestion) => {
		const { number_search: sheetNumber, sheet_id, action, color, binder_color: binderColor, type } = suggestion;
		const params = { sheetNumber, sheetId: sheet_id, sheetColor: color, binderColor, sheetType: type };
		if (action) {
			params.actionId = action.id;
			params.actionPosition = action.position;
		}
		updateSimpleLinkContent(params);
	};

	// handle update other sheet link value
	const handleSelectedValueOtherSheet = (sheetDetail, binderDetail) => {
		const { sheetNumber, techId: sheetTechId, titleRaw, action, sheetColor, binderColor, sheetType } = sheetDetail;
		const params = { sheetNumber, sheetId: sheetTechId, sheetTitle: titleRaw, binder: binderDetail, sheetColor, binderColor, sheetType };
		if (action){
			params.actionId = action.id;
			params.actionPosition = action.position;
		}
		updateSimpleLinkContent(params);
	};


	const generateRadioButtons = (type) => {
		return (
			<li key={type} className="radio-type">
				<input type="radio" id={type} name="radio-type" value={type}
				       checked={templateSelected === type}
				       className="radio-type__input" onChange={handleRadioChange}/>
				<label htmlFor={type} className="radio-type__label">
					{t(`sheet:sheet-details.template.simpleLink.radio-${type}`)}
				</label>
			</li>
		);
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setNodeContent, [ simpleLinkContent ]);

	useEffect(getCurrentSheet, [ binderId, sheetId ]);

	return (
		<div className="simple-link-template-form">
			<form className="simple-link-template-formField" onSubmit={event => event.preventDefault()}>
				<ul className="radio-types">
					{simpleLinkTypes.map(generateRadioButtons)}
				</ul>
			</form>


			{!isTrainBinder && templateSelected === "step" && (
				<SimpleLinkStepForm dropdownValue={content?.step || ""} handleDropdownChange={handleDropdownChange} />
			)}

			{templateSelected === "sheet" && (
				<SimpleLinkSheetForm
					handleSelectedValue={handleSelectedValue}
					content={content}
					binderType={binderData?.type}
					currentSheet={currentSheet}
					isTrainBinder={isTrainBinder}
				/>
			)}

			{binderData?.type !== "man" && templateSelected === "other-sheet" && (
				<SimpleLinkOtherSheetForm
					redirectSheetValue={(content.type === "other-sheet" && content.sheetNumber) || ""}
					binderData={binderData}
					content={content}
					handleSelectedValue={handleSelectedValueOtherSheet}
					currentSheet={currentSheet}
					isTrainBinder={isTrainBinder}
				/>
			)}

		</div>
	);
}

export default SimpleLinkTemplateForm;
