import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { usePopup } from "../../../../../../../../../shared";
import { getBinderThemes } from "../../../../../../../binder-theme.services";
import { Button, SearchBar } from "../../../../../../../../../theme";
import { PopupCreateTheme } from "../../../../../../../components/popup";
import { IconPlus } from "../../../../../../../assets";

import BinderThemeListContent from "./binder-theme-sheet-list-content/binder-theme-sheet-list-content";

import "./binder-theme-sheet-list.scss";

/**
 * Render the list of theme of a binder
 * @param {object} props
 * @param {object[]} props.sheets
 * @param {object} props.binder
 * @param {string} props.search
 * @param {function} props.onSearch
 * @param {function} props.refreshSheetList
 * @param {object} props.analysisResult
 * @returns {JSX.Element}
 */
const BinderThemeSheetList = props => {
	const { sheets = [], binder, search, onSearch, refreshSheetList, analysisResult } = props;
	const { t } = useTranslation();
	const [ themeList, setThemeList ] = useState([]);

	const createThemePopupControl = usePopup();
	const areButtonsDisabled = binder?.status !== "work-in-progress";
	const hasThemes = Array.isArray(themeList) && themeList.length !== 0;

	const getThemes = () => {
		if (binder?.tech_id){
			getBinderThemes(binder.tech_id).then(response => {
				setThemeList(response.data);
			});
		}
	};

	const generateThemeListItem = (theme) => {
		const sheetList = sheets.filter(sheet => sheet?.theme_tech_id === theme?.tech_id);
		return (
			<BinderThemeListContent
				key={theme?.tech_id}
				sheets={sheetList}
				binder={binder}
				analysisResult={analysisResult}
				theme={theme}
				themeList={themeList}
				refreshSheetList={refreshSheetList}
				updateThemeList={getThemes}/>
		);
	};

	useEffect(getThemes, [ binder?.tech_id ]);

	return (
		<div className="binder-theme-sheet-list">
			<PopupCreateTheme popupControl={createThemePopupControl} binder={binder} themeList={themeList} handleClose={getThemes}/>
			<div className="binder-theme-sheet-list__head">
				<h3 className="binder-theme-sheet-list__title">{`${t("binder:binder-detail.theme-list.title")}`}</h3>
				{binder.status !== "published" && binder.status !== "revoke" && (
					<span className="binder-theme-sheet-list__iteration">{t("binder:binder-detail.theme-list.iteration", { number: binder.iteration })}</span>)
				}
				{hasThemes &&
					<div className="binder-theme-sheet-list__actions">
						<SearchBar className="actions__search" value={search} onChange={onSearch}
							placeholder={t("binder:binder-detail.theme-list.search-bar-placeholder")}/>
						<Button
							variant="primary"
							disabled={areButtonsDisabled}
							className="actions__add-theme"
							onClick={createThemePopupControl.show}
							label={t("binder:binder-detail.theme-list.create-theme-btn")}
							icon={IconPlus}
						/>
					</div>
				}
			</div>
			{hasThemes && themeList.map(generateThemeListItem)}
			{!hasThemes &&
				<div className="binder-theme-sheet-list__empty">
					<span className="binder-theme-sheet-list__empty__text">
						{`${t("binder:binder-detail.theme-list.create-theme-desc")} ${binder?.id?.toUpperCase()}`}
					</span>
					<Button
						variant="primary"
						disabled={areButtonsDisabled} 
						type="button" 
						onClick={createThemePopupControl.show}
						label={t("binder:binder-detail.theme-list.create-theme-btn")}
						icon={IconPlus}
					/>
				</div>
			}
		</div>
	);
};

BinderThemeSheetList.propTypes = {
	sheets: PropTypes.arrayOf(PropTypes.shape({
		number: PropTypes.string.isRequired,
		sheet_id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		title: PropTypes.object.isRequired,
		analysis_result: PropTypes.string,
		status: PropTypes.string.isRequired,
		last_change_content_at: PropTypes.string.isRequired,
		editor: PropTypes.string,
		editor_firstname: PropTypes.string,
		editor_lastname: PropTypes.string,
		editor_id: PropTypes.string
	})),
	binder: PropTypes.shape({
		status: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
	}),
	search: PropTypes.string.isRequired,
	onSearch: PropTypes.func.isRequired,
	refreshSheetList: PropTypes.func.isRequired,
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string)
	})
};

export default BinderThemeSheetList;
