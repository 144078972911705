import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useDebouncedAction, usePopup, LoadWrapper, useEffectDidUpdate } from "../../../../shared";
import {
	fetchBinderByTechId,
	updateBinder,
	runAnalysisBinder,
	searchBinder,
} from "../../binder.services";

import { CdrPage } from "../../../../theme";
import { PopupPublishBinder, PopupTypeSuggestionBinder, PopupZoneLink } from "../../components/popup";
import { BinderZoneLinkCard, BinderPublishCard, BinderSearchPublishTypeCard } from "./components/binder-detail-cards";
import CdrMainInfos from "../../../../theme/components/page/cdr-page/cdr-main-infos/cdr-main-infos";
import BinderDetailSubheader from "./components/binder-detail-subheader/binder-detail-subheader";
import BinderDetailInfo from "./components/binder-detail-info/binder-detail-info";
import BinderSheetList from "./components/binder-sheet-list/binder-sheet-list";

import "./binder-detail.scss";

const readOnlyStatus = [ "waiting", "published", "revoke", "cancelled" ];
const trainBinders = [ "train-driver", "train-officer", "train-auto" ];

const BinderDetail = () => {
	const { id: binderId } = useParams();
	const history = useHistory();

	const [ binder, setBinder ] = useState({});
	const [ existingBinderList, setExistingBinderList ] = useState([]);
	const [ networkError, setNetworkError ] = useState(false);
	const [ readOnly, setReadOnly ] = useState(true);
	const [ drawerStatus, setDrawerStatus ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ analysisResult, setAnalysisResult ] = useState({});
	const [ isSearchingType, setIsSearchingType ] = useState(false);
	const [ pageLoading, setPageLoading ] = useState(true);

	const popupPublishControl = usePopup();
	const popupTypeSuggestionControl = usePopup();
	const popupZoneLinkControl = usePopup();

	const isTrainBinder = useMemo(() => trainBinders.includes(binder.type), [ binder.type ]);

	// Update binder when changed
	const reloadBinder = useCallback((newBinder) => {
		newBinder.isf_version = JSON.parse(newBinder.isf_version);
		newBinder.abrogated_documents = JSON.parse(newBinder.abrogated_documents);
		setBinder({ ...newBinder });
	}, []);

	const analysisBinder = () => {
		runAnalysisBinder(binderId, { type: "binder-approved" }).then(response => {
			setAnalysisResult(response?.data);
		});
	};

	//load binders list with same id
	const fetchExistingBinderById = (id) => {
		searchBinder({ id: id, status: [ "published", "revoke" ] }).then(response => {
			setNetworkError(false);
			const binderList = response?.data;
			setExistingBinderList(binderList);
		}).catch((error) => {
			setExistingBinderList([]);
			throw error;
		});
	};

	// Load binder
	const retrieveBinder = () => {
		setPageLoading(true);
		fetchBinderByTechId(binderId, { extendStation: true }).then((response = {}) => {
			const { data } = response;

			//define if binder info drawer should be opened or not at page loading
			const binderFields = [
				data?.line_manager,
				data?.isf_version,
				data?.abrogated_documents,
			];
			const filledFields = binderFields.filter(field => field?.length > 0);
			setDrawerStatus(filledFields.length === 0);
			reloadBinder(data);
			fetchExistingBinderById(data?.id);

			if (data?.status === "approved") {
				analysisBinder();
			}
		}).catch((error) => {
			setBinder({});
			// Handle redirect on unknown or wrong format's id
			if (error?.response?.status === 400 || error?.response?.status === 404) {
				history.push("/404");
			} else {
				throw error;
			}
		}).finally(() => setPageLoading(false));
	};

	const handleChange = (event) => {
		setIsSaving(true);
		const { name, value } = event.target;
		setBinder({ ...binder, [name]: value });
		// launch analysis on status changed, and status is approved
		if (name === "status" && value === "approved") {
			analysisBinder();
		}
	};

	// Save binder
	const handleBinderUpdate = (updatedBinder) => {
		const binderData = { ...updatedBinder, tech_id: undefined };
		if (binderData.isf_version) {
			binderData.isf_version = JSON.stringify(binderData.isf_version);
		}
		if (binderData.abrogated_documents) {
			binderData.abrogated_documents = JSON.stringify(binderData.abrogated_documents);
		}
		updateBinder(binderId, binderData).then(response => {
			setNetworkError(false);
			setIsSaving(false);
			reloadBinder(response?.data);
		}).catch(() => {
			setNetworkError(true);
			setIsSaving(false);
		});
	};

	// Update binder dynamically when publishedTempAt or status changes (debounced)
	const dynamicUpdateBinder = useDebouncedAction(() => {
		if (binder?.tech_id) {
			handleBinderUpdate(binder);
			setReadOnly(readOnlyStatus.includes(binder.status));
		}
	});


	const updateBinderTypeSuggestionStatus = useCallback(async () => {
		try {
			if (isSearchingType) {
				if (binder.type_suggestion === "waiting") {
					const response = await fetchBinderByTechId(binderId);
					reloadBinder(response?.data);
					setNetworkError(false);
					if (response?.data?.type_suggestion !== "waiting") {
						setIsSearchingType(false);
						popupTypeSuggestionControl.show();
					}
				}
			}
		} catch (error) {
			console.error(error);
			setNetworkError(true);
			setIsSearchingType(false);
		}
		// TODO remove eslint
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ binder.type_suggestion, isSearchingType ]);

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		updateBinderTypeSuggestionStatus();
		const intervalID = setInterval(updateBinderTypeSuggestionStatus, 5000);
		return () => clearInterval(intervalID);
	}, [ updateBinderTypeSuggestionStatus ]);

	const syncReadOnlyWithBinderStatus = () => {
		if (binder?.status) {
			setReadOnly(readOnlyStatus.includes(binder.status));
		}
	};

	// Launch only at first render
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(retrieveBinder, []);

	useEffect(syncReadOnlyWithBinderStatus, [ binder ]);

	useEffectDidUpdate(dynamicUpdateBinder, binder, [ binder.published_temp_at, binder.status, binder.show_legend_pdf ]);

	return (
		<LoadWrapper className="binder-detail__loader" loading={pageLoading}>
			<CdrPage
				className="binder-detail"
				mainInfos={<CdrMainInfos binder={binder} isTrainBinder={isTrainBinder} />}
				subheader={
					<BinderDetailSubheader
						binder={binder}
						readOnly={readOnly}
						reloadBinder={reloadBinder}
						handleChange={handleChange}
						isSaving={isSaving}
					/>
				}
			>

				<PopupPublishBinder
					popupControl={popupPublishControl}
					binder={binder}
					existingBinderList={existingBinderList}
					onSubmitSuccess={reloadBinder}/>
				<PopupTypeSuggestionBinder popupControl={popupTypeSuggestionControl} binder={binder}/>
				<PopupZoneLink popupControl={popupZoneLinkControl} binder={binder} onSubmitSuccess={reloadBinder}/>

				<BinderDetailInfo
					readOnly={readOnly}
					binder={binder}
					existingBinderList={existingBinderList}
					drawerStatus={drawerStatus}
					onSubmit={handleBinderUpdate}
					networkError={networkError}/>
				<div className="binder-detail-page__specification">
					<BinderSearchPublishTypeCard
						binder={binder}
						reloadBinder={reloadBinder}
						showPopupTypeSuggestion={popupTypeSuggestionControl.show}
						setNetworkError={setNetworkError}/>
					<BinderPublishCard
						binderPublishedAt={binder?.published_at}
						binderPublishedTempAt={binder?.published_temp_at}
						binderStatus={binder?.status}
						bindersWarnings={analysisResult?.bindersWarning}
						handleChange={handleChange}
						readOnly={readOnly}
						popupControl={popupPublishControl}
					/>
					{binder?.type === "man" &&
						<BinderZoneLinkCard
							binderZoneLink={binder?.zone_link}
							popupControl={popupZoneLinkControl}
						/>
					}
				</div>
				<BinderSheetList className="binder-detail-page__sheets" binder={binder} analysisResult={analysisResult}/>
			</CdrPage>
		</LoadWrapper>
	);
};

export default BinderDetail;
