import { http } from "../../config";

/**
 * @typedef {Object} Signal
 * @prop {String} tech_id
 * @prop {String} title
 * @prop {String} origin
 * @prop {String} destination
 * @prop {String} binder_tech_id
 */

/**
 * Create a new signal for man binder
 * @param {string} binderTechId
 * @param {Signal} signal
 * @returns {Promise<import("axios").AxiosResponse<Signal>>}
 */
const createBinderSignal = async (binderTechId, signal) => http.post(`/binder/${binderTechId}/signal`, signal);

/**
 * Update an existing signal
 * @param {string} binderTechId The binder technical id
 * @param {Signal} [signal={}]
 * @returns {Promise<import("axios").AxiosResponse<Signal>>}
 */
const updateBinderSignal = async (binderTechId, signal = {}) => {
	const { tech_id: signalTechId, ...body } = signal;
	return http.put(`/binder/${binderTechId}/signal/${signalTechId}`, body);
};

/**
 * @param {string} binderTechId
 * @returns {Promise<import("axios").AxiosResponse<Signal[]>>}
 */
const listAllBinderSignal = async (binderTechId) => http.get(`/binder/${binderTechId}/signal`);

/**
 * @param {string} line
 * @returns {Promise<import("axios").AxiosResponse<Signal[]>>}
 */
const listAllSignalByLine = async (line) => http.get(`/binder/signal/${line}`);

/**
 * @param {string} binderTechId
 * @param {string} signalTechId
 */
const deleteBinderSignalByTechId = async (binderTechId, signalTechId) => http.delete(`/binder/${binderTechId}/signal/${signalTechId}`);

export { createBinderSignal, listAllBinderSignal, deleteBinderSignalByTechId, updateBinderSignal, listAllSignalByLine };
