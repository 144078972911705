import { useMemo } from "react";
const mainMenuRoles =  [ "admin-rer", "regulation-rer", "administrator" ];
const lineOperatorRoles =  [ "line-operator-supervisor", "admin-line", "line-operator" ];
const reglementationRoles =  [ "admin-regulation", "regulation" ];
const trainAgentRole =  [ "train-agent" ];

/**
 *
 * @param {"administrator" | "line-operator-supervisor" | "admin-line" | "line-operator" | "admin-regulation" | "regulation" | "train-agent" | "admin-rer" | "regulation-rer" } role
 * @returns {{
 *	isLineOperatorRole?: boolean,
 *	isTrainAgentRole?: boolean,
 *	isReglementationRole?: boolean,
 *	isAdministratorRole?: boolean,
 *	mainMenuRoles?: boolean
 * }}
 */
const useRoleType = (role) => {
	return useMemo(() => ({
		isLineOperatorRole: lineOperatorRoles.includes(role),
		isTrainAgentRole: trainAgentRole.includes(role),
		isReglementationRole: reglementationRoles.includes(role),
		isAdministratorRole: role === "administrator",
		isMainMenuRole: mainMenuRoles.includes(role)
	}), [ role ]);
};


export default  useRoleType;
