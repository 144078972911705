/**
 * Convert a DraftJS content to a simple text list
 * @param {any} draftJSContent
 * @returns {string[]}
 */
const draftJSContentToText = (draftJSContent) => {
	const blocLines = draftJSContent?.blocks?.map((block) => block.text) || [];
	return blocLines.map(text => text.split("\n")).flat();
};

export default draftJSContentToText;
