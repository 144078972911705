// TODO unit test
/**
 * Determine the sheet color to render
 * @param {string} sheetColor
 * @param {string} binderColor
 * @param {string} [themeColor]
 * @returns {string}
 */
const resolveSheetColor = (sheetColor, binderColor, themeColor) => {
	const replaceColorByTheme = !sheetColor || sheetColor === "default";
	const replaceColorByBinder = replaceColorByTheme && (!themeColor || themeColor === "default");

	// display binder color if sheet and theme has default color
	if (replaceColorByBinder) {
		return binderColor;
	}
	// display theme color if sheet has default color
	if (replaceColorByTheme) {
		return themeColor;
	}
	// display sheet's color
	return sheetColor;
};

export default resolveSheetColor;
