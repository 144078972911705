import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { createContext, useCallback, useEffect, useState } from "react";
import { http, httpNetStatus, httpNoErrorRedirect, httpOnline } from "../../../config";

const OktaContext = createContext({});

/**
 * Okta providers to check user's access and to renew user's token
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const OktaProvider = ({ children }) => {
	const { oktaAuth, authState } = useOktaAuth() || {};
	const [ hasAuth, setHasAuth ] = useState(false);
	const [ online, setOnline ] = useState(false);

	// check if online on the refresh/first arrival on app
	const isOnline = () => {
		httpNetStatus.get("/status", { timeout: 1000, withCredentials: false }).then((resp) => {
			if (resp?.status < 400) {
				setOnline(true);
			}
		});
	};

	// redirect to okta idp auth page
	const login = useCallback(() => {
		const originalUri = toRelativeUrl(window.location.href, window.location.origin);
		oktaAuth?.setOriginalUri(originalUri);
		oktaAuth?.signInWithRedirect();
	}, [ oktaAuth ]);

	const renewToken = () => {
		if (online) {
			oktaAuth?.tokenManager?.renew("accessToken").catch(login);
		}
	};

	// add authState token to axios http instances or redirect to login page
	const handleAccessTokenUpdate = () => {
		if (authState && authState?.isAuthenticated) {
			const accessToken = authState?.accessToken?.accessToken?.toString();
			httpOnline.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
			http.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
			httpNoErrorRedirect.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
			setHasAuth(true);
		} else if (online && authState && !authState?.isAuthenticated) {
			login();
		}
	};

	useEffect(isOnline, []);
	useEffect(renewToken, [ login, oktaAuth?.tokenManager, online ]);
	useEffect(handleAccessTokenUpdate, [ online, authState, login ]);

	return (
		<OktaContext.Provider value={{ hasAuth }}>
			{children}
		</OktaContext.Provider>
	);
};

export { OktaProvider, OktaContext };



