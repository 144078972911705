import React from "react";
import { Button } from "../../../../../../theme";
import { IconBackArrow, IconCheckWithCircle } from "../../../../../../theme/assets/img";

import "./validated-substep-card.scss";

const ValidatedSubstepCard = (props) => {
	const { title, backToSubstep } = props;

	return (
		<div className="validated-substep-card" onClick={() => backToSubstep()}>
			<div className="validated-substep-card__wrapper">
				<Button
					type="button"
					variant="minimal"
					icon={IconBackArrow}
					iconBig={true}
					className="validated-substep-card__wrapper__button"
				/>
				<span className="validated-substep-card__wrapper__title">{title}</span>
			</div>
			<IconCheckWithCircle role="img" aria-hidden="true" focusable="false" className="validated-substep-card__wrapper__icon"/>
		</div>
	);
};

export default ValidatedSubstepCard;
