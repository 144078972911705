import { getLocalStorageContent, setLocaleStorageContent, setStoredLocalStorageContentProperty } from "./localstorage-utils";
import { unpadStartNumberInside } from "./unpad-start-number-inside";

const storageName = "sheet-read-context";
const sheetReadLinkContextProperties = Object.freeze({
	sheetLinksVisited: "sheetLinksVisited",
	sheetProcessLinks: "sheetProcessLinks",
	sheetColors: "sheetColors"
});
const { sheetLinksVisited, sheetProcessLinks, sheetColors } = sheetReadLinkContextProperties;

/**
 * @typedef SheetLink
 * @prop {string} uri
 * @prop {string} sheetId
 * @prop {string} sheetNumber
 * @prop {string} sheetNumberUnpadded
 * @prop {string} sheetTitle
 * @prop {string} [binderTechId]
 * @prop {string} [color] 
 * @prop {number} [jumpToActionPosition] 
 * @prop {"man"|"child"|"parent"|"determination"|"m"|"resume"} [sheetType] 
 */

/**
 * @typedef SheetReadLinkContextContent
 * @prop {SheetLink[]} sheetLinksVisited
 * @prop {SheetLink[]} sheetProcessLinks
 * @prop {Object.<string, string} sheetColors
 */

/** 
 * @param {string} uri 
 * @param {string} sheetId 
 * @param {string} sheetNumber 
 * @param {string} sheetNumberUnpadded
 * @param {string} [binderTechId = null]  
 * @param {string} [color = null] 
 * @param {number} [jumpToActionPosition = null] 
 * @param {"man"|"child"|"parent"|"determination"|"m"|"resume"} [sheetType] 
 * @param {string} [sheetTitle = ""] 
 * @returns {SheetLink}
 */
const createSheetLink = (uri, sheetId, sheetNumber, binderTechId = null, color = null, jumpToActionPosition = null, sheetType, sheetTitle) => ({
	uri,
	sheetId,
	sheetNumber,
	sheetNumberUnpadded: unpadStartNumberInside(sheetNumber) || "",
	sheetTitle,
	...(binderTechId && { binderTechId }),
	...(color && { color }),
	...(jumpToActionPosition && { jumpToActionPosition }),
	...(sheetType && { sheetType }),
});
	
/**
 * create sheet links based on the process history
 * @param {object} process
 * @returns {SheetLink[]}
 */
const processHistoryToSheetLinks = (process) => {
	// determination sheets must be removed when the procedure refers to a non determination record
	const hasNonDeterminationSheet = process.history.some(({ sheet }) => sheet?.type !== "determination");
	const shouldSkipSheet = (sheetType) => hasNonDeterminationSheet && sheetType === "determination";

	const sheetsPassed = Object.values(process.history.reduce((sheetsInProcess ,node) => {
		const sheetTechId = node.sheet.techId;
		if (!sheetsInProcess?.[sheetTechId] && !shouldSkipSheet(node.sheet.type)) {
			sheetsInProcess[sheetTechId] = { ...node.sheet, binderTechId: node.binder.techId  };
		}
		return sheetsInProcess;
	}, {}));
	const sheetLinks = sheetsPassed.map(({ color, number, techId, binderTechId, type, title }) => createSheetLink("", techId, number, binderTechId, color, null, type, title));
	return sheetLinks;
};

/** 
 * @param {SheetLink[]} sheetLinks 
 * @param {string} uriToFilter 
 * @returns {SheetLink[]}
 */
const getUniqueSheetLinks = (sheetLinks, uriToFilter) =>{
	let found = 0;
	return sheetLinks.filter(({ uri }) => {
		if (uri !== uriToFilter) return true;
		found++;
		return found < 2;
	});
};

/**
 * Get the sheet-read-link-context storage content
 * @return {SheetReadLinkContextContent}
 */
const getSheetTrainViewStorageContent = () => getLocalStorageContent(storageName);

/**
 * Update the content property in sheet-read-link-context storage
 * @param {keyof SheetReadLinkContextContent} key 
 * @param {any} value 
 */
const setStoredContentProperty = (key, value) => {
	setStoredLocalStorageContentProperty(storageName, key, value);
};

/**
 * Update the sheetlinks in sheet-read-link-context storage
 * @param {SheetLink[]} arr 
 */
const setStoredSheetLinks = (arr = []) => {
	setStoredContentProperty(sheetLinksVisited, arr);
};

/**
 * Update the sheetlinks passed in a process in sheet-read-link-context storage
 * @param {SheetLink[]} arr 
 */
const setStoredSheetProcessLinks = (arr = []) => {
	setStoredContentProperty(sheetProcessLinks, arr);
};

/**
 * Return the last read sheet link
 * @return {string}
 */
const getLastReadSheetLink = () => {
	const { [sheetLinksVisited]: sheetLinkList = [] } = getSheetTrainViewStorageContent() || {};
	const [ lastLink ] = sheetLinkList.slice(-1);
	return lastLink?.uri || "";
};

/**
 * Update the sheetColors in sheet-read-link-context storage
 * @param {Object.<string, string>} obj 
 */
const setStoredSheetColors = (obj = {}) => {
	setStoredContentProperty(sheetColors, obj);
};

/**
 * reset sheet-read-link-context storage
 * @param {SheetReadLinkContextContent} [defaultContent]
 */
const resetStoredSheetReadLinkContext = (defaultContent = { sheetColors: {}, sheetLinks: [], sheetProcessLinks: [] }) => {
	setLocaleStorageContent(storageName, defaultContent);
};

/**
 * reset sheet-read-link-context storage
 * @returns {string} the tech id of the currently stored process
 */
const getStoredSheetTechId = () => {
	return getSheetTrainViewStorageContent().currentProcess?.tech_id;
};

/**
 * add a sheet link in sheet-read-link-context storage
 * @param {SheetLink} sheetLink The current user associated line
 * @returns {SheetLink[]} new sheetlink array
 */
const addStoredSheetLink = (sheetLink) => {
	let previousLinks = getSheetTrainViewStorageContent()?.sheetLinksVisited || [];
	if (!Array.isArray(previousLinks) && !!previousLinks?.length){
		setStoredSheetLinks();
		previousLinks = [];
	}
	
	const uniqueLinks = getUniqueSheetLinks([ ...previousLinks, sheetLink ], sheetLink?.uri);
	setStoredContentProperty(sheetLinksVisited, uniqueLinks);
	return uniqueLinks;
};

/**
 * Update the associated Line in sheet-read-link-context storage
 * @param {string} uri 
 * @param {string} color 
 * @returns {Object.<string, string>} colorMap 
 */
const addStoredSheetColor = (uri, color) => {
	let colorMap = getSheetTrainViewStorageContent()?.sheetColors;
	if (!colorMap){
		setStoredSheetColors();
		colorMap = {};
	}
	colorMap[uri] = color;	
	setStoredContentProperty(sheetColors, colorMap);
	return colorMap;
};

export { 
	sheetReadLinkContextProperties, 
	resetStoredSheetReadLinkContext,
	addStoredSheetLink, 
	setStoredSheetLinks,
	setStoredSheetProcessLinks, 
	getSheetTrainViewStorageContent, 
	createSheetLink, 
	getUniqueSheetLinks, 
	addStoredSheetColor, 
	setStoredSheetColors, 
	processHistoryToSheetLinks,
	getStoredSheetTechId,
	getLastReadSheetLink
};
