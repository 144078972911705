import React, { createContext, useCallback, useContext, useState, useRef } from "react";
import { updateSheet } from "../sheet.services";
import { copyTemplate } from "../pages/sheet-detail/utils/handle-copy-paste-template";
import { findTemplate, insertEmptyTemplateInList, updateTemplate } from "./sheet-content-context-utils";
import { useTemplateTypePosition } from "../../../shared/utils/use-template-type-position";

const SheetContentContext = createContext({});
const updateDelay = 100;

const SheetContentProvider = props => {
	const { children } = props;

	const jumpToActionPositionRef = useRef(null);
	const [ sheetData, setSheetData ] = useState({});
	const [ nodeList, setNodeList ] = useState([]);
	const [ initialNodeList, setInitialNodeList ] = useState({});
	const [ activeLocation, setActiveLocation ] = useState({});
	const [ nodeHistory, setNodeHistory ] = useState([]);
	const [ nodeHistoryPointer, setNodeHistoryPointer ] = useState(0);
	const [ additionalInformation, setAdditionalInformation ] = useState({});
	const { getTemplateTypePosition, getSubTemplateTypePosition } = useTemplateTypePosition(nodeList, nodeHistory);

	const setCopiedTemplate = (copy) => {
		setNodeList(previousNodeList => {
			const newNodeList = updateTemplate(previousNodeList, activeLocation, () => copyTemplate(copy));
			addNodeHistory(newNodeList);
			return newNodeList;
		});
	};

	const getCurrentActive = useCallback(
		() => findTemplate(nodeList, activeLocation),
		[ nodeList, activeLocation ]
	);

	/**
	 * Save the sheet content
	 * @param {string} binderId The sheet's binder technical id
	 * @param {string} sheetId The sheet's technical id
	 */
	const saveSheetContent = async (binderId, sheetId) => {
		const nodeListStringify = JSON.stringify(nodeList);
		// Save if there are some changes in the nodeList
		const isChange = nodeListStringify !== JSON.stringify(initialNodeList);
		const isNotEmpty = Array.isArray(nodeList) && nodeList?.length >= 0;
		if (isChange && isNotEmpty) {
			return updateSheet(binderId, sheetId, { content: JSON.parse(nodeListStringify) });
		}
	};

	const addNodeHistory = (nodeArray) => {
		// Deep clone nodes
		let newHistory = JSON.parse(JSON.stringify(nodeHistory));
		const newHistoryItem = JSON.parse(JSON.stringify(nodeArray));

		// Remove all elements after history pointer
		if (nodeHistoryPointer < newHistory.length - 1) {
			newHistory = newHistory.slice(0, nodeHistoryPointer + 1);
		}

		newHistory = [ ...newHistory, newHistoryItem ];

		// Remove first element
		if (newHistory.length > 11) {
			newHistory = newHistory.slice(1);
		}
		setNodeHistoryPointer(newHistory.length - 1);
		setNodeHistory(newHistory);
	};

	const previousNodeHistory = () => {
		if (nodeHistoryPointer > 0) {
			setNodeHistoryPointer(nodeHistoryPointer - 1);
			const newNodeList = JSON.parse(JSON.stringify(nodeHistory[nodeHistoryPointer - 1]));
			setNodeList(newNodeList);
		}
	};

	const nextNodeHistory = () => {
		if (nodeHistoryPointer < nodeHistory.length - 1) {
			setNodeHistoryPointer(nodeHistoryPointer + 1);
			const newNodeList = JSON.parse(JSON.stringify(nodeHistory[nodeHistoryPointer + 1]));
			setNodeList(newNodeList);
		}
	};

	// TODO refactor template content update
	const updateCurrentNodeContent = (nodeArray, newNodeContent) => {
		// Build new template by merging previous with provided template (merge also content field)
		const buildNewTemplate = (previousTemplate = {}) =>
			({ ...previousTemplate, content: { ...previousTemplate.content, ...newNodeContent } });

		return updateTemplate(nodeArray, activeLocation, buildNewTemplate);
	};

	const isLastTemplate = (location) => {
		const { id, parentId, questionEnd } = location;
		if (parentId){
			const parentPosition = nodeList.findIndex(template => template.id === parentId);
			return nodeList.length - 1 === parentPosition && questionEnd;
		} else {
			const position = nodeList.findIndex((template => template.id === id));
			return nodeList.length - 1 === position;
		}
	};

	const getTemplateByLocation = (location) =>  findTemplate(nodeList, location);

	const setCurrentNodeContent = (newNodeContent, setHistory) => {
		setNodeList(previousNodeList => {
			const newNodeList = updateCurrentNodeContent(previousNodeList, newNodeContent);
			if (setHistory) {
				addNodeHistory(newNodeList);
			}
			return newNodeList;
		});
	};

	const initSheetContent = (content) => {
		if (nodeHistory.length < 1) {
			addNodeHistory(content);
		}
		setNodeList(content);
		// Set initialNodeList if it has not been already set
		if (Array.isArray(initialNodeList) && initialNodeList.length === 0) {
			// content deep copy
			setInitialNodeList(JSON.stringify(content));
		}
	};

	const insertEmptyNodeAt = (position = 0, location) => {
		setNodeList(previousNodeList => {
			const newNodeList = insertEmptyTemplateInList(previousNodeList, position);
			const insertedTemplate = newNodeList[position];
			setActiveLocation({ ...location, id: insertedTemplate.id });
			addNodeHistory(newNodeList);
			return newNodeList;
		});
	};

	const setCurrentNodeType = (type) => {
		setNodeList(previousNodeList => {
			// Build new template from old content. Set template type / clean previous data
			const buildNewTemplate = (previousTemplate) => ({ id: previousTemplate.id, type });

			const newNodeList = updateTemplate(previousNodeList, activeLocation, buildNewTemplate);

			// Add changed type to history for templates without content
			if ([ "termination", "pccInstruction", "officerIntervention", "pageBreak", "space" ].includes(type)) {
				addNodeHistory(newNodeList);
			}

			return newNodeList;
		});
	};

	const setActionToJumpTo = (index) => {
		jumpToActionPositionRef.current = index;
	};

	const isAtEndHistory = nodeHistoryPointer === nodeHistory.length - 1;
	return (
		<SheetContentContext.Provider value={{
			setActiveLocation,
			activeLocation,
			saveSheetContent,
			nodeList,
			setNodeList,
			nodeHistory,
			nodeHistoryPointer,
			isAtEndHistory,
			insertEmptyNodeAt,
			initSheetContent,
			setCurrentNodeType,
			setCurrentNodeContent,
			getCurrentActive,
			isLastTemplate,
			addNodeHistory,
			nextNodeHistory,
			previousNodeHistory,
			additionalInformation,
			setAdditionalInformation,
			setCopiedTemplate,
			getTemplateByLocation,
			updateDelay,
			getTemplateTypePosition,
			getSubTemplateTypePosition,
			sheetData,
			setSheetData,
			setActionToJumpTo, jumpToActionPosition: jumpToActionPositionRef.current
		}}>
			{children}
		</SheetContentContext.Provider>
	);
};

/**
 *
 * @returns {{
 * 	setActiveLocation: React.Dispatch<React.SetStateAction<{}>>,
 *	activeLocation: object,
 *	saveSheetContent: (binderId: string, sheetId: string) => Promise<AxiosResponse<any>>,
 *	nodeList: object[],
 *	setNodeList: React.Dispatch<React.SetStateAction<any[]>>,
 *	nodeHistory: object[],
 *	nodeHistoryPointer: number,
 *	isAtEndHistory: boolean,
 *	insertEmptyNodeAt: (position: number, location: object) => void,
 *	initSheetContent: (content: object) => void,
 *	setCurrentNodeType: (type: string) => void,
 *	setCurrentNodeContent: (newNodeContent: object, setHistory: function) => void,
 *	getCurrentActive: SheetTemplate,
 *	isLastTemplate: boolean,
 *	addNodeHistory: (nodeArray: object) => void,
 *	nextNodeHistory: () => void,
 *	previousNodeHistory: () => void,
 *	additionalInformation: object,
 *	setAdditionalInformation: React.Dispatch<React.SetStateAction<{}>>,
 *	setCopiedTemplate: (copy: object) => void,
 *	getTemplateByLocation: (location: object) => SheetTemplate,
 *	updateDelay: number,
 *	getTemplateTypePosition: import("../../../shared/utils/use-template-type-position").getTemplateTypePositionCallback,
 *	getSubTemplateTypePosition: import("../../../shared/utils/use-template-type-position").getSubTemplateTypePositionCallback,
 *	sheetData: object,
 *	setSheetData: React.Dispatch<React.SetStateAction<{}>>,
 *	loadSheetData: import("./use-sheet-data").loadSheetDataCallback,
 *  refreshSheetData: import("./use-sheet-data").setSheetDataAndContentCallback
 * }}
 */
const useSheetContentContext = () =>  useContext(SheetContentContext);


export { SheetContentContext, SheetContentProvider, useSheetContentContext };
