import React from "react";
import { useTranslation } from "react-i18next";

import "./damage-location-mission-code.scss";

const DamageLocationMissionCode = (props) => {
	const { fieldName, formData, handleChange } = props;
	const { mission_code: missionCode } = formData;
	const { t } = useTranslation();

	const handleKeyPress = (e) => {
		const regex = /^[a-zA-Z-0-9]{0,6}$/;
		const validRegex = regex.test(e.key);
		if (!validRegex) {
			e.preventDefault();
		}
	};

	return (
		<>
			<label className={"damage-location__mission-code__form-wrapper__input-wrapper"}>
				<input
					aria-label="Enter character"
					placeholder={t("process:process-rer-resume.damage-location.mission-code-placeholder")}
					name={fieldName}
					type="text"
					className="damage-location__mission-code__input-wrapper__text-area"
					value={missionCode}
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					required={false}
					maxLength={6}
				/>
			</label>
		</>
	);
};

export default DamageLocationMissionCode;
