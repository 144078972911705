import React, { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDndSheetReference } from "../../../../../../../../shared";
import { useSheetModeContext } from "../../../../../../context/sheet-mode-context";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import "./table-template-view.scss";

/**
 * View part of a table task configured from the panel
 * @param {object} props
 * @returns {JSX.Element}
 */
const TableTemplateView = (props) => {
	const {
		template, currentPosition, allowInsertAfter, moveCard, processCurrentTemplate, location
	} = props;
	const { readOnly } = useSheetModeContext();
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref);
	const table = template?.content;


	const renderTableHeader = (header) => {
		return (
			<tr className="table-template-view__table-tr">
				<th className="table-template-view__table-th">{header.columnOne}</th>
				<th className="table-template-view__table-th">{header.columnTwo}</th>
			</tr>
		);
	};

	const renderTableLine = (line) => {
		return (
			<tr className="table-template-view__table-tr">
				<td className="table-template-view__table-td">{line.columnOne}</td>
				<td className="table-template-view__table-td">{line.columnTwo}</td>
			</tr>
		);
	};


	return (
		<TemplateWrapper
			id={template.id}
			className={classNames( "table-template-view", { "table-template-view--read-only": readOnly })}
			template={template}
			location={location}
			reference={ref} isDragging={isDragging} dragRef={drag}
			currentPosition={currentPosition}
			processCurrentTemplate={processCurrentTemplate}
			allowInsertAfter={allowInsertAfter}>
			<div className="table-template-view__text">
				<div className="table-template-view__content">
					<table className="table-template-view__table">
						<thead className="table-template-view__table-head">
							{table?.header && renderTableHeader(table.header)}
						</thead>
						<tbody className="table-template-view__table-body">
							{table?.lines?.map(renderTableLine)}
						</tbody>
					</table>
				</div>
			</div>
		</TemplateWrapper>
	);
};

TableTemplateView.propTypes = {
	template: PropTypes.object,
	currentPosition: PropTypes.number,
	allowInsertAfter: PropTypes.bool,
	moveCard: PropTypes.any,
	processCurrentTemplate: PropTypes.bool
};

export default TableTemplateView;
