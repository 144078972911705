import React, { useEffect, useMemo } from "react";
import qs from "qs";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
	buildTrainProcessBinderList,
	unpadStartNumberInside,
	useInReadContextCheck,
	useRailwayOnlineReturn,
	useSprContext
} from "../../../../shared";
import { SprPage } from "../../../../theme";
import { fetchSheetByBinderAndNumber, fetchSheetById } from "../../sheet.services";
import SheetPreview from "../../components/sheet-preview/sheet-preview";
import { useSheetContentContext } from "../../context/sheet-content-context";
import { SheetModeContextProvider, useSheetModeContext } from "../../context/sheet-mode-context";
import { ProcessProvider, useRunProcessContext } from "../../../process/components/context-run-process/context-run-process";
import SprSheetDetailSubheader from "./components/spr-sheet-detail-subheader/spr-sheet-detail-subheader";
import ProcessTrainSubheader from "../../../process/components/process-train-subheader/process-train-subheader";
import ProcessTrainBreadcrumbSubheader from "../../../process/components/process-train-breadcrumb-subheader/process-train-breadcrumb-subheader";
import { getLastReadSheetLink } from "../../../../shared/utils/sheet-read-utils";
import "./spr-sheet-detail.scss";

/**
 * sheet detial inner component
 * @returns {JSX.Element}
 */
const SprSheetDetailInner = ({ disableLineSwitch }) => {
	useRailwayOnlineReturn(false);

	const { isInReadContext } = useInReadContextCheck();
	const history = useHistory();
	const { sheetId = "", binderId, processId } = useParams();
	const { t } = useTranslation();
	const { processInfo, getActiveTask } = useRunProcessContext();
	const { initSheetContent, sheetData, setSheetData } = useSheetContentContext();
	const { readOnly } = useSheetModeContext();
	const { side } = useSprContext();

	// retrieve query params if any
	const location = useLocation();
	const queryParam = qs.parse(location?.search || "", { ignoreQueryPrefix: true }) || {};
	const { sheetNumber = "" } = queryParam;

	const activeTask = useMemo(() => {
		const rootActiveTask = getActiveTask();

		const isInQuestion = rootActiveTask?.type === "question" && rootActiveTask?.choice;
		if (isInQuestion){
			// Get active sub task
			const questionSide = rootActiveTask?.content && rootActiveTask?.content[rootActiveTask?.choice];
			const { items = [], "end-item": endItem = {} } = questionSide || {};
			const taskList = [ ...items, endItem ];
			return taskList.find(({ active }) => active);
		}

		const isInMultipleChoice = rootActiveTask?.type === "multipleChoice"
			&& rootActiveTask?.content?.choices?.filter(({ chosen }) => chosen)?.length > 0;
		if (isInMultipleChoice){
			// Get active sub task
			const taskList = rootActiveTask?.content?.subNodes || [];
			return taskList.find(({ active }) => active);
		}

		return rootActiveTask;
	}, [ getActiveTask ]);

	const refreshSheetData = (sheet, updateContent = true) => {
		const { content = [], ...restSheet } = sheet;
		const newSheetData = { ...restSheet, binder_id: undefined };
		if (updateContent) {
			initSheetContent(content);
		}

		setSheetData(newSheetData);
	};

	const fetchSheetData = () => {
		if (sheetNumber) {
			fetchSheetByBinderAndNumber(binderId, sheetNumber)
				.then((response) => {
					const { data: sheet = {} } = response;
					const formattedSheetData = {
						...sheet,
						number: unpadStartNumberInside(sheet.number)
					};
					refreshSheetData(formattedSheetData);
				}).catch(error => {
					if (error?.response?.status === 400 || error?.response?.status === 404) {
						history.push("/404");
					} else {
						throw error;
					}
				});
		} else {
			fetchSheetById(binderId, sheetId)
				.then((response) => {
					const { data: sheet = {} } = response;
					const formattedSheetData = {
						...sheet,
						number: unpadStartNumberInside(sheet.number)
					};
					refreshSheetData(formattedSheetData);
				}).catch(error => {
					if (error?.response?.status === 400 || error?.response?.status === 404) {
						history.push("/404");
					} else {
						throw error;
					}
				});
		}
	};

	const scrollToCurrentTemplate = () => {
		const divTemplate = document.getElementById(activeTask?.id?.toString());
		if (divTemplate) {
			divTemplate.scrollIntoView();
		}
	};

	// Return to last visited sheet else to sheet list
	const subHeaderReturnLink = getLastReadSheetLink() || buildTrainProcessBinderList({ processId }, "spr", true);
	const Subheader = isInReadContext ? <>
		<ProcessTrainSubheader hideButton="sheet"/>
		<ProcessTrainBreadcrumbSubheader
			showOverlay={false}
			processExecutorLabel={t("process:process-train-breadcrumb-subheader.executor-complete")}
			returnLink={subHeaderReturnLink}
			returnLabel={t("process:process-train-breadcrumb-subheader.return-binder")}
		/>
	</> : <SprSheetDetailSubheader sheetData={sheetData} processInfo={processInfo}/>;

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchSheetData, [ binderId, sheetId, processId ]);
	useEffect(scrollToCurrentTemplate, [ activeTask?.id, initSheetContent ]);

	return (
		<SprPage
			className={classNames("spr-sheet-detail-page", { "spr-sheet-detail-page--read-only": readOnly, "spr-sheet-detail-page--read": isInReadContext })}
		    subheader={Subheader}
			borderOverlayType={isInReadContext && "read"}
			allowLineSwitch={side === "train"}
			disabledLineSwitch={disableLineSwitch}
		>
			<DndProvider backend={HTML5Backend}>
				<SheetPreview
					currentTemplate={activeTask}
					sheetType={sheetData?.type}
					sheetNumber={sheetData?.number}
					sheetColor={sheetData?.color}
					binderType={sheetData?.binder_type}
				/>
			</DndProvider>
		</SprPage>
	);
};

const SprSheetDetail = (props) => (
	<SheetModeContextProvider readOnly={true} viewType={"spr"}>
		<ProcessProvider>
			<SprSheetDetailInner {...props}/>
		</ProcessProvider>
	</SheetModeContextProvider>
);

export default SprSheetDetail;
