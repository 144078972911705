import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppSetupContext } from "../../../../../../shared";
import { formatRoleName } from "../../../../utils/format-role-name";
import "./filter-role-label.scss";


/**
 * Render the FilterRoleLabel components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.role
 * @returns {JSX.Element}
 */
const FilterRoleLabel = props => {
	const { className, role } = props;
	const { user: currentUser } = useAppSetupContext();
	const { origin: userOrigin } = currentUser || {};
	const { t } = useTranslation();

	return (
		<label className={classnames(className, "filter-role-label")} htmlFor={role}>
			<span className="filter-role-label__label">{t(`user:admin-user-list.list.user-info.role.${formatRoleName(role, userOrigin)}`)}</span>
		</label>
	);
};

FilterRoleLabel.propTypes = {
	classnames: PropTypes.string,
	role: PropTypes.string.isRequired
};

export default FilterRoleLabel;
