import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../theme";
import classNames from "classnames";

import "./train-trouble-form-footer.scss";

const TrainTroubleFormFooter = (props) => {
	const { line, handleBackClick = () => {}, handleReset, isSubmitBlocked, handleSubmit, fieldName = "", fullPage = false, stepNumber } = props;
	const { t } = useTranslation();
	const confirmBtnTitle = fieldName ? t(`process:process-rer-resume.footer.confirm-${fieldName}`) : t("process:process-rer-resume.footer.confirm");
	const showBackButton = line === "B" && (stepNumber === 1 || stepNumber === 3);

	return (
		<div className={ classNames("process-rer-resume__footer", { "process-rer-resume__footer--two-buttons": !showBackButton })}>
			{showBackButton && <div>
				<Button
					variant="link"
					onClick={handleBackClick}
					label={t("process:process-rer-resume.footer.back")}
					className="process-rer-resume__footer__button"
				/>
			</div>
			}
			<div className={ classNames("process-rer-resume__footer__button__wrapper", { "process-rer-resume__footer__button__wrapper--full-page": fullPage })}>
				<Button
					variant="secondary"
					onClick={handleReset}
					label={t("process:process-rer-resume.footer.reset")}
					className="process-rer-resume__footer__button"
				/>
				<Button
					variant="primary"
					type="submit"
					disabled={isSubmitBlocked}
					onClick={handleSubmit}
					label={confirmBtnTitle}
					className="process-rer-resume__footer__button"
				/>
			</div>
		</div>
	);
};

export default TrainTroubleFormFooter;
