import React, { useState, useEffect } from "react";
import classNames from "classnames";
import TimeKeeper from "react-timekeeper";
import { setHours, setMinutes, setSeconds, setMilliseconds, getHours, getMinutes, parseISO } from "date-fns";
import { ReactComponent as IconTime } from "../../../assets/img/icon-time.svg";
import { IconWrapper } from "../../../index";
import { useTranslation } from "react-i18next";
import { formatJsonDate } from "../../../../shared";
import { dateFormatTime } from "../../../../domains/process/utils";
import "./time-chooser.scss";

const TimeChooser = (props) => {
	const { value, label, onChange, className, disabled } = props;
	const classes = classNames("time-chooser", className);
	const { t } = useTranslation();
	const [ time, setTime ] = useState({});
	const [ newValue, setNewValue ] = useState();
	const [ showTime, setShowTime ] = useState(false);

	const handleDropdownBlur = () => {
		setShowTime(false);
		onChange(newValue);
	};

	const handleChange = (newTime) => {
		let currentDate = new Date();

		let formattedDate = setHours(newValue, newTime.hour);
		formattedDate = setMinutes(formattedDate, newTime.minute);
		formattedDate = setSeconds(formattedDate, 0);
		formattedDate = setMilliseconds(formattedDate, 0);

		if (currentDate > formattedDate) {
			let newTime2 = { hour: getHours(currentDate), minute: getMinutes(currentDate) };
			setTime(newTime2);
			setNewValue(currentDate);
		} else {
			setNewValue(formattedDate);
		}
	};

	const refreshDisplayedTime = () => {
		let originValue = new Date();
		let newTime = { hour: getHours(new Date()), minute: getMinutes(new Date()) };

		if (value) {
			originValue = value;
			if (typeof originValue === "string") {
				originValue = parseISO(value);
			}
			newTime = { hour: getHours(originValue), minute: getMinutes(originValue) };
		}

		setNewValue(originValue);
		setTime(newTime);
	};

	useEffect(refreshDisplayedTime, [ value ]);

	return (
		<div className={classes}>
			{(showTime && !disabled) &&
			<div className="time-chooser__clock" tabIndex={0} onBlur={handleDropdownBlur}>
				<TimeKeeper
					time={time}
					onChange={handleChange}
					doneButton={() => (
						<div className="time-chooser__clock__confirm" onClick={handleDropdownBlur}>
							{t("popup.confirm.cta-label-confirm")}
						</div>
					)}
					hour24Mode
					switchToMinuteOnHourSelect
				/>
			</div>
			}
			{!showTime ?
				<button onClick={() => setShowTime(true)} className="time-chooser__field">
					{value ? formatJsonDate(value, dateFormatTime) : label}
					<IconWrapper className="time-chooser__field__icon" Component={IconTime}/>
				</button>
				:
				<span className="time-chooser__field">
					{value ? formatJsonDate(value, dateFormatTime) : label}
					<IconWrapper className="time-chooser__field__icon" Component={IconTime}/>
				</span>
			}
		</div>
	);
};

export default TimeChooser;
