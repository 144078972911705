import React from "react";
import classname from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./binder-detail-card-template.scss";

/**
 * BinderDetailCardTemplate component
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.title
 * @param {string[]} [props.binderWarnings]
 * @returns {JSX.Element}
 */
const BinderDetailCardTemplate = props => {
	const { title = "", className = "", binderWarnings, children } = props;
	const { t } = useTranslation();

	return (
		<div className="binder-detail-card-template">
			<div className="binder-detail-card-template__title">
				{title}
			</div>
			<div className={classname("binder-detail-card-template__content", className)}>
				{children}
			</div>
			{Array.isArray(binderWarnings) && binderWarnings.length > 0 &&
			<div className="binder-detail-card-template__warning">
				{t(
					"binder:binder-detail.specification.warning-label",
					{ binders: binderWarnings.map((element) => element.id).join(", ") }
				)}
			</div>
			}
		</div>
	);
};

BinderDetailCardTemplate.propTypes = {
	className: PropTypes.string,
	title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
	binderWarnings: PropTypes.arrayOf([ PropTypes.string ]),
	children: PropTypes.node
};

export default BinderDetailCardTemplate;
