import React from "react";
import IconWrapper from "../icon-wrapper/icon-wrapper";
import { IconEye } from "../../assets/img";

import "./eye-triangle.scss";

const EyeTriangle = () => <span className="eye-triangle">
	<IconWrapper
		className="eye-triangle__icon"
		Component={IconEye}
	/>
</span>;

export default EyeTriangle;
