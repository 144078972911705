import React, { memo, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { SheetContentContext } from "../../../domains/sheet/context/sheet-content-context";
import { useSheetReadContext } from "../../../domains/sheet/pages/railway-sheet-view/components/sheet-read-context/sheet-read-context";

/**
 * @typedef SheetLink
 * @prop {string} uri
 * @prop {string} sheetId
 * @prop {string} sheetNumber
 * @prop {string} sheetNumberUnpadded
 * @prop {string} [binderTechId]
 * @prop {number} [jumpToActionPosition] 
 * @prop {"man"|"child"|"parent"|"determination"|"m"|"resume"} [sheetType] 
 */

/**
 * wrap a component in clickable link component to a sheet
 * @param {object} props 
 * @param {boolean} props.isLink 
 * @param {SheetLink} props.sheetLink
 * @returns {JSX.Element}
 */
const SheetLinkWrapper = (props) => {	
	const { addSheetLink } = useSheetReadContext();
	const { isLink, children, sheetLink } = props;
	const { uri, jumpToActionPosition } = sheetLink || {};
	const { setActionToJumpTo = () => {} } = useContext(SheetContentContext);
	const handleLinkClick = (e) => {
		e.stopPropagation();
		addSheetLink(sheetLink);
		setActionToJumpTo(jumpToActionPosition);
	};
	
	return isLink ? <Link to={uri} onClick={handleLinkClick}>{children}</Link> : <>{children}</>;
};

SheetLinkWrapper.prototype = {
	isLink: PropTypes.bool,
	sheetLink: PropTypes.shape({
		uri: PropTypes.string,
		sheetId: PropTypes.string,
		sheetType: PropTypes.string,
		sheetNumber: PropTypes.string,
		sheetNumberUnpadded: PropTypes.string,
		binderTechId: PropTypes.string,
		jumpToActionPosition: PropTypes.number,
	}),
};

export default memo(SheetLinkWrapper);