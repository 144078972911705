import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PopupConfirm } from "../../../../../../theme";
import useStepTemplate from "../../utils/use-step-template";
import { setDisplayDeletePopup } from "../../utils/handle-session-storage";
import "./popup-delete-template.scss";

const PopupDeleteTemplate = props => {
	const { location, popupControl } = props;
	const [ isVisible, setIsVisible ] = useState(true);
	const { t } = useTranslation();
	const { deleteStepTemplate } = useStepTemplate();

	const handleConfirm = () => {
		setDisplayDeletePopup(isVisible);
		deleteStepTemplate(location);
	};

	const handleDisplayPopUp = (event) => {
		setIsVisible(!event.target.checked);
	};

	return (
		<PopupConfirm
			popupControl={popupControl}
			onConfirm={handleConfirm}
			title={t("sheet:popup.delete-template.title")}
			ctaConfirmLabel="sheet:popup.delete-template.cta-confirm-label">
			<div className="popup-delete-template">
				<p className="popup-delete-template__text">{t("sheet:popup.delete-template.text")}</p>
				<div className="popup-delete-template__display-message">
					<input
						className="display-message__checkbox"
						type="checkbox"
						id="display"
						name="display"
						onChange={handleDisplayPopUp}
					/>
					<label htmlFor="display" className="display-message__message">
						{t("sheet:popup.delete-template.display-message")}
					</label>
				</div>
			</div>
		</PopupConfirm>
	);
};

export default PopupDeleteTemplate;
