import React from "react";
import classNames from "classnames";
import { IconArrowRight } from "../../../../../../../../theme/assets/img";
import SheetType from "../../../../../sheet-type/sheet-type";
import { IconWrapper } from "../../../../../../../../theme";
import "./appendix-list-view.scss";

const AppendixListView = (props) => {
	const { className, appendixes = [] } = props;
	const hasAppendix = appendixes.length > 0 && appendixes[0].sheetNumber !== "" && appendixes[0].sheetId !== "";

	const renderAppendixView = (appendix) => (
		<li key={appendix?.appendixId} className="appendix-list-view__item">
			<SheetType
				type={"appendix"}
				sheetNumber={appendix.sheetNumber}
				color={appendix.sheetColor}
			/>
		</li>
	);

	if (hasAppendix) {
		return (
			<ul className={classNames("appendix-list-view", className)}>
				<IconWrapper className="appendix-list-view__item__icon" Component={IconArrowRight} />
				{appendixes.map(renderAppendixView)}
			</ul>);
	}

	return null;

};

export default AppendixListView;

