import React from "react";
import classNames from "classnames";
import { IconWrapper } from "../../index";
import { ReactComponent as IconSpinner } from "../../assets/img/icon-spinner.svg";
import "./overlay.scss";

const Overlay = props => {
	const { text, visible } = props;

	return (
		<div className={classNames("overlay", { visible })}>
			<span className="overlay__text">
				<IconWrapper className="overlay__text__icon" Component={IconSpinner}/>
				{text}
			</span>
		</div>
	);
};

export default Overlay;
