import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { SheetLinkWrapper } from "../../../../../../shared";
import resolveSheetColor from "../../../../utils/resolve-sheet-color";
import SheetType from "../../../../components/sheet-type/sheet-type";

/**
 * @typedef SheetLink
 * @prop {string} uri
 * @prop {string} sheetId
 * @prop {string} sheetNumber
 * @prop {string} sheetNumberUnpadded
 * @prop {string} [binderTechId]
 * @prop {string} [color] 
 * @prop {number} [jumpToActionPosition] 
 * @prop {"man"|"child"|"parent"|"determination"|"m"|"resume"} [sheetType] 
 */

/**
 * @param {object} props 
 * @param {SheetLink} props.sheetLink
 * @param {string} props.sheetColor
 * @param {boolean} [props.disabled = false]
 * @returns {JSX.Element}
 */
const SheetTypeLink = (props) => {
	const { sheetLink = {}, sheetColor, disabled = false } =  props;
	const { sheetNumberUnpadded, sheetType = "" } = sheetLink;
	const { pathname } = useLocation();

	return (<SheetLinkWrapper isLink={!disabled && pathname !== sheetLink?.uri} sheetLink={sheetLink}>
		<SheetType
			className="sheet-type--breadcrumb"
			type={sheetType} 
			sheetNumber={sheetNumberUnpadded} 
			color={resolveSheetColor(sheetColor, null)} 
			isBinderTrain={true}
		/>
	</SheetLinkWrapper>);
};

SheetTypeLink.prototype = {
	sheetLink: PropTypes.shape({
		sheetNumberUnpadded: PropTypes.string,
		sheetType: PropTypes.string,
	}),
	sheetColor: PropTypes.string,
	disabled: PropTypes.bool,
};


export default SheetTypeLink;
