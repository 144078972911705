import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PopupDefault, Button, ErrorMessage } from "../../../../../../theme";
import { deleteTrainByTechId } from "../../../../train.services";

import "./popup-delete-train.scss";

/**
 * PopupDeleteTrain component
 * @param {object} props
 * @param {function} props.onSubmitSuccess Callback called after successful submit
 * @param {object} props.popupControl
 * @param {import("../../../../train.services").Train} props.train
 * @returns {JSX.Element}
 */
const PopupDeleteTrain = props => {
	const { onSubmitSuccess = () => {}, popupControl, train = {} } = props;
	const { tech_id: trainTechId, id: trainId } = train;
	const { t } = useTranslation();

	const [ networkError, setNetworkError ] = useState(false);
	const [ inRunningProcessError, setInRunningProcessError ] = useState(false);


	const handleCancel = e => {
		e.preventDefault();
		popupControl.hide();
	};

	const handleSubmit = e => {
		e.preventDefault();
		deleteTrainByTechId(trainTechId)
			.then(popupControl.hide)
			.then(onSubmitSuccess)
			.catch((error)=>{
				setInRunningProcessError(error.response.status === 500);
				setNetworkError(true);
			});

	};

	return (
		<PopupDefault
			className="popup-delete-train__wrapper"
			popupControl={popupControl}
			title={t("train:popup.delete-train.title")}
		>
			<div className="popup-delete-train__content">
				<h1 className="popup-delete-train__title">
					{t("train:popup.delete-train.text.title")}
				</h1>
				<span className="popup-delete-train__question">
					{t("train:popup.delete-train.text.question")}
				</span>
				<h1 className="popup-delete-train__train-id">
					{trainId}
				</h1>
				<span className="popup-delete-train__note">
					{t("train:popup.delete-train.text.note")}
				</span>
				<div className="popup-delete-train__controller">
					<Button
						variant="minimal"
						className="popup-delete-train__cancel"
						onClick={handleCancel}
						label={t("popup.confirm.cta-label-cancel")}
					/>
					<Button
						variant="primary"
						className="popup-delete-train__confirm"
						onClick={handleSubmit}
						label={t("train:popup.delete-train.cta-confirm-label")}
					/>
				</div>
				{ networkError && <ErrorMessage className="popup-delete-train__network-error">
					{t(inRunningProcessError ? "error.in-process" : "error.default")}
				</ErrorMessage> }
			</div>
		</PopupDefault>
	);
};


PropTypes.PopupDeleteTrain = {
	onSubmitSuccess: PropTypes.func,
	popupControl:  PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool
	}),
	train: PropTypes.shape({
		id: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
	}).isRequired,

};

export default PopupDeleteTrain;
