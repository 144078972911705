import { useState, useCallback, useRef } from "react";

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 */

const usePopup = () => {
	const [ visible, setVisible ] = useState(false);
	const onHide = useRef(() => {});
	const show = useCallback(() => setVisible(true), []);
	const hide = useCallback(() => {
		setVisible(false);
		if (onHide.current){
			onHide.current();
		}
	}, []);

	return {
		visible,
		setVisible,
		show,
		hide,
		onHide
	};
};

export default usePopup;
