import React from "react";
import Proptypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../../../theme";
import { usePopup } from "../../../../../../../../shared";
import { PopupDeleteSheet } from "../../../../../../../sheet";
import { IconTrash } from "../../../../../../../../theme/assets/img";

/**
 * Render the DeleteSheetButton component
 * @param {object} props
 * @param {object} props.sheet
 * @param {function} props.onDelete
 * @param {string} props.defaultColor
 * @returns {JSX.Element}
 */
const DeleteSheetButton = (props) => {
	const { sheet, onDelete = () => {}, defaultColor } = props;

	const { t } = useTranslation();
	const popupDeleteSheetControl = usePopup();

	return (
		<>
			<PopupDeleteSheet
				popupControl={popupDeleteSheetControl}
				sheet={sheet}
				onConfirm={onDelete}
				defaultColor={defaultColor}
			/>
			<Button 
				variant="minimal"
				icon={IconTrash}
				iconTooltipContent={t("binder:binder-detail.sheet-list.icon-label.trash")}
				onClick={popupDeleteSheetControl.show}
			/>
		</>
	);
};

DeleteSheetButton.propTypes = {
	sheet: Proptypes.object.isRequired,
	onDelete: Proptypes.func,
	defaultColor: Proptypes.string,
};

export default DeleteSheetButton;
