import { hasFieldError } from "../../../shared";

/**
 * @typedef BinderPublishFormData
 * @property {string} changed_description
 * @property {string} publish_at
 * @property {string} is_rectification
 */

/**
 * Validate validatePopupPublishBinder form data
 * @param {BinderPublishFormData} form data to validate
 * @param currentBinder
 * @param binderList
 * @returns {BinderFormError}
 */
const validatePopupPublishBinder = (form, currentBinder, binderList) => {

	// prevent duplicates of changed_description in binderList of same id
	const changedDescriptionErrors = binderList.some(({ changed_description, tech_id }) => tech_id !== currentBinder?.tech_id && changed_description === form?.changed_description);

	return {
		changed_description: changedDescriptionErrors ? [ "invalid" ] : []
	};
};

const getPublishBinderMessageKey = (formError) => ({
	changed_description: hasFieldError("invalid") ? "binder:popup.form.error.id-format" : ""
});

export {
	validatePopupPublishBinder,
	getPublishBinderMessageKey
};
