import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SheetTypeLink from "../sheet-type-link/sheet-type-link";
import { useSheetContentContext } from "../../../../context/sheet-content-context";
import { useSheetReadContext } from "../sheet-read-context/sheet-read-context";
import { filterSheetByType } from "../../../../../../shared";
import { IconArrowToLinkRight } from "../../../../assets";
import { DottedCard, EditorRendering, IconWrapper } from "../../../../../../theme";
import "./linkpath-display.scss";


/**
 * render link path display
 * @param {{isProcessLinkDisplay: boolean}} props 
 * @returns {JSX.Element}
 */
const LinkPathDisplay = (props) => {
	const { isProcessLinkDisplay } = props;
	const { sheetLinksVisited, sheetProcessLinks, sheetColors = {} } = useSheetReadContext();
	const { sheetData = {} } = useSheetContentContext();
	const sheetLinks = isProcessLinkDisplay ? filterSheetByType(sheetProcessLinks) : sheetLinksVisited;
	const sheetLinksToDisplay = isProcessLinkDisplay ? [ 0, 1 ,sheetLinks.length - 1 ] : [];
	const currentSheetTitle = isProcessLinkDisplay ? (sheetProcessLinks[sheetProcessLinks.length - 1]?.sheetTitle) : sheetData?.title;

	const renderLinkPathItem = (sheetLink, i) => {
		const isHidden = isProcessLinkDisplay && !sheetLinksToDisplay.includes(i);
		if (isHidden){
			return null;
		}

		const sheetColor = sheetLink?.color || sheetColors[sheetLink?.uri];
		const isFirstOrLast = i  === 0 || i === sheetLinks.length - 1;
		const hasArrow = sheetLinks?.[i+1];
		const isDotted = !isFirstOrLast && isProcessLinkDisplay;

		// sheetType and sheetTitle is not included when sheet link is added via simplelink (added here)
		if ((!sheetLink?.sheetType || !sheetLink?.sheetTitle) && sheetData?.sheet_id === sheetLink.sheetId) {
			sheetLink.sheetType = sheetData?.type;
			sheetLink.sheetTitle= sheetData?.title;
		}

		return <Fragment key={sheetLink?.uri || i}> 
			{isDotted ? <DottedCard/> : <SheetTypeLink disabled={isProcessLinkDisplay} sheetLink={sheetLink} sheetColor={sheetColor} />}
			{hasArrow && <IconWrapper className="linkpath-display__sheet-arrow" Component={IconArrowToLinkRight}/>}
		</ Fragment>;
	};

	return (<>
		{!isProcessLinkDisplay && <span className="linkpath-display__separator"/>}
		<div className={classNames("linkpath-display", { "linkpath-display--process-link": isProcessLinkDisplay })}>
			{Array.isArray(sheetLinks) && sheetLinks.map(renderLinkPathItem)}
			<EditorRendering className="linkpath-display__sheet-title" content={currentSheetTitle}/>
		</div>
	</>);
};


LinkPathDisplay.prototype = {
	isProcessLinkDisplay: PropTypes.bool
};

export default LinkPathDisplay;
