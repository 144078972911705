import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ErrorMessage, PopupDefault, TextField } from "../../../../../theme";
import { updateBinder } from "../../../binder.services";
import { getBinderZoneLinkMessageKey, validatePopupBinderZoneLink } from "../../../utils/validate-zone-link";
import { useAppEnv } from "../../../../../config/env/app-env";

import "./popup-zone-link.scss";

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 */


/**
 * PopupZoneLink component
 * @param {object} props
 * @param {PopupControl} props.popupControl
 * @param {object} props.binder
 * @param {function} props.onSubmitSucces
 * @returns {JSX.Element}
 */
const PopupZoneLink = props => {
	const { popupControl, binder, onSubmitSuccess } = props;
	const { t } = useTranslation();

	const [ link, setLink ] = useState("");
	const [ inputErrorMessage, setInputErrorMessage ] = useState("");
	const [ networkError, setNetworkError ] = useState(false);

	const env =  useAppEnv();

	const resetState = () => {
		setNetworkError(false);
		setInputErrorMessage("");
	};

	const handleChange = (event) => {
		setLink(event.target.value);
	};

	const updateBinderLink = async () => {
		try {
			const { data: updatedBinder } = await updateBinder(binder?.tech_id, { ...binder, zone_link: link });
			if (!updatedBinder) {
				throw new Error();
			}
			onSubmitSuccess(updatedBinder);
			resetState();
			popupControl.hide();
		} catch (e) {
			setNetworkError(true);
		}
	};

	const handleSubmit = async () => {
		const zoneLinkValidation = env.REACT_APP_ZONE_LINK_HOST;
		const error = validatePopupBinderZoneLink(link, zoneLinkValidation);
		const formErrorMessage = getBinderZoneLinkMessageKey(error);
		if (formErrorMessage?.link) {
			setInputErrorMessage(t(formErrorMessage.link));
			setLink("");
			setNetworkError(false);
		} else {
			updateBinderLink();
		}
	};


	return (
		<PopupDefault
			popupControl={popupControl}
			title={t("binder:popup.zone-link.title")}
			onClose={resetState}
		>
			<div className="popup-zone-link">
				<p className="popup-zone-link__subtitle">{t("binder:popup.zone-link.subtitle")}</p>
				<TextField
					className="popup-zone-link__input"
					name="link"
					label={t("binder:popup.zone-link.link-label")}
					value={link}
					onChange={handleChange}
					helperText={inputErrorMessage}
					invalid={!!inputErrorMessage}
				/>
				{networkError &&
				<ErrorMessage className="popup-zone-link__link-error">{t("error.default")}</ErrorMessage>}
				<Button
					variant="primary"
					className="popup-zone-link__button"
					onClick={handleSubmit}
					label={t("binder:popup.zone-link.button")}
				/>
			</div>
		</PopupDefault>
	);
};

PopupZoneLink.propTypes = {
	popupControl: PropTypes.object,
	binder: PropTypes.shape({
		tech_id: PropTypes.string
	}),
	onSubmitSucces: PropTypes.func
};


export default PopupZoneLink;
