import { http, httpOnline } from "../../config";
import { getEntity, getEntityList } from "../../shared/cache-access/entity/entity-utils";
import qs from "qs";

const { REACT_APP_API_BASE_URL } = process.env;

const binderRootUrl = "/binder";

/**
 * @typedef {'work-in-progress' | 'checking' | 'verified' | 'validating' | 'validated' | 'waiting-for-approval' | 'approved' | 'waiting' | 'published' | 'revoke' | 'cancelled'} BinderStatus
 */

/**
 * @typedef Binder
 * @prop {Number} associated_line
 * @prop {String} id
 * @prop {String} title
 * @prop {String} subtitle
 * @prop {BinderStatus} status
 * @prop {String} last_change_at
 * @prop {String} correction
 * @prop {String} published_at
 * @prop {String} document_manager
 * @prop {String} published_temp_at
 * @prop {Object} line_manager
 * @prop {String} isf_version
 * @prop {String} abrogated_documents
 * @prop {String} is_rectification
 * @prop {String} changed_description
 * @prop {String} parent
 */

/**
 * @typedef Station
 * @prop {string} station
 * @prop {string} tech_id
 * @prop {string} label
 * @prop {number} order
 * @prop {string} line
 */

/**
 * Get the list of all existing binder
 * @returns {Promise<import("axios").AxiosResponse<Binder[]>>}
 */
const fetchAllBinder = async () => http.get(binderRootUrl);

/**
 * Get a list of station
 * @param {string[]} line
 * @returns {Promise<import("axios").AxiosResponse<Station[]>>}
 */
const fetchStation = async (line) => http.get("/station", {
	params: { line },
	paramsSerializer: params => qs.stringify(params)
});

/**
 * Get a list of binder with the provided id
 * @param {String} binderId The binder's id
 * @return {Promise<import("axios").AxiosResponse<Binder[]>>}
 */
const fetchBinderListById = async (binderId) => {
	return http.get(binderRootUrl, {
		params: { id: binderId },
		paramsSerializer: params => qs.stringify(params)
	});
};

/**
 * Get a binder by its technical id
 * @param {String} binderId The binder's technical id
 * @param {{extendStation: boolean}} params
 * @return {Promise<import("axios").AxiosResponse<Binder>>}
 */
const fetchBinderByTechId = async (binderId, params = {}) => http.get(`${binderRootUrl}/${binderId}`, {
	params,
	handleOfflineResponse: async () => {
		const storedBinder = await getEntity("binder", binderId);
		return storedBinder.data;
	}
});


/**
 * run analysis for binder
 * @param {string} binderId
 * @param {{type: string}} params
 */
const runAnalysisBinder = async (binderId, params = {}) => http.get(`${binderRootUrl}/${binderId}/analysis`, { params });

/**
 * Create a new binder
 * @param {Binder} binder The binder's data
 */
const createBinder = async (binder) => http.post(binderRootUrl, binder);

/**
 * copy a new binder
 * @param {Binder} binder The binder's data
 * @param {string} copyBinderTechId The binder's data
 */
const copyBinder = async (binder, copyBinderTechId) => http.post(binderRootUrl, binder,
	{ params: { binderId: copyBinderTechId } });

/**
 * Update a binder
 * @param {String} binderId The binder's technical id
 * @param {Binder} binderData The binder's data to update
 */
const updateBinder = async (binderId, binderData) => {
	// Filter null value except for is_rectification field which can be reset in publish binder popup
	const allowedNullProperties = [ "is_rectification", "published_at" ];
	const cleanedData = Object.fromEntries(Object.entries(binderData).filter(([ key, val ]) => allowedNullProperties.includes(key) || val !== null));
	return http.put(`${binderRootUrl}/${binderId}`, cleanedData);
};

/**
 * Find binders matching the user query
 * @param {{
 * 	id: string
 *	associatedLine: string
 *	status: BinderStatus | BinderStatus[]
 *	type: string
 *  material_tech_id: string
 *  techId: string
 * }} params the user query
 * @returns {Promise<import("axios").AxiosResponse<Binder[]>>}
 */
const searchBinder = async (params = {}) => http.get(binderRootUrl, {
	params,
	paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: "repeat" }),
	handleOfflineResponse: async () => {
		const { associatedLine: line, material_tech_id: materialRef, ...otherParams } = params;

		const cacheParams = otherParams;

		if (line) {
			cacheParams.line = line;
		}

		if (materialRef) {
			cacheParams.materialRef = materialRef;
		}

		const binderList = await getEntityList("binder", cacheParams);
		return binderList.map(cacheEntry => cacheEntry.data);
	}
});

/**
 * @param {string} binderTechId
 * @returns {string}
 */
const generateBinderPdfURI = (binderTechId) => `${REACT_APP_API_BASE_URL}/binder/${binderTechId}/pdf?${qs.stringify({
	modified: false,
	download: false,
	name: "digiproc-doc"
})}`;

const generateBinderPdf = (binderTechId, modified) => http.post(`${binderRootUrl}/${binderTechId}/pdf`, { modified }, { timeout: 0 });

/**
 * Analyse the page difference between sheets of current and published binder in PDF
 * @param {String} binderTechId The binder's id to analyse
 */
const analyseBinderSheetsPdf = async (binderTechId) => http.put(`${binderRootUrl}/${binderTechId}/publication-analysis`, {}, { timeout: 0 });
const getBinderPagesAnalyseStatus = async (BinderTechId) => http.get(`${binderRootUrl}/${BinderTechId}/publication-analysis`);

/**
 * Delete a binder
 * @param {string} binderTechId
 */
const deleteBinderByBinderTechId = async (binderTechId) => http.delete(`${binderRootUrl}/${binderTechId}`);

const getBinderStatus = async (line) => httpOnline.get(`${binderRootUrl}/status`, { params: { line } });

export {
	fetchAllBinder,
	fetchStation,
	fetchBinderByTechId,
	fetchBinderListById,
	createBinder,
	updateBinder,
	searchBinder,
	copyBinder,
	generateBinderPdfURI,
	generateBinderPdf,
	analyseBinderSheetsPdf,
	getBinderPagesAnalyseStatus,
	runAnalysisBinder,
	deleteBinderByBinderTechId,
	getBinderStatus
};
