import React, { useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconWrapper } from "../../../../theme";
import { useDndHandleMove } from "../../../../shared";
import SheetEditionDragLayer from "./components/sheet-edition-drag-layer/sheet-edition-drag-layer";
import SheetType from "../sheet-type/sheet-type";
import {
	NewTemplateView,
	QuestionTemplateView,
	StepTemplateView,
	ResumeTemplateView,
	TerminationTemplateView,
	TextTemplateView,
	MultipleLinkTemplateView,
	MultipleLinkTrainTemplateView,
	SimpleLinkTemplateView,
	MultipleChoiceTemplateView,
	PageBreakTemplateView,
	TableTemplateView,
	SpaceTemplateView,
	PccInstructionTemplateView,
	OfficerInterventionTemplateView,
	MRFInterventionTemplateView
} from "./components/template/template-view";
import { useSheetContentContext } from "../../context/sheet-content-context";
import { useSheetModeContext } from "../../context/sheet-mode-context";
import { isTrainBinderType, binderTrainTypeList } from "../../../binder/utils/binder-type";
import { IconAdd } from "../../../../theme/assets/img";
import { useTemplateTypePosition } from "../../../../shared/utils/use-template-type-position";

import "./sheet-preview.scss";

/**
 * @typedef Props
 * @prop {string | number} sheetNumber
 * @prop {string} sheetType
 * @prop {string} sheetColor
 * @prop {object} currentTemplate
 * @prop {"man"|"sheet"|"theme"} binderType
 * @prop {object} signalInfo
 */

/**
 * SheetDetailsSubheaderTitle component
 * @param {Props} props
 * @returns {JSX.Element}
 */
const SheetPreview = (props) => {
	const { sheetNumber, sheetType, sheetColor, currentTemplate, binderType, signalInfo } = props;
	const { t } = useTranslation();
	const { nodeList, setNodeList, insertEmptyNodeAt, jumpToActionPosition } = useSheetContentContext();
	const { readOnly, viewType } = useSheetModeContext();

	const isTrainBinder = useMemo(() => isTrainBinderType(binderType), [ binderType ]);
	const { getTemplateTypePosition } = useTemplateTypePosition(nodeList);

	const components = {
		new: NewTemplateView,
		note: TextTemplateView,
		action: { template: TextTemplateView, icon: "action", hasBullet: isTrainBinder },
		call: { template: TextTemplateView, icon: "call" },
		systemEffect: TextTemplateView,
		pccInstruction: PccInstructionTemplateView,
		officerIntervention: OfficerInterventionTemplateView,
		mrfIntervention: MRFInterventionTemplateView,
		step: StepTemplateView,
		question: QuestionTemplateView,
		termination: TerminationTemplateView,
		resume: ResumeTemplateView,
		multipleLink: binderTrainTypeList.includes(binderType) ? MultipleLinkTrainTemplateView : MultipleLinkTemplateView,
		simpleLink: SimpleLinkTemplateView,
		multipleChoice: MultipleChoiceTemplateView,
		table: TableTemplateView,
		pageBreak: PageBreakTemplateView,
		space: SpaceTemplateView
	};

	const { moveCard } = useDndHandleMove(nodeList, setNodeList);

	const addNewTemplate = (position) => {
		insertEmptyNodeAt(position);
	};

	const loadTemplateComponent = (template, index, templateList = []) => {
		const ComponentConfig = components[template.type];
		let ComponentName = ComponentConfig;
		let additionalConfig = {};

		// in case the template selected has additional configuration like icon or input type
		if (ComponentConfig && ComponentConfig.template) {
			ComponentName = ComponentConfig.template;
			additionalConfig = { input: ComponentConfig.input, icon: ComponentConfig.icon };
		}

		if (ComponentConfig && ComponentConfig.hasBullet){
			const bulletPosition = getTemplateTypePosition(template?.id);
			if (Number.isInteger(bulletPosition)){
				additionalConfig.bulletPosition = bulletPosition;
				// jump to this action position if it pointed to from a link
				additionalConfig.scrollToThisAction = jumpToActionPosition === additionalConfig.bulletPosition - 1;
			}
		}

		// Don't render pageBreak on viewType spr or train
		if ([ "train", "spr" ].includes(viewType) && (template?.type === "pageBreak" || template?.type === "space")) {
			return false;
		}

		return React.createElement(ComponentName,
			{
				key: template.id,
				template: template,
				location: { id: template.id },
				currentPosition: index,
				allowInsertAfter: true,
				processCurrentTemplate: currentTemplate,
				isTrainBinder,
				moveCard,
				...additionalConfig
			}
		);
	};
	const templateDisplayList = nodeList?.map(loadTemplateComponent);

	return (
		<div className={classNames("sheet-preview", { "sheet-preview--spr": viewType === "spr",  "sheet-preview--train": viewType === "train" })}>
			<div className="sheet-preview__head">
				{!viewType && <SheetType
					className="sheet-preview__sheet-number"
					type={sheetType}
					sheetNumber={sheetNumber}
					signalInfo={signalInfo}
					color={sheetColor}
					binderType={binderType}
				/>}
			</div>

			{!readOnly && nodeList?.length === 0 &&
			<button className="sheet-preview__first-add" type="button" onClick={() => addNewTemplate(0)}>
				<IconWrapper className="first-add__icon" Component={IconAdd}/>
				<span className="first-add__label">
					{t("sheet:sheet-details.tabs-panel.preview.first-add")}
				</span>
			</button>
			}
			{templateDisplayList}
			<SheetEditionDragLayer loadTemplateComponent={loadTemplateComponent}/>
		</div>
	);
};

SheetPreview.propTypes = {
	currentTemplate: PropTypes.object,
	binderType: PropTypes.string,
	sheetColor: PropTypes.string,
	sheetNumber: PropTypes.string,
	sheetType: PropTypes.string,
	signalInfo: PropTypes.shape({
		title: PropTypes.string,
		destination: PropTypes.string
	})
};

export default SheetPreview;
