import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./tabs-nav-item.scss";

// TODO render test

/**
 * @param {object} props
 * @param {number} props.id
 * @param {number} props.activeTab
 * @param {function} props.onClick
 * @param {string} props.className
 * @param {string} props.classNameActive
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
const TabsNavItem = props => {
	const { id, children, activeTab, onClick, className, classNameActive, disabled } = props;

	const initClassName = () => {
		const modifier = {
			"tabs-nav-items--active": id === activeTab,
			"tabs-nav-items--disabled": disabled
		};

		// Handle only one active class
		let customActiveClass = "";
		if (classNameActive) {
			customActiveClass = { [classNameActive]: id === activeTab };
		}

		return classNames("tabs-nav-items", className, customActiveClass, modifier);
	};

	return (
		<button
			type="button"
			className={initClassName()}
			onClick={onClick(id)}
			disabled={disabled}
		>
			<div className="tabs-nav-items__wrapper">
				{children}
			</div>
		</button>
	);
};


TabsNavItem.propTypes = {
	id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
	activeTab: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
	onClick: PropTypes.func,
	className: PropTypes.string,
	classNameActive: PropTypes.string,
	disabled: PropTypes.bool,
};

export default TabsNavItem;
