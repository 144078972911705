import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { IconWrapper } from "../../../../../../../../theme";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import SimpleLinkStepView from "./components/simple-link-step-view/simple-link-step-view";
import SimpleLinkSheetView from "./components/simple-link-sheet-view/simple-link-sheet-view";
import SimpleLinkOtherSheetView from "./components/simple-link-other-sheet-view/simple-link-other-sheet-view";
import { buildSprProcessSheetUri, createSheetLink, SheetLinkWrapper } from "../../../../../../../../shared";
import { IconArrowRight } from "../../../../../../../../theme/assets/img";
import { useSheetModeContext } from "../../../../../../context/sheet-mode-context";

import "./simple-link-template-view.scss";

/**
 * simple link template view
 * @param {object} props
 * @param {{content: {type: string, sheetNumber: string, binder?: {techId: string}, sheetColor, binderColor}}} props.template
 * @param {number} props.currentPosition
 * @param {object} props.location
 * @param {boolean} props.isTrainBinder
 * @returns {JSX.Element}
 */
const SimpleLinkTemplateView = (props) => {
	const { template, currentPosition, location, isTrainBinder } = props;
	const params = useParams();
	const { pathname } = useLocation();
	const { viewType } = useSheetModeContext();
	const content = template?.content || {};
	const { type, sheetNumber, sheetId, binder = {}, actionPosition: jumpToActionPosition = null } = content;

	const SimpleLinkView = ({ type: linkType }) => {
		const SimpleLinkProps = { content, isTrainBinder };
		if (linkType === "step") return <SimpleLinkStepView {...SimpleLinkProps}/>;
		if (linkType === "sheet") return <SimpleLinkSheetView  {... SimpleLinkProps }/>;
		if (linkType === "other-sheet") return <SimpleLinkOtherSheetView  {... SimpleLinkProps }/>;
		return null;
	};

	const createSimpleLinkSheetUri = () => {
		const { binderId, processId } = params;
		const { type: linkType, sheetNumber: linkSheetNumber, binder: linkBinder = {} } = content;
		const binderTechId = linkType === "sheet" ? binderId : linkBinder?.techId;
		return buildSprProcessSheetUri(processId, binderTechId, linkSheetNumber);
	};

	const isLink = pathname.startsWith("/spr/process") && viewType === "train" && type !== "step";
	const uri = isLink ? createSimpleLinkSheetUri() : "#";
	// color is null because it is not included in content instead it is resolved via the sheetcolormap
	const sheetLink = isLink ? createSheetLink(uri, sheetId, sheetNumber, binder?.tech_id, null, jumpToActionPosition) : {};

	return (
		<TemplateWrapper
			id={template.id}
			className={classNames("simple-link-template-view", { "simple-link-template-view--link": isLink })}
			template={template}
			location={location}
			currentPosition={currentPosition}>
			{!type && <IconWrapper className="simple-link-template-view__icon" Component={IconArrowRight}/> }
			<SheetLinkWrapper  isLink={isLink} sheetLink={sheetLink}>
				<SimpleLinkView type={type}/>
			</SheetLinkWrapper>
		</TemplateWrapper>
	);
};


SimpleLinkTemplateView.propTypes = {
	template: PropTypes.shape({
		content: PropTypes.shape({
			type: PropTypes.string,
			sheetNumber: PropTypes.string,
			sheetColor: PropTypes.string,
			binderColor: PropTypes.string,
			sheetType: PropTypes.string,
			binder: PropTypes.shape({
				techId: PropTypes.string
			})
		})
	}),
	currentPosition: PropTypes.number,
	location: PropTypes.object,
	isTrainBinder: PropTypes.bool
};

export default SimpleLinkTemplateView;
