import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { updateProcess } from "../../process.services";
import { buildTrainProcessBinderList, useSprContext } from "../../../../shared";
import { IconWrapper, PopupConfirm } from "../../../../theme";
import { iconLines } from "../../../../domains/user/utils/associated-line-icon-utils/associated-line-icon.utils";
import "./popup-start-process-train.scss";

/**
 * Render the popup to take in charge a train process
 * @param {object} props
 * @param {object} props.process
 * @param {object} props.popupControl
 * @return {JSX.Element}
 * @constructor
 */
const PopupStartProcessTrain = props => {
	const { process, popupControl } = props;
	const { associatedLine } = useSprContext();
	const { t } = useTranslation();
	const history = useHistory();

	const startProcessTrain = () => {
		updateProcess(process.processTechId, {}, { action: "startProcessTrain", line: associatedLine })
			.then(() => {
				const link = buildTrainProcessBinderList({ processId: process.processTechId, binderTechnicalId: process.binderTechId }, "railway");
				history.push(link);
			})
			.catch(error => {
				popupControl.hide();
				throw error;
			});
	};

	const displayTrack = () => {
		const trackList = [ "track1", "track2" ];
		const processTrack = process?.track;
		if (!processTrack) return "";
		if (trackList.includes(processTrack)) {
			return t(`process:popup.start-process-train.track.${processTrack}`);
		}
		return ` - ${processTrack}`;
	};

	return (
		<PopupConfirm
			className="popup-start-process-train"
			popupControl={popupControl}
			onConfirm={startProcessTrain}
			title={t("process:popup.start-process-train.title")}>
			<h1 className="popup-start-process-train__title">{t("process:popup.start-process-train.warning")}</h1>
			<p className="popup-start-process-train__question">{t("process:popup.start-process-train.content")}</p>
			<div className="popup-start-process-train__process">
				<IconWrapper className="popup-start-process-train__process__line" Component={iconLines[associatedLine]} />
				<div className="popup-start-process-train__process__info">
					{/* TODO handle cases without track and or positiontype */}
					{process.positionType && <span>{t(`process:popup.start-process-train.position-type.${process.positionType}`)}</span>}
					{process.positionInfo && <span>{`${process.positionInfo} ${displayTrack()}`}</span>}
					<span>{process.trainId}</span>
				</div>
			</div>
			{process.description &&
			<>
				<p className="popup-start-process-train__note">{t("process:popup.start-process-train.info")}</p>
				<div className="popup-start-process-train__description">{process.description}</div>
			</>
			}
		</PopupConfirm>
	);
};

PopupStartProcessTrain.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool
	}),
	process: PropTypes.shape({
		track: PropTypes.string,
		processTechId: PropTypes.string,
		binderTechId: PropTypes.string,
		positionType: PropTypes.string,
		positionInfo: PropTypes.string,
		description: PropTypes.string
	}).isRequired
};

export default PopupStartProcessTrain;
