// Add to process object signal and station data info
import { getNetworkState, offlineUser } from "../../../config/offline";
import { addLabelAndLinkToProcess } from "../../../domains/process/utils/localstorage-process";
import { searchBinder } from "../../../domains/binder/binder.services";

/**
 * InProgressProcess needs some info to be read in burger menu and in blue headband
 * It rework some attributs and retrieve other in this function
 * @param data
 * @param side
 * @param user
 * @returns {Promise<*>}
 */
const prepareProcessData = async (data, side, user) => {
	const binderManTechIdList = [];
	const inProgressProcessData = data.map((process) => {
		const { history } = process;

		// get process's first binder informations
		const binderProcess = history[0]?.binder || {};

		// get Attribut binder abbreviation
		const binderAbbreviation = binderProcess.abbreviation?.toUpperCase() || "";

		// get Attribut Binder Tech Id
		const processBinderTechId = retrieveBinderTechId(process);

		// get Label and Link value
		const { label, link } = addLabelAndLinkToProcess(process, side === "train" ? "railway" : "spr" );

		// format process attribut
		const processRenamed = updatesProcessKeysToCamelCase(process, user);

		// stock tech id if binder is of type Man for retrieving signal and station informations
		if (binderProcess.type === "man") {
			binderManTechIdList.push(processBinderTechId);
		}

		return { ...processRenamed, processBinderTechId, binderAbbreviation, label, link };
	});

	if (binderManTechIdList.length > 0) {
		return retrieveProcessManData(inProgressProcessData, binderManTechIdList);
	} else {
		return inProgressProcessData;
	}
};

/**
 * There is only signal and station info in binder of type Man
 * Retrieve those informations from api's data
 * @param processList
 * @param techIdList
 * @returns {Promise<*>}
 */
const retrieveProcessManData = async (processList, techIdList) => {
	const searchParams = {
		techId: techIdList ?? "",
		status: "published",
		type: "man"
	};
	const binderManResponse = await searchBinder(searchParams);
	if (binderManResponse) {
		return processList.map(process => addStationAndSignalToProcess(process, binderManResponse.data));
	}
	return processList;
};

/**
 * retrieve station and signal information from binder's information and send it to the inProgressProcessData object
 * @param process
 * @param binderList
 * @returns {(*&{binderType, binderSignalDestination: string, stationLabel: string, binderTitle, binderSignalOrigin: string, binderSignalTitle: string})|*}
 */
const addStationAndSignalToProcess = (process, binderList) => {
	const relatedBinder = binderList.find(binder => binder.tech_id === process.processBinderTechId);
	if (!relatedBinder) {
		return process;
	}
	const {
		binder_signal_origin: binderSignalOrigin = "",
		binder_signal_destination: binderSignalDestination = "",
		binder_signal_title: binderSignalTitle = "",
		station_label: stationLabel = "",
		title: binderTitle,
		type: binderType,
	} = relatedBinder;
	return { ...process, binderSignalOrigin, binderSignalDestination, binderSignalTitle, stationLabel, binderTitle, binderType };
};

/**
 * Function remapProcessFields
 * Changes process object keys from snakeCase to camelCase
 * Changes in order to match how process object is used in other context
 * @param {import("../../../domains/process/process.services").Process} process
 * @param user
 * @returns {import("../../../domains/process/process.services").Process} process updated with camelCase Properties
 */
const updatesProcessKeysToCamelCase = (process, user) => {
	const networkState = getNetworkState();
	const isProcessOwner = (networkState === "offline" || offlineUser.tech_id === process.owner || user.tech_id === process.owner);
	return {
		history: process.history,
		firstname: process.owner_firstname,
		lastname: process.owner_lastname,
		ownerRole: process.owner_role,
		ownerId: process.owner_id,
		startedAt: process.started_at,
		createdAt: process.created_at,
		owner: process.owner,
		processTechId: process.tech_id,
		type: process.type,
		positionInfo: process.position_info,
		positionType: process.position_type,
		track: process.track,
		description: process.description,
		trainId: process.train_id,
		materialLabel: process.material_label,
		binderTechId: process.binder_tech_id,
		label: process.label,
		link: process.link,
		processBinderTechId: process.processBinderTechId,
		binderAbbreviation: process.binderAbbreviation,
		userId: process.userId,
		isProcessOwner: isProcessOwner,
		binderTitle: process.binderTitle
	};
};

/**
 * Retrieve binder data from history in process
 * @param process
 * @returns {*|string}
 */
const retrieveBinderTechId = (process) => {
	const dataFromHistory = process?.history?.[process?.history.length - 1];
	return dataFromHistory?.binder?.techId || "";
};



export { prepareProcessData };
