import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./history-multiple-choice-step-task-view.scss";

/**
 * Render HistoryMultipleChoiceStepTaskView component
 * @param {object} props
 * @param {object} props.task
 * @returns {JSX.Element}
 */
const HistoryMultipleChoiceStepTaskView = (props) => {
	const { task = {} } = props;

	return (
		<div className={classnames("history-multiple-choice-step-task-view", { "odd": task?.index % 2 === 1 })}>
			<div className="history-multiple-choice-step-task-view__content">
				{task?.choiceTitle}
			</div>
		</div>
	);
};

HistoryMultipleChoiceStepTaskView.propTypes = {
	index: PropTypes.number,
	choiceTitle: PropTypes.string
};

export default HistoryMultipleChoiceStepTaskView;
