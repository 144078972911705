import i18next from "../../config/i18next";
import i18nSheet from "./i18n/fr.json";
import SheetDetail from "./pages/sheet-detail/sheet-detail";
import RailwaySheetView from "./pages/railway-sheet-view/railway-sheet-view";
import SheetEditionAction from "./pages/sheet-detail/components/sheet-edition-action/sheet-edition-action";
import SheetEditionPanel from "./pages/sheet-detail/components/sheet-edition-panel/sheet-edition-panel";
import SheetPreview from "./components/sheet-preview/sheet-preview";
import SheetComments from "./pages/sheet-detail/components/sheet-comments/sheet-comments";
import Comment from "./pages/sheet-detail/components/sheet-comments/components/comment";
import SprSheetDetail from "./pages/spr-sheet-detail/spr-sheet-detail";

import PopupDeleteSheet from "./components/popup-delete-sheet/popup-delete-sheet";
import PopupCreateSheet from "./components/popup-create-sheet/popup-create-sheet";
import SheetImageLoader from "./components/sheet-image-loader/sheet-image-loader";
import SheetType from "./components/sheet-type/sheet-type";
import SheetStatus from "./components/sheet-status/sheet-status";

import sheetColorList from "./utils/sheet-color-list.json";
import sheetColorTrainList from "./utils/sheet-color-train-list.json";
import sheetTypeMap from "./utils/sheet-type-map.json";
import sheetStatusMap from "./utils/sheet-status-map.json";
import sheetTemplateMap from "./utils/sheet-templates-map.json";
import resolveSheetColor from "./utils/resolve-sheet-color";
import formatSheetNumberDiamond from "./utils/format-sheet-number-diamond";
import defaultEditorToolbarConfig from "./utils/default-editor-toolbarconfig.json";

i18next.addResourceBundle("fr", "sheet", i18nSheet);

export {
	SheetDetail,
	RailwaySheetView,
	SheetEditionAction,
	SheetEditionPanel,
	SheetPreview,
	SheetComments,
	Comment,
	SprSheetDetail,
	PopupDeleteSheet,
	PopupCreateSheet,
	SheetImageLoader,
	SheetType,
	SheetStatus,
	sheetTypeMap,
	sheetStatusMap,
	sheetColorTrainList,
	sheetColorList,
	sheetTemplateMap,
	resolveSheetColor,
	formatSheetNumberDiamond,
	defaultEditorToolbarConfig
};
