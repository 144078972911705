import React from "react";
import { useTranslation } from "react-i18next";
import ValveManeuverSummary from "../valve-maneuver-summary/valve-maneuver-summary";
import BreakTestLineASummary from "../break-test-line-A-summary/break-test-line-A-summary";
import RestrictionSummary from "../restriction-summary/restriction-summary";
import { useSprContext } from "../../../../../../../shared";
import classNames from "classnames";
import DamageLocationSummary from "../damage-location-summary/damage-location-summary";

const RerResumeSummaryLineA = (props) => {
	const { tableField, processInfo, isProcessRecap } = props;
	const { t } = useTranslation();

	const { damage_location: damageLocation, results_sheet: resultsSheet, resume_rer: resumeRer } = processInfo?.resume_conditions || {};
	const { break_test_group: breakTestGroup = {} } = resultsSheet || {};

	const { currentBinder } = useSprContext();
	const { material_label, materialLabel } = currentBinder || {};
	const material = materialLabel ?? material_label;
	const trainComposition = damageLocation?.train_composition;

	return (
		<div>
			<div className={classNames({ "rer-resume-summary__step": !isProcessRecap })}>
				{isProcessRecap && <div>
					<span className={classNames("rer-resume-summary__step__title", { "rer-resume-summary__step__title--bold": isProcessRecap })}>
						{t("process:process-rer-resume.damage-location.title")}
					</span>
					<DamageLocationSummary
						line={"A"}
						damageLocation={damageLocation}
						material={material}
						tableField={tableField}
						isProcessRecap={isProcessRecap}
					/>
				</div>}
				<span className={classNames("rer-resume-summary__step__title", { "rer-resume-summary__step__title--bold": isProcessRecap })}>
					{t("process:process-rer-resume.results-sheet.title")}
				</span>

				<ValveManeuverSummary
					line={"A"}
					damageLocation={damageLocation}
					resultsSheet={resultsSheet}
					trainComposition={trainComposition}
					material={material}
					tableField={tableField}
					isProcessRecap={isProcessRecap}/>
				<BreakTestLineASummary
					breakTestGroup={breakTestGroup}
					trainComposition={trainComposition}
					material={material}
					isProcessRecap={isProcessRecap}/>
			</div>

			<RestrictionSummary resumeRer={resumeRer} isProcessRecap={isProcessRecap}/>
		</div>
	);
};

export default RerResumeSummaryLineA;
