import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import { EditorRendering, IconWrapper } from "../../../../../../../../theme";
import { formatJsonDate, LoadWrapper } from "../../../../../../../../shared";
import { resolveSheetColor, SheetStatus, SheetType } from "../../../../../../../sheet";

import { IconPathCircle } from "../../../../../../../../theme/assets/img";
import DeleteSheetButton from "../delete-sheet-button/delete-sheet-button";
import CreateSheetButton from "../create-sheet-button/create-sheet-button";
import UserNameLabel from "../../../../../../../../shared/components/user-name-label/user-name-label";

import "./binder-sheet-list-content.scss";

/**
 * Render the a sheet list on the binder detail page
 * @param {object} props
 * @param {object[]} props.sheets
 * @param {object} props.analysisResult
 * @param {object} props.binder
 * @param {boolean} props.loading
 * @param {function} props.showCreateSheetPopup
 * @param {function} props.refreshSheetList
 * @returns {JSX.Element}
 */
const BinderSheetListContent = props => {
	const {
		sheets = [],
		analysisResult = {},
		binder,
		loading,
		showCreateSheetPopup,
		refreshSheetList,
		defaultColor
	} = props;
	const isSheetListEmpty = sheets.length === 0;

	const { t } = useTranslation();
	const history = useHistory();
	const isBinderWIP = binder?.status === "work-in-progress";

	const generateSheetListItem = (sheet) => {
		const endOfUrl = isBinderWIP ? "/edit" : "";
		const sheetLink = `/binders/${binder?.tech_id}/sheet/${sheet?.sheet_id}${endOfUrl}`;
		const color = resolveSheetColor(sheet.color, defaultColor);
		return (
			<li
				key={sheet.number}
				className="list__row"
				onClick={sheetLink ? () => history.push(sheetLink) : () => {}}
			>
				<Link to={sheetLink} className="list__row__link">
					<SheetType
						className="list__cell list__cell--number"
						type={sheet.type}
						sheetNumber={sheet.number}
						color={resolveSheetColor(sheet.color, defaultColor)}
						binderType={binder?.type}
					/>
					<EditorRendering
						content={sheet.title}
						className="list__cell"
						noStyle
						sheetNumber={sheet.number}
						color={color}/>
					<span className="list__cell list__cell--status">
						{sheet.analysis_result &&
						<IconWrapper
							className={`list__cell--icon list__cell_analyse--${sheet.analysis_result}`}
							Component={IconPathCircle}
							tooltip={t(`binder:binder-detail.tooltip.analysis.${sheet.analysis_result}`)}
						/>
						}
					</span>
					<SheetStatus
						className="list__cell list__cell--status"
						warning={analysisResult?.sheetsWarning?.find((resultSheet) => resultSheet === sheet.sheet_id)}
						tooltip={t("binder:binder-detail.tooltip.status-warning")}
						status={sheet.status}
					/>
					<span className="list__cell">
						{formatJsonDate(sheet.last_change_content_at, "dd/MM/yy")}
						{sheet.editor &&
						<span className="list__cell__tooltip">
							<UserNameLabel
								className="list__cell__tooltip__text"
								user={{ firstname: sheet.editor_firstname, lastname: sheet.editor_lastname, id: sheet.editor_id }}
							/>
						</span>
						}
					</span>
				</Link>
				{isBinderWIP &&
					<span className="list__cell action__wrapper" onClick={(e) => e.stopPropagation()}>
						<DeleteSheetButton
							className="action__icon"
							sheet={sheet}
							onDelete={refreshSheetList}
							defaultColor={defaultColor}
						/>

					</span>
				}
			</li>
		);
	};

	return (
		<LoadWrapper loading={loading}>
			{
				isSheetListEmpty && isBinderWIP && (
					<div className="binder-sheet-sheet-list__empty">
						<span className="empty__text">
							{t("binder:binder-detail.sheet-list.create-first-sheet", { id: binder?.id?.toUpperCase() })}
						</span>
						<CreateSheetButton onClick={showCreateSheetPopup}/>
					</div>
				)
			}
			<ul className="binder-sheet-list-content">
				{!isSheetListEmpty && (
					<li className="list__head-row">
						<span className="list__head-cell list__head-cell--number">
							{t("binder:binder-detail.sheet-list.head.number")}
						</span>
						<span className="list__head-cell">{t("binder:binder-detail.sheet-list.head.title")}</span>
						<span className="list__head-cell">{t("binder:binder-detail.sheet-list.head.test")}</span>
						<span className="list__head-cell">{t("binder:binder-detail.sheet-list.head.change")}</span>
						<span className="list__head-cell">{t("binder:binder-detail.sheet-list.head.change-date")}</span>
					</li>
				)}
				{Array.isArray(sheets) && sheets.map(generateSheetListItem)}
			</ul>
		</LoadWrapper>
	);
};

BinderSheetListContent.propTypes = {
	sheets: PropTypes.arrayOf(PropTypes.shape({
		number: PropTypes.string.isRequired,
		sheet_id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		title: PropTypes.object.isRequired,
		analysis_result: PropTypes.string,
		status: PropTypes.string.isRequired,
		last_change_content_at: PropTypes.string.isRequired,
		editor: PropTypes.string,
		editor_firstname: PropTypes.string,
		editor_lastname: PropTypes.string,
		editor_id: PropTypes.string
	})),
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string)
	}),
	binder: PropTypes.shape({
		status: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired
	}).isRequired,
	defaultColor: PropTypes.string,
	loading: PropTypes.bool,
	showCreateSheetPopup: PropTypes.func.isRequired,
	refreshSheetList: PropTypes.func.isRequired
};

export default BinderSheetListContent;
