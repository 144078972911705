import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { CustomDropdown, IconWrapper, Button } from "../../../../../theme";
import SyncStatus from "../sync-status/sync-status";
import PopupSwitchLine from "../popup-switch-line/popup-switch-line";
import { useSprContext, useRailwayOfflineContext, useAppSetupContext } from "../../../../index";
import { usePopup } from "../../../../";
import { iconLines } from "../../../../../domains/user/utils/associated-line-icon-utils/associated-line-icon.utils";
import { setupLineScopeAllowed } from "../../../../../domains/user/utils/user-associated-line-scope";
import { IconDownload } from "../../../../../theme/assets/img";
import PopupCacheSync from "../popup-cache-sync/popup-cache-sync";
import "./line-switch.scss";

/**
 * Render the LineSwitch component
 * @param {object} props
 * @param {string} [props.className=""]
 * @return {JSX.Element}
 * @constructor
 */
const LineSwitch = (props) => {
	const { className = "", disabled = false } = props;
	const { t } = useTranslation();
	const { associatedLine = "", setStoredAssociatedLine } = useSprContext();
	const lineSwitchPopupControl = usePopup();
	const popupCacheSyncControl = usePopup();
	const [ targetLine, setTargetLine ] = useState(associatedLine);
	const { launchSync = () => {}, isMainLine, syncStatus } = useRailwayOfflineContext();
	const { online, user } = useAppSetupContext();
	const options = setupLineScopeAllowed(user);

	const renderLineOption = (line, isSelected) => {
		return (
			<div key={line} className={classnames("line-switch__option", { "line-switch__option--selected": isSelected })}>
				<IconWrapper className="line-switch__icon" Component={iconLines[line]} />
				{isSelected && <span className="line-switch__text">{t("theme:spr-page.line-label", { line })}</span>}
				{!isSelected && <SyncStatus disabled={disabled} />}
			</div>
		);
	};

	const handleConfirm = () => {
		setStoredAssociatedLine(`${targetLine}`).then(() => {
			document.location.reload();
		});
	};

	const handleCancel = () => {
		setTargetLine(associatedLine);
	};

	const handleChange = event => {
		setTargetLine(event.target.value);
		lineSwitchPopupControl.show();
	};

	const setLineSwitchStyles = () => classnames(
		className,
		"line-switch",
		{
			"line-switch--disabled": disabled
		}
	);

	return (
		<>
			<PopupSwitchLine popupControl={lineSwitchPopupControl} onConfirm={handleConfirm} onCancel={handleCancel} onClose={handleCancel} targetLine={targetLine} />
			<PopupCacheSync popupControl={popupCacheSyncControl} onConfirm={launchSync} line={associatedLine} />
			<CustomDropdown
				className={setLineSwitchStyles()}
				name="line-switch"
				options={options}
				renderOptionValue={(line) => renderLineOption(line, true)}
				renderActiveValue={(line) => renderLineOption(line, false)}
				defaultValue={targetLine}
				onChange={handleChange}
				disabled={disabled}
			/>
			{!isMainLine && online && syncStatus?.data?.state !== "success" &&
				<Button
					variant="secondary"
					type="button"
					onClick={popupCacheSyncControl.show}
					icon={IconDownload}
					disabled={disabled}
					className="line-switch__sync-btn"
				/>
			}
		</>
	);
};

LineSwitch.propTypes = { className: PropTypes.string };

export default LineSwitch;
