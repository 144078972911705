import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { checkFormError } from "../../../../shared";
import { Button, Dropdown, PopupDefault, TextField, ColorChooser, Editor, SquareColorIcon } from "../../../../theme";
import resolveThemeColor from "../../../binder/utils/resolve-theme-color";
import { createSheet, fetchSheetByBinder } from "../../sheet.services";
import { sheetColorTrainList, sheetTypeMap, sheetColorList } from "../../";
import validateCreateSheetForm from "./utils/validate-create-sheet-form";
import PopupCreateSheetCopyForm from "./components/popup-create-sheet-copy-form";

import "./popup-create-sheet.scss";

/**
 * @param {object} props
 * @param {object} props.binder
 * @param {string} props.defaultColor
 * @param {string} props.signalTechId
 * @param {string} props.theme
 * @param {function} props.onClose
 * @param {object} props.popupControl
 * @param {function} props.onCreate
 * @param {boolean} props.isTrain
 * @returns {JSX.Element}
 */
const PopupCreateSheet = props => {
	const { binder, isTrainBinder, theme, defaultColor, signal, popupControl, onCreate = () => {} } = props;
	const { t } = useTranslation();

	const signalTechId = signal?.tech_id;

	const editorToolbarConfig = {
		options: [ "inline", "list", "colorPicker" ],
		inline: { options: [ "bold", "italic" ] },
		list: { options: [ "unordered" ] },
		colorPicker: { colors: [ "black", "red", "transparent" ] }
	};
	const [ formData, setFormData ] = useState({});
	const [ formValidation, setFormValidation ] = useState({});
	const [ existingSheet, setExistingSheet ] = useState();
	const [ existingSheetLoading, setExistingSheetLoading ] = useState(true);

	const [ checkCopySheet, setCheckCopySheet ] = useState(false);
	const [ sheetSelected, setSheetSelected ] = useState();

	const numberDefaultValue = binder.type === "sheet" ? `${binder?.abbreviation?.toUpperCase()} N°` : "";

	const initForm = () => {
		setFormData({});
		setFormValidation({});
		setCheckCopySheet(false);
	};

	const loadBinderSheetList = () => {
		if (popupControl.visible && binder?.tech_id) {
			setExistingSheetLoading(true);
			const params = signalTechId ? { signalTechId } : {};
			fetchSheetByBinder(binder?.tech_id, params)
				.then(response => {
					setExistingSheet(response?.data);
				})
				.finally(() => setExistingSheetLoading(false));
		}
	};

	const handleFormValidation = () => {
		if (!existingSheetLoading) {
			const formError = validateCreateSheetForm(formData, existingSheet);
			const newFormValidation = {
				number: formError?.number?.includes("invalid") ? t("sheet:popup.create.error.existing-sheet-number") : "",
				title: "",
				type: ""
			};
			setFormValidation(newFormValidation);
		}
	};

	const handleTrainTypeChange = (type) => {
		const trainSheetColorMapping = {
			[sheetTypeMap.m]: sheetColorTrainList[0],
			[sheetTypeMap.determination]: sheetColorTrainList[1],
			[sheetTypeMap.resume]: sheetColorTrainList[2],
			[sheetTypeMap.child]: sheetColorTrainList[3],
			[sheetTypeMap.parent]: sheetColorTrainList[3],
			[sheetTypeMap.appendix]: sheetColorTrainList[7]
		};
		const color = trainSheetColorMapping[type];
		setFormData({ ...formData, color, type });
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		const isTrainBinderTypeChange = isTrainBinder && name === "type";
		if (isTrainBinderTypeChange){
			handleTrainTypeChange(value);
		} else  {
			setFormData({ ...formData, [name]: value });
		}
	};

	const isFormReady = () => {
		if (!existingSheetLoading) {
			const formError = validateCreateSheetForm(formData, existingSheet);
			let isFormValid = !checkFormError(formError);

			if (checkCopySheet) {
				isFormValid = isFormValid && !!sheetSelected;
			}

			return isFormValid;
		}

		return false;
	};

	const handleSubmit = event => {
		event.preventDefault();
		const formReady = isFormReady();
		if (formReady) {
			const newSheetData = {
				...formData,
				signal_tech_id: signalTechId,
				theme_tech_id: theme?.tech_id,
				content: []
			};

			// Handle default binder color
			if (!formData.color) {
				formData.color = "default";
			}

			const params = checkCopySheet ? { action: "copy", sheetId: sheetSelected } : {};

			createSheet(binder?.tech_id, newSheetData, params)
				.then(response => {
					// Reset form and close popup after success
					initForm();
					onCreate(response?.data);
					popupControl.hide();
				})
				.then(onCreate);
		}
	};

	const handleClosePopup = () => {
		initForm();
		onCreate();
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(loadBinderSheetList, [ binder, popupControl.visible ]);

	// Handle form validation
	useEffect(handleFormValidation, [ formData, existingSheetLoading, existingSheet, t ]);

	return (
		<PopupDefault popupControl={popupControl} title={t("sheet:popup.create.title")} onClose={handleClosePopup}>
			<form className="popup-create-sheet" onSubmit={handleSubmit}>
				<div className="popup-create-sheet__form-wrapper">
					<div className="popup-create-sheet__binder-id">
						{theme &&
						<>
							<SquareColorIcon color={resolveThemeColor(theme.color, binder?.color)}/>
							<span className="binder-id__text">{theme.title}</span>
						</>
						}
						{signalTechId &&
						<>
							<span className="binder-id__text">{`${signal?.title} / ${signal?.destination}`}</span>
						</>
						}
						{!signalTechId && !theme &&
						<>
							<SquareColorIcon color={binder.color}/>
							<span className="binder-id__text">{binder?.id}</span>
						</>
						}
					</div>
					<Dropdown
						name="type"
						value={formData.type}
						onChange={handleChange}
						placeholder={t("sheet:popup.create.field.type.options.placeholder")}>
						{isTrainBinder && <option value={sheetTypeMap.m}>{t("sheet:popup.create.field.type.options.m")}</option>}
						<option value={sheetTypeMap.determination}>{t("sheet:popup.create.field.type.options.determination")}</option>
						<option value={sheetTypeMap.parent}>{t("sheet:popup.create.field.type.options.parent")}</option>
						<option value={sheetTypeMap.child}>{t("sheet:popup.create.field.type.options.child")}</option>
						{isTrainBinder && existingSheet?.length > 0 && <option value={sheetTypeMap.appendix}>{t("sheet:popup.create.field.type.options.appendix")}</option>}
						{isTrainBinder && <option value={sheetTypeMap.resume}>{t("sheet:popup.create.field.type.options.resume")}</option>}
					</Dropdown>
					<TextField
						name="number"
						label={t("sheet:popup.create.field.sheet-number.label")}
						value={formData.number !== undefined ? formData.number : numberDefaultValue}
						helperText={formValidation?.number}
						invalid={formValidation?.number?.length > 0}
						onChange={handleChange}/>
					<Editor
						name="title"
						label={t("sheet:popup.create.field.title.label")}
						toolbarConfig={editorToolbarConfig}
						onChange={handleChange}
					/>
					{!signalTechId &&
						<ColorChooser
							name="color"
							label={t("sheet:popup.create.field.color.label")}
							value={formData?.color || defaultColor}
							onChange={handleChange}
							colorList={isTrainBinder ? sheetColorTrainList : sheetColorList }
						/>
					}
					<div className="popup-create-sheet__form-check">
						<input type="checkbox"
						       checked={checkCopySheet}
						       onChange={() => setCheckCopySheet(!checkCopySheet)}
						       className="popup-create-sheet__checkbox"
						/>
						<span>
							{t("sheet:popup.create.field.checkbox")}
						</span>
					</div>
					{/* TODO rename <- it a form not a popup */}
					{checkCopySheet &&
					<PopupCreateSheetCopyForm binder={binder} setSheetSelected={setSheetSelected}/>
					}
				</div>
				<div className="popup-create-sheet__controller">
					<Button
						variant="primary"
						type="submit"
						disabled={!isFormReady()}
						label={t("binder:popup.form.field.submit")}
					/>
				</div>
			</form>
		</PopupDefault>
	);
};

PopupCreateSheet.propTypes = {
	binder: PropTypes.object.isRequired,
	defaultColor: PropTypes.string,
	signal: PropTypes.shape({
		tech_id: PropTypes.string,
		title: PropTypes.string,
		destination: PropTypes.string
	}),
	theme: PropTypes.shape({
		tech_id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired
	}),
	onClose: PropTypes.func,
	popupControl: PropTypes.object.isRequired,
	onCreate: PropTypes.func,
	isTrainBinder:  PropTypes.bool,
};

export default PopupCreateSheet;
