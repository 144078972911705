import React, { useContext } from "react";
import { SheetType } from "../../../../index";
import BackToCurrentStep from "../../../../../../shared/components/back-to-current-step/back-to-current-step";
import { useParams } from "react-router-dom";
import resolveSheetColor from "../../../../utils/resolve-sheet-color";
import { EditorRendering } from "../../../../../../theme";
import getSignalInfo from "../../../../../../shared/utils/get-signal-info";
import { SprContext } from "../../../../../../shared";

const SprSheetDetailSubheader = (props) => {
	const { sheetData } = props;
	const { processId } = useParams();
	const { signalList } = useContext(SprContext);

	const signalInfo = getSignalInfo(signalList, sheetData?.signal_tech_id);
	const sheetColor = resolveSheetColor(sheetData?.color, sheetData?.binder_color, sheetData?.theme_color);

	return (
		<div className="spr-sheet-detail__subheader">
			<div className="spr-sheet-detail__subheader__title">
				<SheetType
					className="subheader__title__sheet-type"
					type={sheetData?.type}
					sheetNumber={sheetData?.number}
					binderType={sheetData?.binder_type}
					signalInfo={signalInfo}
					color={sheetColor}/>
				<span className="subheader__title__sheet-title">
					<EditorRendering content={sheetData?.title}/>
				</span>
			</div>
			<BackToCurrentStep className="spr-sheet-detail-page__back" processId={processId}/>
		</div>
	);
};

export default SprSheetDetailSubheader;
