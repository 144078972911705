import {
	checkNetwork,
	getAppStoredNetworkStatus,
	getStoredNetworkStatus, setAppStoredNetworkStatus,
	setStoredNetworkStatus
} from "../../config/offline";
import { useEffect, useMemo, useState } from "react";


const useNetworkStatus = () => {
	const [ loading, setLoading ] = useState(true);
	const [ networkStatus, setNetworkStatus ] = useState();
	const [ appNetworkStatus, setAppNetworkStatus ] = useState();

	/**
	 * @type {"online"|"offline"}
	 */
	const mergedNetworkStatus = useMemo(() => {
		if (!networkStatus || !appNetworkStatus){
			return "online";
		} else {
			return networkStatus === "online" ? appNetworkStatus : "offline" ;
		}
	}, [ networkStatus, appNetworkStatus ]);

	/**
	 * @param {"online"|"offline"} status
	 * @returns {void}
	 */
	const updateNetworkStatus = (status) => {
		setStoredNetworkStatus(status);
		setNetworkStatus(status);
	};

	const registerNetworkEventHandlerEffect = () => {
		const handleOffline = () => updateNetworkStatus("offline");
		const handleOnline = () => updateNetworkStatus("online");

		// Add network custom event listener
		window.addEventListener("offline", handleOffline);
		window.addEventListener("server-reachable", handleOnline);
		window.addEventListener("server-unreachable", handleOffline);
		window.addEventListener("online", handleOnline);

		return () => {
			// Handle default browser event
			window.removeEventListener("offline", handleOffline);
			window.removeEventListener("online", handleOnline);

			// Handle custom app event
			window.removeEventListener("server-reachable", handleOnline);
			window.removeEventListener("server-unreachable", handleOffline);
		};
	};

	/**
	 * @param {boolean} isOnline
	 * @returns {void}
	 */
	const updateAppNetworkStatus = (isOnline) => {
		const status = isOnline ? "online" : "offline";
		setAppStoredNetworkStatus(status);
		setAppNetworkStatus(status);
	};

	const resetNetworkStatusHome = async () => {
		if (window.location.pathname === "/") {
			await setAppStoredNetworkStatus("online");
		}
	};

	const initiateNetworkStatusEffect = async () => {
		await resetNetworkStatusHome();
		// Sync storage and state
		const storedNetworkStatus = getStoredNetworkStatus() ?? "online";
		if (!storedNetworkStatus) {
			updateNetworkStatus(navigator.onLine && storedNetworkStatus === "online" ? "online": "offline");
		} else {
			updateNetworkStatus(storedNetworkStatus);
		}

		const storedAppNetworkStatus = getAppStoredNetworkStatus();
		if (!storedAppNetworkStatus){
			updateAppNetworkStatus("online");
		} else {
			updateAppNetworkStatus(storedAppNetworkStatus === "online");
		}

		if (loading){
			checkNetwork().finally(() => setLoading(false));
		}
	};

	useEffect(()=> {
		async function initNetworkStatus() {
			await initiateNetworkStatusEffect();
		}
		initNetworkStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ loading ]);
	useEffect(registerNetworkEventHandlerEffect, []);

	return {
		online: mergedNetworkStatus === "online",
		networkStatus,
		appNetworkStatus,
		updateNetworkStatus: updateAppNetworkStatus,
		loading
	};
};

export default useNetworkStatus;
