import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PopupConfirm, EditorRendering, IconWrapper } from "../../../../../../theme";
import { LoadWrapper } from "../../../../../../shared";
import { resolveSheetColor, SheetType } from "../../../../../sheet";
import { fetchSheetByBusinessIds } from "../../../../../sheet/sheet.services";
import { useRunProcessContext } from "../../../../components/context-run-process/context-run-process";
import { IconPointerRight } from "../../../../assets";

import "./popup-confirm-sheet-redirect.scss";

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 * @prop {function} onHide - function to call on hide
 */

/**
 * @param {{popupControl: PopupControl}} props
 * @returns {JSX.Element}
 */
const PopupConfirmSheetRedirect = props => {
	const { popupControl } = props;
	const { t } = useTranslation();
	const { nextSheetData = {}, setNextSheetData, currentSheetData  } = useRunProcessContext() || {};
	const [ isLoading, setIsLoading ] = useState(true);

	const getNextSheetInfo = useCallback(async () => {
		if (nextSheetData?.sheetNumber){
			setIsLoading(true);
			try {
				const binderId = nextSheetData?.binder ? nextSheetData?.binder?.id : currentSheetData.binder_id;
				const binderTechId = nextSheetData?.binder ? nextSheetData?.binder?.techId : currentSheetData.binder_tech_id;
				const { data } = await fetchSheetByBusinessIds(binderId, nextSheetData?.sheetNumber, binderTechId);
				const color = resolveSheetColor(data?.color, data?.binder_color);
				setNextSheetData(prevSheetData => ({ ...prevSheetData, color, sheetType: data?.type  }));
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[ nextSheetData?.sheetNumber, nextSheetData?.binder?.techId, nextSheetData?.binder?.id, setNextSheetData ]);

	useEffect(getNextSheetInfo, [ getNextSheetInfo ]);

	return (
		<PopupConfirm
			cancellable={false}
			popupControl={popupControl}
			onConfirm={() => popupControl.hide()}
			title={t("process:popup.confirm-sheet-redirect.title")}
			ctaConfirmLabel="process:popup.confirm-sheet-redirect.cta-confirm-label"
		>
			<LoadWrapper className="material-list__loader" loading={isLoading} >
				<div className="popup-confirm-sheet-redirect">
					<h1 className="popup-confirm-sheet-redirect__subtitle">{t("process:popup.start-process-train.warning")}</h1>
					<p className="popup-confirm-sheet-redirect__text">{t("process:popup.confirm-sheet-redirect.text")} : </p>
					<div className="popup-confirm-sheet-redirect__sheet-info">
						<SheetType
							className="sheet-type"
							type={currentSheetData?.type || ""}
							sheetNumber={currentSheetData?.number_search || ""}
							color={resolveSheetColor(currentSheetData?.color, currentSheetData?.binder_color)}
							isBinderTrain
						/>
						<IconWrapper className="popup-confirm-sheet-redirect__icon-pointer" Component={IconPointerRight}/>
						<SheetType
							className="sheet-type"
							type={nextSheetData?.sheetType || nextSheetData?.type || ""}
			           	sheetNumber={nextSheetData?.sheetNumber || ""}
			           	color={nextSheetData?.color}
							binderType={nextSheetData?.binder_type}
							isBinderTrain
						/>
					</div>
					<EditorRendering editorClassName="title--full" content={nextSheetData?.title}/>
				</div>
			</LoadWrapper>
		</PopupConfirm>
	);
};

PopupConfirmSheetRedirect.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func
	})
};

export default PopupConfirmSheetRedirect;
