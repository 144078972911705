import React, {  useMemo, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { SprContext } from "../../context/spr-context/spr-context";
import SubheaderReturn from "../subheader-return/subheader-return";
import { IconWrapper } from "../../../theme";
import { IconTrain } from "../../../theme/assets/img";
import "./railway-subheader.scss";

/**
 * Render railway subheader
 * @param {object} props
 * @param {string} props.bintrainLabelders
 * @param {string} props.SlTrainAssociatedBinderList
 * @param {string} props.linkTo
 * @param {string} props.trainId
 * @param {string} props.returnLabel
 * @param {string} props.className
 * @return {JSX.Element}
 * @constructor
 */
const RailWaySubheader = (props) => {
	const { trainLabel = "", trainId = "", title, linkTo = "", returnLabel, className } = props;
	const { currentBinder: binder } = useContext(SprContext);
	const history = useHistory();

	const binderTypeModifier = useMemo(() => trainLabel ? ({
		"binder__type--auto": binder?.type === "train-auto",
		"binder__type--driver": binder?.type === "train-driver",
		"binder__type--officer": binder?.type === "train-officer"
	}) : {}, [ binder?.type, trainLabel ]);


	const onClickHandler = () => {
		if (linkTo){
			return history.push(linkTo);
		}
		history.goBack();
	};

	return <div className={classnames("railway-header", className)}>
		<div className="header-top">
			<div className="header-top__train">
				<IconWrapper className="header-top__train__icon"  Component={IconTrain} />
				{trainId && <span className="header-top__train__id">{trainId}</span>}
			</div>
			<span className="header-top__separator" />
			{trainLabel && <span className={classnames("binder__type", binderTypeModifier)}>{trainLabel}</span>}
			{title && <span className="header-top__title">{title}</span>}
		</div>
		<div className="header__bottom">
			<div className="header__link">
				<SubheaderReturn
					label={returnLabel}
					onClick={onClickHandler}
				/>
			</div>
		</div>
	</div>;

};

RailWaySubheader.propTypes = {
	trainLabel: PropTypes.string,
	trainId: PropTypes.string,
	title: PropTypes.string,
	linkTo: PropTypes.string,
	returnLabel: PropTypes.string,
	className: PropTypes.string,
};

export default RailWaySubheader;
