import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, PopupDefault } from "../../../../../../../../../theme";
import { checkFormError } from "../../../../../../../../../shared";
import FormSignal from "../../form-signal/form-signal";
import { validateSignalForm } from "../../../../../utils/signal-form-utils";
import { updateBinderSignal } from "../../../../../../../binder-signal.services";

import "./popup-update-signal.scss";

/**
 * Render the popup to create man binder signal
 * @param {object} props
 * @param {object} props.popupControl
 * @param {string} props.binderTechId
 * @param {function} props.onCreate
 * @returns {JSX.Element}
 */
const PopupUpdateSignal = props => {
	const {
		popupControl, signal, binderTechId, signalList, onUpdate = () => {}
	} = props;

	const [ form, setForm ] = useState({});

	const { t } = useTranslation();

	const formError = useMemo(() => validateSignalForm(form, signalList), [ form, signalList ]);

	const handleChange = (event) => {
		setForm(previousForm => {
			const { name, value } = event.target;
			return { ...previousForm, [name]: value };
		});
	};

	const handleSubmit = () => {
		if (!checkFormError(formError)) {
			updateBinderSignal(binderTechId, form)
				.then(() => {
					setForm({});
					popupControl.hide();
					onUpdate();
				});
		}
	};

	useEffect(() => {
		if (signal) {
			setForm(signal);
		}
	}, [ signal ]);

	return (
		<PopupDefault className="popup-update-signal__wrapper" popupControl={popupControl}
		              title={t("binder:popup.update-signal.title")}>
			<div className="popup-update-signal">
				<FormSignal className="popup-update-signal__form" form={form} validation={formError}
				            onChange={handleChange}/>
				<Button
					variant="primary"
					className="popup-update-signal__submit"
					onClick={handleSubmit}
					disabled={checkFormError(formError)}
					label={t("binder:popup.create-signal.validate")}
				/>
			</div>
		</PopupDefault>
	);
};

PopupUpdateSignal.propTypes = {
	binderTechId: PropTypes.string.isRequired,
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired
	}).isRequired,
	onUpdate: PropTypes.func,
	signalList: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		origin: PropTypes.string.isRequired,
		destination: PropTypes.string.isRequired
	})).isRequired
};

export default PopupUpdateSignal;
