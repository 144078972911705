import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./history-resume-task-view.scss";

/**
 * history resume template component
 * @param {object} props
 * @param {object} props.task
 * @param {object} props.className
 * @param {boolean} props.passed
 * @returns {JSX.Element}
 */
const HistoryResumeTaskView = (props) => {
	const { task = {}, className, passed } = props;

	const { t } = useTranslation();

	const initClassName = () => {
		const { content: { type } } = task;
		const styleType = type || "cm"; // fallback style is cm (green)
		const modifier = { "history-resume-task-view--passed": passed };
		return classNames("history-resume-task-view", `history-resume-task-view--${styleType}` ,className , modifier);
	};

	return (
		<span className={initClassName()}>{t(`process:history-task.resume.${task.content?.type}`)}</span>
	);
};

HistoryResumeTaskView.propTypes = {
	task: PropTypes.shape({
		content: PropTypes.shape({
			type: PropTypes.string
		})
	}),
	className: PropTypes.string,
	passed: PropTypes.bool
};

export default HistoryResumeTaskView;
