import { useState, useCallback } from "react";


const useDebouncedAction = (callback, delay = 1000) => {
	const [ savedTimeout, setSavedTimeout ] = useState();

	const requestAction = useCallback((...args) => {
		if (savedTimeout) {
			clearTimeout(savedTimeout);
		}
		const timeoutToSave = setTimeout(() => {
			callback.apply(null, args);
		}, delay);
		setSavedTimeout(timeoutToSave);
	}, [ callback, delay, savedTimeout ]);

	return requestAction;
};


export default useDebouncedAction;
