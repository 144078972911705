import checkIfTrainContext from "./check-if-train-context";
import { offlineUser } from "../../config/offline";
import { getSprStorageContent } from "../context/spr-context/spr-context-localstorage-utils";
import formUserRoleConfig from "../../domains/user/pages/admin-user-list/components/form-user/form-user-role-config";


const getOfflineUser =  () => {
	if (checkIfTrainContext()) {
		return {
			associated_line: formUserRoleConfig["train-agent"]?.linesAllowed,
			role: "train-agent",
			...offlineUser
		};
	} else {
	   const  associatedLine = getSprStorageContent()?.associatedLine || formUserRoleConfig["line-operator"]?.linesAllowed;
	   return {
		  associated_line: Array.isArray(associatedLine) ? associatedLine :  [ associatedLine ],
		   role: "line-operator",
		   ...offlineUser
	   };
	}
};

export default getOfflineUser;
