import { getElementNumberMax, getElementType } from "../pages/process-rer-resume/utils/get-car-element-info";

const validateRadioButton = (choice) => {
	const radioButtonChoices =  [ "yes", "no" ];
	return radioButtonChoices.includes(choice) ? [] : [ "not-valid-radio-button" ];
};

const validateNumber = (number, line, fieldName, trainComposition, material, affectedEquipment = []) => {

	const elementType = getElementType(fieldName);
	const numberMax = getElementNumberMax(elementType, trainComposition, material);
	if (fieldName === "isolated_emd_units") {
		const affectedEmdNumber = affectedEquipment.filter(el => el.includes("EMD")).length;
		const wrongEmdNumber = affectedEmdNumber !== number;
		return (0 <= number <= numberMax && !wrongEmdNumber) ? [] : [ "not-valid-affected-equipment" ];
	}
	return (0 <= number <= numberMax) ? [] : [ "not-valid-affected-equipment" ];
};

const validateCarPosition = (value) => {
	const { choice, pressure } = value || {};
	const isPositionPressureValid = typeof pressure === "string" && pressure.length <= 6 && pressure.length > 0;
	const isPositionChoiceValid = [ "normal", "direct" ].includes(choice);
	const isPositionValid = isPositionPressureValid && isPositionChoiceValid;
	return isPositionValid ? [] : [ "not-valid-car-position" ];
};

const validateZiscovPosition = (value) => {
	const ziscovPositionChoices = [ "service", "COVTHS" ];
	return ziscovPositionChoices.includes(value) ? [] : [ "not-valid-ziscov-position" ];
};

const validateResume = (choice) => {
	const resumeChoices = [ "travellers", "hlp", "assistance" ];
	return resumeChoices.includes(choice) ? [] : [ "not-valid-resume" ];
};

const validateSpeedDecrease = (objectChoice) => {
	const { other_restriction: otherRestriction, choice } = objectChoice || {};
	const valueToValidate = !!choice ? choice : otherRestriction;
	const validSpeedChoice = typeof valueToValidate === "string" && valueToValidate?.length <= 6;

	return validSpeedChoice ? [] : [ "not-valid-speed-decrease" ];
};

const validateAffectedEquipment = (affectedEquipment) => {
	return affectedEquipment?.length === 0 ? [ "empty-affected-equipment" ] : [];
};

const isFirstStepLineAValid = (valveManeuver, affectedEquipment, isolatedCompressorUnits, isolatedEmdUnits, line, parentSelected, trainComposition, material) => {
	const formError = {
		valve_maneuver: validateRadioButton(valveManeuver),
		affected_equipment: validateAffectedEquipment(affectedEquipment),
		isolated_compressor_units: validateNumber(isolatedCompressorUnits, line, "isolated_compressor_units", trainComposition, material),
		isolated_emd_units: validateNumber(isolatedEmdUnits, line, "isolated_emd_units", trainComposition, material, affectedEquipment)
	};
	return parentSelected ? formError : { valve_maneuver: validateRadioButton(valveManeuver) };
};

const isFirstStepLineBValid = (valveManeuver, affectedEquipment, isolatedCompressorUnits, isolatedEmdUnits, kadiPosition, line, parentSelected, trainComposition, material) => {
	const formError = {
		valve_maneuver: validateRadioButton(valveManeuver),
		affected_equipment: validateAffectedEquipment(affectedEquipment),
		isolated_compressor_units: validateNumber(isolatedCompressorUnits, line, "isolated_compressor_units", trainComposition, material),
		isolated_emd_units: validateNumber(isolatedEmdUnits, line, "isolated_emd_units", trainComposition, material, affectedEquipment),
		kadi_position: validateCarPosition(kadiPosition)
	};
	return parentSelected ? formError : { valve_maneuver: validateRadioButton(valveManeuver) };
};

const isSecondStepLineAValid = (breakTest, breakEmdUnits, ziscovPosition, speedDecrease, zalidiPosition, resume, line, parentSelected, trainComposition, material) => {
	const formError = {
		break_test: validateRadioButton(breakTest),
		break_emd_units: validateNumber(breakEmdUnits, line, "break_emd_units", trainComposition, material),
		ziscov_position: validateZiscovPosition(ziscovPosition),
		speed_decrease: validateSpeedDecrease(speedDecrease),
		zalidi_position: validateCarPosition(zalidiPosition),
		resume: validateResume(resume)
	};
	return parentSelected ? formError : { break_test: validateRadioButton(breakTest) };

};

const isSecondStepLineBValid = (parentSelected, breakTest, isolatedBreakBogiesUnits, breakBogiesUnits, line, trainComposition, material) => {
	const formError = {
		break_test: validateRadioButton(breakTest),
		isolated_break_bogies_units: validateNumber(isolatedBreakBogiesUnits, line, "isolated_break_bogies_units", trainComposition, material),
		break_bogies_units: validateNumber(breakBogiesUnits, line, "break_bogies_units", trainComposition, material)
	};
	return parentSelected ? formError : { break_test: validateRadioButton(breakTest) };
};

/**
 * Validate damage location form data
 * @param {Object} form form data to validate
 * @param {string} line the current line
 * @param {number} step the current step
 * @param {boolean} parentSelected
 * @param {string} trainComposition the train composition US or UM
 * @param {string} material the train material
 * @returns {Object}
 */
const validateProcessResultsSheetForm = (form, line, step, parentSelected, trainComposition, material) => {
	const { valve_maneuver_group: valveManeuverGroup, break_test_group: breakTestGroup } = form || {};
	const { valve_maneuver: valveManeuver, kadi_position: kadiPosition, affected_equipment: affectedEquipment, isolated_compressor_units: isolatedCompressorUnits, isolated_emd_units: isolatedEmdUnits } = valveManeuverGroup || {};
	const { break_test: breakTest, break_emd_units: breakEmdUnits, ziscov_position: ziscovPosition, speed_decrease: speedDecrease, zalidi_position: zalidiPosition, resume,
		isolated_break_bogies_units: isolatedBreakBogiesUnits, break_bogies_units: breakBogiesUnits } = breakTestGroup || {};

	if (line === "A" && step === 0) {
		return isFirstStepLineAValid(valveManeuver, affectedEquipment, isolatedCompressorUnits, isolatedEmdUnits, line, parentSelected, trainComposition, material);
	} else if (line === "A" && step === 1) {
		return isSecondStepLineAValid(breakTest, breakEmdUnits, ziscovPosition, speedDecrease, zalidiPosition, resume, line, parentSelected, trainComposition, material);
	} else if (line === "B" && step === 0) {
		return isFirstStepLineBValid(valveManeuver, affectedEquipment, isolatedCompressorUnits, isolatedEmdUnits, kadiPosition, line, parentSelected, trainComposition, material);
	} else if (line === "B" && step === 1) {
		return isSecondStepLineBValid(parentSelected, breakTest, isolatedBreakBogiesUnits, breakBogiesUnits, line, trainComposition, material);
	}
};

export default validateProcessResultsSheetForm;
