import i18next from "../../config/i18next";
import i18nTrain from "./i18n/fr.json";
import TrainList from "./pages/train-list/train-list";
import SlTrainSearch from "./pages/sl-train-search/sl-train-search";
import TrainAssociatedBinderList from "./pages/railway-binder-list/train-binder-list/train-binder-list";
import MaterialAssociatedBinderList from "./pages/railway-binder-list/material-binder-list/material-binder-list";
import PopupCreateTrain from "./pages/train-list/components/popup-create-train/popup-create-train";
import PopupUpdateTrain from "./pages/train-list/components/popup-update-train/popup-update-train";
import PopupDeleteTrain from "./pages/train-list/components/popup-delete-train/popup-delete-train";

i18next.addResourceBundle("fr", "train", i18nTrain);

export {
	TrainList,
	SlTrainSearch,
	TrainAssociatedBinderList,
	MaterialAssociatedBinderList,
	PopupCreateTrain,
	PopupUpdateTrain,
	PopupDeleteTrain
};
