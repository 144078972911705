import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useAppSetupContext } from "../../../shared";

/**
 * guard route component redirects to access error page if user is unauthorized
 * @param {object} props
 * @param {import("../user.services").UserRole[]} [props.authorizedRoles]
 * @returns {JSX.Element}
 */
const RoleGuardedRoute = (props) => {
	const { children, authorizedRoles } = props;
	const { user } = useAppSetupContext();

	if (Array.isArray(authorizedRoles) && user?.role && user.role !== "administrator"){
		if (!authorizedRoles.includes(user?.role)) return <Redirect to={"/error-access"}/>;
	}

	return children;
};

RoleGuardedRoute.propTypes = {
	authorizedRoles: PropTypes.arrayOf(PropTypes.string),
};


export default RoleGuardedRoute;
