import React from "react";
import { useHistory } from "react-router-dom";
import { resolveSheetColor, SheetType } from "../../../../../sheet";
import { IconWrapper, EditorRendering, DottedCard } from "../../../../../../theme";
import { filterPreviousSheetsByType, useSprContext } from "../../../../../../shared";
import { useRunProcessContext } from "../../../../components/context-run-process/context-run-process";
import { ReactComponent as IconArrowNext } from "../../../../../../theme/assets/img/icon-arrow-next.svg";
import getSignalInfo from "../../../../../../shared/utils/get-signal-info";
import "./spr-run-process-breadcrumb.scss";

/**
 * SprRunProcessBreadcrumb component
 * @returns {JSX.Element}
 */
const SprRunProcessBreadcrumb = () => {
	const { currentSheetData, processTechId, previousSheets: previousSheetsUnfiltered  = [], processInfo = {} } = useRunProcessContext();
	const previousSheets  = processInfo?.type === "train" ? filterPreviousSheetsByType(previousSheetsUnfiltered, currentSheetData) : previousSheetsUnfiltered;
	const { signalList } = useSprContext();
	const history = useHistory();

	const isEqualToCurrentSheet= (sheetToCompare) => sheetToCompare?.sheet.techId !== currentSheetData.sheet_id;

	const renderFirstSheetBreadcrumb = () => {
		// Display the id of the process first sheet if it's not the current one
		const firstSheet = previousSheets?.[0];
		const previousSheetIsDifferent = previousSheets.length > 1 || isEqualToCurrentSheet(firstSheet);

		if (firstSheet && previousSheetIsDifferent) {
			const renderedColor = resolveSheetColor(firstSheet?.sheet?.color, firstSheet?.binder?.color, firstSheet?.theme?.theme_color);
			const signalInfo = getSignalInfo(signalList, firstSheet?.sheet?.signalTechId);
			return (
				<>
					<SheetType className="head__sheet-type"
							   type={firstSheet.sheet.type}
					           sheetNumber={firstSheet.sheet.number}
							   color={renderedColor}
							   signalInfo={signalInfo}
							   binderType={firstSheet.binder?.type}/>
					<IconWrapper className="head__arrow__icon" Component={IconArrowNext}/>
				</>
			);
		}
	};

	const renderMultipleSheetBreadcrumb = () => {
		// Display a link to process history if the process has more than 2 sheets
		const secondSheet = previousSheets?.[1];
		const previousSheetIsDifferent = previousSheets.length > 2 || isEqualToCurrentSheet(secondSheet);
		if (secondSheet && previousSheetIsDifferent) {
			return (
				<>
					<button onClick={() => history.push(`/spr/process/${processTechId}`)}>
						<DottedCard />
					</button>
					<IconWrapper className="head__arrow__icon" Component={IconArrowNext}/>
				</>
			);
		}
	};

	return (
		<>
			{renderFirstSheetBreadcrumb()}
			{renderMultipleSheetBreadcrumb()}
			<SheetType
				className="head__sheet-type"
				type={currentSheetData?.type}
				signalInfo={getSignalInfo(signalList, currentSheetData?.signal_tech_id)}
				binderType={currentSheetData?.binder_type}
				sheetNumber={currentSheetData?.number}
				color={resolveSheetColor(currentSheetData?.color, currentSheetData?.binder_color, currentSheetData?.theme_color)}
			/>
			<EditorRendering className="head__sheet-title" content={currentSheetData?.title}/>
		</>
	);
};

export default SprRunProcessBreadcrumb;
