import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { IconWrapper } from "../../../../../../../../theme";
import { IconArrowRight } from "../../../../../../../../theme/assets/img";

import "./signal-title.scss";

/**
 * Render the signal title
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.origin
 * @param {string} props.destination
 * @returns {JSX.Element}
 */
const SignalTitle = (props) => {
	const { className, title, origin, destination } = props;

	if (!title || !origin || !destination) {
		return null;
	}

	return (
		<span className={classnames("signal-title", className)}>
			{title} / {origin}
			<IconWrapper className={classnames("signal-title__icon", className)} Component={IconArrowRight}/>
			{destination}
		</span>
	);
};

SignalTitle.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	origin: PropTypes.string.isRequired,
	destination: PropTypes.string.isRequired
};

export default SignalTitle;
