const trainCompositionLineB = [
	{
		element1: [
			{
				header: "Motrice",
				key: "first-car",
				content: [
					[ "BG 1 EMD", "BG 2 EMD" ],
				],
			},
			{
				header: "Remorque",
				key: "second-car",
				content: [
					[ "BG 3 EMD", "BG 4 EMD" ],
				],
			},
			{
				header: "Remorque",
				key: "third-car",
				content: [
					[ "BG 4 EMD", "BG 3 EMD" ],
				],
			},
			{
				header: "Motrice",
				key: "fourth-car",
				content: [
					[ "BG 2 EMD", "BG 1 EMD" ],
				],
			},
		],
	},
	{
		element2: [
			{
				header: "Motrice",
				key: "fifth-car",
				content: [
					[ "BG 1 EMD", "BG 2 EMD" ],
				],
			},
			{
				header: "Remorque",
				key: "sixth-car",
				content: [
					[ "BG 3 EMD", "BG 4 EMD" ],
				],
			},
			{
				header: "Remorque",
				key: "seventh-car",
				content: [
					[ "BG 4 EMD", "BG 3 EMD" ],
				],
			},
			{
				header: "Motrice",
				key: "eighth-car",
				content: [
					[ "BG 2 EMD", "BG 1 EMD" ],
				],
			},
		]
	}
];

export default trainCompositionLineB;
