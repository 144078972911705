import { createKey, filterEntity, getAllEntity, getEntityKeys, parseKey } from "../cache/cache-utils";
import { getItem, removeItem, setItem } from "../../../config/offline";
import { getApiItemLastChangeDate, getApiItemRef } from "../api/api-utils";
import { differenceInMilliseconds } from "date-fns";

/**
 * @typedef {'train'|'binder'|'sheet'|'image'} EntityNames
 */

/**
 * extends entity with other entities info
 * @param {EntityNames} entityName
 * @param {object} entity
 * @param {object} extendOptions
 * @return {object}
 */
const extendEntity = (entityName, entity, extendOptions) => {
	// Handle process extend options
	if (entityName === "process" && (extendOptions?.extendMaterial || extendOptions?.extendTrain)) {
		return extendProcess(entity);
	}
	return entity;
};

/**
 * Get a entity from cache by it's tech id
 * @param {EntityNames} entity Entity name
 * @param {string} entityRef The entity reference
 * @param {string} [line] Current line
 * @param {object} [options] extend options
 * @return {{Promise<CacheEntity>}}
 */
const getEntity = async (entity, entityRef, line, options = {}) => {
	let keyItem = "";
	if (line){
		keyItem = createKey(entity, line, entityRef);
	} else {
		const allKey = await getEntityKeys(entity);
		keyItem = allKey.find((key) => parseKey(key).ref === entityRef);
	}
	const result = await getItem(keyItem);

	if (!result) {
		throw Error(`Not found ${entity} (ref:${entityRef}, line: ${line})`);
	}
	return extendEntity(entity, result, options);
};

/**
 * Remove an entity from cache by ref
 * @param {EntityNames} entity Entity name
 * @param {string} line Current line
 * @param {string} entityRef The entity reference
 * @return {Promise<void>}
 */
const removeEntity = async (entity, line, entityRef) => {
	const keyItem = createKey(entity, line, entityRef);
	return removeItem(keyItem);
};

/**
 * Get a list of entities in cache with filter options
 * @param {EntityNames} entity Entity name
 * @param {Object} [includeParam={}] Filters options
 * @return {Promise<CacheEntity[]>}
 */
const getEntityList = async (entity, includeParam = {}) => {
	// Apply special key base filter for line
	const { line, ...otherIncludeParam } = includeParam;
	const fullEntityList = await getAllEntity(entity, line);

	return filterEntity(fullEntityList, otherIncludeParam, entity);
};

/**
 * Create or update an entity in cache
 * @param {EntityNames} entity Entity name
 * @param {string} line Current line
 * @param {string} entityRef The entity reference
 * @param {object} data The entity's data
 * @param {{ ref: string, line: string, ...other }} metadata Entity's metadata (ex: line)
 * @return {Promise<void>}
 */
const setEntity = async (entity, line, entityRef, data, metadata) => {
	const newItem = { data, metadata };
	const previousItem = await getEntity(entity, entityRef, line)
		.catch(() => ({ data: {}, metadata: {} }));
	const keyItem = createKey(entity, line, entityRef);
	if (data === undefined || data === null) {
		newItem.data = previousItem.data;
	}
	if (metadata === undefined || metadata === null) {
		newItem.metadata = previousItem.metadata;
	}

	return setItem(keyItem, newItem);
};

/**
 * Get entity metadata depending on entity
 * @param {{ train: { data : * }[], binder: { data : * }[], sheet: { data : * }[], image: { ref: string, sheetRef: string }[] }} api Last fetched data
 * @param {string} line Current line
 * @param {EntityNames} entity Entity name
 * @param {string} ref Entity reference
 * @returns {{ ref: string, ...other }}
 */
const buildEntityMetadata = (api, line, entity, ref) => {
	let metadata = {};

	// metadata of one item can depends on other entity
	if (entity === "binder") {
		const currentBinder = api.binder.find(binder => binder.data.tech_id === ref)?.data;
		const binderId = currentBinder?.id;
		const binderTrainList = api.train.filter(train =>
			binderId &&
			(train.data.binder_auto === binderId || train.data.binder_driver === binderId || train.data.binder_officer === binderId)
		);

		metadata = {
			trainRef: binderTrainList.map(train => train.data.tech_id),
			materialRef: currentBinder?.material_tech_id,
			status: currentBinder?.status,
			type: currentBinder?.type,
			id: currentBinder?.id
		};
	} else if (entity === "sheet") {
		const currentSheet = api.sheet.find(sheet => sheet.data.sheet_id === ref)?.data;
		const parentBinder = api.binder.find(binder => binder.data.tech_id === currentSheet?.binder_tech_id)?.data;

		metadata = {
			binderRef: currentSheet?.binder_tech_id,
			number: currentSheet.number_search,
			binderId: parentBinder.id,
			type: currentSheet?.type
		};
	} else if (entity === "image") {
		const currentImage = api.image.find(image => image.ref === ref);
		metadata = { sheetRef: currentImage?.sheetRef };
	} else if (entity === "train") {
		const currentTrain = api.train.find(train => train.data.tech_id === ref)?.data;
		metadata = { is_active: currentTrain?.is_active };
	}

	return { ...metadata, ref };
};

/**
 * Eval changes to apply on the current cache content for an entity
 * @param {EntityNames} entity Entity name
 * @param {{ data, metadata }[]} cacheData Entity cache content
 * @param {{ data }[]} apiData entity last fetched data
 * @param {Date} lastSyncDate Last synchronisation date
 * @returns {CacheUpdateAction[]}
 */
const getEntityDiff = (entity, cacheData, apiData, lastSyncDate) => {
	const cacheEntityIds = cacheData.map(item => item.metadata.ref);

	// Eval all entity to upsert (save) or remove cache
	const storedCacheAction = cacheData
		.map(cacheItem => {
			// Get item from api data by tech ids
			const APIItem = apiData.find((item) => getApiItemRef(entity, item) === cacheItem.metadata.ref);

			const result = { entity, ref: cacheItem.metadata.ref };

			if (APIItem){
				// Case item exist in the current cache and api -> eval if update needed based on last data change date
				const itemTimeDiff = differenceInMilliseconds(getApiItemLastChangeDate(entity, APIItem), lastSyncDate);
				const isUpdate = itemTimeDiff > 0;
				result.action = isUpdate ? "save" : "idle";
			} else {
				// Case item not exist in api data -> must be removed
				result.action = "remove";
			}

			return result;
		})
		.filter(({ action }) => action!== "idle");

	// Eval all new data to add into the cache
	const createAction = apiData
		// Filter all entity also in the current cache
		.filter(item => !cacheEntityIds.includes(getApiItemRef(entity, item)))
		// Transform left entity in upsert action
		.map(item => ({ action: "save", entity, ref: getApiItemRef(entity, item) }));

	return [ ...createAction, ...storedCacheAction ];
};

/**
 * Eval all change to apply on the current cache content
 * @param cacheData Current cache content
 * @param apiData Last fetched data
 * @param lastSyncDate Last synchronisation date
 * @returns {CacheUpdateAction[]}
 */
const getAllEntityDiff = (cacheData, apiData, lastSyncDate) => {
	return [
		...getEntityDiff("train", cacheData.train, apiData.train, lastSyncDate),
		...getEntityDiff("binder", cacheData.binder, apiData.binder, lastSyncDate),
		...getEntityDiff("sheet", cacheData.sheet, apiData.sheet, lastSyncDate),
		...getEntityDiff("image", cacheData.image, apiData.image, lastSyncDate),
	];
};

/**
 * Return process with material info and train info if process has a train
 * @param {object} process
 * @return {{train_tech_id}|*}
 */
const extendProcess = async (process) =>  {
	const { binder_tech_id: binderTechId, train_tech_id: trainTechId, line } = process.data;
	try {
		const binder = await getEntity("binder", binderTechId, line);
		process.data.material_label = binder.data.material_label;
		process.data.material_tech_id = binder.data.material_tech_id;
		if (trainTechId) {
			const train = await getEntity("train", trainTechId, line);
			process.data.train_id = train.data.id;
			process.data.train_brake_system = train.data.brake_system;
		}
	} catch {
		// no process if nothing in binder or train cache
		console.error("missing entity train or binder");
	}
	return process;
};

/**
 * Get a entity from cache by it's tech id
 * @param entityFilter The entity filters
 * @param {boolean} [extended] Show train and material info
 * @return {Promise<CacheEntity>}
 */
const getEntityProcessList = async (entityFilter, extended = false) => {
	let processList = await getEntityList("process", entityFilter);
	if (extended) {
		processList = await Promise.all(processList.map(process => extendProcess(process)));
	}
	return processList;
};

/**
 * check if cache data for offline mode is available
 * @return {boolean}
 */
const checkIsCachePresent = async () => {
	const lineCacheEntryList = await getEntityList("status");
	const hasCache = lineCacheEntryList?.length > 0;
	return hasCache;
};

export { getEntity, removeEntity, getEntityList, setEntity, buildEntityMetadata, getEntityDiff, getAllEntityDiff, getEntityProcessList, checkIsCachePresent };
