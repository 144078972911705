
/**
 * Build the uri to get binder list of a train process
 * @param {Object} info
 * @param {string} [info.processId]
 * @param {'railway'|'spr'} [side = 'spr']
 * @param {boolean} [read = false]
 * @returns {string} `/${side}/process/${processId}/binders`
 */
const buildTrainProcessBinderList = (info, side = "spr", read = false) => {
	const { processId } = info;
	return read ?  `/${side}/read/process/${processId}/binders` : `/${side}/process/${processId}/binders`;
};

/**
 * Build the uri to get binder detail of a train process
 * @param {Object} info
 * @param {string} [info.processId]
 * @param {string} [info.binderTechnicalId]
 * @param {'railway'|'spr'} [side = 'spr']
 * @param {boolean} [read = false]
 * @returns {string} `/${side}/process/${processId}/binders/${binderTechnicalId}`
 */
const buildTrainProcessBinderDetail = (info,  side = "spr", read = false) => {
	const { processId, binderTechnicalId } = info;
	return read ? `/${side}/read/process/${processId}/binders/${binderTechnicalId}` : `/${side}/process/${processId}/binders/${binderTechnicalId}`;
};



/**
 * Build the uri to get binder list of a train process
 * @param {Object} info
 * @param {string} [info.processId]
 * @param {'railway'|'spr'} [side = 'spr']
 * @param {boolean} [read = false]
 * @returns {string} `/${side}/read/process/${processId}`
 */
const buildTrainProcess = (info, side = "spr", read = false) => {
	const { processId } = info;
	return read ?  `/${side}/read/process/${processId}` : `/${side}/process/${processId}`;
};

export { buildTrainProcessBinderList, buildTrainProcessBinderDetail,buildTrainProcess };
