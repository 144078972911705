import React, { useEffect, useState, useReducer, useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { searchSheetsByBinderId } from "../../../../../../../../sheet.services";
import { searchBinder } from "../../../../../../../../../binder/binder.services";
import { binderStatusRules } from "../../../../../../../../../binder";
import { Dropdown, TextfieldAutocomplete } from "../../../../../../../../../../theme";
import { addSheetsTitleRaw } from "../../../../../../../../utils/add-sheet-title-raw-utils/add-sheet-title-raw-utils";
import "./simple-link-other-sheet-form.scss";

/**
 * Render the SimpleLinkOtherSheetForm component
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const SimpleLinkOtherSheetForm = (props) => {
	const { handleSelectedValue, content = {}, binderData, isTrainBinder } = props;
	const { titleRaw: currTitleRaw, sheetNumber: currSheetNumber, sheet_id: techId, sheetColor: currSheetColor, binderColor: currBinderColor, sheetType: currSheetType, binder: currBinder, actionId: currActionId, actionPosition: currPosition } = content || {};
	const { t } = useTranslation();
	const subtitleDisplayStatus = binderStatusRules.hideSubtitle;

	const initSelectedSheet = { titleRaw: currTitleRaw, sheetNumber: currSheetNumber, techId, sheetColor: currSheetColor, binderColor: currBinderColor, sheetType: currSheetType };
	const initSelectedBinder = currBinder || {};
	const initSelectedAction = { id: currActionId, position: currPosition };

	const clearParentLinkContent = () => {
		if (techId) {
			handleSelectedValue({}, {});
		}
	};

	const [ form, updateForm ] = useReducer((previous, action) => {
		const { type: actionType, value } = action;
		const { selectedBinder: previousSelectedBinder, selectedSheet: previousSelectedSheet, initialized = false } = previous;
		switch (actionType) {
		case "init":
			const { sheetNumber, sheetId, sheetTitle, binder, actionId, actionPosition, sheetColor, binderColor, sheetType } = value;
			const newSelectedSheet = { techId: sheetId, sheetNumber, titleRaw: sheetTitle, sheetColor, binderColor, sheetType };
			const newSelectedAction = { id: actionId, position: actionPosition };
			return initialized ? previous : { initialized: true, selectedBinder: binder, selectedSheet: newSelectedSheet, selectedAction: newSelectedAction };
		case "init sheet content":
			return { ...previous, selectedSheet: { ...previousSelectedSheet, content: value } };
		case "select binder":
			// Clear selected sheet and action in parent
			clearParentLinkContent();
			// Reset sheet and action on binder id change
			return { ...previous, selectedBinder: value, selectedSheet: {}, selectedAction: {} };
		case "search sheet":
			// Clear selected sheet and action in parent
			clearParentLinkContent();
			return { ...previous, sheetQuery: value, selectedSheet: {}, selectedAction: {} };
		case "select sheet":
			const { status, subtitle, binderTechId, ...sheetData } = value;
			const newSheetDetail = { ...previousSelectedSheet, ...sheetData, action: {} };
			const newSelectedBinder = { ...previousSelectedBinder, status, subtitle, techId: binderTechId };
			// Update sheet and binder info in parent
			handleSelectedValue(newSheetDetail, newSelectedBinder);
			// Reset action on sheet selection
			return { ...previous, selectedBinder: newSelectedBinder, selectedSheet: newSheetDetail, selectedAction: {} };
		case "select action":
			const newSheetInfo = { ...previousSelectedSheet, action: value };
			// Update sheet and binder and action info in parent
			handleSelectedValue(newSheetInfo, previousSelectedBinder);
			return { ...previous, selectedBinder: previousSelectedBinder, selectedSheet: newSheetInfo, selectedAction: value };
		default:
			return previous;
		}

	}, {});

	const {
		selectedBinder = initSelectedBinder,
		selectedSheet = initSelectedSheet,
		selectedAction = initSelectedAction,
		sheetQuery = ""
	} = form;

	const [ binderList, setBinderList ] = useState([]);
	const [ binderSheetList, setBinderSheetList ] = useState([]);

	const handleDropdownChange = (e) => {
		// Update binder id on dropdown value change
		updateForm({ type: "select binder", value: { id: e.target.value } });
	};

	// handle input sheet value
	const handleAutoCompleteChange = (e) => {
		updateForm({ type: "search sheet", value: e.target.value });
	};

	// handle input sheet value selected by autocompletion
	const handleAutoCompleteSelectedValue = (suggestion) => {
		const { value: sheetNumber, titleRaw, sheet_id, status, subtitle, binderTechId, color: sheetColor, binderColor, type: sheetType } = suggestion;

		updateForm({
			type: "select sheet",
			value: { titleRaw, sheetNumber, techId: sheet_id, status, subtitle, binderTechId, sheetColor, binderColor, sheetType }
		});
	};

	const handleSelectAction = (event) => {
		const parsedValue = JSON.parse(event.target.value);
		updateForm({ type: "select action", value: parsedValue });
	};

	const getBinderSheetsList = () => {
		if (selectedBinder.id) {
			const params = { simpleLink: true };
			searchSheetsByBinderId(null, selectedBinder.id, params).then((response) => {
				const sheetWithPlainTitle = addSheetsTitleRaw(response?.data);
				setBinderSheetList(sheetWithPlainTitle);
				if (selectedSheet?.techId) {
					const selectedSheetDetail = sheetWithPlainTitle?.find(sheet => sheet.sheet_id === selectedSheet.techId);
					updateForm({ type: "init sheet content", value: selectedSheetDetail?.content });
				}
			});
		}
	};

	// getting suggestion result, map for preparing rendering then pass to child
	const getSuggestions = async (val) => {
		const dataList = await searchExistingSheetByNumber(val) || [];
		return dataList.map(renderLabelAutoCompletion);
	};

	/**
	 * function preparing return object for autocompletion rendering
	 * @param  sheet
	 * @returns {{binderTechId, sheet_id, subtitle, titleRaw, label: {className: string, lines: []}, value, status}}
	 */
	const renderLabelAutoCompletion = (sheet) => {
		const {
			sheet_id,
			binder_status: status,
			number_search,
			binder_id: id,
			binder_subtitle: subtitle,
			binder_tech_id: binderTechId,
			titleRaw,
			color,
			binder_color: binderColor,
			type
		} = sheet;
		// lines constant is for rendering the label in the autocompletion block
		const lines = [];
		if (id && status) {
			lines.push({ type: "id", text: `${id} [${t(`binder:binder-status.${status}`)}]` });
		}
		if (subtitle && !subtitleDisplayStatus.includes(status)) {
			lines.push({ type: "subtitle", text: subtitle });
		}
		if (number_search && titleRaw) {
			lines.push({ type: "sheet-number", text: `${number_search.toUpperCase()} - ${titleRaw}` });
		}

		const label = { className: "simple-link-other-sheet-form__autocomplete-label", lines };
		return ({ sheet_id, titleRaw, status, value: number_search, label, subtitle, binderTechId, color, binderColor, type });
	};

	const searchExistingSheetByNumber = async (val) => {
		const sheetList = [ ...binderSheetList ];
		if (val) {
			return sheetList.filter((sheet) => (sheet.number_search?.includes(val) || sheet.titleRaw?.includes(val)));
		} else {
			return sheetList;
		}
	};

	const getBinderList = () => {
		const params = { "-status": [ "revoke", "cancelled" ] };
		if (isTrainBinder){
			params.material_tech_id = binderData.material_tech_id;
		} else {
			params.associatedLine = binderData?.associated_line;
			params.type = [ "theme", "sheet" ];
		}
		searchBinder(params).then(response => {
			const newBinderList = response.data?.filter((binder) => binder.id !== binderData?.id);
			setBinderList(newBinderList);
		});
	};

	const binderDropdownOptions = useMemo(() => {
		// filter the sheet's binder id and map into id for dropdown options rendering
		const binderListId = binderList
			.filter(binder => binder.id !== binderData.id)
			.map((binder) => binder.id);
		const binderOptions = [ ...new Set(binderListId) ];

		return binderOptions.map((binderId) => (
			<option value={binderId} key={binderId}>
				{binderId}
			</option>
		));
	}, [ binderData.id, binderList ]);

	const actionDropdownOptions = useMemo(() => {
		const templateList = selectedSheet.content || [];
		return templateList.filter(template => template.type === "action").map((template, position) => {
			const { id } = template;
			return (
				<option key={id} value={JSON.stringify({ id, position })}>
					{t("sheet:sheet-details.template.simpleLink.action-option", { bullet: position + 1 })}
				</option>
			);
		});

	}, [ selectedSheet.content, t ]);


	// Display error message if there is no published version of selected binder
	const displayError = () => {
		if (selectedBinder.id && binderList.length > 0 && !binderList.find(binder => binder.id === selectedBinder.id && binder.status === "published")) {
			return (
				<div className="simple-link-other-sheet__warning">
					{t("sheet:sheet-details.template.simpleLink.form-warning")}
				</div>
			);
		}
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(getBinderList, []);
	useEffect(getBinderSheetsList, [ selectedSheet?.techId, selectedBinder ]);
	useEffect(() => updateForm({ type: "init", value: content }), [ content ]);

	return (
		<div className="simple-link-other-sheet-form">
			<div className="simple-link-other-sheet-form__dropdown-binder">
				<label
					className="simple-link-other-sheet-form__label">{t("sheet:sheet-details.template.simpleLink.sheet-label-id")}</label>
				<Dropdown
					className="simple-link-other-sheet__dropdown-type"
					onChange={handleDropdownChange}
					value={selectedBinder.id}
					placeholder={t("sheet:sheet-details.template.simpleLink.placeholder-dropdown-binderid")}>
					{binderDropdownOptions}
				</Dropdown>
			</div>

			<div className="simple-link-other-sheet-form__autocomplete-sheet">
				<label
					className="simple-link-other-sheet-form__label">{t("sheet:sheet-details.template.simpleLink.sheet-label")}</label>
				<TextfieldAutocomplete
					name="sheetNumber"
					className="simple-link-other-sheet-form__input"
					value={selectedSheet.sheetNumber || sheetQuery}
					handleChange={(e) => {
						handleAutoCompleteChange(e);
					}}
					getSuggestions={getSuggestions}
					selectedValue={handleAutoCompleteSelectedValue}
					disabled={!selectedBinder.id}
					refreshSuggestion={binderSheetList}
				/>
			</div>
			{isTrainBinder && (
				<div className="simple-link-other-sheet-form__dropdown-action">
					<label
						className="simple-link-other-sheet-form__label">{t("sheet:sheet-details.template.simpleLink.action-dropdown-label")}</label>
					<Dropdown
						className="simple-link-other-sheet__dropdown-type"
						onChange={handleSelectAction}
						value={JSON.stringify(selectedAction)}
						placeholder={t("sheet:sheet-details.template.simpleLink.action-dropdown-placeholder")}
						placeholderValue="{}"
						disabledPlaceholder={false}
						disabled={!selectedSheet?.content}>
						{actionDropdownOptions}
					</Dropdown>
				</div>
			)}
			{displayError()}
		</div>
	);
};

SimpleLinkOtherSheetForm.propTypes = {
	handleSelectedValue : PropTypes.func.isRequired,
	binderContent : PropTypes.shape({ id: PropTypes.string }),
	redirectSheetValue : PropTypes.string.isRequired,
	binderData : PropTypes.shape({
		associated_line: PropTypes.number,
		id: PropTypes.string
	})
};

export default SimpleLinkOtherSheetForm;
