import React from "react";
import classNames from "classnames";
import "./digiproc-loader.scss";

const DigiprocLoader = props => {
	const { className } = props;
	return (
		<div className={classNames("digiproc-loader", className)}>
			{/* Add Loader points */}
			{[ ...Array(12) ].map((_elm, pos) => <div key={pos}/>)}
		</div>
	);
};

export default DigiprocLoader;
