import i18next from "../../config/i18next";
import i18nBinder from "./i18n/fr.json";
import BinderDetail from "./pages/binder-detail/binder-detail";
import BinderList from "./pages/binder-list/binder-list";
import SprBinderList from "./pages/spr-binder-list/spr-binder-list";
import SprBinderDetail from "./pages/spr-binder-detail/spr-binder-detail";
import SprBinderGroup from "./pages/spr-binder-group/spr-binder-group";
import BinderStatus from "./components/binder-status/binder-status";
import SlBinderDetail from "./pages/sl-binder-detail/sl-binder-detail";
import { PopupCreateBinder, PopupBinderEditTitle, FormBinder } from "./components/popup";
import binderStatusList from "./utils/binder-status-list.json";
import binderStatusRules from "./utils/binder-status-rules.json";
import binderColorList from "./utils/binder-color-list.json";
import BinderByMaterialList from "./pages/binder-by-material-list/binder-by-material-list";
i18next.addResourceBundle("fr", "binder", i18nBinder);

export {
	BinderDetail,
	BinderList,
	BinderByMaterialList,
	BinderStatus,
	FormBinder,
	PopupCreateBinder,
	PopupBinderEditTitle,
	SprBinderList,
	SprBinderDetail,
	SprBinderGroup,
	SlBinderDetail,
	binderStatusList,
	binderStatusRules,
	binderColorList
};
