import React from "react";
import { useTranslation } from "react-i18next";
import TrainTroubleFormRadioButton from "../../../../shared/train-trouble-form-radio-button/train-trouble-form-radio-button";
import { ratpNetworkFields } from "../../../utils/resume-form-fields";
import ValidatedSubstepCard from "../../../../shared/validated-substep-card/validated-substep-card";
import TrainTroubleFormRadioButtonWithInput
	from "../../../../shared/train-trouble-form-radio-button-with-input/train-trouble-form-radio-button-with-input";
import { initLineB } from "../../../utils/init-resume-form";
import classNames from "classnames";
import TrainTroubleFormFooter from "../../../../shared/train-trouble-form-footer/train-trouble-form-footer";

import "./resume-form-step-two-line-B.scss";
import "../../resume-form-block.scss";

const ResumeFormStepTwoLineB = (props) => {
	const { resumeStep, handleChange, handleReset, handleSubmit, resumeForm, hasEmergencyLightOn, backToSubstep, handleBackClick, stepNumber } = props;
	const { t } = useTranslation();

	const fields = [ ...ratpNetworkFields ];
	const { ratp_network: ratpNetwork,
		ratp_resume_type: ratpResumeType,
		ratp_speed_restriction: ratpSpeedRestriction,
		head_of_regulation_informed: headOfRegulationInformed } = resumeForm?.ratp_network_group || {};

	const isSubstepValidated = resumeForm?.ratp_network_group.validated;
	const isRatpNetwork = resumeForm?.ratp_network_group.ratp_network === "yes";

	const className = classNames({ "resume-form-block": resumeStep === 1 });

	const handleSubmitSubstep = () => {
		let updatedResumeForm;
		if (isRatpNetwork) {
			updatedResumeForm = { ...resumeForm, ratp_network_group: { ...resumeForm.ratp_network_group, validated: true } };
		} else {
			updatedResumeForm = { ...resumeForm, ratp_network_group: { ...initLineB.ratp_network_group, ratp_network: "no", validated: true } };
		}
		handleSubmit(updatedResumeForm);
	};

	const checkFormValidity = () => {
		let fieldsList = fields;
		if (hasEmergencyLightOn) {
			fieldsList = fields.slice(0,3);
		}
		if (!isRatpNetwork) {
			fieldsList = fields.slice(0,1);
		}
		const formErrorList = fieldsList.map((field) => {
			const { name: fieldName } = field;
			return field.validator(resumeForm?.ratp_network_group[fieldName]);
		});
		return formErrorList?.every(el => el === true);
	};

	const isFormValid = checkFormValidity();


	return (
		<div className={className}>
			{resumeStep > 1 && isSubstepValidated && <ValidatedSubstepCard
				title={t("process:process-rer-resume.resume-rer.substep.ratp_network")}
				backToSubstep={() => backToSubstep(1)}/>}
			{resumeStep === 1 && <div className={className}>
				<TrainTroubleFormRadioButton
					title={t("process:process-rer-resume.resume-rer.ratp_network")}
					step={"resume-rer"}
					substep={fields[0].name}
					options={fields[0].values}
					isFirstQuestion={fields[0].is_first_question}
					checkedValue={ratpNetwork}
					onChange={handleChange}/>
				{isRatpNetwork && <div>
					<TrainTroubleFormRadioButton
						title={t("process:process-rer-resume.resume-rer.ratp_resume_type")}
						step={"resume-rer"}
						substep={fields[1].name}
						options={ hasEmergencyLightOn ? [ "hlp", "travellers" ] : fields[1].values }
						isFirstQuestion={fields[1].is_first_question}
						checkedValue={ratpResumeType}
						onChange={handleChange}/>
					<TrainTroubleFormRadioButtonWithInput
						title={t("process:process-rer-resume.resume-rer.ratp_speed_restriction")}
						step={"resume-rer"}
						substep={fields[2].name}
						group={"ratp_network_group"}
						options={fields[2].values}
						resumeForm={resumeForm}
						checkedValue={ratpSpeedRestriction}
						onChange={handleChange}/>
					{!hasEmergencyLightOn && <TrainTroubleFormRadioButton
						title={t("process:process-rer-resume.resume-rer.head_of_regulation_informed")}
						step={"resume-rer"}
						substep={fields[3].name}
						options={fields[3].values}
						isFirstQuestion={fields[3].is_first_question}
						checkedValue={headOfRegulationInformed}
						onChange={handleChange}/>}
					{hasEmergencyLightOn && <div className={"resume-form__reminder"}>{t("process:process-rer-resume.resume-rer.bogie-reminder")}</div>}
				</div>}
			</div>}
			{resumeStep === 1 && <TrainTroubleFormFooter line={"B"} stepNumber={hasEmergencyLightOn ? stepNumber : ""} handleBackClick={handleBackClick} handleReset={handleReset} handleSubmit={handleSubmitSubstep} isSubmitBlocked={!isFormValid}/>}
		</div>
	);
};

export default ResumeFormStepTwoLineB;
