const validateUserId = (id) => {
	const regexLetter = /[a-zA-Z]/g;
	const regexNumber = /\d/g;
	let idValidationLabel = "";
	let isValid = false;
	if (id){
		if (!regexLetter.test(id)) {
			idValidationLabel = "user:popup.create-user.id-validation.no-letter";
		} else if (!regexNumber.test(id)) {
			idValidationLabel = "user:popup.create-user.id-validation.no-number";
		} else {
			isValid = true;
		}
	}
	return [ isValid, idValidationLabel ];

};

const validateUserForm = (newFormUser, isCreate) => {
	const isTrainAgent = newFormUser.role === "train-agent";
	const fieldsRequired = [ 
		(isCreate ? "id" : null), 
		"lastname", 
		"firstname", 
		"role", 
		"associated_line"
	].filter(x => !!x);

	// mandatory checks
	const fieldsExist = fieldsRequired.every((field) => newFormUser.hasOwnProperty(field));
	const fieldsAreFilled = fieldsRequired.every((field) => !!newFormUser[field] || newFormUser[field] === null);
	const fieldAssociatedLineValid = isTrainAgent ? newFormUser["associated_line"]?.length === 1 : newFormUser["associated_line"]?.length > 0;

	const validationObj = {
		isValid : fieldsExist && fieldsAreFilled && fieldAssociatedLineValid,
		idValidationLabelKey: ""
	};

	// extra id check for edit
	if (isCreate){
		const [ isValidId, idValidationLabelKey ] = validateUserId(newFormUser?.id);
		validationObj.isValid = isValidId && validationObj.isValid;
		validationObj.idValidationLabelKey = idValidationLabelKey;
	}

	return validationObj;
};

export default validateUserForm;
