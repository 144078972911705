import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSheetContentContext } from "../../../../../../context/sheet-content-context";
import { IconWrapper, Textarea, ToggleSwitch } from "../../../../../../../../theme";
import { useDebouncedAction } from "../../../../../../../../shared";
import { IconTrash } from "../../../../../../../../theme/assets/img";
import "./table-template-form.scss";

/**
 * @typedef TableData
 * @prop {string} columnOne
 * @prop {string} columnTwo
 */

/**
 * Table panel form for task configuration
 * @param {object} props
 * @param {{
 * 	header: TableData
 * 	lines: TableData[]
 * }} props.content task content
 * @returns {JSX.Element}
 */
const TableTemplateForm = (props) => {
	const { content = {} } = props;

	const { t } = useTranslation();
	const { setCurrentNodeContent, updateDelay } = useSheetContentContext();

	// boolean to show table's header
	const [ showHeader, setHeadCheck ] = useState(true);

	const blockSample = { columnOne: "", columnTwo: "" };
	const [ tableHeader, setTableHeader ] = useState(content?.header || { ...blockSample });
	const [ tableLines, setTableLines ] = useState(content?.lines || [ { ...blockSample } ]);

	// update item content for nodeList
	const setNodeContent = useDebouncedAction((tableContent, setHistory = false, headerCheck = showHeader) => {
		let updateTableContent = { ...content, ...tableContent };
		if (!headerCheck) {
			// delete header attribut from content item
			updateTableContent.header = undefined;
			// reinitialize tableHeader state to undefined
			setTableHeader(undefined);
		}
		setCurrentNodeContent({ ...updateTableContent }, setHistory);
	}, updateDelay);

	const handleDeleteBlock = (index) => {
		const updatedRedirectBlocks = [ ...tableLines ];
		updatedRedirectBlocks.splice(index, 1);
		setTableLines(updatedRedirectBlocks);
		setNodeContent({ lines: updatedRedirectBlocks }, true);
	};

	const handleChangeLine = (event, index, setHistory) => {
		let { value, name } = event.target;
		const updatedTable = [ ...tableLines ];
		updatedTable[index][name] = value;
		setTableLines(updatedTable);
		setNodeContent({ lines: updatedTable }, setHistory);
	};

	const handleChangeHead = (event, setHistory) => {
		let { value, name } = event.target;
		const updatedTable = { ...tableHeader };
		updatedTable[name] = value;
		setTableHeader(updatedTable);
		setNodeContent({ header: updatedTable }, setHistory);
	};

	const handleChangeToggle = (check) => {
		setHeadCheck(check);
		setNodeContent({ header: { ...blockSample } }, true, check);
	};

	const addNewLine = () => {
		const updatedTable = [ ...tableLines, { ...blockSample } ];
		setTableLines(updatedTable);
		setNodeContent({ lines: updatedTable }, true);
	};

	// reload component state for redo function
	const reloadForm = () => {
		if (content && content.header !== tableHeader) {
			setTableHeader(content.header);
			setHeadCheck(content.header !== null);
		}
		if (content && content.lines !== tableLines) {
			setTableLines(content.lines);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setNodeContent({ header: tableHeader, lines: tableLines }), []);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(reloadForm, [ content ]);


	const renderTableHeadBlock = () => {
		return (
			<div className="table-template-form__head-form">
				<div className="table-template-form-field">
					<Textarea
						name="columnOne"
						className="table-template-form__input"
						value={tableHeader?.columnOne || ""}
						label={t("sheet:sheet-details.template.table.text-column-one")}
						onChange={(e) => handleChangeHead(e, false)}
						onBlur={(e) => handleChangeHead(e, true)}
					/>
				</div>

				<div className="table-template-form-field">
					<Textarea
						name="columnTwo"
						className="table-template-form__input"
						value={tableHeader?.columnTwo || ""}
						label={t("sheet:sheet-details.template.table.text-column-two")}
						onChange={(e) => handleChangeHead(e, false)}
						onBlur={(e) => handleChangeHead(e, true)}
					/>
				</div>
			</div>
		);
	};

	const renderTableLineBlock = (line, index) => {
		return (
			// TODO Key index, find a better way without the index in the key
			<div className="table-template-form__list" key={`table-line-${index}`}>
				<div className="table-template-form-field__head">
					<span className="table-template-form-field__badge">{index + 1}</span>
					{tableLines.length > 1 &&
					<button className=" table-template-form-field__delete" type="button"
					        onClick={() => handleDeleteBlock(index)}>
						<IconWrapper className={"table-template-form-field__icon"} Component={IconTrash}
						             tooltip={t("sheet:sheet-details.tooltip.delete")}/>
					</button>
					}
				</div>
				<div className="table-template-form-field">
					<Textarea
						name="columnOne"
						className="table-template-form__input"
						value={line?.columnOne}
						label={t("sheet:sheet-details.template.table.text-column-one")}
						onChange={(e) => handleChangeLine(e, index, false)}
						onBlur={(e) => handleChangeLine(e, index, true)}
					/>
				</div>

				<div className="table-template-form-field">
					<Textarea
						name="columnTwo"
						className="table-template-form__input"
						value={line?.columnTwo}
						label={t("sheet:sheet-details.template.table.text-column-two")}
						onChange={(e) => handleChangeLine(e, index, false)}
						onBlur={(e) => handleChangeLine(e, index, true)}
					/>
				</div>

			</div>
		);
	};

	return (
		<div className="table-template-wrapper">
			<div className="table-template-form__head">
				<div className="table-template-form__head__top">
					<span className="table-template-form__head__title">
						{t("sheet:sheet-details.template.table.label-header")}</span>
					<div className="table-template-form__head__filter-toggle">
						<span className="table-template-form__head__filter-toggle__label">
							{t("sheet:sheet-details.template.table.label-toggle")}</span>
						<ToggleSwitch isChecked={showHeader} handleOnChange={() => handleChangeToggle(!showHeader)}/>
					</div>
				</div>
				{showHeader && renderTableHeadBlock()}
			</div>
			<div className="table-template-form__lines-form">
				<span className="table-template-form__lines__title">
					{t("sheet:sheet-details.template.table.label-lines")}</span>
				{tableLines?.map(renderTableLineBlock)}
			</div>
			<button className="table-template-form__add-node" onClick={() => addNewLine()}>
				{t("sheet:sheet-details.template.table.add-line")}
			</button>
		</div>
	);
};

TableTemplateForm.propTypes = {
	content: PropTypes.shape({
		header: PropTypes.shape({
			columnOne: PropTypes.string,
			columnTwo: PropTypes.string,
		}),
		lines: PropTypes.arrayOf(PropTypes.shape({
			columnOne: PropTypes.string,
			columnTwo: PropTypes.string,
		})),
	})
};

export default TableTemplateForm;
