import React from "react";
import { useTranslation } from "react-i18next";
import SimpleLinkSheetnumber from "../simple-link-sheetnumber/simple-link-sheetnumber";
import "./simple-link-sheet-view.scss";

const SimpleLinkSheetView = (props) => {
	const { content, isTrainBinder } = props;
	const { actionId, actionPosition } = content || {};

	const { t } = useTranslation();

	return (
		<div className="simple-link-sheet-view">
			<div className="simple-link-sheet-view__content">
				<SimpleLinkSheetnumber content={content}/>
			</div>
			{isTrainBinder && (
				<div className="simple-link-sheet-view__informations">
					{actionId &&
					<p>
						{t("sheet:sheet-details.template.simpleLink.action-option", { bullet: actionPosition + 1 })}
					</p>
					}
				</div>
			)}
		</div>
	);
};

export default SimpleLinkSheetView;
