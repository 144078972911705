import { csrfRequestHeader, getCSRFToken } from "../csrf";
import offlineProxyInterceptor from "./offline-proxy-interceptor";
import offlinePostPutInterceptor from "./offline-post-put-interceptor";
import { ServerUnreachableEvent, ServerErrorEvent, getNetworkState } from "../offline";
import offlineAuthInterceptor from "./offline-auth-interceptor";
import { logoutOffline } from "../otp";
const { NODE_ENV, REACT_APP_API_BASE_URL } = process.env;
const authRootUrl = REACT_APP_API_BASE_URL + "/auth";

/**
 * Redirect to error page if handled error code is 403
 * @param { {response: { status: number }}} error
 */
const handleForbiddenRedirect = error => {
	if (error?.response?.status === 403) {
		window.location = "/error-access";
	}
};

/**
 * Redirect to login page (depending on network status)
 * @param {import("axios").AxiosResponse} response
 */
const handleUnauthorizedRedirect = async (response) => {
	const errorTypeHeader = response?.headers?.["error-type"];
	if (response?.status === 401 && (errorTypeHeader === "TOKEN_INVALID" || errorTypeHeader === "OFFLINE_TOKEN_INVALID") ) {
		const networkState = getNetworkState();
		await logoutOffline();
		const totpCodeUrl = "/totp/code";
		const { pathname } = window.location;
		if (networkState === "online") {
			const redirectPath = totpCodeUrl === pathname ? "/" : pathname;
			window.location = `${authRootUrl}/login?redirectPath=${redirectPath}`;
		} else {
			window.location = totpCodeUrl;
		}
	}
};

/**
 * Setup axios interceptor
 * @param {import("axios").AxiosInstance}  axiosInstance The axios instance to setup
 * @param {{redirectOnForbidden: boolean, offline: boolean}} [options = {}] Options applied to setup the axios instance
 * @param {boolean} [options.redirectOnForbidden=true] If `true` when a response handled by this axios instance is a forbidden error then redirect to the proper error page
 */
const setupInterceptor = (axiosInstance, options = {}) => {
	const { redirectOnForbidden = true, offline = false } = options;

	axiosInstance.interceptors.request.use(reqConfig => {
		const csrfToken = getCSRFToken();
		if (csrfToken) {
			reqConfig.headers[csrfRequestHeader] = csrfToken;
		}
		return reqConfig;
	});

	if (offline){
		axiosInstance.interceptors.request.use(offlineProxyInterceptor);
		axiosInstance.interceptors.response.use(offlinePostPutInterceptor);
		axiosInstance.interceptors.response.use(offlineAuthInterceptor);
	}

	axiosInstance.interceptors.response.use(async res => {
		await handleUnauthorizedRedirect(res);
		return res;
	}, async error => {
		await handleUnauthorizedRedirect(error.response);

		if (redirectOnForbidden) {
			handleForbiddenRedirect(error);
		}

		// dispatch event for server timeout
		if (error.code === "ECONNABORTED") {
			window.dispatchEvent(ServerUnreachableEvent);
			return;
		}

		// dispatch event for network error
		if (error.message === "Network Error") {
			window.dispatchEvent(ServerUnreachableEvent);
			window.dispatchEvent(ServerErrorEvent);
			return;
		}

		if (NODE_ENV !== "production" && error.response) {
			const { id, error: errorMessage } = error.response.data;
			console.error(`Error: ${errorMessage}\nMore detail about this error can be found in API log by searching the following id: "${id}"`);
		}
		return Promise.reject(error);
	});

};

export default setupInterceptor;
