import { http } from "../../config";

/**
 * Get all comments contained in the specified sheet
 * @param {string} sheetId The sheet's technical id
 */
const fetchCommentBySheetId = async (sheetId) => http.get(`/comment/${sheetId}`);

/**
 * Create a new Comment
 * @param {Object} dataComment The comment data
 * @param {string} dataComment.content The content of the comment
 * @param {string} dataComment.sheet_id The sheet's id containing the comment
 */
const createComment = async (dataComment) => http.post("/comment", dataComment);

/**
 * Delete a comment
 * @param {string} commentId The comment's technical id
 */
const deleteComment = async (commentId) => http.delete(`/comment/${commentId}`);

/**
 * Update a comment
 * @param {string} commentId The comment's technical id
 * @param {Object} dataComment The comment data to update
 */
const updateComment = async (commentId, dataComment) => http.put(`/comment/${commentId}`, dataComment);


export {
	fetchCommentBySheetId,
	createComment,
	deleteComment,
	updateComment
};
