import React from "react";
import classNames from "classnames";
import { IconLeftArrowWithCircle, IconRightArrowWithCircle } from "../../../../../../../theme/assets/img";

import "./damage-location-choice-button.scss";

const DamageLocationChoiceButton = (props) => {
	const { fieldName, formData, handleChange, options } = props;

	return (
		<>
			<ul className="damage-location__form__choice-button">
				{options.map((option)=> {
					const isTrainComposition = [ "UM", "US" ].includes(option);
					const selected = formData?.[fieldName] === option;
					return <li className={classNames("damage-location__form__choice-button__item", { "damage-location__form__choice-button__item--selected": selected })}>
						<label htmlFor={option} className="damage-location__form__choice-button__item__wrapper">
							<input
								className="input-field__choice-button"
								id={option}
								name={fieldName}
								type="choice"
								checked={selected}
								value={option}
								onClick={handleChange}
							/>
							{ option === "left" && <IconLeftArrowWithCircle fill={selected ? "#0009A4" : "#2A2A2E"} role="img" aria-hidden="true" focusable="false"/>}
							{ option === "right" && <IconRightArrowWithCircle fill={selected ? "#0009A4" : "#2A2A2E"} role="img" aria-hidden="true" focusable="false"/>}
							{ isTrainComposition && <span className={classNames("damage-location__form__choice-button__item__wrapper__text", { "damage-location__form__choice-button__item__wrapper__text--selected": selected })}>{option}</span> }
						</label>
					</li>;
				})}
			</ul>
		</>
	);
};

export default DamageLocationChoiceButton;
