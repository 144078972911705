/**
 * Return true if the new focused element is contained by last focused element
 * @param blurEvent A blur event object
 * @returns {boolean}
 */
const focusInCurrentTarget = (blurEvent) => {
	const { relatedTarget, currentTarget } = blurEvent;
	if (relatedTarget === null) return false;

	let node = relatedTarget.parentNode;

	while (node !== null) {
		if (node === currentTarget) return true;
		node = node.parentNode;
	}

	return false;
};

export default focusInCurrentTarget;
