import React, { useState } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { buildUserName, LoadWrapper, usePopup, useSprContext } from "../../../../shared";
import { IconWrapper } from "../../../../theme";
import PopupStartProcessTrain from "../popup-start-process-train/popup-start-process-train";
import { SprProcessItem } from "../spr-in-progress-item/spr-in-progress-item";
import { ReactComponent as IconTools } from "../../../../theme/assets/img/icon-tools.svg";
import { offlineUser } from "../../../../config/offline";

import "./spr-in-progress-process-list.scss";

/**
 * SprInProgressProcessList
 * @param {object} props
 * @param {string} props.className
 * @returns {JSX.Element}
 */
const SprInProgressProcessList = (props) => {
	const { className } = props;
	const { t } = useTranslation();
	const { newInProgressProcess, processLoading, side } = useSprContext();

	const [ startProcessTrain, setStartProcessTrain ] = useState(null);

	const startProcessTrainPopupControl = usePopup();

	const handlePopupStartProcessTrainVisible = (e, process) => {
		e.preventDefault();
		setStartProcessTrain(process);
		startProcessTrainPopupControl.show();
	};

	const renderUserLabel = (firstname, lastname, id, owner) => {
		if (owner === offlineUser.tech_id){
			return t("user:user-offline");
		}
		else if (!id) {
			return t("user:user-deleted");
		}
		return buildUserName(firstname, lastname, id);
	};

	return (
		<LoadWrapper loading={processLoading}>
			{startProcessTrain && <PopupStartProcessTrain
				process={startProcessTrain}
				popupControl={startProcessTrainPopupControl}
			/>}
			{newInProgressProcess?.length !== 0 && (
				<div className={classnames("spr-in-progress-process-list", className)}>
					<div className="spr-in-progress-process-list__side">
						<IconWrapper className="spr-in-progress-process-list__side__icon" Component={IconTools}/>
						<span
							className="spr-in-progress-process-list__side__title">
							{t("process:spr-in-progress-list.title")}
						</span>
					</div>
					<ul className="spr-in-progress-process-list__list">
						{newInProgressProcess?.map((process) => <SprProcessItem
							key={process.processTechId}
							process={process}
							handlePopupStartProcessTrainVisible={handlePopupStartProcessTrainVisible}
							renderUserLabel={renderUserLabel}
							side={side}
						/>)}
					</ul>
				</div>
			)}
		</LoadWrapper>
	);
};

export default SprInProgressProcessList;
