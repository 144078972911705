import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { IconWrapper } from "../../../../../../theme";
import { buildUserName } from "../../../../../../shared/utils/build-user-name";
import { ReactComponent as IconOwner } from "../../../../assets/icon-owner.svg";
import "./history-change-owner-view.scss";

/**
 * Render HistoryChangeOwnerView component
 * @param {object} props
 * @param {Object} props.task
 * @param {String} props.className
 * @param {boolean} props.passed
 * @returns {JSX.Element}
 */
const HistoryChangeOwnerView = (props) => {
	const { task = {}, className, passed } = props;
	const { t } = useTranslation();

	const initClassName = (baseClassName) => classNames(baseClassName, className, { [`${baseClassName}--passed`]: passed });

	const renderUserLabel = () => {
		const { firstname, lastname, id } = task?.userInfo;
		if (!id) {
			return t("user:user-deleted");
		}
		return buildUserName(firstname, lastname, id);
	};

	return (
		<div className={initClassName("history-change-owner-view")}>
			<IconWrapper className="history-text-task-view__icon" Component={IconOwner}/>
			{t("process:history-task.change-owner", { name: renderUserLabel() })}
		</div>
	);
};

HistoryChangeOwnerView.propTypes = {
	task: PropTypes.shape({
		userInfo: PropTypes.shape({
			firstname: PropTypes.string,
			lastname: PropTypes.string,
			id: PropTypes.string
		})
	}),
	className: PropTypes.string,
	passed: PropTypes.bool
};

export default HistoryChangeOwnerView;
