import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "../../../../../../../../theme";
import { useDebouncedAction } from "../../../../../../../../shared";
import { useSheetContentContext } from "../../../../../../context/sheet-content-context";
import { defaultEditorToolbarConfig } from "../../../../../..";
import ImageForm from "../../image-form/image-form";
import AppendixForm from "../../appendix-form/appendix-form";

import "./text-template-form.scss";

const TextTemplateForm = (props) => {
	const { content, type, isTrainBinder, binderData } = props;
	const { t } = useTranslation();

	const { setCurrentNodeContent, updateDelay } = useSheetContentContext();
	const debouncedSetCurrentNodeContent = useDebouncedAction(setCurrentNodeContent, updateDelay);

	const [ text, setText ] = useState(content?.text || "");
	const [ initText, setInitText ] = useState("");
	const [ saveHistory, setSaveHistory ] = useState(true);

	// send data to context
	const setNodeContent = (newText, setHistory) => {
		let formattedInitText = "";
		const hasNewText = newText && newText !== "";
		if (initText) {
			formattedInitText = JSON.stringify(initText);
		}
		// update in history only if text value changed compared to initial value
		const updateHistory = newText !== formattedInitText && setHistory;
		if (updateHistory && hasNewText) {
			//update the init text
			setInitText(JSON.parse(newText || null));
			setSaveHistory(false);
		}

		debouncedSetCurrentNodeContent({ ...content, text: newText || "" }, updateHistory);
		setText(newText || " ");
	};


	const handleBlur = () => {
		if (saveHistory){
			setNodeContent(text || "", true);
		}
	};

	const handleChange = (event) => {
		let { value } = event.target;
		if (value) {
			value = JSON.stringify(value);
		}
		setNodeContent(value, false);
		setSaveHistory(true);
	};

	const reloadForm = () => {
		const newStrText = (content?.text || "");
		const strInitText = JSON.stringify(initText || null);
		const isTextChange = newStrText !== strInitText;
		if (isTextChange) {
			setText(newStrText);
			// init current node content and set history if it's a new template
			if (!content) {
				setNodeContent(newStrText, true);
			}
			// initialize editor or update when selecting another text template
			if (!initText || content?.text !== text) {
				try {
					setInitText(JSON.parse(content?.text));
				} catch (e) {
					setInitText(null);
				}
				setSaveHistory(false);
			}
		}
	};

	const renderImageForm = () => {
		let displayImagesTypes = [ "note", "action" ];
		if (!isTrainBinder){
			displayImagesTypes = [ ...displayImagesTypes, "systemEffect" ];
		}

		if (displayImagesTypes.includes(type)) {
			return <ImageForm content={content}/>;
		}
		return null;
	};

	const renderAppendixForm = () => {
		let displayAppendixTypes = [ "note", "action", "call" ];
		if (isTrainBinder && displayAppendixTypes.includes(type)) {
			return <AppendixForm content={content} binderData={binderData}/>;
		}
		return null;
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(reloadForm, [ content, type ]);

	return (
		<div>
			<form className="text-template-form" onSubmit={event => event.preventDefault()}>
				<label className="text-template-form__label">{t(`sheet:sheet-details.template.${type}.label`)}</label>
				<Editor
					className="text-template-form__editor"
					name="text"
					focus={type}
					value={initText || null}
					toolbarConfig={defaultEditorToolbarConfig}
					onChange={handleChange}
					onBlur={handleBlur}/>
				{renderImageForm()}
				{renderAppendixForm()}
			</form>
		</div>
	);
};

export default TextTemplateForm;
