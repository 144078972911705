import React from "react";
import classNames from "classnames";
import { LogoDigiproc } from "../../../../../../theme";
import { CdrRunProcess } from "../../../../../process";
import { ProcessProvider } from "../../../../../process/components/context-run-process/context-run-process";
import { useSheetContentContext } from "../../../../context/sheet-content-context";
import tabletImage from "../../../../assets/img-process-tablet.svg";
import "./sheet-process-run.scss";

const SheetProcessRun = props => {
	const { className, sheetData = {} } = props;
	const { nodeList } = useSheetContentContext();

	const sheet = { ...sheetData, content: nodeList };

	return (
		<ProcessProvider cdrMode initialSheet={sheet}>
			<div className={classNames(className, "sheet-process-run")}>
				<div className="sheet-process-run__wrapper">
					<img className="sheet-process-run__image" src={tabletImage} alt="tablet"/>
					<div className="sheet-process-run__process">
						<div className="sheet-process-run__process__header">
							<div className="header__wrapper">
								<LogoDigiproc className="header__logo" alt="logo digiproc"/>
								<span className="header__title">Classeur PCC Ligne</span>
							</div>
							<span className="header__bar"/>
						</div>
						<CdrRunProcess className="sheet-process-run__process__list"/>
					</div>
				</div>
			</div>
		</ProcessProvider>
	);
};

export default SheetProcessRun;
