import localForage from "localforage";
import { getItemGenerator, removeItemGenerator, setItemGenerator } from "../localforage";

/** @type {LocalForageOptions} */
const localForageOption = {
	name: "digiproc",
	storeName: "digiproc_enilffo_htua",
	description : "Storage for digiproc enilffo huta",
	driver: localForage.INDEXEDDB
};

const offlineAuthStorage = localForage.createInstance(localForageOption);

/**
 * Get offline authentication storage item
 * @type {(function(string): Promise<*>)}
 */
const getAuthItem = getItemGenerator(offlineAuthStorage);

/**
 * Set offline authentication storage item
 * @type {function(string, *): Promise<*>}
 */
const setAuthItem = setItemGenerator(offlineAuthStorage);

/**
 * Remove offline authentication storage item
 * @type {function(string): Promise<void>}
 */
const removeAuthItem = removeItemGenerator(offlineAuthStorage);


export { getAuthItem, setAuthItem, removeAuthItem, offlineAuthStorage };
