import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Textarea, IconWrapper } from "../../../../../../../../theme";
import ImageFormFileInput from "../image-form-file-input/image-form-file-input";
import { ReactComponent as IconTrash } from "../../../../../../../../theme/assets/img/icon-trash.svg";

import "./image-form-row.scss";


const ImageFormRow = (props) => {
	const {
		imageId,
		title,
		description,
		onUpload,
		onTitleChange,
		onDescriptionChange,
		onFileChange,
		onDeleteImage,
		onDeleteRow,
		selectedFile,
		errorHelperText,
		index
	} = props;

	const { t } = useTranslation();

	const handleUpload = () => {
		onUpload();
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleUpload, [ selectedFile ]);

	return (
		<li className="image-form-row">
			<div className="image-form__head">
				<span className="image-form__head__badge">{index + 1}</span>
				<button className="image-form-row__delete" type="button" onClick={onDeleteRow}>
					<IconWrapper className="image-form-row__delete__icon" Component={IconTrash}
					             tooltip={t("sheet:sheet-details.image-form.delete-tooltip")}/>
				</button>
			</div>
			<span className="image-form-row__image__label">
				{t("sheet:sheet-details.image-form.image-field-label")}
			</span>
			<ImageFormFileInput className="image-form-row__image" value={selectedFile} imageId={imageId}
			                    onChange={onFileChange} onDelete={onDeleteImage} errorHelperText={errorHelperText}/>
			<span className="image-form-row__title__label">
				{t("sheet:sheet-details.image-form.title-field-label")}
			</span>
			<Textarea className="image-form-row__title" name="title" onChange={onTitleChange} value={title}
			          preventNewLine/>
			<span className="image-form-row__description__label">
				{t("sheet:sheet-details.image-form.description-field-label")}
			</span>
			<Textarea className="image-form-row__description" name="description" onChange={onDescriptionChange}
			          value={description}/>
		</li>
	);
};

export default ImageFormRow;
