/**
 * Stringify a signal to render it as dropdown option
 * @param signal
 * @param signal.title
 * @param signal.origin
 * @param signal.destination
 * @returns {string}
 */
const signalOptionToString = (signal = {}) => {
	const { title = "", origin = "", destination = "" } = signal;
	return `${title.toUpperCase()} / ${origin.toUpperCase()} => ${destination.toUpperCase()}`;
};

export default signalOptionToString;
