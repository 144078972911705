import React, { useEffect, useState } from "react";
import ResumeFormLineA from "./components/line-A/resume-form-line-A/resume-form-line-A";
import ResumeFormLineB from "./components/line-B/resume-form-line-B/resume-form-line-B";
import { initLineA, initLineB, initShortLineA, initShortLineB } from "./utils/init-resume-form";
import { updateProcess } from "../../../process.services";

import "./resume-form.scss";

const ResumeForm = (props) => {
	const { line, processInfo, resumeStep = 0, setResumeStep = () => {}, hasEmergencyLightOn, loadProcess, setStepNumber, stepNumber, setDamageFormData } = props;

	const { resume_conditions: resumeConditions, tech_id } = processInfo || {};
	const { resume_rer: resumeRer = {}, damage_location: damageLocation } = resumeConditions || {};
	const { train_composition: trainComposition } = damageLocation || {};

	// Init the form object regarding the current line
	const initObjectLineB = hasEmergencyLightOn ? initLineB : initShortLineB;
	const initObject = line === "A" ? initLineA : initObjectLineB;

	const isEmptyResumeForm = Object.keys(resumeRer).length === 0;
	const resumeFormObject = isEmptyResumeForm ? initObject : resumeRer;

	const [ resumeForm, setResumeForm ] = useState({ ...resumeFormObject });
	const [ pauseStep, setPauseStep ] = useState(false);
	const subStepNameList = Object.keys(initObject);
	const currentSubstepName = subStepNameList[resumeStep];

	const handleBackClick = async () => {
		if (resumeStep === 0) {
			await setDamageFormData({
				step_completed: false,
				emergency_lights: "",
				train_composition: "",
				direction: "",
				equipment_involved: [],
				mission_code: ""
			});
			const clearProcess = { ...resumeConditions, resume_rer: {} };
			await updateProcess(processInfo.tech_id, { resume_conditions: clearProcess }, { action: "updateProcessRerResume", line });
			await loadProcess(tech_id);
			await setStepNumber(0);
		} else {
			await setPauseStep(true);
			await setResumeStep(oldStep => oldStep - 1);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		const updatedSubstep = { ...resumeForm[currentSubstepName], [name]: value };
		if (resumeStep === 0 && value === "no") {
			setResumeForm({ ...initShortLineA });
		} else {
			setResumeForm(oldResumeForm => {
				return { ...oldResumeForm, [currentSubstepName]: updatedSubstep };
			});
		}
	};

	const backToSubstep = async (substepNumber) => {
		await setPauseStep(true);
		await setResumeStep(substepNumber);
	};

	const handleReset = () => {
		setResumeForm(oldResumeForm => {
			return { ...oldResumeForm, [currentSubstepName]: initObject[currentSubstepName] };
		});
	};

	const handleSubmit = async (updatedResumeForm) => {
		let updatedProcess = { ...resumeConditions, resume_rer: updatedResumeForm };
		if (hasEmergencyLightOn) {
			updatedProcess.damage_location = {
				emergency_lights: "yes",
				step_completed: true
			};
			updatedProcess.has_emergency_lights_on = true;
		}
		updatedProcess.updated_at = new Date();
		await updateProcess(processInfo.tech_id, { resume_conditions: updatedProcess }, { action: "updateProcessRerResume", line });
		setPauseStep(false);
		setResumeForm(oldResumeForm => {
			return { ...oldResumeForm, ...updatedProcess.resume_rer };
		});
		loadProcess(tech_id);
	};

	const findActiveStep = () => {
		if (!pauseStep) {
			const substepStatusList = subStepNameList.map(substep => resumeForm[substep].validated);
			const activeStep = substepStatusList.findIndex((status) => !status);
			setResumeStep(activeStep !== -1 ? activeStep : 5);
		}
	};

	useEffect((findActiveStep), [ resumeForm, setResumeStep, subStepNameList, pauseStep ]);

	return (
		 <>
			{line === "A" && <ResumeFormLineA
				resumeStep={resumeStep}
				handleChange={handleChange}
				handleReset={handleReset}
				handleSubmit={handleSubmit}
				resumeForm={resumeForm}
				backToSubstep={backToSubstep}/>}

			{line === "B" && <ResumeFormLineB
				resumeStep={resumeStep}
				stepNumber={stepNumber}
				setStepNumber={setStepNumber}
				handleChange={handleChange}
				handleReset={handleReset}
				handleSubmit={handleSubmit}
				handleBackClick={handleBackClick}
				backToSubstep={backToSubstep}
				hasEmergencyLightOn={hasEmergencyLightOn}
				trainComposition={trainComposition}
				resumeForm={resumeForm}/>}
		</>
	);
};

export default ResumeForm;
