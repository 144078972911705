import { groupBy } from "../../../shared/utils/array-group-by";

/**
 * @typedef Template
 * @prop {{techId: string}} sheet
 * @prop {string} type 
 * @prop {string} id  
 */

/**
 * @typedef PositionAndTypeMap
 * @prop {Object.<string, number>} positionMap
 * @prop {Object.<string, number>} typeCounter 
 */

/**
 * @typedef QuestionTemplateContent
 * @prop {{
 *  images: *[] 
 *  no: {
 *      items: { 
 *          content: object[] 
 *          id:string type: string 
 *      }[] 
 *      } 
 *  yes: {
 *      items: { 
 *          content: object[] 
 *          id:string type: string 
 *      }[] 
 *      }
 *  }} content
 * @prop {"question"} type 
 * @prop {string} id  
 */


/**
 * does a list of template contrain mutliple unique sheets ?
 * @param {Template[]} templateList 
 * @returns {boolean} true if the list contains multiple sheets
 */
const checkIsMultipleSheet = (templateList) => (new Set(templateList.map(t => t?.sheet?.techId))).size > 1;

/**
 * reduce function creating a map of the template position and the count the occurences of the template type
 * @param {PositionAndTypeMap} acc 
 * @param {Template} template 
 * @returns {PositionAndTypeMap}
 */
const createPositionMapAndTypeCounter = (acc, template) => {
	const { positionMap, typeCounter } = acc;
	const { type, id } = template;
	if (!Number.isInteger(typeCounter[type])){
		positionMap[id] = 1;
		typeCounter[type] = 1;
	} else {
		positionMap[id] = typeCounter[type] + 1;
		typeCounter[type] += 1;
	}

	return acc;
};

/**
 * in case of multiple different sheets reset the count sheet by sheet
 * @param {Template[]} templateList 
 * @returns {Object.<string, number>} merged position maps
 * @example
 * const templatesGroupedBySheet = [
 *  [ { type: "note", id: "id1" },
 *    { type: "note", id: "id2" } ],
 *  [ { type: "action", id: "id3" },
 *    { type: "note", id: "id4" },
 *    { type: "action", id: "id5" } ]
 * ];
 *
 * const templatePosition = mapTemplatePositionByType(templateList);
 * // templatePosition contains :
 * {
 *  positionMapForSheet: { // first position map
 *     id1: 1 // note
 *     id2: 2 // note
 *  }
 *  positionMapForSheet: { // second position map
 *     id3: 1 // action
 *     id4: 1 // note
 * 	   id5: 2 // action
 *  }
 * }
 */
const mapMultipleSheetTemplatePositionByType = (templateList) => {
	const templatesGroupedBySheet = Object.values(groupBy(templateList, "sheet", "techId"));
	let positionMap = {};
	templatesGroupedBySheet.forEach((templatesForSheet) => {
		const { positionMap: positionMapForSheet }= templatesForSheet.reduce(createPositionMapAndTypeCounter, { positionMap: {}, typeCounter: {} });
		positionMap = { ...positionMap, ...positionMapForSheet };
	});
	return positionMap;
};

/**
 * For each template in the list map by template's id its position relative to other template of the same type
 * @param {Template[]} templateList
 * @returns {Object.<string, number>} the position map
 * @example
 * const templateList = [
 *  { type: "note", id: "id1" },
 *  { type: "note", id: "id2" },
 *  { type: "action", id: "id3" },
 *  { type: "note", id: "id4" },
 *  { type: "action", id: "id5" },
 * ];
 *
 * const templatePosition = mapTemplatePositionByType(templateList);
 * // templatePosition contains :
 * {
 *  positionMap: {
 *     id1: 1 // note
 *     id2: 2 // note
 *     id3: 1 // action
 *     id4: 3 // note
 *     id5: 2 // action
 *  }
 *  typeCounter: {
 *      note: 3,
 *      action: 2
 *  }
 * }
 */
const mapTemplatePositionByType = (templateList = []) => {
	const isMultipleSheet = checkIsMultipleSheet(templateList);
	return isMultipleSheet ? 
		mapMultipleSheetTemplatePositionByType(templateList) : 
		templateList.reduce(createPositionMapAndTypeCounter, { positionMap: {}, typeCounter: {} }).positionMap;
};



/**
 * check if the template question has multiple actions
 * @param {QuestionTemplateContent} questionTemplate 
 * @param {'yes'|'no'} [side = 'yes'] 
 * @returns {boolean} 
 */
const hasMultipleActions = (questionTemplate, side = "yes") => {
	return questionTemplate?.[side]?.items?.reduce((count, t) => t?.type === "action" ? count + 1 : count, 0) > 1;
};


/**
 * 
 * @param {Template[]} templates 
 * @param {Template} templateToLoad 
 * @param {'yes'|'no'} key 
 * @returns {[Template, 'yes'|'no']}
 */
const findParentTemplate = (templates, templateToLoad, key) => {
	const parentElementFound = templates.find((template) => {
		const items = template?.content[key]?.items || [];
		return items.some(el => el?.id === templateToLoad?.id);
	});
	if (parentElementFound){
		return [ parentElementFound, key ];
	}
	return null;
};

export  { mapTemplatePositionByType, hasMultipleActions, findParentTemplate };
