import React from "react";
import TrainTroubleFormRadioButton from "../../../../shared/train-trouble-form-radio-button/train-trouble-form-radio-button";
import { hlpResumeFields } from "../../../utils/resume-form-fields";
import ValidatedSubstepCard from "../../../../shared/validated-substep-card/validated-substep-card";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import TrainTroubleFormFooter from "../../../../shared/train-trouble-form-footer/train-trouble-form-footer";

import "../../resume-form-block.scss";

const ResumeFormStepThreeLineA = (props) => {
	const { resumeStep, handleChange, handleReset, handleSubmit, resumeForm, backToSubstep } = props;
	const { t } = useTranslation();

	const fields = [ ...hlpResumeFields ];
	const { head_of_regulation_order: headOfRegulationOrder, hlp_resume: hlpResume, troubleshooting_guide: troubleShootingGuide } = resumeForm?.hlp_resume_group || {};

	const hasHlpResume = resumeForm?.hlp_resume_group.hlp_resume === "yes";
	const isSubstepValidated = resumeForm?.hlp_resume_group.validated;

	const className = classNames({ "resume-form-block": resumeStep === 2 });

	const handleSubmitSubstep = () => {
		let updatedResumeForm;
		if (hasHlpResume) {
			updatedResumeForm = { ...resumeForm, hlp_resume_group: { ...resumeForm.hlp_resume_group, validated: true } };
		} else {
			updatedResumeForm = { ...resumeForm, hlp_resume_group: { hlp_resume: "no", troubleshooting_guide: "", head_of_regulation_order: "", validated: true } };
		}
		handleSubmit(updatedResumeForm);
	};

	const checkFormValidity = () => {
		let fieldsList = fields;
		if (!hasHlpResume) {
			fieldsList = fields.slice(0,1);
		}
		const formErrorList = fieldsList.map((field) => {
			const { name: fieldName } = field;
			return field.validator(resumeForm?.hlp_resume_group[fieldName]);
		});
		return formErrorList?.every(el => el === true);
	};

	const isFormValid = checkFormValidity();

	return (
		<div className={className}>
			{resumeStep > 2 && isSubstepValidated && <ValidatedSubstepCard
				title={t("process:process-rer-resume.resume-rer.substep.hlp_resume")}
				backToSubstep={() => backToSubstep(2)}/>}
			{resumeStep === 2 && <div className={className}>
				<TrainTroubleFormRadioButton
					title={t("process:process-rer-resume.resume-rer.hlp_resume")}
					step={"resume-rer"}
					substep={fields[0].name}
					options={fields[0].values}
					isFirstQuestion={fields[0].is_first_question}
					checkedValue={hlpResume}
					onChange={handleChange}/>
				{hasHlpResume && <TrainTroubleFormRadioButton
					title={t("process:process-rer-resume.resume-rer.troubleshooting_guide")}
					step={"resume-rer"}
					substep={fields[1].name}
					options={fields[1].values}
					isFirstQuestion={fields[1]?.is_first_question}
					checkedValue={troubleShootingGuide}
					onChange={handleChange}/>}
				{hasHlpResume && <TrainTroubleFormRadioButton
					title={t("process:process-rer-resume.resume-rer.head_of_regulation_order")}
					step={"resume-rer"}
					substep={fields[2].name}
					options={fields[2].values}
					isFirstQuestion={fields[2]?.is_first_question}
					checkedValue={headOfRegulationOrder}
					onChange={handleChange}/>}
			</div>}
			{resumeStep === 2 && <TrainTroubleFormFooter handleReset={handleReset} handleSubmit={handleSubmitSubstep} isSubmitBlocked={!isFormValid}/>}
		</div>
	);
};

export default ResumeFormStepThreeLineA;
