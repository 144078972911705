import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { IconWrapper } from "../../../../../../../theme";
import { IconArrowLeft } from "../../../../../assets";
import { getElementNumberMax } from "../../../utils/get-car-element-info";
import classNames from "classnames";

const BreakTestLineASummary = (props) => {
	const { breakTestGroup = {}, trainComposition, material, isProcessRecap } = props;
	const { break_test: breakTest, break_emd_units: breakEmdUnits, ziscov_position: ziscovPosition, resume,
		zalidi_position, speed_decrease } = breakTestGroup || {};
	const { choice: zalidiChoice, pressure } = zalidi_position || {};
	const { choice, other_restriction: other } = speed_decrease || {};

	const hasBreakTest = breakTest === "yes";

	const { t } = useTranslation();

	return (
		<>
			<div className={"rer-resume-summary__substep"}>
				<span className="rer-resume-summary__substep__title">
					{t("process:process-rer-resume.results-sheet.break-test")}
				</span>
				<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
					{t(`process:process-rer-resume.results-sheet.break-test-form.${breakTest}`)}
				</span>
			</div>
			{hasBreakTest && <div>
				<div className={"rer-resume-summary__substep--secondary"}>
					<span className={classNames("rer-resume-summary__substep__title--secondary", { "rer-resume-summary__substep__title--secondary--lighter": isProcessRecap })}>
						{t("process:process-rer-resume.results-sheet.break-emd-units")}
					</span>
					<div className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
						<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
							{breakEmdUnits}
						</span>
						<Trans
							parent="span"
							className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}
							i18nKey="process:process-rer-resume.summary.operator"
							values={{
								name: "EMD",
								number: getElementNumberMax("EMD", trainComposition, material) }}>
							<strong className="damage-form-number-selector__label--bold"/>
						</Trans>
					</div>
				</div>
				<div className={"rer-resume-summary__substep--secondary"}>
					<span className={classNames("rer-resume-summary__substep__title--secondary", { "rer-resume-summary__substep__title--secondary--lighter": isProcessRecap })}>
						{t("process:process-rer-resume.results-sheet.ziscov-position")}
					</span>
					<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
						{t(`process:process-rer-resume.results-sheet.ziscov-position-form.${ziscovPosition}`)}
					</span>
				</div>
				<div className={"rer-resume-summary__substep--secondary"}>
					<span className={classNames("rer-resume-summary__substep__title--secondary", { "rer-resume-summary__substep__title--secondary--lighter": isProcessRecap })}>
						{t("process:process-rer-resume.results-sheet.speed-decrease")}
					</span>
					<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
						{ choice ? t(`process:process-rer-resume.results-sheet.speed-decrease-form.${choice}`) : other }
					</span>
				</div>
				<div className={"rer-resume-summary__substep--secondary"}>
					<span className={classNames("rer-resume-summary__substep__title--secondary", { "rer-resume-summary__substep__title--secondary--lighter": isProcessRecap })}>
						{t("process:process-rer-resume.results-sheet.zalidi-position")}
					</span>
					<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
						{t(`process:process-rer-resume.results-sheet.zalidi-position-form.${zalidiChoice}`)}
						{ pressure && <IconWrapper className="right-arrow__icon" Component={IconArrowLeft}/>}
						{pressure}
					</span>
				</div>
				<div className={"rer-resume-summary__substep--secondary"}>
					<span className={classNames("rer-resume-summary__substep__title--secondary", { "rer-resume-summary__substep__title--secondary--lighter": isProcessRecap })}>
						{t("process:process-rer-resume.results-sheet.resume")}
					</span>
					<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
						{t(`process:process-rer-resume.results-sheet.resume-form.${resume}`)}
					</span>
				</div>
			</div>}
		</>
	);
};

export default BreakTestLineASummary;
