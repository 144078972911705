import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTabs } from "../../../../../../shared";
import { Button, TabsNav, TabsPanel } from "../../../../../../theme";
import { DamageLocationForm } from "../../index";
import ResultsSheetForm from "../../results-sheet/results-sheet-form";
import ResumeForm from "../../resume/resume-form";
import classNames from "classnames";
import { IconCheckWithCircle } from "../../../../../../theme/assets/img";
import RerResumeSummary from "../../rer-resume-summary/rer-resume-summary";

import "./train-trouble-form-stepper.scss";

const TrainTroubleFormStepper = (props) => {
	const { loadProcess, damageFormData, handleChange, line, setDamageFormData, processInfo= {}, setStepNumber, stepNumber } = props;
	const { t } = useTranslation();

	const { tabsItemConfig, tabsPanelConfig } = useTabs(stepNumber);
	const activeTab = tabsItemConfig.activeTab;

	const [ resultsSheetStep, setResultsSheetStep ] = useState(0);
	const [ resumeStep, setResumeStep ] = useState(0);

	const { damage_location: damageLocation, results_sheet: resultsSheet, resume_rer: resumeRer } = processInfo?.resume_conditions || {};

	const isDamageLocationValidated = damageLocation?.step_completed;
	const isResultsSheetValidated = resultsSheet?.step_completed;
	const isResumeValidated = resumeRer?.step_completed;

	const hasEmergencyLightOn = damageFormData?.emergency_lights === "yes";

	const findCurrentStep = () => {
		if ((line === "B" && damageFormData?.emergency_lights === "no") && !isDamageLocationValidated) {
			setStepNumber(1);
		} else if (line === "B" && damageFormData?.emergency_lights === "yes" && !isResumeValidated) {
			setStepNumber(3);
		} else if (line === "B" && damageFormData?.emergency_lights === "yes" && isResumeValidated) {
			setStepNumber(4);
		} else if (isDamageLocationValidated && !isResultsSheetValidated) {
			setStepNumber(2);
		} else if ( isDamageLocationValidated && isResultsSheetValidated && isResumeValidated) {
			setStepNumber(4);
		} else if (isDamageLocationValidated && isResultsSheetValidated && !isResumeValidated) {
			setStepNumber(3);
		}
	};

	const displayResumeProgression = () => {
		const hasNoSpecificRestriction = resumeRer?.specific_restriction_group?.specific_restriction === "no";
		if (line === "B" || (line === "A" && hasNoSpecificRestriction)) {
			const currentStep = resumeStep <=  1 ? 1 : 2;
			return (
				<span className="tabs-nav-items__wrapper__title__stepper">{`(${currentStep}/2)`}</span>
			);
		} else {
			return (
				<span className="tabs-nav-items__wrapper__title__stepper">{`(${resumeStep + 1}/5)`}</span>
			);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(findCurrentStep, []);

	useEffect(() => {
		tabsItemConfig.onClick(stepNumber)();
	}, [ stepNumber, tabsItemConfig ]);

	const findFormValidated = (name) => {
		const isDamageLocationExists = name === "damage_location" && isDamageLocationValidated;
		const isResultsSheetExists = name === "results_sheet" && isResultsSheetValidated;
		const isResumeExists = (name === "resume_rer" && isResumeValidated);
		return isDamageLocationExists || isResultsSheetExists || isResumeExists;
	};

	const handleClickModify = (currentId) => {
		setStepNumber(currentId);
	};

	const renderTabsNavItem = (name, key, id) => {
		const isFormValidated = findFormValidated(name);
		const isActiveTab = id === activeTab;
		const disabled = !isActiveTab && !isFormValidated;
		const isResultSheetActive = id === 2 && activeTab === 2;
		const isResumeActive = id === 3 && activeTab === 3;

		const initClassName = () => classNames(
			"process-rer-resume__stepper__tabs__item",
			{
				"process-rer-resume__stepper__tabs__item--active": id === activeTab,
				"process-rer-resume__stepper__tabs__item--checked": isFormValidated,
				"process-rer-resume__stepper__tabs__item--disabled": disabled
			}
		);

		const initClassNameTitle = () => classNames(
			"tabs-nav-items__wrapper__title",
			{
				"tabs-nav-items__wrapper__title--disabled": (!isActiveTab && !isFormValidated),
				"tabs-nav-items__wrapper__title--validated": (!isActiveTab && isFormValidated),
			}
		);

		return (
			<button
				type="button"
				className={initClassName()}
				disabled={disabled}
			>
				<div className="tabs-nav-items__wrapper">
					{(!isFormValidated || isActiveTab) && <div className={ classNames("tabs-nav-items__wrapper__icon", { "tabs-nav-items__wrapper__icon--disabled": !isActiveTab }) }>
						<label className={ classNames("tabs-nav-items__wrapper__icon__title", { "tabs-nav-items__wrapper__icon__title--disabled": !isActiveTab }) }>{id}</label>
					</div>}
					{isFormValidated && !isActiveTab && <IconCheckWithCircle role="img" aria-hidden="true" focusable="false" class="tabs-nav-items__wrapper__icon-check"/>}
					<div>
						<div className={ classNames("tabs-nav-items__wrapper__title__wrapper", { "tabs-nav-items__wrapper__title__wrapper--active": (isResultSheetActive || isFormValidated) }) }>
							<span className={initClassNameTitle()}>
								{t(`process:process-rer-resume.stepper.${key}`)}
							</span>
							{isResultSheetActive && resultsSheetStep !== 2 && <span className="tabs-nav-items__wrapper__title__stepper">{`(${resultsSheetStep + 1}/2)`}</span>}
							{isResumeActive && resumeStep < 5 && displayResumeProgression()}
						</div>
						{isFormValidated && !isActiveTab && <Button
							variant="link"
							type="button"
							onClick={() => handleClickModify(id)}
							label={t("process:process-rer-resume.stepper.update")}
							className="process-rer-resume__stepper__button"
						/>}
					</div>
				</div>
			</button>
		);
	};

	const renderDamageLocationStepper = () => {
		return (
			<div className="process-rer-resume__stepper">
				<TabsNav className="process-rer-resume__stepper__tabs">
					<div>
						{renderTabsNavItem("damage_location", "damage-location", 1)}
					</div>
					<div className="process-rer-resume__stepper__tabs__divider" />
					<div>
						{renderTabsNavItem("results_sheet", "results-sheet", 2)}
					</div>
					<div className="process-rer-resume__stepper__tabs__divider" />
					<div>
						{renderTabsNavItem("resume_rer", "resume-rer", 3)}
					</div>
				</TabsNav>
			</div>
		);
	};

	return (
		<div className="process-rer-resume">
			{!hasEmergencyLightOn && renderDamageLocationStepper()}
			<TabsPanel id={1} {...tabsPanelConfig} className="process-rer-resume__fields">
				<DamageLocationForm loadProcess={loadProcess} stepNumber={stepNumber} damageFormData={damageFormData} handleChange={handleChange} line={line} setDamageFormData={setDamageFormData} processInfo={processInfo} setStepNumber={setStepNumber}/>
			</TabsPanel>
			<TabsPanel id={2} {...tabsPanelConfig} className="process-rer-resume__fields">
				<ResultsSheetForm loadProcess={loadProcess} resultsSheetStep={resultsSheetStep} setResultsSheetStep={setResultsSheetStep} line={line} processInfo={processInfo}/>
			</TabsPanel>
			<TabsPanel id={3} {...tabsPanelConfig}  className="process-rer-resume__fields">
				<ResumeForm hasEmergencyLightOn={hasEmergencyLightOn} loadProcess={loadProcess} resumeStep={resumeStep} setResumeStep={setResumeStep} line={line} processInfo={processInfo} setStepNumber={setStepNumber} stepNumber={stepNumber} setDamageFormData={setDamageFormData}/>
			</TabsPanel>
			<TabsPanel id={4} {...tabsPanelConfig} className="process-rer-resume__fields--disabled">
				<RerResumeSummary processInfo={processInfo} line={line} handleBackButton={handleClickModify}/>
			</TabsPanel>
		</div>
	);
};

export default TrainTroubleFormStepper;
