import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DigiprocLoader, IconWrapper, SprPage } from "../../../../theme";
import { formatJsonDate, useAppSetupContext, useSprContext } from "../../../../shared";
import { ProgressBar } from "../../index";
import { getBinderStatus } from "../../../binder/binder.services";
import { getTotpCode } from "../../../../shared/auth/auth.services";
import { IconArrowLeft } from "../../../process/assets";
import { iconLines } from "../../utils/associated-line-icon-utils/associated-line-icon.utils";
import { dateFormatDate } from "../../../process/utils";
import { getRemainingTime } from "../../../../config/otp";
import "./generate-offline-password.scss";

const GenerateOfflinePassword = () => {
	const [ code, setCode ] = useState("");
	const [ codeExpirationTime, setCodeExpirationTime ] = useState(null);
	const [ progressRatio, setProgressRatio ] = useState(0);
	const [ progressSmooth, setProgressSmooth ] = useState(false);
	const [ lineLastPublishDate, setLineLastPublishDate ] = useState("");

	const { t } = useTranslation();
	const { associatedLine } = useSprContext();
	const { otpConfig } = useAppSetupContext();

	const progressBarRefreshTime = 200;

	/**
	 * @type {(totpConfig: OtpConfigType) => void}
	 */
	const fetchTotpCode = useCallback((totpConfig) => {
		setProgressSmooth(false);
		getTotpCode().then(response => {
			setCode(response.data.code);
			const time = getRemainingTime(totpConfig);
			setCodeExpirationTime(() => {
				return Date.now() + time * 1000;
			});
			setTimeout(() => setProgressSmooth(true), progressBarRefreshTime + 10);
		});
	}, []);

	const setupOtpInfo = () => {

		// Update code remaining time each 1s and fetch next code when needed
		const intervalId = setInterval(() => {
			if (otpConfig){
				// Init first totp code
				if (codeExpirationTime === null || codeExpirationTime - Date.now() < 0 ){
					fetchTotpCode(otpConfig);
				}
				setProgressRatio(codeExpirationTime ? (codeExpirationTime - Date.now()) / (otpConfig.step * 1000) : 0 );
			}
		}, progressBarRefreshTime);

		return () => clearInterval(intervalId);
	};

	const fetchBinderStatus = useCallback(() => {
		if (associatedLine){
			getBinderStatus(associatedLine).then(res => {
				const { data = {} } = res;
				if (data?.binder_last_update){
					setLineLastPublishDate(data.binder_last_update);
				}
			});
		}
	}, [ associatedLine ]);

	useEffect(setupOtpInfo, [ codeExpirationTime, fetchTotpCode, otpConfig ]);
	useEffect(fetchBinderStatus, [ associatedLine, fetchBinderStatus ]);

	const subHeader = (
		<div className="generate-offline-password__subheader">
			<p className="generate-offline-password__subheader__title">{t("user:generate-otp.header.title")}</p>
			<Link className="generate-offline-password__subheader__link" to="/">
				<IconWrapper className="generate-offline-password__subheader__icon" Component={IconArrowLeft} />
				<span>{t("user:generate-otp.header.link")}</span>
			</Link>
		</div>
	);

	const progressBarClassName = classnames(
		"generate-offline-password__timer",
		{ "generate-offline-password__timer--smooth": progressSmooth },
		{ "generate-offline-password__timer--reset": !progressSmooth }
	);

	return (
		<SprPage className="generate-offline-password" subheader={subHeader}>
			<h1 className="generate-offline-password__title">{t("user:generate-otp.title")}</h1>
			{code ? (
				<>
					<div className="generate-offline-password__code">
						<span>{code.substring(0, 3)}</span>
						<span>{code.substring(3)}</span>
					</div>
					<p className="generate-offline-password__text-light">{t("user:generate-otp.progress-bar-note")}</p>
					<ProgressBar
						className={progressBarClassName}
						ratio={progressRatio ? 1 - progressRatio : 0} />
				</>
			) : (
				<DigiprocLoader className="generate-offline-password__loader"/>
			)}
			<h2 className="generate-offline-password__sub-title">{t("user:generate-otp.warning")}</h2>
			<p className="generate-offline-password__text">{t("user:generate-otp.communicate-with-pcc")}</p>
			<IconWrapper className="generate-offline-password__line" Component={iconLines[associatedLine]} />
			<p className="generate-offline-password__sub-title">{t("user:generate-otp.last-publish", { date: formatJsonDate(lineLastPublishDate, dateFormatDate) })}</p>
			<p className="generate-offline-password__text-light">{t("user:generate-otp.last-publish-note")}</p>
		</SprPage>
	);
};

export default GenerateOfflinePassword;
