import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSprContext } from "../../../../context/spr-context/spr-context";
import { DynamicBackgroundText } from "../../../../index";
import "./spr-header-detail.scss";

/**
 * Render the SprHeaderDetail component
 * @param {object} props
 * @param {string} [props.className]
 * @param {boolean} props.displayCurrentBinderColor
 * @return {JSX.Element}
 * @constructor
 */
const SprHeaderDetail = props => {
	const { className, displayCurrentBinderColor } = props;
	const { side = "train", currentBinder = {} } = useSprContext();
	const { t } = useTranslation();

	const Title = () => (
		<>
			<span className="spr-header-detail__title">{t(`theme:spr-page.header.${side}-main-title`)}</span>
			{side === "train" && <span className="spr-header-detail__title--phone">{t("theme:spr-page.header.train-main-title-phone")}</span>}
		</>
	);

	const content = [ <Title key={side}/> ];

	if (side === "pcc" && displayCurrentBinderColor && currentBinder?.abbreviation) {
		content.push(
			<DynamicBackgroundText
				key={currentBinder?.abbreviation}
				className="spr-binder-detail__binder"
				color={currentBinder?.color}
				text={currentBinder?.abbreviation?.toUpperCase()}/>
		);
	} else if (side === "train" && displayCurrentBinderColor) {
		const binderTypeModifier = {
			"spr-binder-detail__binder--auto": currentBinder.type === "train-auto",
			"spr-binder-detail__binder--driver": currentBinder.type === "train-driver",
			"spr-binder-detail__binder--officer": currentBinder.type === "train-officer",
		};

		content.push(
			<div key={currentBinder?.materialLabel} className={classnames("spr-binder-detail__binder", binderTypeModifier)}>{currentBinder?.materialLabel  || currentBinder?.material_label}</div>
		);
	}

	return (
		<div className={classnames("spr-header-detail", className)}>
			{ content }
		</div>
	);
};

SprHeaderDetail.propTypes = {
	className: PropTypes.string,
	displayCurrentBinderColor: PropTypes.bool
};

export default SprHeaderDetail;
