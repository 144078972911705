import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { fetchTrain } from "../../train.services";
import { LoadWrapper, usePopup, useSprContext, useStoredFilter } from "../../../../shared";
import { Button, SprPage, SearchBar, FilterTags } from "../../../../theme";
import { IconPlus } from "../../../binder/assets";
import { PopupCreateTrain } from "../../index";
import TrainListContent from "./components/train-list-content/train-list-content";
import { padStartNumberInside } from "../../../../shared";
import "./train-list.scss";

const filterConfig = {
	storageName: "trainListFilter",
	filter: { search: "" },
	sort: {},
};

const TrainList = () => {
	const { t } = useTranslation();
	const { associatedLine } = useSprContext();
	const [ trainsLoading, setTrainsLoading ] = useState(true);
	const popupCreateTrainControl = usePopup();
	const {
		filter,
		updateFilter,
		resetFilter
	} = useStoredFilter(filterConfig.storageName, filterConfig.filter, filterConfig.sort);
	const [ trainList, setTrainList ] = useState([]);
	const filteredTrains =  useMemo(() =>{
		const filterById = ({ id }) => id.toLowerCase().includes(filter?.search?.toLowerCase() || "");
		return trainList?.filter(filterById) || [];
	} , [ filter?.search, trainList ]);



	const searchTrains = useCallback(() => {
		if (associatedLine) {
			fetchTrain("", { line: associatedLine, is_active: true })
				.then(({ data: foundTrains = [] }) => {
					setTrainList(foundTrains.sort((a, b) => {
						const padIdA = padStartNumberInside(a.id);
						const padIdB = padStartNumberInside(b.id);
						if ( padIdA < padIdB ) return -1;
						if ( padIdA > padIdB ) return 1;
						return 0;
					}));
				})
				.finally(() => setTrainsLoading(false));

		}
	}, [ associatedLine ]);



	const handleTrainSearch = useCallback((e) => {
		const searchValue = e.target.value.toLowerCase();
		updateFilter("search", searchValue);
	}, [ updateFilter ]);


	const SubHeader = (
		<div className="train-list-subheader">
			<div className="train-list-subheader__left">
				<h2 className="train-list-subheader-left__title">{t("train:train-list.head.title")}</h2>
			</div>
			<div className="train-list-subheader__actions">
				<SearchBar
					onChange={handleTrainSearch}
					value={filter?.search}
					placeholder={t("train:train-list.head.search-bar-placeholder")}/>
				<Button
					onClick={popupCreateTrainControl.show}
					label={t("train:train-list.head.create-train-button")}
					icon={IconPlus}
				/>
			</div>

		</div>
	);

	const FilterSection = <div className="train-list__filter">
		<FilterTags
			className="train-list__filter__tags"
			filter={filter}
			updateFilter={updateFilter}
			resetFilter={resetFilter}
		/>
	</div>;

	const TrainCount = <div className="train-list__count">
		{t("train:train-list.head.train-count", { trainCount: filteredTrains.length })}
	</div>;

	const TrainListInfo = <div className="train-list__info">
		{FilterSection}
		{TrainCount}
	</div>;

	useEffect(searchTrains, [ searchTrains ]);

	return (
		<LoadWrapper className="train-list__loader" loading={trainsLoading}>
			<SprPage className="train-list">
				<PopupCreateTrain
					popupControl={popupCreateTrainControl}
					onSubmitSuccess={searchTrains}
					line={associatedLine}
					trainList={trainList}/>
				{SubHeader}
				{TrainListInfo}
				<TrainListContent trains={filteredTrains} searchTrains={searchTrains} associatedLine={associatedLine}/>
			</SprPage>
		</LoadWrapper>
	);
};

export default TrainList;
