import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { EditorRendering, IconWrapper } from "../../../../../../theme";
import ImageListView from "../../../../../../theme/components/image-list-view/image-list-view";
import NextButton from "../next-button/next-button";
import { ReactComponent as IconCall } from "../../../../../../theme/assets/img/icon-call.svg";
import AppendixTaskView from "../appendix-task-view/appendix-task-view";

import "./generic-task-view.scss";

const GenericTaskView = (props) => {
	const { task = {}, onClickNext } = props;
	const { type, content, optionalInfos } = task;
	const { t } = useTranslation();

	const handleNextButton = () => {
		onClickNext();
	};

	// multipleChoice subheader info
	const renderMultipleChoiceStep = (info) => {
		if (info?.choiceTitle) {
			const className = classNames("generic-task-view__subheader", { "odd": optionalInfos?.index % 2 === 1, });
			return (
				<div className={className}>
					{optionalInfos?.choiceTitle}
				</div>
			);
		}
	};

	return (
		<div id={task?.id} className="generic-task-view">
			{
				optionalInfos?.parentType === "multipleChoice" && renderMultipleChoiceStep(optionalInfos)
			}
			{
				type === "call" &&
				<div className={`generic-task-view__content ${type}`}>
					<div className={"generic-task-view__content__title"}>
						<IconWrapper className="generic-task-view__icon" Component={IconCall}/>
						{content?.text && <EditorRendering className="generic-task-view__text" content={content?.text}/>}
						{content?.note && <EditorRendering className="generic-task-view__note" content={content?.note}/>}
					</div>
					<AppendixTaskView className="text-template-view__appendixes" appendixes={content?.appendixes}/>
				</div>
			}
			{
				type === "note" &&
				<div className={`generic-task-view__content ${type}`}>
					<div className={"generic-task-view__content__title"}>
						{content?.text && <EditorRendering className="generic-task-view__text" content={content?.text}/>}
						{content?.note && <EditorRendering className="generic-task-view__note" content={content?.note}/>}
						<ImageListView className="text-template-view__images" images={content?.images}/>
					</div>
					<AppendixTaskView className="text-template-view__appendixes" appendixes={content?.appendixes}/>
				</div>
			}
			{
				type === "systemEffect" &&
				<div className={`generic-task-view__content ${type}`}>
					{content?.text && <EditorRendering className="generic-task-view__text" content={content?.text}/>}
					{content?.note && <EditorRendering className="generic-task-view__note" content={content?.note}/>}
					<ImageListView className="text-template-view__images" images={content?.images}/>
				</div>
			}
			{
				type === "resume" &&
				<div className={`generic-task-view__content ${type} ${content?.type}`}>
					{t(`sheet:sheet-details.template.resume.${content.type}-label`)}
				</div>
			}
			{
				type === "mrfIntervention" &&
					<div className={`generic-task-view__content ${type}`}>
						{t(`sheet:sheet-details.template.${type}.label`)}
					</div>
			}
			{
				type === "officerIntervention" &&
				<div className={`generic-task-view__content ${type}`}>
					{t(`sheet:sheet-details.template.${type}.label`)}
				</div>
			}
			{
				type === "pccInstruction" &&
				<div className={`generic-task-view__content ${type}`}>
					{t(`sheet:sheet-details.template.${type}.label`)}
				</div>
			}
			<div className="generic-task-view__next-button">
				<NextButton onClick={handleNextButton}/>
			</div>
		</div>
	);
};

export default GenericTaskView;
