import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CdrPage, SearchBar, TabsNav, TabsNavItem, TabsPanel, IconWrapper } from "../../../../theme";
import { useAppSetupContext, useDebouncedAction, usePopup, useStoredFilter, useTabs } from "../../../../shared";
import { PopupCreateBinder } from "../../index";
import BinderFilters from "./components/binder-filters/binder-filters";
import BinderListContent from "./components/binder-list-content/binder-list-content";
import { IconPlus, IconTrain, IconPCC } from "../../assets";
import { searchBinder } from "../../binder.services";
import { fetchMaterial } from "../../../../shared/material.services";
import { setupLineScopeAllowed } from "../../../user/utils/user-associated-line-scope";
import { getStoredActiveTab, saveActiveTab } from "../../utils/binder-list-localstorage-utils";

import binderStatusList from "../../utils/binder-status-list.json";
import binderAssociatedLineList from "../../utils/binder-associated-line-list.json";

import binderDocTypeList from "../../utils/binder-doc-type-list-train.json";

import "./binder-list.scss";

const defaultSort = { sortBy: "id", sortOrder: "asc" };

const filterConfigPCC = {
	storageName: "binderPCCFilterParams",
	sort: defaultSort,
	filter: { search: "", associatedLine: [], status: [] }
};

const filterConfigTrain = {
	storageName: "binderTrainFilterParams",
	sort: defaultSort,
	filter : { search: "", material: [] , docType: [], associatedLine: [], status:[] }
};

const defaultBinderTrainTypes = [ "train-driver", "train-officer", "train-auto" ];

const BinderList = () => {

	const { user: currentUser } = useAppSetupContext();
	const { role: userRole, origin: userOrigin } = currentUser || {};
	const { t } = useTranslation();
	const isRoleRegulationRer = userRole === "regulation-rer";
	const tabActive = isRoleRegulationRer ? "train" : "pcc";

	// binder list to display
	const [ binders, setBinders ] = useState([]);
	const [ statusOptions ] = useState(binderStatusList);
	const [ associatedLineOptions, setAssociatedLineOptions ] = useState(binderAssociatedLineList || []);
	const [ materialTrainOptions, setMaterialTrainOptions ] = useState([]);
	const [ docTypeTrainOptions ] = useState(binderDocTypeList);

	const { activeId, tabsItemConfig, tabsPanelConfig } = useTabs(getStoredActiveTab() || tabActive);

	const storedPccFilter = useStoredFilter(filterConfigPCC.storageName, filterConfigPCC.filter, filterConfigPCC.sort);
	const storedTrainFilter = useStoredFilter(filterConfigTrain.storageName, filterConfigTrain.filter, filterConfigTrain.sort);
	const { filter, sort, updateFilter, updateSort, resetFilter } = activeId === "pcc" ? storedPccFilter : storedTrainFilter;

	const popupCreateBinderControl = usePopup();

	// get binders with filters
	const searchBinders = useDebouncedAction(() => {
		const params = { side: "cdr", ...filter, ...sort };

		params.associatedLine = filter.associatedLine.length > 0 ? params.associatedLine : undefined;
		params.status = filter.status.length > 0 ? params.status : undefined;

		let materialTechId;
		if (params.material && params.material.length > 0) {
			materialTechId = params.material.map(materialLabel =>
				materialTrainOptions.find(materialTrainOption =>
					materialTrainOption.label === materialLabel
				))
				.map(element => element.tech_id);
		}

		// Avoid empty list to avoid empty result
		if (activeId === "train") {
			params.material_tech_id = materialTechId;
			params.type = params?.docType?.length > 0 ? params?.docType : defaultBinderTrainTypes;
		} else {
			params["-type"] = defaultBinderTrainTypes;
		}

		searchBinder(params)
			.then((response) => setBinders(() => response?.data || []))
			.catch(error => {
				setBinders([]);
				console.error(error);
			});
	}, 500);

	// filter associate line list by user's perimeter
	const setupAssociatedLineAllowed = () => {
		const lineOptionsAllowed = setupLineScopeAllowed(currentUser);
		setAssociatedLineOptions(lineOptionsAllowed);
	};

	// save last clicked tab until logout
	const handleTabClicked = (id) => () => {
		tabsItemConfig.onClick(id)();
		saveActiveTab(id);
	};

	useEffect(setupAssociatedLineAllowed, [ currentUser ]);

	useEffect(() => {
		const origin = userRole !== "administrator" ? userOrigin : "";
		if (activeId === "train") {
			fetchMaterial(origin).then(({ data }) => setMaterialTrainOptions(data));
		}
	}, [ activeId, userRole, userOrigin ]);

	const materialTrainOptionsList = materialTrainOptions.map(material => material.label);

	// trigger search binder with only filter and sort changes
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const subHeader = (
		<div className="binder-list__head">
			<TabsNav className="binder-list__head__tabs">
				<TabsNavItem
					className="binder-list__head__tab-item"
					classNameActive="binder-list__head__tab--active"
					id="pcc"
					{...tabsItemConfig}
					// only user with role "regulation-rer" can only have access to train binders
					onClick={ !isRoleRegulationRer ? handleTabClicked : () => {}}
				>
					<IconWrapper Component={IconPCC} className="binder-list__head__icon"/>
					{t("binder:binder-list.pcc")}
				</TabsNavItem>
				<TabsNavItem
					className="binder-list__head__tab-item"
					classNameActive="binder-list__head__tab--active"
					id="train"
					{...tabsItemConfig}
					onClick={handleTabClicked}
				>
					<IconWrapper Component={IconTrain} className="binder-list__head__icon"/>
					{t("binder:binder-list.train")}
				</TabsNavItem>
			</TabsNav>
			<span className="binder-list__head__separator"/>
			<div className="binder-list__head__actions">
				<SearchBar
					onChange={(e) => {updateFilter("search", e.target.value);}}
					value={filter?.search}
				    placeholder={t("binder:binder-list.head.search-bar-placeholder")}
				/>
				<Button
					variant="primary"
					onClick={popupCreateBinderControl.show}
					icon={IconPlus}
					label={`${activeId === "train" ? t("binder:binder-list.head.create-binder-button-train") : t("binder:binder-list.head.create-binder-button-pcc")}`}
				/>
			</div>
		</div>
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(searchBinders, [ filter, sort, activeId ]);

	return (
		<CdrPage className="binder-list" subheader={subHeader}>
			<PopupCreateBinder popupControl={popupCreateBinderControl} onSubmitSuccess={searchBinders}
			                   train={activeId === "train"}/>
			<TabsPanel id="pcc" {...tabsPanelConfig} className="binder-list__content">
				<BinderFilters
					className="binder-list__filter"
					binders={binders}
					filter={filter}
					updateFilter={updateFilter}
					resetFilter={resetFilter}
					sort={sort}
					updateSort={updateSort}
					associatedLineOptions={associatedLineOptions}
					statusOptions={statusOptions}
				/>
				<BinderListContent binders={binders} searchBinders={searchBinders}/>
			</TabsPanel>
			<TabsPanel id="train" {...tabsPanelConfig} className="binder-list__content">
				<BinderFilters
					className="binder-list__filter"
					binders={binders}
					filter={filter}
					updateFilter={updateFilter}
					resetFilter={resetFilter}
					sort={sort}
					updateSort={updateSort}
					associatedLineOptions={associatedLineOptions}
					statusOptions={statusOptions}
					docTypesListOptions={docTypeTrainOptions}
					materialListOptions={materialTrainOptionsList}
					activeId={activeId}
				/>
				<BinderListContent binders={binders} searchBinders={searchBinders} train/>
			</TabsPanel>
		</CdrPage>
	);
};

export default BinderList;
