import i18next from "../../config/i18next";
import SprInProgressProcessList from "./components/spr-in-progress-process-list/spr-in-progress-process-list";
import SprRunProcess from "./pages/spr-run-process/spr-run-process";
import CdrRunProcess from "./components/cdr-run-process/cdr-run-process";
import ProcessList from "./pages/process-list/process-list";
import ProcessHistoryView from "./pages/process-history-view/process-history-view";
import ProcessBinderDetail from "./pages/process-binder-detail/process-binder-detail";
import ProcessBinderList from "./pages/process-binder-list/process-binder-list";
import ProcessResume from "./pages/process-resume/process-resume";
import ProcessRerResumePage from "./pages/process-rer-resume/process-rer-resume-page";
import CdrRunEnd from "./components/cdr-run-end/cdr-run-end";
import taskTypeMap from "./utils/task-types-map.json";
import i18nProcess from "./i18n/fr.json";

i18next.addResourceBundle("fr", "process", i18nProcess);


export {
	ProcessHistoryView,
	SprRunProcess,
	CdrRunProcess,
	ProcessList,
	SprInProgressProcessList,
	CdrRunEnd,
	ProcessBinderList,
	ProcessBinderDetail,
	taskTypeMap,
	ProcessResume,
	ProcessRerResumePage
};
