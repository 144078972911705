import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "../../../../../../theme";
import { IconMap } from "../../../../../process/assets";
import { IconBack } from "../../../../../../theme/assets/img";
import ProcessActionButton from "../../../../../process/pages/spr-run-process/components/action-button/process-action-button";

import "./spr-binder-detail-subheader.scss";

/**
 * SprBinderDetailSubheader
 * @param {{ binderTitle: string, binderType: string }} props
 * @returns
 */
const SprBinderDetailSubheader = (props) => {
	const { binderTitle, binderType, binderZoneLink } = props;
	const { t } = useTranslation();

	return (
		<>
			<div className="spr-binder-detail-page__subheader__title">
				<div className="binder-title">{binderTitle}</div>
			</div>
			<div className="spr-binder-detail-page__actions">
				<Link className="spr-binder-detail-page__come-back-binders" to="/spr/binders">
					<Button
						variant="primary"
						small
						icon={IconBack}
						type="button"
					/>
					<span className="come-back-binders__label">{t("binder:spr-binder-detail.come-back-button")}</span>
				</Link>
				{binderType === "man" && !!binderZoneLink && <ProcessActionButton
					handleClick={() => window.open(binderZoneLink, "_blank", "noopener,noreferrer")}
					label={t("sheet:run-process.cta-zone-link")}
					Icon={IconMap}
				/>}
			</div>
		</>
	);
};

SprBinderDetailSubheader.propTypes = {
	binderTitle: PropTypes.string.isRequired
};

export default SprBinderDetailSubheader;
