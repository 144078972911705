import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import i18nfr from "./i18n-fr";

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			fr: {
				translation: i18nfr
			}
		},
		lng: "fr",
		react: {
			useSuspense: false
		}

	}).then();


export default i18n;
