import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppSetupContext, usePopup } from "../../../../../../shared";
import { Button } from "../../../../../../theme";
import PopupEditUser from "../popup-edit-user/popup-edit-user";
import PopupPurgeUser from "../popup-purge-user/popup-purge-user";
import { purgeUser } from "../../../../user.services";
import { ReactComponent as EditIcon } from "../../../../../../theme/assets/img/icon-edit.svg";
import displayEditUserPerm from "../../user-edit-perm-display-map.json";
import { hasAllLines } from "../../../../utils/user-associated-line-scope";
import { formatRoleName } from "../../../../utils/format-role-name";
import "./user-list-row.scss";

/**
 * user row
 * @param {object} props
 * @param {string} props.className
 * @param {object} props.displayedUser
 * @param {function} props.updateList
 * @returns {JSX.Element}
 */
const UserListRow = props => {
	const {
		className, displayedUser = {}, updateList = () => {}
	} = props;
	const { id, firstname, lastname, role, associated_line: associatedLine = [], main_line: mainLine = null , active } = displayedUser;

	const popupPopupEditUserControl = usePopup();
	const popupPopupPurgeUserControl = usePopup();

	const { user: currentUser } = useAppSetupContext();
	const { role: userRole, origin: userOrigin } = currentUser || {};
	const { t } = useTranslation();

	const displayAssociatedLine = (lines, primaryLine) => {
		if (primaryLine) {
			return <span className="row__associated-line" key={primaryLine}>{`L${primaryLine}`}</span>;
		}

		if (hasAllLines(displayedUser)) {
			return <span className="row__associated-line">{t("user:admin-user-list.list.all")}</span>;
		}

		return lines.map(line => {
			return <span className="row__associated-line" key={line}>{`L${line}`}</span>;
		});
	};

	const handleEdit = (e) => {
		e.preventDefault();
		popupPopupEditUserControl.show();
	};

	const displayEditUserCta = (displayedUserRole) => {
		const editableRole = displayEditUserPerm[userRole] || [];
		return editableRole?.includes(displayedUserRole);
	};

	const handleSubmitPurgeUser = () => {
		const { tech_id } = displayedUser;
		purgeUser(tech_id).then(updateList).catch((err) => {
			console.error(err);
		});
	};

	return (
		<li className={classNames(className, "user-list-row")} key={id}>
			<PopupEditUser
				userToEdit={displayedUser}
				userRole={userRole || ""}
				onSubmitSuccess={updateList}
				onSubmitPurgeUser={popupPopupPurgeUserControl.show}
				popupControl={popupPopupEditUserControl}/>
			<PopupPurgeUser
				user={displayedUser}
				onConfirm={handleSubmitPurgeUser}
				popupControl={popupPopupPurgeUserControl}/>
			<span>{id}</span>
			<span>{lastname}</span>
			<span>{firstname}</span>
			<span>{t(`user:admin-user-list.list.user-info.role.${formatRoleName(role, userOrigin)}`)}</span>
			<span>{displayAssociatedLine(associatedLine, mainLine)}</span>
			<span className={classNames("row__enable", { "row__enable--active": active })}>
				{active ? t("user:admin-user-list.list.user-info.active") : t("user:admin-user-list.list.user-info.disable")}
			</span>
			<span>
				{displayEditUserCta(role) &&
				<Button
					className="edit__icon"
					variant="minimal"
					onClick={handleEdit}
					icon={EditIcon}
					iconTooltipContent={t("user:admin-user-list.list.header-label.edit")}
				/>
				}
			</span>
		</li>
	);
};

UserListRow.propTypes = {
	updateList: PropTypes.func,
	displayedUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		lastname: PropTypes.string.isRequired,
		firstname: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
		creation_date: PropTypes.string,
		role: PropTypes.string,
		active: PropTypes.bool,
		associated_line: PropTypes.arrayOf(PropTypes.string),
		main_line: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
	}).isRequired,
	className: PropTypes.string.isRequired,
};


export default UserListRow;
