/**
 * Get the position of the last valid element
 * An element is valid when callback(element) return true
 * Return -1 when no valid element found
 * @param array The array to search in
 * @param callback The callback to chack array elements
 * @return {number} The position of the last valid element found
 */
const findLastIndexOf = (array = [], callback = function() { return null; }) => {
	const reverseIndex = [ ...array ].reverse().findIndex(callback);
	if (reverseIndex !== -1) {
		return array.length - reverseIndex - 1;
	}
	return -1;
};

export default findLastIndexOf;
