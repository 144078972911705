import React from "react";
import { useTranslation } from "react-i18next";
import ResumeFormStepOneLineA from "../resume-form-step-one-line-A/resume-form-step-one-line-A";
import ResumeFormStepTwoLineA from "../resume-form-step-two-line-A/resume-form-step-two-line-A";
import ResumeFormStepThreeLineA from "../resume-form-step-three-line-A/resume-form-step-three-line-A";
import ResumeFormStepFourLineA from "../resume-form-step-four-line-A/resume-form-step-four-line-A";
import ResumeFormStepFiveLineA from "../resume-form-step-five-line-A/resume-form-step-five-line-A";
import { Button } from "../../../../../../../../theme";

const ResumeFormLineA = (props) => {
	const { resumeStep, handleChange, handleReset, handleSubmit, resumeForm, backToSubstep } = props;

	const { t } = useTranslation();

	const hasSpecificRestriction = resumeForm?.specific_restriction_group?.specific_restriction === "yes";

	const handleSubmitResumeForm = () => {
		const updatedResumeForm = { ...resumeForm, step_completed: true };
		handleSubmit(updatedResumeForm);
	};

	const propsObject = {
		resumeStep: resumeStep,
		handleChange: handleChange,
		handleReset: handleReset,
		handleSubmit: handleSubmit,
		resumeForm: resumeForm,
		backToSubstep: backToSubstep
	};

	return (
		<>
			{resumeStep >= 0 && <ResumeFormStepOneLineA {...propsObject}/>}
			{resumeStep >= 1 && hasSpecificRestriction && <ResumeFormStepTwoLineA {...propsObject}/>}
			{resumeStep >= 2 &&  hasSpecificRestriction && <ResumeFormStepThreeLineA {...propsObject}/>}
			{resumeStep >= 3 && hasSpecificRestriction && <ResumeFormStepFourLineA {...propsObject}/>}
			{resumeStep >= 4 && <ResumeFormStepFiveLineA {...propsObject}/>}
			{resumeStep === 5 && <div className="resume-rer__recap__button-wrapper">
				<Button
					variant="primary"
					type="submit"
					onClick={handleSubmitResumeForm}
					label={t("process:process-rer-resume.footer.confirm-resume")}
					className="resume-rer__recap__button-wrapper__button"
				/>
			</div>}
		</>
	);
};

export default ResumeFormLineA;
