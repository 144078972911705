import React, { forwardRef } from "react";
import classNames from "classnames";
import { IconWrapper } from "../../../../../../../../theme";
import { ReactComponent as IconDrag } from "../../../../../../../../theme/assets/img/icon-drag.svg";
import "./template-drag.scss";

const TemplateDrag = (props, ref) => {
	const { className, readOnly, type } = props;
	if (!readOnly) {
		return (
			<div ref={ref} className={classNames(`template-drag template-drag--${type}`, className)}>
				<IconWrapper className="template-drag__icon" Component={IconDrag}/>
			</div>
		);
	}

	return null;
};

export default forwardRef(TemplateDrag);
