import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IconWrapper, Button } from "../../../../../../theme";
import { formatJsonDate, useDebouncedAction, usePopup, useEffectDidUpdate } from "../../../../../../shared";
import { useSheetContentContext } from "../../../../context/sheet-content-context";
import { useSheetModeContext } from "../../../../context/sheet-mode-context";
import { ReactComponent as ArrowBack } from "../../../../../../theme/assets/img/icon-arrow-back.svg";
import { ReactComponent as ArrowForward } from "../../../../../../theme/assets/img/icon-arrow-forward.svg";
import { ReactComponent as IconPath } from "../../../../../../theme/assets/img/icon-path.svg";
import { ReactComponent as IconSave } from "../../../../../../theme/assets/img/icon-save.svg";
import { ReactComponent as IconSpinner } from "../../../../../../theme/assets/img/icon-spinner.svg";
import { runAnalysis } from "../../../../sheet.services";
import PopupRunAnalysis from "../popup-run-analysis/popup-run-analysis";

import "./sheet-edition-action.scss";

/**
 * @param {{
 * 	lastChangeAt: string
 *  binderId: string
 *  refresh: (sheet: object, updatedContent: object) => void
 * }} props
 * @returns {JSX.Element}
 */
const SheetEditionAction = (props) => {
	const {
		lastChangeAt, binderId, refresh = (data, b) => {}
	} = props;

	const { t } = useTranslation();
	const { sheetId } = useParams();

	const {
		saveSheetContent,
		nodeList,
		nodeHistory,
		nextNodeHistory,
		previousNodeHistory,
		nodeHistoryPointer
	} = useSheetContentContext();
	const { readOnly } = useSheetModeContext();
	const popupRunAnalysisControl = usePopup();
	const [ resultAnalysis, setResultAnalysis ] = useState();
	const [ displaySpinner, setDisplaySpinner ] = useState(false);

	const handleSave = useCallback(() => {
		setDisplaySpinner(true);
		saveSheetContent(binderId, sheetId).then(response => {
			if (response?.data) {
				refresh(response.data, false);
			}
		}).catch(error => {
			console.error(error);
		}).finally(() => setDisplaySpinner(false));
	}, [ binderId, refresh, saveSheetContent, sheetId ]);

	// handle save on nodeList changes
	const handleSaveDebounce = useDebouncedAction(handleSave, 3000);

	const handlePreviousButton = () => {
		previousNodeHistory();
	};

	const handleNextButton = () => {
		nextNodeHistory();
	};

	const launchAnalysis = () => {
		runAnalysis(sheetId).then((response) => {
			if (response?.data) {
				setResultAnalysis(response.data);
				popupRunAnalysisControl.show();
			}
		});
	};

	useEffectDidUpdate(handleSaveDebounce, nodeList, [ nodeList ]);

	return (
		<div className="sheet-edition__actions">
			<PopupRunAnalysis analysisResult={resultAnalysis} popupControl={popupRunAnalysisControl}/>
			<div className="sheet-edition__actions__wrapper">
				{!readOnly && !displaySpinner &&
				(<>
					<span className="sheet-edition__actions__last-save">
						{t("sheet:sheet-details.tabs-panel.actions.last-save")} {formatJsonDate(lastChangeAt, "HH:mm")}
					</span>
					<Button
						variant="minimal"
						type="button"
						onClick={handleSave}
						icon={IconSave}
					/>
				</>)
				}
				{!readOnly && displaySpinner &&
				(<>
					<button type="button" className="sheet-edition__actions__save " onClick={handleSave}>
						<IconWrapper className="sheet-edition__actions__icon sheet-edition__actions__icon__spinner"
						             Component={IconSpinner}/>
					</button>
					<span className="sheet-edition__actions__last-save">
						{t("sheet:sheet-details.tabs-panel.actions.saving")}...
					</span>
				</>)
				}

			</div>

			<div className="sheet-edition__actions__wrapper">
				{!readOnly && (
					<>
						<Button
							small
							variant="secondary"
							type="button"
							className="sheet-edition__actions__single-action sheet-edition__actions__single-action--cancel"
							onClick={handlePreviousButton}
							disabled={nodeHistoryPointer < 1}
							icon={ArrowBack}
							iconTooltipContent={t("sheet:sheet-details.tabs-panel.actions.cancel")}
						/>
						<Button
							small
							variant="secondary"
							type="button"
							className="sheet-edition__actions__single-action sheet-edition__actions__single-action--undo"
							onClick={handleNextButton}
							disabled={nodeHistoryPointer >= nodeHistory.length - 1}
							icon={ArrowForward}
							iconTooltipContent={t("sheet:sheet-details.tabs-panel.actions.undo")}
						/>
						<Button
							small
							variant="primary"
							type="button"
							className="sheet-edition__actions__single-action sheet-edition__actions__single-action--path"
							onClick={launchAnalysis}
							icon={IconPath}
							iconTooltipContent={t("sheet:sheet-details.tabs-panel.actions.test-paths")}
						/>
					</>
				)}
			</div>

		</div>
	);
};

SheetEditionAction.propTypes = {
	lastChangeAt: PropTypes.string,
	binderId: PropTypes.string,
	refresh: PropTypes.func
};

export default SheetEditionAction;
