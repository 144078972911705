import React, { useEffect, useState, useCallback } from "react";
import { useSprContext, LoadWrapper } from "../../../../shared";
import { SprPage } from "../../../../theme";
import { searchBinder } from "../../binder.services";
import { groupBinderByAbbrev } from "../../utils/binder-list-utils";
import { SprInProgressProcessList } from "../../../process";
import binderTrainTypeList from "../../utils/binder-doc-type-list-train.json";
import CardBlockTemplate from "../../../../shared/components/card-block-template/card-block-template";

import "./spr-binder-list.scss";

/**
 * Render the Spr binder list page
 * @returns {JSX.Element}
 * @constructor
 */
const SprBinderList = () => {
	const [ binderList, setBinderList ] = useState([]);
	const [ binderLoading, setBinderLoading ] = useState(false);
	const { associatedLine, setCurrentBinder } = useSprContext();

	const retrieveSprBinders = () => {
		if (associatedLine) {
			setBinderLoading(true);
			searchBinder({
				associatedLine: associatedLine,
				status: "published",
				sortBy: "abbreviation",
				sortOrder: "asc",
				"-type": binderTrainTypeList,
			}).then(response => {
				const binderLinkList = groupBinderByAbbrev(response?.data);
				setBinderList(binderLinkList);
			}).catch(error => {
				setBinderList([]);
				throw error;
			}).finally(() => setBinderLoading(false));
		}
	};

	const resetCurrentBinder = () => {
		setCurrentBinder({});
	};

	useEffect(retrieveSprBinders, [ associatedLine ]);
	useEffect(resetCurrentBinder, [ setCurrentBinder ]);

	const generateBinderCard = useCallback((binderLink) => {
		const { tech_id: binderTechId, id: binderId, abbreviation, count, title, color } = binderLink;
		const isBinderGroup = count > 1;

		return <CardBlockTemplate
			onClick={() => setCurrentBinder(binderLink)}
			linkTo={isBinderGroup ? `/spr/binders/group/${abbreviation}` : `/spr/binders/${binderTechId}`}
			mainText={abbreviation?.toUpperCase()}
			key={binderTechId}
			title={title}
			subTitle={binderId}
			color={color}
			isBinderGroup={isBinderGroup}
		/>;

	}, [ setCurrentBinder ]);

	return (
		<LoadWrapper className="spr-binder-list-page__loader" loading={binderLoading}>
			<SprPage className="spr-binder-list-page" binderAbbreviationNotVisible showInProgressList>
				<SprInProgressProcessList/>
				<ul className="spr-binder-list-page__binder-cards">
					{binderList.map(generateBinderCard)}
				</ul>
			</SprPage>
		</LoadWrapper>
	);
};

export default SprBinderList;
