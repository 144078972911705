import { getEntityIds, purgeAllEntity } from "./cache-utils";
import { getEntityList, buildEntityMetadata, removeEntity, setEntity } from "../entity/entity-utils";
import { getApiItemRef } from "../api/api-utils";
import { publishProcess } from "../api/api-service";

/**
 * Remove all cache content for the provided line
 * @param {string} line Current line
 * @returns {Promise<void>}
 */
const purgeCacheContent = async (line) => {
	await purgeAllEntity("train", line);
	await purgeAllEntity("binder", line);
	await purgeAllEntity("sheet", line);
	await purgeAllEntity("image", line);
};

/**
 * Apply action to current cache
 * @param {{ train: { data }[], binder: { data }[], sheet: { data }[], image: { ref: string, sheetRef: string }[] }} apiData Last fetched data
 * @param {CacheEntity} status status from the synchronisation start
 * @returns {(function(CacheUpdateAction): Promise<void>)}
 */
const handleCacheAction = (apiData, status) => async (cacheAction) => {
	const { action, entity, ref } = cacheAction;
	const line = status.metadata.ref;
	if (action === "remove") {
		return removeEntity(entity, line, ref);
	} else if (action === "save") {
		// Replace all data
		const data = apiData[entity].find(apiItem => getApiItemRef(entity, apiItem) === ref)?.data;
		const metadata = buildEntityMetadata(apiData, line, entity, ref);
		return setEntity(entity, line, ref, data, metadata);
	}
};

/**
 * Save images into cache
 * @param {{ data: Blob, metadata: { ref: string } }[]} images
 * @param {{ train: { data }[], binder: { data }[], sheet: { data }[], image: { ref: string, sheetRef: string }[] }} api Last fetched data
 * @param {string} line Current line
 * @returns {Promise<void>}
 */
const saveImageInCache = async (images, api, line) => {
	await Promise.all(images.map( async image => {
		const metadata = buildEntityMetadata(api, line, "image", image.metadata.ref);
		await setEntity("image", line, image.metadata.ref, { light: image.data }, metadata  );
	}));
};

/**
 * Load all entity needed to evaluate sync content (no image file)
 * @param {boolean} isCacheValid Cache validity
 * @param {string} line Current line
 * @return {{sheet: CacheEntity, binder: CacheEntity, train: CacheEntity, image: { ref: string, sheetRef: string }[]}}
 */
const getCacheContent = async (isCacheValid, line) => {
	// If Cache is not valid, erase all corrupted data of current line
	if (!isCacheValid) {
		await purgeCacheContent(line);
		return { binder: [], sheet: [], train: [], image: [] };
	}

	const [ train, binder, sheet, imageRefs ] = await Promise.all([
		getEntityList("train", { line }),
		getEntityList("binder", { line }),
		getEntityList("sheet", { line }),
		// Get only image ref and sheet ref (no file)
		getEntityIds("image", line)
	]);

	// Transform image cache content to fit default format
	const image = imageRefs.map(ref => ({ data: {}, metadata: { ref } }));

	return { binder, sheet, train, image };
};

/**
 * Publish to server an entity stored in cache
 * @param {CacheEntity} entityCacheEntry
 * @returns {Promise<void>}
 */
const publishEntity = async (entityCacheEntry) => {
	const { metadata, data } = entityCacheEntry;
	const { ref, entity, line } = metadata;

	if (entity === "process"){
		try {
			return await publishProcess(entityCacheEntry);
		} catch (error) {
			const newMetadata = { ...metadata, isSyncFailed: true };
			await setEntity(entity, line, ref, data, newMetadata);

			throw error;
		}
	}
};

/**
 * Clean cache by removing closed & aborted processes synchronized
 * @return {Promise<void>}
 */
const cleanProcessFromCache = async () => {
	const processList = await getEntityList("process", { status: [ "closed", "aborted" ], isPending: false, isSyncFailed: [ undefined, false ] });
	await Promise.all(processList.map(async processCacheEntry => {
		const { entity, ref, line } = processCacheEntry.metadata;
		return removeEntity(entity, line, ref);
	}));
};

export { purgeCacheContent, handleCacheAction, saveImageInCache, getCacheContent, publishEntity, cleanProcessFromCache };
