import React, { Component } from "react";
import ErrorDownPage from "../../../error/pages/error-down-page/error-down-page";

// ErrorBoundary is not a hooks component because error boundary feature is only available as class component.
// TODO: Refactor in hooks when error boundary feature is available.

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.t = props.t;
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.error(error, errorInfo);
		this.setState({ hasError: true });
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <ErrorDownPage/>;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
