import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSetupContext, usePopup, useRailwayOnlineReturn } from "../../index";
import checkNetwork from "../../../config/offline/check-network";
import PopupProcessOffline from "../popup-process-offline/popup-process-offline";
import Overlay from "../../../theme/components/overlay/overlay";

const SprNetworkWrapper = (props) => {
	const { children } = props;
	const { t } = useTranslation();
	// override the auto-reconnect prevention for railway ('useRailwayOnlineReturn(false)') in pages used by both sides, to allow pcc reconnection when network is back
	useRailwayOnlineReturn(true);

	const popupProcessOfflineControl = usePopup();
	const [ overlay, setOverlay ] = useState(false);
	const { networkStatus } = useAppSetupContext();

	const handlePopupDisplay = useCallback(() => {
		setTimeout(() => {
			checkNetwork().then((status) => {
				if (!status || status >= 400){
					popupProcessOfflineControl.show();
				}
			}).finally(() =>  setOverlay(false));
		}, 5000);
		setOverlay(true);
	}, [ popupProcessOfflineControl ]);

	// show popup on API timeout
	useEffect(() => {
		if (networkStatus === "offline" && !popupProcessOfflineControl.visible && !overlay) {
			handlePopupDisplay();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ networkStatus, handlePopupDisplay ]);

	return (
		<>
			<PopupProcessOffline popupControl={popupProcessOfflineControl}/>
			<Overlay text={t("process:popup.popup-offline.overlay-title")} visible={overlay}/>
			{children}
		</>
	);
};

export default SprNetworkWrapper;
