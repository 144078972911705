import { useEffect, useState } from "react";

/**
 *
 * Hook to trigger an useEffect after value has been initialized at first ( ex: value initialize after a get API)
 * @param callback function de be called in the useEffect
 * @param value (String | Array | Object) value to check the first initialize
 * @param dependencies variables changes to trigger the useEffect
 */
const useEffectDidUpdate = (callback, value, dependencies = []) => {
	const [ initialize, setInitialize ] = useState(false);

	const valueType = getTriggerType(value);
	const triggerCondition = getTriggerCondition(value, valueType);

	const handleDidUpdateCallback = () => {
		if (initialize) {
			callback();
		}
		if (!initialize && triggerCondition) {
			setInitialize(true);
		}
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleDidUpdateCallback, [ ...dependencies ]);
};

const getTriggerType = (value) => {
	if (!!value) {
		if (typeof value === "string") return String;
		if (Array.isArray(value)) return Array;
		if (Object.keys(value).length > 0) return Object;
	}
	return null;
};

const getTriggerCondition = (trigger, type) => {
	if (type === String) {
		return trigger !== "";
	} else if (type === Array) {
		return trigger.length > 0 && trigger.some(x => x !== null);
	} else if (type === Object) {
		return Object.values(trigger).some(x => (x !== null && x !== undefined));
	} else {
		return false;
	}
};

export default useEffectDidUpdate;
