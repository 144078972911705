import { getNetworkState, offlineUser } from "../offline";
import { setupOfflineAuth, verifyOfflineAuth } from "../otp";

/**
 * Axios interceptor for for offline authentication
 * @param response
 */
const offlineAuthInterceptor = async response => {

	const { ignoreOfflineAuth } = response?.config || {};

	const networkState = getNetworkState();
	const responseUnauthorized = {
		...response,
		status: 401,
		statusText: "Unauthorized",
		headers: { "error-type": "OFFLINE_TOKEN_INVALID" },
		request: {},
		data: {},
	};

	if (networkState === "online" && !ignoreOfflineAuth){
		await setupOfflineAuth(offlineUser);
	}
	else if (response.status < 400 && !ignoreOfflineAuth) {
		try {
			const isAuth = await verifyOfflineAuth();
			if (!isAuth){
				return responseUnauthorized;
			}
		} catch {
			return  responseUnauthorized;
		}
	}

	return response;
};

export default offlineAuthInterceptor;
