import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconWrapper, PopupConfirm } from "../../../../../theme";
import { IconArrowRight } from "../../../../../theme/assets/img";
import "./popup-switch-side.scss";

/**
 * Render to popup to switch between pcc and railway side
 * @param props
 * @param {string} props.side
 * @param props.onConfirm
 * @param props.popupControl
 * @return {JSX.Element}
 * @constructor
 */
const PopupSwitchSide = (props) => {
	const { popupControl, side, onConfirm = () => {} } = props;

	const { t } = useTranslation();

	const pccText = t("theme:popup.switch-side.pcc");
	const railwayText = t("theme:popup.switch-side.railway");

	return (
		<PopupConfirm className="popup-side-switch" popupControl={popupControl} onConfirm={onConfirm} cancellable title={t("theme:popup.switch-side.title")}>
			<div className="popup-side-switch__content">
				<h1 className="popup-side-switch__text--emphase">{t("theme:popup.switch-side.warning")}</h1>
				<p className="popup-side-switch__text">{t("theme:popup.switch-side.text.0")}</p>
				<div className="popup-side-switch__wrapper">
					<p className="popup-side-switch__text--emphase">{ side === "pcc" ? pccText : railwayText }</p>
					<IconWrapper className="popup-side-switch__arrow" Component={IconArrowRight} />
					<p className="popup-side-switch__text--emphase">{ side === "pcc" ? railwayText : pccText }</p>
				</div>
				<span className="popup-side-switch__text--light">{t(`theme:popup.switch-side.text.${ side === "pcc" ? "1" : "2" }`)}</span>

			</div>
		</PopupConfirm>
	);
};

PopupSwitchSide.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func
	}),
	side: PropTypes.string.isRequired,
	onConfirm: PropTypes.func
};

export default PopupSwitchSide;
