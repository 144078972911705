import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { usePopup } from "../../../../../../../../shared";
import SignalTitle from "../signal-title/signal-title";
import BinderSheetListContent from "../binder-sheet-list-content/binder-sheet-list-content";
import { Button } from "../../../../../../../../theme";
import { PopupCreateSheet } from "../../../../../../../sheet";
import { PopupDeleteSignal, PopupUpdateSignal } from "../popup";
import { IconArrowDown, IconTrash, IconEdit } from "../../../../../../../../theme/assets/img";
import { IconPlus } from "../../../../../../assets";

import "./binder-detail-signal.scss";

/**
 * Render signal detail for a binder of type man
 * @param {object} props
 * @param {object} props.analysisResult
 * @param {object[]} props.sheets
 * @param {object} props.signal
 * @param {object[]} props.signalList
 * @param {function} props.refreshSheetList
 * @param {function} props.refreshSignalList
 * @param {object} props.binder
 * @returns {JSX.Element}
 */
const BinderDetailSignal = props => {
	const { analysisResult, sheets = [], signal, signalList = [], binder = {}, refreshSheetList, refreshSignalList } = props;

	const [ open, setOpen ] = useState(true);

	const popupCreateSheetControl = usePopup();
	const popupDeleteSignalControl = usePopup();
	const popupUpdateSignalControl = usePopup();

	const { t } = useTranslation();

	const hasSheets = Array.isArray(sheets) && sheets?.length > 0;
	const areButtonsDisabled = binder?.status !== "work-in-progress";

	return (
		<div className="binder-detail-signal">
			<PopupCreateSheet popupControl={popupCreateSheetControl} binder={binder} signal={signal} onCreate={refreshSheetList}/>
			<PopupDeleteSignal popupControl={popupDeleteSignalControl} binderTechId={binder?.tech_id} signal={signal} onDelete={refreshSignalList}/>
			<PopupUpdateSignal binderTechId={binder?.tech_id} signal={signal} popupControl={popupUpdateSignalControl} signalList={signalList} onUpdate={refreshSignalList} />
			{!hasSheets &&
			<div className="binder-detail-signal__empty">
				<div className="binder-detail-signal__head">
					<SignalTitle className="empty__head__title" title={signal?.title} origin={signal?.origin} destination={signal?.destination} />
					<div className="binder-detail-signal__actions">
						<Button
							variant="minimal"
							disabled={areButtonsDisabled} 
							type="button" 
							onClick={popupUpdateSignalControl.show}
							icon={IconEdit}
							iconTooltipContent={t("binder:binder-detail.tooltip.edit")}
						/>
						<Button
							variant="minimal"
							disabled={areButtonsDisabled} 
							type="button" 
							onClick={popupDeleteSignalControl.show}
							icon={IconTrash}
							iconTooltipContent={t("binder:binder-detail.tooltip.delete")}
						/>
					</div>
				</div>
				<div className="empty__body">
					<span className="empty__body__text">
						{t("binder:binder-detail.man-list.create-first-sheet")}
						<SignalTitle title={signal?.title} origin={signal?.origin} destination={signal?.destination} />
					</span>
					<Button
						variant="primary"
						disabled={areButtonsDisabled}
						className="empty__body__add" 
						onClick={popupCreateSheetControl.show}
						label={t("binder:binder-detail.man-list.create-sheet")}
						icon={IconPlus}
					/>
				</div>
			</div> 
			}
			{hasSheets &&
			<div className={classnames("binder-detail-signal__drawer", { "binder-detail-signal__drawer--close": !open })}>
				<div className="binder-detail-signal__head">
					<div className="drawer__head__wrapper">
						<SignalTitle className="drawer__head__title" title={signal?.title} origin={signal?.origin} destination={signal?.destination} />
					</div>
					<div className="binder-detail-signal__actions">
						<Button
							variant="minimal"
							disabled={areButtonsDisabled} 
							type="button" 
							onClick={popupUpdateSignalControl.show}
							icon={IconEdit}
							iconTooltipContent={t("binder:binder-detail.tooltip.edit")}
						/>
						<Button 
							variant="secondary"
							onClick={popupCreateSheetControl.show}
							disabled={areButtonsDisabled}
							label={t("binder:binder-detail.man-list.create-sheet")}
							icon={IconPlus}
						/>
						<span className="binder-detail-signal__separator"/>
						<Button
							variant="minimal"
							type="button" 
							className="drawer__control__icon"
							onClick={() => setOpen(previousOpen => !previousOpen)}
							icon={IconArrowDown}
						/>
					</div>
				</div>
				{open && <div className="drawer__body">
					<BinderSheetListContent
						sheets={sheets}
						binder={binder}
						loading={false}
						analysisResult={analysisResult}
						showCreateSheetPopup={() => {}}
						refreshSheetList={refreshSheetList}
					/>
				</div>}
			</div> 
			}
		</div>
	);
};

BinderDetailSignal.propTypes = {
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string)
	}),
	sheets: PropTypes.arrayOf(PropTypes.shape({
		number: PropTypes.string.isRequired,
		sheet_id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		title: PropTypes.object.isRequired,
		analysis_result: PropTypes.string,
		status: PropTypes.string.isRequired,
		last_change_content_at: PropTypes.string.isRequired,
		editor: PropTypes.string,
		editor_firstname: PropTypes.string,
		editor_lastname: PropTypes.string,
		editor_id: PropTypes.string
	})),
	signal: PropTypes.shape({
		title: PropTypes.string.isRequired,
		origin: PropTypes.string.isRequired,
		destination: PropTypes.string.isRequired
	}),
	refreshSheetList: PropTypes.func.isRequired,
	refreshSignalList: PropTypes.func.isRequired,
	binder: PropTypes.shape({
		status: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
	}),
	signalList: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		origin: PropTypes.string.isRequired,
		destination: PropTypes.string.isRequired
	}))
};

export default BinderDetailSignal;
