import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useHistory } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { useAppEnv } from "../../config/env/app-env";
import { useEffect, useState } from "react";

/**
 * Okta wrapper for initialize okta auth object if not offline
 * @param children
 * @returns {JSX.Element|null}
 * @constructor
 */
const OktaWrapper = ({ children }) => {
	const history = useHistory();
	const env = useAppEnv();
	const [ oktaAuth, setOktaAuth ] = useState();

	const { SSO_CLIENT_ID: ssoClientId = "", SSO_ISSUER_URL: ssoIssuerUrl = "" } = env;

	// create and save oktaAuth params
	const oauth = () => {
		if (ssoClientId && ssoIssuerUrl) {
			const newAuth = new OktaAuth({
				issuer: ssoIssuerUrl,
				clientId: ssoClientId,
				redirectUri: `${window.location.origin}/login/callback`,
				scopes: [ "openid", "offline_access", "profile" ],
				services: { autoRenew: true }
			});
			setOktaAuth(newAuth);
		}
	};

	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		history.replace(toRelativeUrl(originalUri || "/", window.location.origin), { replace: true });
	};

	useEffect(oauth, [ env, ssoClientId, ssoIssuerUrl ]);

	if (oktaAuth) {
		// return the application with the <Security/>'s okta component
		return (
			<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
				{children}
			</Security>
		);
	} else {
		return null;
	}

};

export default OktaWrapper;
