import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./history-termination-task-view.scss";

/**
 * history termination template component
 * @param {object} props
 * @param {string} props.className
 * @param {boolean} props.passed
 * @returns {JSX.Element}
 */
const HistoryTerminationTaskView = (props) => {
	const { className, passed } = props;

	const { t } = useTranslation();

	const initClassName = (baseClassName) => classNames(baseClassName, className, {
		[`${baseClassName}--passed`]: passed
	});

	return (
		<div className={initClassName("history-termination-task-view")}>{t("process:history-task.termination")}</div>
	);
};

HistoryTerminationTaskView.propTypes = {
	className: PropTypes.string,
	passed: PropTypes.bool
};

export default HistoryTerminationTaskView;
