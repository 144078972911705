import React from "react";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./cdr-run-end.scss";

/**
 * Render ending template of process
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.img
 * @param {string} props.i18nLabel
 * @returns {JSX.Element}
 */
const CdrRunEnd = (props) => {
	const { className, img, i18nLabel } = props;

	return (
		<div className={classnames("cdr-run-end", className)}>
			<img className="cdr-run-end__image" src={img} alt=""/>
			<Trans className="cdr-run-end__label" parent="span" i18nKey={i18nLabel}/>
		</div>
	);
};

CdrRunEnd.propTypes = {
	className: PropTypes.string,
	img: PropTypes.string.isRequired,
	i18nLabel: PropTypes.string.isRequired
};

export default CdrRunEnd;
