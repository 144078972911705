import React from "react";
import { useTranslation } from "react-i18next";
import "./pcc-instruction-template-form.scss";

/**
 * Render the PccInstructionTemplateForm component
 * @returns {JSX.Element}
 */
const PccInstructionTemplateForm = () => {

	const { t } = useTranslation();

	return (
		<div className="pcc-instruction-template-panel">
			<div className="pcc-instruction-template-panel__title">
				{t("sheet:sheet-details.template.pccInstruction.label")}
			</div>
		</div>
	);
};

export default PccInstructionTemplateForm;
