import React, { memo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Drawer } from "../../../../../../theme";
import { formatJsonDate } from "../../../../../../shared";
import { dateFormatTime } from "../../../../utils";

import "./rer-resume-conditions-drawer.scss";
import RerResumeSummary from "../../../process-rer-resume/rer-resume-summary/rer-resume-summary";

/**
 * ResumeConditionsDrawer component
 * @param {object} props
 * @param {{ position_supply: 'normal' | 'direct', engine_units: number, bogie_car_units: number, resume: 'travelers' | 'hlp' | 'secours', last_update: Date }} props.resumeConditions
 * @returns {JSX.Element}
 */
const RerResumeConditionsDrawer = (props) => {
	const { processInfo, line } = props;
	const { updated_at: updatedAt } = processInfo?.resume_conditions;
	const { t } = useTranslation();

	return <Drawer
		drawerStatus={true}
		className="resume-conditions-drawer"
		leftText={t("process:history-view.rer-resume-conditions.title")}
		rightText={formatJsonDate(updatedAt, dateFormatTime)}
	>
		<div className="resume-condition-drawer__resume">
			<RerResumeSummary processInfo={processInfo} line={line} isProcessRecap={true} />
		</div>
	</Drawer>;
};

RerResumeConditionsDrawer.propTypes = {
	processInfo: PropTypes.object.isRequired,
	line: PropTypes.string.isRequired,
};

export default memo(RerResumeConditionsDrawer);
