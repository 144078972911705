import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { IconBack } from "../../../../theme/assets/img";
import { Button, IconWrapper } from "../../../../theme";
import { buildTrainProcessBinderList, buildUserName } from "../../../../shared";
import { ReactComponent as IconReadLook } from "../../assets/icon-read-look.svg";
import { useSheetReadContext } from "../../../sheet/pages/railway-sheet-view/components/sheet-read-context/sheet-read-context";
import LinkPathDisplay from "../../../sheet/pages/railway-sheet-view/components/linkpath-display/linkpath-display";
import "./process-train-breadcrumb-subheader.scss";

/**
 * Process train Breadcrumb subheader for sheets reading feature
 * @param {object} props
 * @param {object} [props.train = {}]
 * @param {string} [props.returnLabel = ""]
 * @param {string} [props.returnLink = "]
 * @param {boolean} [props.showOverlay = true]
 * @param {boolean} [props.showLinkPathDisplay = false]
 * @param {string} [props.processExecutorLabel = ""]
 * @returns {JSX.Element}
 */
const ProcessTrainBreadcrumbSubheader = (props) => {
	const { train = {}, returnLabel = "", returnLink = "", showLinkPathDisplay = null, showOverlay = true, processExecutorLabel = "" } = props;
	const { t } = useTranslation();
	const { processId, binderId } = useParams();
	const { currentProcess: process = {} } = useSheetReadContext() || {};
	// without the train, train-id is entirely left out

	const trainBinders = [ train.binder_officer, train.binder_auto, train.binder_driver ].filter(n => !!n);
	const redirectHome = (trainBinders.length <= 1 && binderId) || !binderId;

	const generatePreviousLink = () => {
		if (returnLink) {
			return returnLink;
		} else if (redirectHome) {
			return "/spr/binders";
		} else {
			return buildTrainProcessBinderList({ processId }, "spr", true);
		}
	};

	const renderProcessExecutor = () => {
		const { owner_firstname: firstName, owner_lastname: lastName, owner_id: userId } = process;
		const userLabel = userId ? `${processExecutorLabel} ${buildUserName(firstName, lastName, userId)}` : t("user:user-deleted");

		return <>
			<span className="process-train-breadcrumb-subheader__separator"/>
			<div className="process-train-breadcrumb-subheader__executor">{userLabel}</div>
		</>;

	};

	const linkLabel = returnLabel || t(`process:process-train-breadcrumb-subheader.${redirectHome ? "home" : "binders"}`);
	return (
		<div className="process-train-breadcrumb-subheader">
			{showOverlay && <div className="process-train-breadcrumb-subheader__top-sticky">
				<span className="process-train-breadcrumb-subheader__viewer-triangle">
					<IconWrapper className="process-train-breadcrumb-subheader__viewer-triangle__icon" Component={IconReadLook}/>
				</span>
			</div>}
			<div className="process-train-breadcrumb-subheader__previous-link">
				<Link className="previous-link" to={generatePreviousLink()}>
					<Button 
						variant="primary"
						small
						icon={IconBack}
					/>
					<span className="previous-link__label">
						{linkLabel}
					</span>
				</Link>
				{showLinkPathDisplay && <LinkPathDisplay/>}
				{processExecutorLabel && process && renderProcessExecutor()}
			</div>
		</div>
	);
};


ProcessTrainBreadcrumbSubheader.prototype = {
	train: PropTypes.shape({
		binder_officer: PropTypes.string,
		binder_auto: PropTypes.string,
		binder_driver: PropTypes.string
	}),
	returnLabel: PropTypes.string,
	returnLink: PropTypes.string,
	showOverlay: PropTypes.bool,
	showLinkPathDisplay: PropTypes.bool,
	processExecutorLabel: PropTypes.string
};

export default ProcessTrainBreadcrumbSubheader;
