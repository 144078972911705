import React from "react";
import { Route } from "react-router-dom";
import { AppSetupProvider } from "../../shared/context/app-setup-context/app-setup-context";

/**
 * Using a custom Route for adding Context separately.
 * Using multiple context in the Switch Router doesn't separate correctly the context attribution for each route
 * @param {string} path route Path parameter
 * @param {object} children
 */
const AuthContextRoute = ({ path, children }) => {
	return (
		<Route exact path={path}>
			<AppSetupProvider>
				{children}
			</AppSetupProvider>
		</Route>
	);
};

export default AuthContextRoute;
