import { getEntity } from "../../shared/cache-access/entity/entity-utils";
import { isSomeTruthyProperty, replaceAllPropertyValue } from "../../shared/cache-access/api/api-utils";

/**
 * Axios interceptor for Post/Put requests
 * If there is a valid post/put request and a response cache handler, execute handleCacheSync
 * @param response
 */
const offlinePostPutInterceptor = response => {

	const { config = {}, data: responseData, status, fromCache } = response || {};

	const { handleCacheSync, entity, ref, line, method: configMethod } = config;


	if ( !fromCache && (configMethod === "post" || configMethod === "put") && handleCacheSync && status < 400 ) {
		const sentData = config.data ? JSON.parse(config.data) : null;

		// Keep stored method to avoid erasing post call
		getEntity(entity, ref, line)
			.catch(() => null)
			.then((cacheEntity) => {
				const previousPendingState = cacheEntity?.metadata?.pendingState || {};
				const sentField = replaceAllPropertyValue(sentData, false);
				const newPendingState = { ...previousPendingState, ...sentField };
				const isPending = isSomeTruthyProperty(newPendingState);
				const method = cacheEntity?.metadata?.method && isPending ? cacheEntity.metadata.method : configMethod;
				const metadata = {
					isPending,
					pendingState: newPendingState,
					entity,
					ref,
					line,
					method
				};
				return config.handleCacheSync(config, responseData, metadata);
			});
	}

	return response;
};

export default offlinePostPutInterceptor;
