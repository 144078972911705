import { useEffect } from "react";
import update from "immutability-helper";

/**
 *
 * @param list
 * @param setList
 * @param attributeId
 * @returns {{moveCard: moveCard}}
 */
const useDndHandleMove = (list, setList, attributeId = "id") => {

	// frame manipulated
	let requestedFrame;
	let pendingUpdateFn;

	const moveCard = (id, afterId) => {

		const card = list.find(node => node[attributeId] === id);
		const afterCard = list.find(node => node[attributeId] === afterId);

		if (card && afterCard) {
			const cardIndex = list.indexOf(card);
			const afterIndex = list.indexOf(afterCard);

			/**
			 * dnd use a lot a list modification
			 * using immutability-helper provide a clean deepCopy of original List for dnd manipulation
			 * $splice: [ get and remove card selected], [insert card at index dropped], ]
			 */
			pendingUpdateFn = {
				$splice: [ [ cardIndex, 1 ], [ afterIndex, 0, card ], ],
			};

			if (!requestedFrame) {
				requestedFrame = requestAnimationFrame(drawFrame);
			}
		}

	};

	// update list with immutability-helper update
	const drawFrame = () => {
		const nextList = update(list, pendingUpdateFn);
		setList(nextList);
		pendingUpdateFn = undefined;
		requestedFrame = undefined;

	};

	const cancelAnimationFrameEffect = () => {
		// cancel animation frame if component is detroyed before animation end
		return () => cancelAnimationFrame(requestedFrame);
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(cancelAnimationFrameEffect, []); // Make sure the effect runs only once

	return { moveCard };
};

export default useDndHandleMove;
