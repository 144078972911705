import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppSetupContext, useInReadContextCheck, useSprContext } from "../../../../../../shared";
import { useTemplateTypePosition } from "../../../../../../shared/utils/use-template-type-position";
import { getProcessByTechId, updateProcess } from "../../../../process.services";

const HistoryProcessContext = createContext({});

/**
 * Context wrapper history process page view
 * Main Process information handler
 * handle change on task on history page
 * @param {object} props
 * @param {boolean} props.processOffline
 */
const HistoryProcessProvider = props => {
	const { children, processOffline } = props;
	const { processId } = useParams();
	const { isInReadContext } = useInReadContextCheck();
	const history = useHistory();

	const { user = {} } = useAppSetupContext();
	const { associatedLine } = useSprContext();
	const [ pageLoading, setPageLoading ] = useState(true);

	const [ processInfo, setProcessInfo ] = useState({});
	const { getTaskPosition } = useTemplateTypePosition(processInfo?.history);

	const isOwner = processInfo.owner === user.tech_id;
	const isFinish = processInfo.ended_at || processInfo.status === "aborted";
	const isOffline = processOffline;

	const loadHistory = useCallback(() => {
		if (processId) {
			// Display loader only on first load
			setPageLoading(pageLoading);
			getProcessByTechId(processId, { extendOwner: true, extendTrain: true })
				.then(({ data: processFound }) => {
					setProcessInfo(processFound);
				})
				.catch((error) => {
					if (error?.response?.status === 404) {
						history.push("/404");
					} else {
						throw error;
					}
				})
				.finally(() => setPageLoading(false));
		} else {
			setPageLoading(false);
		}

		if (processOffline) {
			setProcessInfo(processOffline);
		}

	}, [ processId, pageLoading, history, processOffline ]);


	const saveProcess = (processToSave) => {
		const { tech_id, history: processHistory } = processToSave;
		updateProcess(tech_id, { history: processHistory }, { line: associatedLine })
			.then(() => loadHistory())
			.catch(error => {
				console.error(error);
			});
	};

	const handleHistoryActionCheckbox = (task) => {
		if (isOwner && !isFinish) {
			const processUpdated = { ...processInfo };
			const taskFound = processUpdated.history.find(taskElement =>
				taskElement.id === task.id &&
				taskElement.finishedAt === task.finishedAt
			);
			taskFound.infoAction = task.infoAction;
			saveProcess(processUpdated);
		}
	};

	useEffect(() => {
		if (!isInReadContext){
			loadHistory();
			const intervalID = setInterval(loadHistory, 5000);
			return () => clearInterval(intervalID);
		}
	}, [ loadHistory, isInReadContext ]);

	return (
		<HistoryProcessContext.Provider
			value={{
				processInfo, setProcessInfo,
				isOwner, isFinish, isOffline,
				handleHistoryActionCheckbox,
				getTaskPosition
			}}>
			{children}
		</HistoryProcessContext.Provider>
	);
};

/**
 * use the context history process hook
 * @returns {{
 * 	processInfo: object,
 * 	setProcessInfo: React.Dispatch<React.SetStateAction<{}>>,
 * 	isOwner: boolean,
 * 	isFinish: boolean,
 * 	isOffline: boolean,
 * 	getTaskPosition: import("../../../../../../shared/utils/use-template-type-position").getTaskPositionCallback
 * }}
 */
const useHistoryProcessContext = () => useContext(HistoryProcessContext);


export { HistoryProcessProvider, HistoryProcessContext, useHistoryProcessContext };
