import axios from "axios";
import setupInterceptor from "./setup-interceptor";

const { REACT_APP_API_BASE_URL } = process.env;

const defaultAxiosInstanceOptions = {
	baseURL: REACT_APP_API_BASE_URL,
	timeout: 30000,
	withCredentials: true,
	validateStatus: status => status < 400
};

/**
 * Create and setup axios instance
 * @param options axios interceptor setup options
 * @param {boolean} options.offline
 * @param {boolean} options.redirectOnForbidden
 * @param {Object} options.instanceOption Custom options to pass to the created axios instance
 * @param {boolean} options.noInterceptor Set to true to avoid setting up feature interceptors
 * @return {import("axios").AxiosInstance}
 */
const createDefaultAxiosInstance = (options = {}) => {
	const { instanceOption = {}, noInterceptor = false } = options;
	const instance = axios.create({ ...defaultAxiosInstanceOptions, ...instanceOption });

	if (!noInterceptor) {
		// Setup interceptors
		setupInterceptor(instance, options);
	}

	return instance;
};

/**
 * Default axios instance
 * @type {import("axios").AxiosInstance}
 */
const http = createDefaultAxiosInstance({ offline: true });
const httpOnline = createDefaultAxiosInstance({ offline: false });

const httpNetStatus = createDefaultAxiosInstance({
	redirectOnForbidden: false, instanceOption: { withCredentials: false, noInterceptor: true } });


/**
 * Axios instance without error redirect except for auth purpose
 * @type {import("axios").AxiosInstance}
 */
const httpNoErrorRedirect = createDefaultAxiosInstance({ redirectOnForbidden: false, offline: true });

export { http, httpOnline, httpNoErrorRedirect, httpNetStatus };
