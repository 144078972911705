import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../../../../../../../../../../theme";
import { useSheetContentContext } from "../../../../../../../../context/sheet-content-context";
import "./simple-link-step-form.scss";

/**
 * Render the SimpleLinkStepForm component
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const SimpleLinkStepForm = (props) => {
	const { dropdownValue, handleDropdownChange } = props;
	const { t } = useTranslation();
	const { nodeList } = useSheetContentContext();
	const [ dropdownOptions, setDropdownOptions ] = useState();


	const filterStepNodeList = ((node) => node.type === "step");

	const setPhaseOptions = () => {
		const nodeOptions = nodeList.filter(filterStepNodeList);

		const options = nodeOptions.map((val, index) => (
			<option
				value={val.id}
				key={index}>
				{`${t("sheet:sheet-details.template.simpleLink.step")} ${val.content.step}`}
			</option>
		));

		setDropdownOptions(options);
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setPhaseOptions, [ nodeList ]);

	return (
		<div className="simple-link-phase-form">
			<label className="simple-link-phase-form__label">
				{t("sheet:sheet-details.template.simpleLink.step-label")}
			</label>
			<Dropdown
				className="simple-link-phase-form__dropdown-type"
				onChange={handleDropdownChange}
				value={dropdownValue}
				placeholder={t("sheet:sheet-details.template.simpleLink.placeholder-dropdown-step")}>
				{dropdownOptions}
			</Dropdown>
		</div>

	);
};

SimpleLinkStepForm.propTypes = {
	dropdownValue: PropTypes.string.isRequired,
	handleDropdownChange: PropTypes.func.isRequired
};

export default SimpleLinkStepForm;
