import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./history-train-intervention-task-view.scss";

/**
 * history train intervention template component
 * @param {object} props
 * @param {object} props.task
 * @param {string} props.className
 * @param {boolean} props.passed
 * @returns {JSX.Element}
 */
const HistoryTrainInterventionTaskView = (props) => {
	const { task = {}, passed, className } = props;
	const { type } = task;
	const { t } = useTranslation();

	const initClassName = () => {
		const modifier = { "history-train-intervention-task-view--passed": passed };
		return classNames("history-train-intervention-task-view", `history-train-intervention-task-view--${type}`, modifier);
	};

	return (
		<div className={className}>
			<span className={initClassName()}>{`${t(`sheet:sheet-details.template.${type}.label`)}`}</span>
		</div>
	);
};

HistoryTrainInterventionTaskView.propTypes = {
	task: PropTypes.shape({
		type: PropTypes.string,
	}),
	className: PropTypes.string,
	passed: PropTypes.bool
};

export default HistoryTrainInterventionTaskView;
