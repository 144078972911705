import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, SquareColorIcon } from "../../../../../../../../../../theme";
import { IconPlus } from "../../../../../../../../assets";
import { usePopup } from "../../../../../../../../../../shared";
import { PopupCreateSheet } from "../../../../../../../../../sheet";
import { PopupDeleteTheme, PopupEditTheme } from "../../../../../../../../components/popup";
import BinderSheetListContent from "../../../binder-sheet-list-content/binder-sheet-list-content";

import resolveThemeColor from "../../../../../../../../utils/resolve-theme-color";
import { IconEdit, IconTrash, IconArrowDown } from "../../../../../../../../../../theme/assets/img";

import "./binder-theme-sheet-list-content.scss";

/**
 * Render a theme of a binder
 * @param {object} props
 * @param {object} props.analysisResult
 * @param {object[]} props.sheets
 * @param {object} props.theme
 * @param {function} props.refreshSheetList
 * @param {object} props.binder
 * @returns {JSX.Element}
 */
const BinderThemeSheetListContent = props => {
	const { analysisResult, sheets = [], theme, themeList, refreshSheetList, updateThemeList, binder = {} } = props;

	const [ open, setOpen ] = useState(true);
	const { t } = useTranslation();
	const popupDeleteThemeControl = usePopup();
	const popupCreateSheetControl = usePopup();
	const editThemePopupControl = usePopup();

	const defaultColor = resolveThemeColor(theme?.color, binder?.color);
	const hasSheets = Array.isArray(sheets) && sheets?.length > 0;
	const areButtonsDisabled = binder?.status !== "work-in-progress";

	return (
		<div className="binder-theme-list-content">
			{!!Object.keys(binder).length && !!Object.keys(theme).length  && <>
				<PopupDeleteTheme
					popupControl={popupDeleteThemeControl}
					binder={binder}
					theme={theme}
					handleClose={updateThemeList}/>
				<PopupCreateSheet
					popupControl={popupCreateSheetControl}
					binder={binder}
					theme={theme}
					defaultColor={defaultColor}
					onCreate={refreshSheetList}/>
				<PopupEditTheme
					popupControl={editThemePopupControl}
					binder={binder}
					theme={theme}
					themeList={themeList}
					handleClose={updateThemeList}/>
			</>}
			{!hasSheets &&
			<div className="binder-theme-list-content__empty">
				<div className="binder-theme-list-content__head">
					<div className="binder-theme-list-content__title">
						<SquareColorIcon color={defaultColor}/>
						<span className="binder-theme-list-content__title__text">{theme?.title}</span>
					</div>
					<div className="binder-theme-list-content__actions">
						<Button
							variant="minimal"
							disabled={areButtonsDisabled}
							type="button"
							onClick={editThemePopupControl.show}
							icon={IconEdit}
							iconTooltipContent={t("binder:binder-detail.tooltip.edit")}
						/>
						<Button
							variant="minimal"
							disabled={areButtonsDisabled}
							type="button"
							onClick={popupDeleteThemeControl.show}
							icon={IconTrash}
							iconTooltipContent={t("binder:binder-detail.tooltip.delete")}
						/>
					</div>
				</div>
				<div className="empty__body">
					<span className="empty__body__text">
						{`${t("binder:binder-detail.theme-list.create-sheet-desc")} "${theme?.title}"`}
					</span>
					<Button
						variant="primary"
						disabled={areButtonsDisabled}
						className="empty__body__add"
						onClick={popupCreateSheetControl.show}
						label={t("binder:binder-detail.theme-list.create-sheet-btn")}
						icon={IconPlus}
					/>
				</div>
			</div>
			}
			{hasSheets &&
			<div className={classnames("binder-theme-list-content__drawer", { "binder-theme-list-content__drawer--close": !open })}>
				<div className="binder-theme-list-content__head">
					<div className="binder-theme-list-content__title">
						<SquareColorIcon color={defaultColor}/>
						<span className="binder-theme-list-content__title__text">{theme?.title}</span>
					</div>
					<div className="binder-theme-list-content__actions">
						<Button
							variant="minimal"
							disabled={areButtonsDisabled}
							type="button"
							onClick={editThemePopupControl.show}
							icon={IconEdit}
							iconTooltipContent={t("binder:binder-detail.tooltip.edit")}
						/>
						<Button
							variant="secondary"
							onClick={popupCreateSheetControl.show}
							disabled={areButtonsDisabled}
							label={t("binder:binder-detail.theme-list.create-sheet-btn")}
							icon={IconPlus}
						/>
						<span className="binder-theme-list-content__separator"/>
						<Button
							disabled={areButtonsDisabled}
							variant="minimal"
							type="button"
							className="drawer__control__icon"
							onClick={() => setOpen(previousOpen => !previousOpen)}
							icon={IconArrowDown}
						/>
					</div>
				</div>
				{open &&
				<div className="drawer__body">
					<BinderSheetListContent
						sheets={sheets}
						binder={binder}
						loading={false}
						defaultColor={defaultColor}
						analysisResult={analysisResult}
						showCreateSheetPopup={() => {}}
						refreshSheetList={refreshSheetList}
					/>
				</div>}
			</div>
			}
		</div>
	);
};

BinderThemeSheetListContent.propTypes = {
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string)
	}),
	sheets: PropTypes.arrayOf(PropTypes.shape({
		number: PropTypes.string.isRequired,
		sheet_id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		title: PropTypes.object.isRequired,
		analysis_result: PropTypes.string,
		status: PropTypes.string.isRequired,
		last_change_content_at: PropTypes.string.isRequired,
		editor: PropTypes.string,
		editor_firstname: PropTypes.string,
		editor_lastname: PropTypes.string,
		editor_id: PropTypes.string
	})),
	theme: PropTypes.shape({
		title: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired
	}),
	refreshSheetList: PropTypes.func.isRequired,
	updateThemeList: PropTypes.func.isRequired,
	binder: PropTypes.object.isRequired
};

export default BinderThemeSheetListContent;
