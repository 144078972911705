/**
 * Format sheet number text inside diamond shape (eg type determination and resume sheets) as follow :
 * word must be on two lines if word is five or more characters,
 * the two lines must not have more than five chartacters each
 * 
 * @param sheetNumber
 * @return {string}
 */

//each line must be 5 chars max
const MAX_CHAR_BY_LINE = 5;

function formatSheetNumberDiamond(sheetNumberString = "") {
	const sheetNumberArray = sheetNumberString.split(" ");
	const sheetNumberContentLength = sheetNumberArray.length;

	let result;

	if (sheetNumberContentLength === 1) {
		const textContent = sheetNumberArray[0];
		result = textContent.slice(0, MAX_CHAR_BY_LINE);
	} else {
		const string1 = sheetNumberArray[0];
		const string2 = sheetNumberArray[1];
		const stringSlice1 = string1.slice(0, MAX_CHAR_BY_LINE);
		const stringSlice2 = string2.slice(0, MAX_CHAR_BY_LINE);
		result = `${stringSlice1}
		${stringSlice2}
		`;
	}

	return result;
}

export default formatSheetNumberDiamond;
