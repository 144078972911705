import React, { useRef, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./otp-input-field.scss";

/**
 * Render the OtpInput's text input
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OtpInputField = (props) => {
	const {
		className = "",
		position,
		onChange = () => {},
		onClick = () => {},
		onKeyDown = () => {},
		focus = false,
		value,
		error = false
	} = props;

	const input = useRef();

	// Handle focus
	useEffect(() => {
		if (focus && input.current?.focus){
			input.current?.focus();
		}
	}, [ focus, position ]);

	const selectAllText = () => {
		if (input.current?.select) {
			input.current?.select();
		}
	};

	const handleChange = event => {
		selectAllText();
		onChange(event);
	};

	const handleClick = event => {
		onClick(event);
		selectAllText();
	};

	return (
		<input
			ref={input}
			className={classnames(className, "otp-input-field", { "otp-input-field--error": error })}
			type="tel"
			onKeyDown={onKeyDown}
			onFocus={selectAllText}
			onInput={handleChange}
			onClick={handleClick}
			maxLength={1}
			value={value}
			size={1}
		/>
	);
};

OtpInputField.propTypes = {
	className: PropTypes.string,
	position: PropTypes.number.isRequired,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	focus: PropTypes.bool,
	value: PropTypes.string.isRequired,
	error: PropTypes.bool
};

export default OtpInputField;
