import React from "react";
import classNames from "classnames";
import { SheetImageLoader } from "../../../domains/sheet";
import "./image-list-view.scss";

const ImageListView = (props) => {
	const { className, images = [] } = props;

	const renderImageView = (image) => (
		<li key={image?.imageId} className="image-list-view__item">
			<SheetImageLoader className="image-list-view__item__image" imageId={image?.imageId} tag="light"/>
			{image.title && (
				<span className="image-list-view__item__title">
					{image.title}
				</span>
			)}
			{image.description && (
				<span className="image-list-view__item__description">
					{image.description}
				</span>
			)}
		</li>
	);

	return (
		<ul className={classNames("image-list-view", className)}>
			{images.map(renderImageView)}
		</ul>
	);
};

export default ImageListView;
