const elementTypeObject = {
	break_emd_units: "EMD",
	isolated_emd_units: "EMD",
	isolated_compressor_units: "compresseurs",
	isolated_break_bogies_units: "bogies",
	break_bogies_units: "bogies",
	rescued_bogies_units: "bogies",
};

const  getElementType = (fieldName) => {
	return elementTypeObject[fieldName];
};

const elementNumberByMaterialTrain = {
	MI09 : {
		EMD: 10,
		compresseurs: 3,
		bogies: 10
	},
	MI2N : {
		EMD: 10,
		compresseurs: 3,
		bogies: 10
	},
	MI79 : {
		EMD: 8,
		compresseurs: 2,
		bogies: 8
	},
	MI84 : {
		EMD: 8,
		compresseurs: 2,
		bogies: 8
	}
};

const getElementNumberMax = (elementType, composition, material) => {
	const multiple = composition === "US" ? 1 : 2;
	return elementNumberByMaterialTrain?.[material]?.[elementType] * multiple;
};

export { getElementNumberMax, getElementType };
