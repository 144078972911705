const trainCompositionLineA = [
	{
		element1: [
			{
				header: "Remorque",
				key: "first-car",
				content: [
					[ "RB-PT" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
			{
				header: "Motrice",
				key: "second-car",
				content: [
					[ "PO/BM", "PO/BM" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},{
				header: "Motrice",
				key: "third-car",
				content: [
					[ "PO/BM", "PO/BM" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
			{
				header: "Motrice",
				key: "fourth-car",
				content: [
					[ "PO/BM", "PO/BM" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
			{
				header: "Remorque",
				key: "fifth-car",
				content: [
					[ "RB-PT" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
		],
	},
	{
		element2:  [
			{
				header: "Remorque",
				key: "sixth-car",
				content: [
					[ "RB-PT" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
			{
				header: "Motrice",
				key: "seventh-car",
				content: [
					[ "PO/BM", "PO/BM" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
			{
				header: "Motrice",
				key: "eighth-car",
				content: [
					[ "PO/BM", "PO/BM" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
			{
				header: "Motrice",
				key: "ninth-car",
				content: [
					[ "PO/BM", "PO/BM" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
			{
				header: "Remorque",
				key: "tenth-car",
				content: [
					[ "RB-PT" ],
					[ "EMD", "EMD" ],
					[ "FI", "FI" ]
				],
			},
		],
	},
];

export default trainCompositionLineA;
