import React from "react";
import PropTypes from "prop-types";

import { Button } from "../../../../../../theme";

import "./process-action-button.scss";

/**
 * ProcessActionButton props
 * @typedef {object} Props
 * @prop {string} label
 * @prop {object} children
 * @prop {object} Icon
 * @prop {function} handleClick
 */

/**
 * ProcessActionButton Icon
 * @param {Props} props
 * @returns {JSX.Element}
 */
const ProcessActionButton = (props) => {
	const { handleClick, label, Icon, iconPositionRight = false } = props;
	return <Button
		variant={"secondary"}
		type="button"
		onClick={handleClick}
		icon={Icon}
		label={label}
		iconPositionRight={iconPositionRight}
	/>;
};

ProcessActionButton.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node,
	onSubmitSucces: PropTypes.func,
	Icon: PropTypes.elementType
};


export default ProcessActionButton;
