import React from "react";
import IconWrapper from "../icon-wrapper/icon-wrapper";
import { IconReturn } from "../../../domains/process/assets";

import "./return-triangle.scss";

const ReturnTriangle = () => <span className="return-triangle">
	<IconWrapper
		className="return-triangle__icon"
		Component={IconReturn}
	/>
</span>;

export default ReturnTriangle;
