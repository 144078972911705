import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import IconWrapper from "../../icon-wrapper/icon-wrapper";
import Spinner from "../../spinner/spinner";
import "./button.scss";

/**
 * Button component
 * @param {object} props
 * @param {"button" | "submit" | "reset"} [props.type = "button"]
 * @param {string} props.className
 * @param {boolean} props.selected
 * @param {boolean} props.disabled
 * @param {"primary" | "secondary" | "minimal" | "switch" | "dark"} props.variant
 * @param {boolean} props.small
 * @param {string} props.label
 * @param {bool} props.loading
 * @param {string} props.iconTooltipContent
 * @param {func} props.onClick
 * @param {boolean} props.iconPositionRight
 * @param {JSX.Element} props.icon
 * @returns {JSX.Element}
 */
const Button = (props) => {
	const {
		className = "",
		disabled,
		icon,
		iconTooltipContent = "",
		label = "",
		loading = false,
		small = false,
		type = "button",
		variant = "primary",
		selected = false,
		onClick = () => {},
		iconPositionRight = false,
		iconBig = false,
		...rest
	} = props;

	const initButtonClassName = () => classNames(
		className,
		"btn",
		{
			[`btn--${variant}`]: variant,
			"btn--icon-only": icon && !label,
			"btn--small": small,
			"btn--selected" : selected,
			"btn--loading": loading
		},
		{ "btn--icon-position": iconPositionRight }
	);

	const iconClassName = () => classNames("btn__icon", { "btn__icon--big": iconBig });

	const handleOnClick = (event) => {
		if (!disabled) {
			onClick(event);
		}
	};


	return (
		<button disabled={disabled} onClick={handleOnClick} className={initButtonClassName()} type={type}  {...rest}>
			{
				loading
					? <Spinner className="btn__icon" />
					: <>
						{icon  && <IconWrapper className={iconClassName()} Component={icon} tooltip={iconTooltipContent} />}
						{label && <span className={classNames("btn__label")}>{label}</span>}
					</>
			}
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.oneOfType([ PropTypes.node, PropTypes.element, PropTypes.object ]) ,
	iconTooltipContent: PropTypes.string,
	onClick: PropTypes.func,
	label: PropTypes.string,
	loading: PropTypes.bool,
	small: PropTypes.bool,
	selected: PropTypes.bool,
	type: PropTypes.string,
	variant: PropTypes.string,
	iconPositionRight: PropTypes.bool
};

export default Button;
