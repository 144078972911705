import React, { useCallback, useLayoutEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LogoDigiproc from "../../logo-digiproc/logo-digiproc";
import ProfileIcon from "../../../../shared/components/profile-icon/profile-icon";

import "./cdr-page.scss";
/**
 * CdrPage component
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.mainInfos
 * @param {string} props.subheader
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 */
const CdrPage = props => {
	const { className, children, mainInfos, subheader } = props;

	const [ scrolled, setScrolled ] = useState(false);

	const handleScroll = useCallback(() => {
		setScrolled(window.scrollY > 0);
	}, []);

	useLayoutEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	return (
		<main className="page">

			<header className={classNames("page__header", { "header--scrolled": scrolled })}>
				<div className="page__header__top">
					<div className="page__header__top__wrapper">
						<Link to="/"><LogoDigiproc className="page__logo"/></Link>
						<div className="header__top__main-infos">
							{mainInfos}
						</div>
					</div>
					<ProfileIcon/>
				</div>

				{subheader && (
					<div className="page__header__subheader">
						{subheader}
					</div>
				)}
			</header>

			<div className={classNames("page__content", className)}>
				{children}
			</div>

		</main>
	);
};

CdrPage.propTypes = {
	className: PropTypes.string,
	mainInfos: PropTypes.node,
	children: PropTypes.node,
	subheader: PropTypes.node
};

export default CdrPage;
