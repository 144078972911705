import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "../../index";
import { IconArrowDown } from "../../assets/img";
import "./drawer.scss";


/**
 * @param {object} props
 * @param {string} props.className
 * @param {string | object} props.title
 * @param {string | object} props.leftText
 * @param {string} props.rightText
 * @param {boolean} props.showDropDownToggle
 * @param {boolean} props.drawerStatus
 * @returns {JSX.Element}
 */
const Drawer = (props) => {
	const {
		className,
		children,
		title = null,
		leftText = null,
		rightText = "",
		drawerStatus,
		showDropDownToggle = true,
	} = props;
	const [ expanded, setExpanded ] = useState(drawerStatus);

	const handleClick = () => {
		setExpanded(!expanded);
	};

	useEffect(() => setExpanded(drawerStatus), [ drawerStatus ]);

	return (
		<div className={classNames("drawer", className, { "drawer--open" : expanded })}>
			<div className={classNames("drawer__header-wrapper", { "drawer__header-wrapper--open" : expanded })} onClick={handleClick}>
				{title && <h1 className="drawer__header-wrapper__title">{title}</h1>}
				<span className="drawer__header-wrapper__text">
					{leftText}
					<span className="drawer__header-wrapper__time--mobile">{rightText}</span>
				</span>
				<div className="drawer__header-wrapper__right">
					<span className="drawer__header-wrapper__time">{rightText}</span>
					{showDropDownToggle &&
						<Button
							variant="minimal"
							className="drawer__header-wrapper__reducer-icon"
							icon={IconArrowDown}
						/>
					}
				</div>
			</div>
			<div className="drawer__content">
				{children}
			</div>
		</div>
	);
};

Drawer.propTypes = {
	className: PropTypes.string,
	title: PropTypes.oneOfType([ PropTypes.element, PropTypes.elementType, PropTypes.string ]),
	leftText: PropTypes.oneOfType([ PropTypes.element, PropTypes.elementType, PropTypes.string ]),
	rightText: PropTypes.string,
	showDropDownToggle: PropTypes.bool,
	drawerStatus: PropTypes.bool,
};


export default Drawer;
