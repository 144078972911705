import React, { useState } from "react";
import classNames from "classnames";
import { EditorRendering, IconWrapper } from "../../../../../../theme";
import NextButton from "../next-button/next-button";
import { useRunProcessContext } from "../../../../components/context-run-process/context-run-process";
import { IconCheck } from "../../../../../binder/assets";
import { IconReturn } from "../../../../assets";

import "./multiple-choice-task-view.scss";

const MultipleChoiceTaskView = (props) => {
	const { task = {}, previousTaskInfo = {} } = props;
	const { content = {} } = task;
	const { choices: previousChoices = [] } = previousTaskInfo?.content || {};
	const [ choicesChecked, setChoicesChecked ] = useState([]);
	const { handleMultipleChoiceTaskAnswer } = useRunProcessContext();

	const handleCheckboxChange = (event) => {
		const { checked, name } = event.target;
		let choicesToUpdate = [ ...choicesChecked ];
		if (checked) {
			choicesToUpdate.push(parseInt(name));
		} else {
			choicesToUpdate = choicesToUpdate.filter(dataFiltered => dataFiltered !== parseInt(name));
		}
		setChoicesChecked(choicesToUpdate);
	};

	const handleNextClick = (choices) => {
		if (Array.isArray(choices) && choices.length > 0) {
			handleMultipleChoiceTaskAnswer(choices);
		}
	};

	const renderChoiceBlocks = (choiceBlock, index) => {
		const isPreviouslySelected = previousChoices[index] && previousChoices[index].chosen;
		return (
			<div key={index} className="multiple-choice-task-view__choice-block">
				<div className="multiple-choice-task-view__choice-block--icon">
					<input id={index} type="checkbox" name={index}
					       className={"multiple-choice-task-view__choice-block--input"}
					       checked={choicesChecked.includes(index)}
					       onChange={(e) => handleCheckboxChange(e)}/>
					<label htmlFor={index}
					       className={classNames("multiple-choice-task-view--template-view__icon-head",
						       { "multiple-choice-task-view--template-view__icon-head--active": choicesChecked.includes(index) })}>
						<IconWrapper className="multiple-choice-task-view--template-view__icon--check"
						             Component={IconCheck}/>
					</label>
				</div>
				<div className="multiple-choice-task-view__choice-block__content">
					<div className="multiple-choice-task-view__choice-block__content--title">
						{choiceBlock.title}
					</div>
					<div className="multiple-choice-task-view__choice-block__content--subtitle">
						{choiceBlock.subtitle}
					</div>
				</div>
				{isPreviouslySelected &&
				<IconWrapper className="multiple-choice-task-view__choice-block__icon-return" Component={IconReturn}/>}
			</div>
		);
	};

	return (
		<div className="multiple-choice-task-view">
			<div className="multiple-choice-task-view__header">
				<EditorRendering className="multiple-choice-task-view__header--title" content={content?.title}/>
			</div>
			<div className="multiple-choice-task-view__choice-blocks">
				{Array.isArray(content.choices) && content.choices.map(renderChoiceBlocks)}
			</div>

			<div className={classNames("multiple-choice-task-view__next-button", { "multiple-choice-task-view__next-button--disabled": Array.isArray(choicesChecked) && choicesChecked.length === 0 })}>
				<NextButton onClick={() => handleNextClick(choicesChecked)}/>
			</div>

		</div>
	);
};

export default MultipleChoiceTaskView;
