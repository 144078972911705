import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Dropdown, TextField } from "../../../../../../theme";
import { groupBy, useAppSetupContext } from "../../../../../../shared";
import { searchBinder } from "../../../../../binder/binder.services";
import { fetchMaterial } from "../../../../../../shared/material.services";
import { binderTrainTypeList } from "../../../../../binder/utils/binder-type";
import MaterialDropdown from "../../../../../binder/components/material-dropdown/material-dropdown";
import "./form-train.scss";

/**
 * Render the FormTrain component
 * @param {object} props
 * @param {import("../../../../train.services").Train} props.form
 * @param {object} props.validation
 * @param {object} props.errorMessage
 * @param {number} props.line
 * @param {boolean} [props.create]
 * @param {boolean} [props.update]
 * @param {boolean} [props.copy]
 * @returns {JSX.Element}
 */
const FormTrain = (props) => {
	const { form, validation, errorMessage, create, update, copy, onChange, line } = props;
	const { t } = useTranslation();
	const { user: currentUser } = useAppSetupContext();
	const { role: userRole } = currentUser || {};
	const [ materialList, setMaterialList ] = useState([]);
	const [ binderList, setBinderList ] = useState([]);

	const lineEntity = [ "A", "B" ].includes(line) ? "rer" : "mts";

	const radioButtonElements = [
		{
			options: lineEntity === "mts" ? [ 3, 4, 5, 6, 8 ] : [ 3, 4, 5, 6, 8, 10 ],
			key: "car-number",
			fieldName: "car_number",
			className: "form-train__radio__car",
			disableTranslate: true
		},
		{
			options: [ true, false ],
			key: "engine-system",
			fieldName: "engine_system",
			className: "form-train__radio__engine"
		},
		{
			options: [ "car", "bogie" ],
			key: "brake-system",
			fieldName: "brake_system",
			className: "form-train__radio__brake"
		},
		{
			options: [ true, false ],
			key: "brake-isolation",
			fieldName: "brake_isolation",
			className: "form-train__radio__brake-isolation"
		}
	];

	const getMaterialList = () => {
		fetchMaterial(lineEntity).then(response => {
			setMaterialList(response?.data);
		});
	};

	const getBinderTrainList = () => {
		const params = {
			associatedLine: line,
			status: [ "published" ],
			type: binderTrainTypeList
		};

		if (form.material_tech_id){
			params.material_tech_id = form.material_tech_id;
		}

		searchBinder(params).then((res) => {
			const { data = [] } = res || {};
			if (!!data?.length) {
				// group binders by type "train-auto" | "train-driver" | "train-officer"
				const binderListGroupedByType = groupBy(data, "type");
				setBinderList(binderListGroupedByType);
			} else {
				setBinderList([]);
			}
		});
	};

	const generateRadioButtons = (fieldName, optionValue, useTranslate) => {
		const translateKey = typeof optionValue === "boolean" ? (optionValue ? "active" : "inactive") : optionValue;
		// for rer train, disabled radio button for brake system (default value = bogie)
		const disabled = lineEntity === "rer" && fieldName === "brake_system" ? true : copy;

		return (
			<li key={optionValue} className="form-train__radio-item">
				<input disabled={disabled} type="radio" name={fieldName} value={optionValue}
				       checked={form[fieldName]?.toString() === optionValue?.toString()}
				       className="form-train__radio-item__input" onChange={onChange}/>
				<label htmlFor={optionValue?.toString()} className="form-train__radio-item__label">
					{useTranslate ? t(`train:popup.form.field.${translateKey}`) : optionValue}
				</label>
			</li>
		);
	};

	const generateRadioElements = () => radioButtonElements.map(({ key, options, fieldName, className, disableTranslate }) => {
		return (<Fragment key={key}>
			<span className="form-train__label">{t(`train:popup.form.field.${key}`)}</span>
			<ul className={className}>
				{options.map(option => generateRadioButtons(fieldName, option, !disableTranslate))}
			</ul>
		</ Fragment>);
	});

	const renderBinderOptions = (binders) => Array.isArray(binders) ? binders.map((binder) => (
		<option
			value={binder.id}
			key={binder.tech_id}>
			{`${binder.id} - ${binder.title}`}
		</option>))
		: null;

	useEffect(getMaterialList, [ lineEntity, userRole ]);
	useEffect(getBinderTrainList, [ form?.material_tech_id, line ]);

	return (
		<div className="form-train">
			<div className="form-train__wrapper">
				<TextField
					name="id"
					label={t("train:popup.form.field.id")}
					value={form.id}
					invalid={form.id !== undefined && (create || copy) && validation.id.length !== 0}
					helperText={t(errorMessage.id)}
					onChange={onChange}
					disabled={update}
				/>
				<MaterialDropdown
					placeholder={t("train:popup.form.field.material")}
					name="material_tech_id"
					value={form.material_tech_id}
					onChange={onChange}
					materialList={materialList}
					disabled={copy}
				/>
				{generateRadioElements()}
			</div>
			<div className="form-train__wrapper">
				<span className="form-train__label">{t("train:popup.form.field.binders.label")}</span>
				<Dropdown
					name="binder_auto"
					placeholder={t("train:popup.form.field.binders.binder-auto")}
					value={form.binder_auto}
					onChange={onChange}
					disabledPlaceholder={false}
					disabled={copy || !form.material_tech_id || !binderList["train-auto"]}>
					{renderBinderOptions(binderList["train-auto"])}
				</Dropdown>
				<Dropdown
					name="binder_driver"
					placeholder={t("train:popup.form.field.binders.binder-driver")}
					value={form.binder_driver}
					onChange={onChange}
					disabledPlaceholder={false}
					disabled={copy || !form.material_tech_id || !binderList["train-driver"]}>
					{renderBinderOptions(binderList["train-driver"])}
				</Dropdown>
				<Dropdown
					name="binder_officer"
					placeholder={t("train:popup.form.field.binders.binder-officer")}
					value={form.binder_officer}
					onChange={onChange}
					disabledPlaceholder={false}
					disabled={copy || !form.material_tech_id || !binderList["train-officer"]}>
					{renderBinderOptions(binderList["train-officer"])}
				</Dropdown>
			</div>
		</div>
	);
};


FormTrain.propTypes = {
	form: PropTypes.shape({
		id: PropTypes.string,
		line: PropTypes.string,
		material: PropTypes.string,
		brake_system: PropTypes.string,
		car_number: PropTypes.string,
		binder_auto: PropTypes.string,
		binder_driver: PropTypes.string,
		binder_officer: PropTypes.string,
		brake_isolation: PropTypes.string,
		engine_system: PropTypes.string
	}).isRequired,
	validation: PropTypes.shape({
		id: PropTypes.array,
		line: PropTypes.array,
		material_tech_id: PropTypes.array,
		brake_system: PropTypes.array,
		car_number: PropTypes.array,
		binder_auto: PropTypes.array,
		binder_driver: PropTypes.array,
		binder_officer: PropTypes.array,
		brake_isolation: PropTypes.array,
		engine_system: PropTypes.array
	}).isRequired,
	errorMessage: PropTypes.shape({
		id: PropTypes.string,
		line: PropTypes.string,
		material_tech_id: PropTypes.string,
		brake_system: PropTypes.string,
		car_number: PropTypes.string,
		binder_auto: PropTypes.string,
		binder_driver: PropTypes.string,
		binder_officer: PropTypes.string,
		brake_isolation: PropTypes.string,
		engine_system: PropTypes.string
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	create: PropTypes.bool,
	update: PropTypes.bool
};

export default FormTrain;
