import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { listAllBinderSignal } from "../../../../../../../binder-signal.services";
import { usePopup } from "../../../../../../../../../shared";
import { SearchBar, Button } from "../../../../../../../../../theme";
import { PopupCreateSignal } from "../../popup";
import { IconPlus } from "../../../../../../../assets";
import BinderDetailSignal from "../../binder-detail-signal/binder-detail-signal";

import "./binder-man-sheet-list.scss";


/**
 * Render the sheet list of a binder of type man
 * @param {object} props
 * @param {object[]} props.sheets
 * @param {object} props.binder
 * @param {function} props.search
 * @param {function} props.onSearch
 * @param {function} props.refreshSheetList
 * @param {object} props.analysisResult
 * @returns {JSX.Element}
 */
const BinderManSheetList = props => {
	const { sheets = [], binder, search, onSearch, analysisResult, refreshSheetList } = props;

	const [ signalList, setSignalList ] = useState([]);
	const { t } = useTranslation();

	const popupCreateSignalControl = usePopup();
	const hasSignals = Array.isArray(signalList) && signalList?.length > 0;

	const getBinderSignal = () => {
		if (binder?.tech_id) {
			listAllBinderSignal(binder.tech_id).then(response => {
				setSignalList(response.data);
			});
		}
	};

	const renderSignal = (signal) => {
		const signalSheets = sheets.filter(sheet => sheet?.signal_tech_id === signal?.tech_id);
		return (
			<li className="binder-man-sheet-list__signal" key={signal?.tech_id}>
				<BinderDetailSignal
					analysisResult={analysisResult}
					binder={binder}
					signal={signal}
					signalList={signalList}
					sheets={signalSheets}
					refreshSheetList={refreshSheetList}
					refreshSignalList={getBinderSignal}
				/>
			</li>
		);
	};

	useEffect(getBinderSignal, [ binder?.tech_id ]);

	const CreateSignalButton = ({ className }) => <Button
		variant="primary"
		className={className}
		onClick={popupCreateSignalControl.show}
		disabled={binder?.status !== "work-in-progress"}
		icon={IconPlus}
		label={t("binder:binder-detail.man-list.create-signal")}
	/>;


	return (
		<div className="binder-man-sheet-list">
			<PopupCreateSignal popupControl={popupCreateSignalControl} binderTechId={binder?.tech_id}
			                   onCreate={getBinderSignal} signalList={signalList}/>
			<div className="binder-man-sheet-list__head">
				<h3 className="binder-man-sheet-list__title">{t("binder:binder-detail.man-list.title")}</h3>
				<span className="binder-man-sheet-list__iteration">
					{t("binder:binder-detail.sheet-list.iteration", { number: binder?.iteration })}
				</span>
				{hasSignals && (
					<div className="binder-man-sheet-list__actions">
						<SearchBar
							className="actions__search"
							value={search}
							onChange={onSearch}
							placeholder={t("binder:binder-detail.sheet-list.search-bar-placeholder")}
						/>
						<CreateSignalButton className="actions__add-signal"/>
					</div>
				)}
			</div>
			{hasSignals && <ul className="binder-man-sheet-list__signals">
				{signalList.map(renderSignal)}
			</ul>}
			{!hasSignals &&
				<div className="binder-man-sheet-list__no-signal">
					<span className="no-signal__text">
						{t("binder:binder-detail.man-list.create-first-signal", { id: binder?.id?.toUpperCase() })}
					</span>
					<CreateSignalButton className="no-signal__add-signal"/>
				</div>
			}
		</div>
	);
};

BinderManSheetList.propTypes = {
	sheets: PropTypes.arrayOf(PropTypes.shape({
		number: PropTypes.string.isRequired,
		sheet_id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		title: PropTypes.object.isRequired,
		analysis_result: PropTypes.string,
		status: PropTypes.string.isRequired,
		last_change_content_at: PropTypes.string.isRequired,
		editor: PropTypes.string,
		editor_firstname: PropTypes.string,
		editor_lastname: PropTypes.string,
		editor_id: PropTypes.string
	})),
	binder: PropTypes.shape({
		status: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired,
	}),
	search: PropTypes.string.isRequired,
	onSearch: PropTypes.func.isRequired,
	refreshSheetList: PropTypes.func.isRequired,
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string)
	})
};

export default BinderManSheetList;
