import { http } from "../../config";
import qs from "qs";

/**
 * @typedef {'administrator' | 'regulation' | 'train-agent'|'admin-regulation'|'admin-line'|'line-operator-supervisor'|'line-operator'} UserRole
 */

/**
 * @typedef User
 * @prop {string} id
 * @prop {string} lastname
 * @prop {string} firstname
 * @prop {string} tech_id
 * @prop {string} creation_date
 * @prop {UserRole} role
 * @prop {string} active
 * @prop {string | string[]} associated_line
 * @prop {string} main_line
 */

/**
 * Get all users
 * @param {object} params
 * @returns {Promise<import("axios").AxiosResponse<User[]>>}
 */
const searchUser = async (params) => http.get("/user", {
	params,
	paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: "repeat" })
});

/**
 * Get user by its id
 * @param {String} id The user id
 * @returns {Promise<import("axios").AxiosResponse<User>>}
 */
const fetchUserById = async (id) => http.get(`/user/${id}`);

/**
 * Create a new user
 * @param {Object} userData The user data object
 * @returns {Promise<import("axios").AxiosResponse<User>>}
 */

const createUser = async (userData) => http.post("/user", userData);

/**
 * Update an user
 * @param {string} userTechId The user tech id
 * @param {User} userData The user data object
 * @returns {Promise<import("axios").AxiosResponse<User>>}
 */
const updateUser = async (userTechId, userData) => http.put(`/user/${userTechId}`, userData);


const purgeUser = async (userTechId) => http.delete(`/user/${userTechId}`);

export {
	searchUser, fetchUserById, createUser, updateUser, purgeUser
};
