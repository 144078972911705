import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LogoDigiproc } from "../../../../theme";
import { ReactComponent as LogoUnauthorized } from "../../assets/img/logo-unauthorized.svg";
import { ReactComponent as IconArrowLeft } from "../../../process/assets/icon-arrow-left.svg";
import { logout } from "../../../../shared/auth/auth.services";
import { IconWrapper } from "../../../../theme";
import "./unauthorized-page.scss";

const UnauthorizedPage = () => {
	const { t } = useTranslation();

	return (
		<main className="unauthorized-page">
			<header className="unauthorized-page__header">
				<Link to="/"><LogoDigiproc className="page__logo"/></Link>
			</header>
			<div className="unauthorized-page__content">
				<LogoUnauthorized className="unauthorized-page__content__logo"/>
				<div className="unauthorized-page__content__title">{t("error:unauthorized-page.title")}</div>
				<div className="unauthorized-page__content__content">{t("error:unauthorized-page.content")}</div>
				<Link to="/" className="unauthorized-page__content__redirect">
					<IconWrapper className="back-to-current-step__icon" Component={IconArrowLeft}/>
					<span className="back-to-current-step__text">{t("error:unauthorized-page.home-link")}</span>
				</Link>
				<button onClick={logout} className="unauthorized-page__home-link">
					{t("error:unauthorized-page.logout-button")}
				</button>
			</div>
		</main>
	);
};

export default UnauthorizedPage;
