import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import binderColorList from "../../../utils/binder-color-list.json";
import { Button, ColorChooser, SquareColorIcon, TextField } from "../../../../../theme";
import { checkFormError } from "../../../../../shared";
import resolveThemeColor from "../../../utils/resolve-theme-color";
import "./form-theme.scss";

/**
 * FormTheme component
 * @param {object} props
 * @param {object} props.formData - form information
 * @param {object} props.binder - theme's binder
 * @param {object} props.theme - theme to update
 * @param {object} props.formErrorMessage - form error messages per field
 * @param {object} props.formError - validation of form input per field
 * @param {function} props.handleChange - change handler
 * @returns {JSX.Element}
 */
const FormTheme = (props) => {
	const { formData, binder, theme, formErrorMessage, formError, handleChange } = props;
	const { t } = useTranslation();

	return (
		<>
			<div className="popup-form-theme__binder">
				<SquareColorIcon color={binder.color}/>
				<span className="binder__text">{binder?.id}</span>
			</div>
			<TextField
				name="title"
				label={t("binder:popup.form-theme.form.title")}
				value={formData.title}
				helperText={(t(formErrorMessage.title))}
				invalid={formData.title !== undefined && formError.title.length !== 0}
				onChange={handleChange}/>
			<ColorChooser
				colorList={binderColorList}
				name="color"
				value={formData.color || resolveThemeColor(theme?.color, binder?.color)}
				onChange={handleChange}
				label={t("binder:popup.form-theme.form.color")}
			/>
			<Button
				className="popup-form-theme__submit"
				type="submit"
				disabled={checkFormError(formError)}
				label={t("binder:popup.form.field.submit")}
			/>
		</>
	);
};

FormTheme.propTypes = {
	formData: PropTypes.shape({
		title: PropTypes.string,
		color: PropTypes.string
	}).isRequired,
	formError: PropTypes.shape({
		title: PropTypes.array.isRequired,
		color: PropTypes.array
	}).isRequired,
	formErrorMessage: PropTypes.shape({
		title: PropTypes.string,
		color: PropTypes.string
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	binder: PropTypes.shape({
		id: PropTypes.string,
		color: PropTypes.string
	}),
	theme: PropTypes.shape({
		color: PropTypes.string
	})
};

export default FormTheme;
