import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IconWrapper } from "../../../../../../../../theme";
import { SheetImageLoader } from "../../../../../../";
import { ReactComponent as IconCross } from "../../../../../../../../theme/assets/img/close.svg";

import "./image-form-file-input.scss";

const ImageFormFileInput = props => {
	const { className, value, imageId, onChange, onDelete, errorHelperText } = props;

	const wrapperClassName = classNames("image-form-file-input", className);

	const { t } = useTranslation();

	const handleFileClick = (event) => {
		if (value) {
			event.preventDefault();
		}
	};

	if (imageId) {
		return (
			<div className={wrapperClassName}>
				<span className="image-form-file-input__preview">
					<SheetImageLoader className="image-form-file-input__thumbnail" imageId={imageId} tag="thumbnail"/>
				</span>
				<IconWrapper className="image-form-file-input__delete" onClick={onDelete} Component={IconCross}/>
			</div>
		);
	}

	return (
		<>
			<label className={wrapperClassName}>
				<input className="image-form-file-input__input" type="file" onChange={onChange}
				       onClick={handleFileClick}/>
				<span className="image-form-file-input__placeholder">
					{t("sheet:sheet-details.image-form.file-input-placeholder")}
				</span>
			</label>
			{errorHelperText && <p className="image-form-file-input__error">{errorHelperText}</p>}
		</>
	);
};

export default ImageFormFileInput;
