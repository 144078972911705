import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { TabsPanel, CdrPage } from "../../../../theme";
import { useTabs, LoadWrapper } from "../../../../shared";
import { fetchSheetByBinder, fetchSheetById } from "../../sheet.services";
import { fetchBinderByTechId } from "../../../binder/binder.services";
import { listAllBinderSignal } from "../../../binder/binder-signal.services";
import { removeDisplayDeletePopupKey } from "./utils/handle-session-storage";
import SheetEditionPanel from "./components/sheet-edition-panel/sheet-edition-panel";
import SheetPreview from "../../components/sheet-preview/sheet-preview";
import SheetComments from "./components/sheet-comments/sheet-comments";
import { SheetContentProvider, useSheetContentContext } from "../../context/sheet-content-context";
import { SheetModeContextProvider, useSheetModeContext } from "../../context/sheet-mode-context";
import { useSheetReload } from "../../utils/use-sheet-reload";
import SheetProcessRun from "./components/sheet-process-run/sheet-process-run";
import CdrMainInfos from "../../../../theme/components/page/cdr-page/cdr-main-infos/cdr-main-infos";
import SheetDetailsSubheaderTitle from "../../components/sheet-details-subheader-title/sheet-details-subheader-title";
import SheetDetailsSubheaderOptions from "../../components/sheet-details-subheader-options/sheet-details-subheader-options";
import resolveSheetColor from "../../utils/resolve-sheet-color";
import "./sheet-detail.scss";

const SheetDetailInner = () => {
	const history = useHistory();
	const { sheetId, binderId } = useParams();
	const location = useLocation();

	const { sheetData } = useSheetContentContext();
	const { readOnly, setForceReadMode } = useSheetModeContext();

	const { activeId, tabsItemConfig, tabsPanelConfig } = useTabs(readOnly ? 0 : 1);
	const [ signalInfo, setsignalInfo ] = useState(null);
	const [ binderData, setBinderData ] = useState({});
	const [ sheetList, setSheetList ] = useState({});
	const [ pageLoading, setPageLoading ] = useState(true);
	const { reloadSheetData } = useSheetReload();

	const isBinderStatusWIP = binderData?.status === "work-in-progress";
	const sheetColor = binderData?.type !== "man" && resolveSheetColor(sheetData.color, binderData.color, sheetData.theme_color);

	// TODO - simplify with a shared context
	const fetchBinderSignalsData = async (signal_tech_id, binder_tech_id) => {
		const { data: signalList } = await listAllBinderSignal(binder_tech_id);
		const currentSignal = signalList.find(s => s.tech_id === signal_tech_id);
		const isDuplicateSignalInBinder = signalList.some(s => s?.title?.toUpperCase() === currentSignal?.title?.toUpperCase() && s.tech_id !== currentSignal?.tech_id);
		setsignalInfo({
			title: currentSignal?.title,
			destination: isDuplicateSignalInBinder ? currentSignal?.destination : null
		});
	};

	const loadSheetForPage = async () => {
		try {
			setPageLoading(true);
			const { data: sheet = {} } = await fetchSheetById(binderId, sheetId);
			const { data: binder = {} } = await fetchBinderByTechId(binderId);
			const { data: sheetListData = {} } = await fetchSheetByBinder(binderId, { action: "navigation" });

			reloadSheetData(sheet);
			setBinderData(binder);
			setSheetList(sheetListData);

			if (sheet?.binder_type === "man" && sheet?.signal_tech_id) {
				await fetchBinderSignalsData(sheet.signal_tech_id, sheet.binder_tech_id);
			}
		} catch (error) {
			if ([ 400, 404 ].includes(error?.response?.status)) {
				history.push("/404");
			} else {
				throw error;
			}
		} finally {
			setPageLoading(false);
		}

	};

	const initSheetData = () => {
		setPageLoading(true);
		loadSheetForPage();
	};


	const displaySubHeader = () => (
		<>
			<SheetDetailsSubheaderTitle
				binderData={binderData}
				isBinderStatusWIP={isBinderStatusWIP}
				sheetList={sheetList}
			/>
			<SheetDetailsSubheaderOptions
				tabsItemConfig={tabsItemConfig}
				isBinderStatusWIP={isBinderStatusWIP}
			/>
		</>
	);

	useEffect(removeDisplayDeletePopupKey, [ location.pathname ]);
	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initSheetData, [ binderId, sheetId ]);
	useEffect(() => {
		const readOnlyTabs = [ 0, 2, 3 ]; // Force read mode when displaying comments or read tab
		setForceReadMode(readOnlyTabs.includes(activeId));
	}, [ activeId, setForceReadMode ]);

	return (
		<LoadWrapper className="sheet-detail__loader" loading={pageLoading}>
			<CdrPage className={classNames( "sheet-detail", { "sheet-detail--read-only": readOnly })}
			         mainInfos={
			         	<CdrMainInfos
				            binder={binderData}
				            sheet={sheetData}
				            signalInfo={signalInfo}
				            onSheetDetails/>
			         }
			         subheader={displaySubHeader()}
			>
				<DndProvider backend={HTML5Backend}>

					<div className="sheet-detail-page__content">
						<TabsPanel id={0} {...tabsPanelConfig} className="sheet-edition">
							<main className="sheet-edition__main-content">
								<SheetPreview
									readOnly
									binderType={binderData?.type}
									sheetNumber={sheetData.number}
									sheetType={sheetData.type}
									signalInfo={signalInfo}
									sheetColor={sheetColor}
								/>
							</main>
						</TabsPanel>
						<TabsPanel id={1} {...tabsPanelConfig} className="sheet-edition">
							{!readOnly && <SheetEditionPanel binderData={binderData}/>}
							<main className="sheet-edition__main-content">
								<SheetPreview
									binderType={binderData?.type}
									sheetNumber={sheetData.number}
									sheetType={sheetData.type}
									signalInfo={signalInfo}
									sheetColor={sheetColor}
								/>
							</main>
						</TabsPanel>

						<TabsPanel id={2} {...tabsPanelConfig}>
							<SheetComments
								sheetId={sheetId}
								binderTechId={binderId}
								binderStatus={binderData.status}
								binderType={binderData?.type}
								sheetNumber={sheetData.number}
								sheetType={sheetData.type}
								sheetColor={sheetColor}
								signalInfo={signalInfo}
								binderIteration={binderData.iteration}
							/>
						</TabsPanel>
						<TabsPanel id={3} {...tabsPanelConfig} className="sheet-detail__tab-process">
							<SheetProcessRun sheetData={sheetData}/>
						</TabsPanel>
					</div>

				</DndProvider>
			</CdrPage>
		</LoadWrapper>
	);
};

const SheetDetail = ({ readOnly }) => (
	<SheetModeContextProvider readOnly={readOnly}>
		<SheetContentProvider>
			<SheetDetailInner/>
		</SheetContentProvider>
	</SheetModeContextProvider>
);

export default SheetDetail;
