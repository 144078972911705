import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./list-content-template.scss";

/**
 * ListContentTemplate component
 * @param {Props} props
 * @param {JSX.Element} props.children
 * @param {string[]} props.headers
 * @param {string} props.className
 * @param {string} props.headerKeyPrefix
 * @returns {JSX.Element}
 */
const ListContentTemplate = (props) => {
	const { headers, headerKeyPrefix, className = "", children } = props;
	const { t } = useTranslation();

	const renderHeader = (headerKey) => <span
		key={headerKey}
		className="list__header__item">
		{t(`${headerKeyPrefix}${headerKey}`)}
	</span>;

	return <ul className={classNames("list", className)}>
		<li className="list__header">
			{headers.map(renderHeader)}
		</li>
		{children}
	</ul>;
};

ListContentTemplate.propTypes = {
	headers: PropTypes.arrayOf(PropTypes.string).isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string.isRequired,
	headerKeyPrefix: PropTypes.string.isRequired
};


export default ListContentTemplate;
