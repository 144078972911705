import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { IconWrapper } from "../../../../../../../../theme";
import { resolveSheetColor, SheetImageLoader, SheetType } from "../../../../../../index";
import { fetchSheetByBinder } from "../../../../../../sheet.services";
import { SheetModeContext } from "../../../../../../context/sheet-mode-context";
import { buildSprProcessSheetUri, createSheetLink, SheetLinkWrapper } from "../../../../../../../../shared";
import { IconArrowRight } from "../../../../../../../../theme/assets/img";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import "./multiple-link-train-template-view.scss";

/**
 * MultipleLinkTrainTemplateView
 * @param {object} props
 * @param {{content: {items: object[]}}} props.template
 * @param {number} props.currentPosition
 * @param {object} props.processCurrentTemplate
 * @param {object} props.location
 * @returns {JSX.Element}
 */
const MultipleLinkTrainTemplateView = (props) => {
	const { template, currentPosition, processCurrentTemplate, location } = props;
	const [ binderSheets, setBinderSheets ] = useState();
	const params = useParams();
	const { pathname } = useLocation();
	const { viewType } = useContext(SheetModeContext);
	const { binderId } = params;
	const isLink = pathname.startsWith("/spr/process") && viewType === "train";
	const redirectBlocks = template?.content?.items;
	const hasImage = template?.content?.hasImage;

	// Get sheet from same binder for autocompletion
	const initBinderSheets = () => {
		const param = {};
		fetchSheetByBinder(binderId, param)
			.then((response) => {
				if ( response?.data ) {
					setBinderSheets(response?.data);
				}
			});
	};

	const createMultiLinkSheetUri =  (content) => {
		const { processId } = params;
		const { sheetNumber } = content;
		return buildSprProcessSheetUri(processId, binderId, sheetNumber);
	};

	const generateRedirectBlock = (redirectBlock, index) => {
		const binderSheet = binderSheets?.find(sheet => sheet.number_search === redirectBlock.sheetNumber);
		const sheetColor = resolveSheetColor(binderSheet?.color, binderSheet?.binder_color);
		const uri = isLink ? createMultiLinkSheetUri(redirectBlock) : "#";
		const { sheetNumber, actionPosition: jumpToActionPosition = null } = redirectBlock;
		const sheetLink = createSheetLink(uri, binderSheet?.sheet_id, sheetNumber, binderSheet?.binder_tech_id ,binderSheet?.color, jumpToActionPosition, binderSheet?.type, binderSheet?.title);

		return (
			<SheetLinkWrapper key={redirectBlock.id} isLink={isLink} sheetLink={sheetLink} >
				{!!redirectBlock.category &&
				<div className="item__category">{redirectBlock.category}</div>
				}
				<div className="item" key={index}>
					<div className="item__description" style={{ "--description-color": sheetColor }}>
						<span>{redirectBlock.text}</span>
						{hasImage &&
						<SheetImageLoader className="image-list-view__item__image" imageId={redirectBlock.image?.imageId} tag="light"/>
						}
					</div>
					<IconWrapper className="item__arrow" Component={IconArrowRight}/>
					<SheetType
						className="item__sheet-number"
						type={binderSheet?.type || "parent"}
						sheetNumber={redirectBlock.sheetNumber}
						color={sheetColor}
						binderType={"train-auto"}
					/>
				</div>
			</SheetLinkWrapper>
		);
	};

	useEffect(initBinderSheets, [ binderId ]);

	return (
		<TemplateWrapper
			id={template.id}
			location={location}
			className={classnames("multiple-link-train-template-view", { "multiple-link-train-image-template-view": hasImage })}
			template={template}
			currentPosition={currentPosition}
			processCurrentTemplate={processCurrentTemplate}>
			{Array.isArray(redirectBlocks) && redirectBlocks.length > 0 && redirectBlocks.map(generateRedirectBlock)}
		</TemplateWrapper>
	);
};

MultipleLinkTrainTemplateView.prototype = {
	template: PropTypes.shape({
		content: PropTypes.shape({
			items: PropTypes.arrayOf(PropTypes.object)
		})
	}),
	currentPosition: PropTypes.number,
	processCurrentTemplate: PropTypes.object,
	location: PropTypes.object
};

export default MultipleLinkTrainTemplateView;
