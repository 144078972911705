import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, SearchBar } from "../../../../../../../../../theme";
import { IconPlus } from "../../../../../../../assets";
import BinderSheetListContent from "../../binder-sheet-list-content/binder-sheet-list-content";

import "./binder-default-sheet-list.scss";

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 */

/**
 * Render the a sheet list on a sheet or train binder
 * @param {object} props, 
 * @param {function} props.sheetSearch, 
 * @param {function} props.onSearch,
 * @param {function} props.retrieveSheets, 
 * @param {object} props.analysisResult, 
 * @param {PopupControl} props.createSheetPopupControl, 
 * @param {boolean} props.loading, 
 * @param {object} props.binder, 
 * @param {object[]} props.sheets,
 * @returns {JSX.Element}
 */
const BinderDefaultSheetList = props => {
	const { 
		sheetSearch, 
		onSearch,
		retrieveSheets, 
		analysisResult, 
		createSheetPopupControl, 
		loading, 
		binder, 
		sheets,
	} = props;

	const { t } = useTranslation();

	const isSheetListEmpty = sheets.length === 0;

	return <>
		<div className="binder-sheet-list__head">
			<h3 className="binder-sheet-list__title">{t("binder:binder-detail.sheet-list.title")}</h3>
			{binder.status !== "published" && binder.status !== "revoke" && (
				<span className="binder-sheet-list__iteration">
					{t("binder:binder-detail.sheet-list.iteration", { number: binder.iteration })}
				</span>
			)}
			{
				!isSheetListEmpty && <>
					<SearchBar 
						className="binder-sheet-list__search" 
						value={sheetSearch} 
						onChange={onSearch}
						placeholder={t("binder:binder-detail.sheet-list.search-bar-placeholder")}
					/>
					<Button
						variant="primary"
						disabled={binder?.status !== "work-in-progress"} 
						className="binder-sheet-list__create-cta" 
						onClick={createSheetPopupControl.show}
						icon={IconPlus}
						label={t("binder:binder-detail.sheet-list.create-cta-label")}
					/>
				</>
			}
		</div>
		<BinderSheetListContent
			sheets={sheets}
			refreshSheetList={retrieveSheets}
			binder={binder}
			defaultColor={binder?.color}
			analysisResult={analysisResult}
			showCreateSheetPopup={createSheetPopupControl.show}
			loading={loading} />
	</>;
};

BinderDefaultSheetList.propTypes = {
	sheets: PropTypes.arrayOf(PropTypes.shape({
		number: PropTypes.string.isRequired,
		sheet_id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired,
		title: PropTypes.object.isRequired,
		analysis_result: PropTypes.string,
		status: PropTypes.string.isRequired,
		last_change_content_at: PropTypes.string.isRequired,
		editor: PropTypes.string,
		editor_firstname: PropTypes.string,
		editor_lastname: PropTypes.string,
		editor_id: PropTypes.string
	})),
	analysisResult: PropTypes.shape({
		sheetsWarning: PropTypes.arrayOf(PropTypes.string)
	}),
	binder: PropTypes.shape({
		status: PropTypes.string.isRequired,
		tech_id: PropTypes.string.isRequired
	}).isRequired,
	loading: PropTypes.bool,
	sheetSearch: PropTypes.string.isRequired,
	onSearch: PropTypes.func.isRequired,
	retrieveSheets: PropTypes.func.isRequired,
	createSheetPopupControl: PropTypes.shape({
		show: PropTypes.func
	}).isRequired
};

export default BinderDefaultSheetList;
