import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { buildSprProcessUri, LoadWrapper } from "../../../../shared";
import { IconArrowLeft } from "../../assets";
import { IconWrapper, SprPage } from "../../../../theme";
import { getProcessByTechId } from "../../process.services";
import classnames from "classnames";
import TrainTroubleFormStepper from "./shared/train-trouble-form-stepper/train-trouble-form-stepper";

import "./process-rer-resume-page.scss";

const ProcessRerResumePage = () => {
	const { processId } = useParams();
	const [ processInfo, setProcessInfo ] = useState(null);
	const { line: processLine, resume_conditions: resumeConditions } = processInfo || {};
	const [ isInfoLoading, setIsInfoLoading ] = useState(true);
	const [ stepNumber, setStepNumber ] = useState(0);
	const [ damageFormData, setDamageFormData ] = useState({
		step_completed: false,
		emergency_lights: "",
		train_composition: "",
		direction: "",
		equipment_involved: [],
		mission_code: ""
	});

	const { t } = useTranslation();

	const returnLink = buildSprProcessUri({ processId },"railway");

	const loadProcess = useCallback(async (processTechId) => {
		try {
			if (processInfo === null) {
				setIsInfoLoading(true);
			}
			if (processTechId){
				const { data: processFound } = await getProcessByTechId(processTechId, { extendTrain: true });
				setProcessInfo(processFound);
				const damageLocationFound = processFound?.resume_conditions?.damage_location || {};
				if (Object.keys(damageLocationFound).length > 0) {
					setDamageFormData(processFound?.resume_conditions?.damage_location);
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsInfoLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	const handleChangeDamageFormData = (event) => {
		const { name, value } = event.target;
		setDamageFormData(oldDamageFormData => {
			const newFormData = { ...oldDamageFormData };
			return { ...newFormData, [name]: value };
		});
	};

	const handleStepOnChange = (event) => {
		event.preventDefault();
		const { value } = event.target;
		handleChangeDamageFormData(event);
		setStepNumber(value === "no" ? "1" : "3");
	};

	useEffect(() => loadProcess(processId), [ processId, loadProcess ]);

	useEffect(() => {
		const isEmergencyLightsUncompleted = !resumeConditions?.damage_location?.emergency_lights && processLine === "B";
		const step = isEmergencyLightsUncompleted ? 0 : 1;
		setStepNumber(step);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ processLine ]);

	const SubHeader = () => {
		let title;

		if (stepNumber === 0 || stepNumber === 1) {
			title = "process:process-rer-resume.damage-location.title";
		} else if (stepNumber === 2) {
			title = "process:process-rer-resume.results-sheet.title";
		} else if (stepNumber === 3) {
			title = "process:process-rer-resume.resume-rer.title";
		}

		return (
			<>
				<div className="process-rer-resume__header__top">
					<span className="process-rer-resume__title">{t(title)}</span>
				</div>
				<div className="process-rer-resume__header__bottom">
					<Link to={returnLink} className="back-to-current-step">
						<IconWrapper className="back-to-current-step__icon" Component={IconArrowLeft}/>
						<span className="back-to-current-step__text">{t("process:spr-run-process.come-back-list-sheet")}</span>
					</Link>
				</div>
			</>
		);
	};

	const renderEmergencyLights = () => {
		const emergencyLightsObject = {
			name: "emergency_lights",
			key: "emergency-lights",
			type: "radio",
			values: [ "yes", "no" ]
		};
		const { name, key, type, values: options } = emergencyLightsObject;

		return (
			<div className="process-rer-resume__steps__radio-button">
				<span className="process-rer-resume__steps__radio-button__label">{t(`process:process-rer-resume.damage-location.${key}`)}</span>
				<ul className="process-rer-resume__steps__radio-button__list">
					{options.map((option)=> {
						return <li className="process-rer-resume__steps__radio-button__list__item">
							<input
								className="input-field__radio-button"
								name={name}
								checked={damageFormData[name] === option}
								type={type}
								value={option}
								onChange={handleStepOnChange}
							/>
							<label htmlFor={option} className="process-rer-resume__steps__radio-button__list__item__label">
								{t(`process:process-rer-resume.damage-location.emergency-lights-form.${option}`)}
							</label>
						</li>;
					})}
				</ul>
			</div>
		);
	};

	const renderStepper = () => {
		return (
			<div className={classnames("process-process-rer-resume__steps__wrapper", { "process-process-rer-resume__steps__wrapper--padding": stepNumber === 0 })}>
				{stepNumber === 0 && renderEmergencyLights()}
				{stepNumber !== 0 && <TrainTroubleFormStepper loadProcess={loadProcess} damageFormData={damageFormData} handleChange={handleChangeDamageFormData} line={processLine} setDamageFormData={setDamageFormData} processInfo={processInfo} setStepNumber={setStepNumber} stepNumber={stepNumber}/>}
			</div>
		);
	};

	return (
		<LoadWrapper className="spr-run-process__loader" loading={isInfoLoading}>
			<SprPage
				className={"spr-run-process--process-rer-resume"}
				subheader={SubHeader()}
			>
				<div className="process-process-rer-resume__steps">
					{renderStepper()}
				</div>
			</SprPage>
		</LoadWrapper>
	);
};

export default ProcessRerResumePage;
