import { differenceInMilliseconds, isBefore } from "date-fns";

const convertToDate = (date) => typeof date === "string" ? new Date(date) : date;

/**
 * Sort process by the last date save in the last history saved
 * @param processList
 * @returns {Array|*}
 */
const orderByHistoryLastUpdated = (processList) => {
	if (!Array.isArray(processList)) {
		return [];
	}
	return processList.sort((a, b) => {
		const historyNotEmptyA = Array.isArray(a.history) && a.history.length > 0;
		const historyNotEmptyB = Array.isArray(b.history) && b.history.length > 0;
		const [ lastHistoryItemA ] = a.history.slice(-1);
		const [ lastHistoryItemB ] = b.history.slice(-1);
		if (historyNotEmptyA && historyNotEmptyB) {
			return isBefore(new Date(lastHistoryItemA.finishedAt), new Date(lastHistoryItemB.finishedAt)) ? 1 : -1;
		}
		return 0;
	});
};

/**
 * get difference in milliseconds between two dates
 * @param fieldName
 * @return {function(*, *): number}
 */
const compareByDate = (fieldName) => (process1, process2) => {
	const creationDate1 = convertToDate(process1?.[fieldName]);
	const creationDate2 = convertToDate(process2?.[fieldName]);

	return differenceInMilliseconds(creationDate2, creationDate1);
};

/**
 * get all owners from the process history to generate the owner_history field on create/update process offline
 * @param {object[]} history
 */
const getOwnersFromProcessHistory = (history) => {
	// retrieve all owners from history process
	if (!Array.isArray(history)) {
		return [];
	}
	const processOwnerIds = history.map((task) => task.finishedBy);
	// remove duplicates
	return [ ...new Set(processOwnerIds) ];
};

export { orderByHistoryLastUpdated, compareByDate, getOwnersFromProcessHistory };
