import React from "react";
import classNames from "classnames";
import { DigiprocLoader } from "../../../theme";
import "./load-wrapper.scss";

/**
 * @param {object} props
 * @param {string} props.className
 * @param {boolean} props.loading
 * @returns {JSX.Element}
 */
const LoadWrapper = props => {
	const { className, loading = false, children = false } = props;

	if (loading) {
		return (
			<div className={classNames("load-wrapper", className)}>
				<DigiprocLoader className="load-wrapper__loader"/>
			</div>
		);
	}

	return children;
};

export default LoadWrapper;
