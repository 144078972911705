import React from "react";
import { Trans } from "react-i18next";
import classNames from "classnames";
import { IconWrapper } from "../../../../../../theme";
import { IconAdd, IconSubtract } from "../../../../assets";
import { getElementNumberMax, getElementType } from "../../utils/get-car-element-info";

import "./train-trouble-form-number-selector.scss";

const TrainTroubleFormNumberSelector = (props) => {
	const { title = "", label = "", value, fieldName , handleChange, currentGroup = "", trainComposition, material, emdWarningMessage = "" } = props;

	const elementType = getElementType(fieldName);
	const numberMax = getElementNumberMax(elementType, trainComposition, material);

	const disableIncrement = value === numberMax;
	const disableDecrement = value === 0 || !value;

	const displayEmdWarningMessage = fieldName === "isolated_emd_units" && emdWarningMessage;
	const displayDetails = fieldName !== "rescued_bogies_units";

	const handleClick = (changeValue) => {
		if (currentGroup) {
			handleChange({ target: { group: currentGroup, name: fieldName, value: ((value || 0) + changeValue) } });
		} else {
			handleChange({ target: { name: fieldName, value: ((value || 0) + changeValue) } });
		}
	};

	return (
		<div className="train-trouble-form-number-selector">
			<>
				{title && <div className="train-trouble-form-number-selector__title">
					{title}
				</div>}
				<div className="train-trouble-form-number-selector__wrapper">
					<span className="train-trouble-form-number-selector__label">
						{label}
					</span>
					<div className="train-trouble-form-number-selector__value">
						<IconWrapper
							className={classNames("train-trouble-form-number-selector__icon", { "train-trouble-form-number-selector__icon--disabled": disableDecrement })}
							Component={IconSubtract}
							onClick={() => handleClick(-1)}
						/>
						<span className="train-trouble-form-number-selector__number">{value || 0}</span>
						<IconWrapper
							className={classNames("train-trouble-form-number-selector__icon", { "train-trouble-form-number-selector__icon--disabled": disableIncrement })}
							Component={IconAdd}
							onClick={() => handleClick(1)}
						/>
					</div>
					{displayDetails && <Trans
						parent="span"
						className="train-trouble-form-number-selector__label"
						i18nKey="process:process-rer-resume.results-sheet.operator"
						values={{
							name: elementType,
							number: numberMax
						}}>
						<strong className="train-trouble-form-number-selector__label--bold"/>
					</Trans>}
				</div>
			</>
			{displayEmdWarningMessage && <span className="train-trouble-form-number-selector__warning">{emdWarningMessage}</span>}
		</div>
	);
};

export default TrainTroubleFormNumberSelector;
