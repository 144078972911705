import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./progess-bar.scss";

/**
 * Render a progress bar
 * @param {{
 *     ratio: number,
 *     className: string
 * }}props
 * @return {JSX.Element}
 * @constructor
 */
const ProgressBar = (props) => {
	const { ratio, className = "" } = props;

	return (
		<span
			className={classnames("progress-bar", className)}
			style={{ "--progress": `${Math.min(ratio, 1) * 100}%` }} />
	);
};

ProgressBar.propTypes = {
	ratio: PropTypes.number.isRequired,
	className: PropTypes.string
};

export default ProgressBar;
