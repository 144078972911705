import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRunProcessContext } from "../../components/context-run-process/context-run-process";
import {
	useSprContext,
	useAppSetupContext,
	LoadWrapper,
	buildTrainProcessBinderList,
	useInReadContextCheck,
	useRailwayOnlineReturn
} from "../../../../shared";
import { Button, SprPage, EditorRendering } from "../../../../theme";
import { resolveSheetColor } from "../../../sheet";
import { getLastReadSheetLink } from "../../../../shared/utils/sheet-read-utils";
import TaskLoader from "./components/task-loader/task-loader";
import RunProcessHistory from "./components/run-process-history/run-process-history";
import SprRunProcessSubheader from "./components/spr-run-process-subheader/spr-run-process-subheader";
import ProcessTrainBreadcrumbSubheader from "../../components/process-train-breadcrumb-subheader/process-train-breadcrumb-subheader";
import ProcessTrainSubheader from "../../components/process-train-subheader/process-train-subheader";
import SheetType from "../../../sheet/components/sheet-type/sheet-type";
import PopupConfirmSheetRedirect from "./components/popup-confirm-sheet-redirect/popup-confirm-sheet-redirect";
import "./spr-run-process.scss";

const sidePrefix = {
	train: "/railway",
	pcc: "/spr"
};

/**
 * spr run process component
 * @returns {JSX.Element}
 */
const SprRunProcess = ({ disableLineSwitch }) => {
	useRailwayOnlineReturn(false);
	const [ showActionButtons, setShowActionButtons ] = useState(false);
	const { isInReadContext } = useInReadContextCheck();
	const {
		currentSheetData, getActiveTask, processTechId, processInfo, processHist, createNewProcess, processStarted,
		processLoading, queryParam: processQueryParam, popupControlSheetRedirect
	} = useRunProcessContext();
	const { setCurrentBinder, currentBinder, associatedLine, side } = useSprContext();
	const { user: currentUser } = useAppSetupContext();
	const activeTask = getActiveTask();
	const { t } = useTranslation();

	const isCurrentUserViewerOnly = currentUser?.tech_id && processInfo?.owner && currentUser?.tech_id !== processInfo?.owner;

	const syncCurrentBinder = () => {
		setCurrentBinder({ color: currentSheetData.binder_color, abbreviation: currentSheetData.binder_abbreviation, type: currentSheetData.binder_type,
			materialLabel: processInfo?.material_label }, true);
	};

	const isEndOfProcess = Array.isArray(processHist) && [ ...processHist ].reverse()[0]?.type === "termination";
	const showStartButton = typeof processStarted === "boolean" && (!processStarted || processHist?.length === 0);


	const isCanceledTask = processHist?.some(task => task?.canceled);

	// Set returned modifier only if viewer modifier is not already set
	const addStatusClassModifier = (baseClassName) => classNames(baseClassName, {
		[`${baseClassName}--viewer`]: !isInReadContext && isCurrentUserViewerOnly,
		[`${baseClassName}--read`]: isInReadContext,
		[`${baseClassName}--returned`]: isCanceledTask && !isCurrentUserViewerOnly
	});

	const handleCreateProcess = () => {
		const { sheetNumber, binderId, binderTechnicalId, trainTechId, processId } = processQueryParam;
		createNewProcess(
			sheetNumber,
			binderId,
			binderTechnicalId,
			{
				trainTechId,
				line: associatedLine,
				processId,
				started_at: true
			}
		);
	};

	useEffect(syncCurrentBinder, [ currentSheetData, setCurrentBinder, processInfo ]);

	// Return to last visited sheet else to sheet list
	const subHeaderReturnLink = getLastReadSheetLink() ||  buildTrainProcessBinderList({ processId: processQueryParam.processId }, "spr", true);
	const SubHeader = isInReadContext ? (
		<>
			<ProcessTrainSubheader hideButton="direct"/>
			<ProcessTrainBreadcrumbSubheader
				showOverlay={false}
				processExecutorLabel={t("process:process-train-breadcrumb-subheader.executor-live")}
				returnLink={subHeaderReturnLink}
				returnLabel={t("process:process-train-breadcrumb-subheader.return-binder")}
			/>
		</>
	) : <SprRunProcessSubheader showActionButtons={showActionButtons} setShowActionButtons={setShowActionButtons}/>;

	const getBorderOverlayTypes = () => {
		const types = [];
		if (isCanceledTask) {
			types.push("canceled");
		}
		if (isCurrentUserViewerOnly || isInReadContext) {
			types.push("read");
		}
		return types;
	};

	return (
		<LoadWrapper className="spr-run-process__loader" loading={processLoading}>
			<SprPage
				className={classNames("spr-run-process",{
					"spr-run-process--overlay": showActionButtons
				})}
				subheader={SubHeader}
				borderOverlayType={getBorderOverlayTypes()}
				allowLineSwitch={side === "train"}
				disabledLineSwitch={disableLineSwitch}
			>
				{side === "train" && popupControlSheetRedirect.visible && <PopupConfirmSheetRedirect popupControl={popupControlSheetRedirect} />}
				<div className="spr-run-process__scroll-wrapper">
					<div className={addStatusClassModifier("spr-run-process__wrapper")}>
						{processStarted && processHist?.length > 0 &&
						<ul className="spr-run-process__list">
							<RunProcessHistory/>
							{activeTask && <TaskLoader task={activeTask}/>}
						</ul>
						}
						{showStartButton &&
						<div className="spr-run-process__sheet-detail">
							<div className="spr-run-process__sheet-detail__sheet-number">
								<SheetType
									className="head__sheet-type"
									binderType={currentBinder?.type}
									type={currentSheetData?.type}
									sheetNumber={currentSheetData?.number_search}
									color={resolveSheetColor(currentSheetData?.color, currentSheetData?.binder_color, currentSheetData?.theme_color)}
								/>
							</div>
							<div className="spr-run-process__sheet-information">
								{/* TODO refacto editor rendering -> no data parsing */}
								<EditorRendering
									content={currentSheetData?.title}
									className="spr-run-process__sheet-information__title" noStyle
								/>
								<Button
									variant="primary"
									className="spr-run-process__sheet-information__submit"
									onClick={handleCreateProcess}
									label={t("process:spr-run-process.start-process")}
								/>
							</div>
						</div>
						}
						<div className="spr-run-process__cta">
							{isEndOfProcess && (
								<Link to={`${sidePrefix[side]}/process/${processTechId}`}>
									<Button
										variant="primary"
										label={t("process:spr-run-process.cta-report")}
									/>
								</Link>
							)}
						</div>
					</div>
				</div>
			</SprPage>
		</LoadWrapper>
	);
};

const SprRunProcessWrapper = (props) => <SprRunProcess {...props}/>;

export default SprRunProcessWrapper;
