
import React from "react";
import PropTypes from "prop-types";
import { Button as BtnIcon } from "../../../theme";
import { IconBack } from "../../../theme/assets/img";

import "./subheader-return.scss";

/**
 * subheader return
 * @param {object} props
 * @param {string} props.onClick
 * @param {string} props.label
 * @return {JSX.Element}
 * @constructor
 */
const SubheaderReturn = (props) => {
	const { onClick, label } = props;

	return (<span className="subheader-return" onClick={onClick} >
		<BtnIcon
			variant="primary"
			small
			icon={IconBack}
		/>
		 <span className="subheader-return__text">{label}</span>
	</span>);
};

SubheaderReturn.propTypes = {
	onClick: PropTypes.func,
	label: PropTypes.string,
};

export default SubheaderReturn;
