import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { useDndSheetReference, useDndHandleMove } from "../../../../../../../../shared";
import { IconWrapper, EditorRendering } from "../../../../../../../../theme";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import TerminationTemplateView from "../termination-template-view/termination-template-view";
import NewTemplateView from "../new-template-view/new-template-view";
import NextTemplateView from "../next-template-view/next-template-view";
import TextTemplateView from "../text-template-view/text-template-view";
import MultipleLinkTemplateView from "../multiple-link-template-view/multiple-link-template-view";
import ResumeTemplateView from "../resume-template-view/resume-template-view";
import SimpleLinkTemplateView from "../simple-link-template-view/simple-link-template-view";
import ImageListView from "../../../../../../../../theme/components/image-list-view/image-list-view";
import SpaceTemplateView from "../space-template-view/space-template-view";
import PccInstructionTemplateView from "../pcc-instruction-template-view/pcc-instruction-template-view";
import OfficerInterventionTemplateView from "../officer-intervention-template-view/officer-intervention-template-view";
import MRFInterventionTemplateView from "../mrf-intervention-template-view/mrf-intervention-template-view";
import { createTemplate } from "../../../../../../context/sheet-content-context-utils";
import { useSheetModeContext } from "../../../../../../context/sheet-mode-context";
import { useSheetContentContext } from "../../../../../../context/sheet-content-context";
import { IconAdd } from "../../../../../../../../theme/assets/img";

import "./question-template-view.scss";

const QuestionTemplateView = (props) => {
	const {
		template, currentPosition, allowInsertAfter, moveCard, processCurrentTemplate, location, isTrainBinder } = props;
	const { note = "", text = "", images = [] } = template?.content || {};
	const { t } = useTranslation();

	const { setCurrentNodeContent, setActiveLocation, activeLocation, getSubTemplateTypePosition } = useSheetContentContext();
	const { readOnly, viewType } = useSheetModeContext();

	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref);

	const [ questionTemplate, setQuestionTemplate ] = useState(template);
	const [ allowInsert, setAllowInsert ] = useState(allowInsertAfter);

	// setter for subNodes on question section for dnd hook typeSubNode = "yes" || "no"
	const setSubNodes = (newSubNodes, typeSubNode) => {
		questionTemplate.content[typeSubNode].items = newSubNodes;
		if (activeLocation?.parentId) {
			setActiveLocation({ id: activeLocation?.parentId });
		}
		setCurrentNodeContent(questionTemplate.content, true);
	};

	// dnd handle move sub card
	const { moveCard: moveYesSubCard } = useDndHandleMove(
		questionTemplate?.content?.yes?.items,
		(resultSubNodes) => setSubNodes(resultSubNodes, "yes")
	);
	const { moveCard: moveNoSubCard } = useDndHandleMove(
		questionTemplate?.content?.no?.items,
		(resultSubNodes) => setSubNodes(resultSubNodes, "no")
	);

	const components = {
		new: NewTemplateView,
		next: NextTemplateView,
		note: TextTemplateView,
		systemEffect: TextTemplateView,
		action: { template: TextTemplateView, icon: "action", hasBullet: isTrainBinder },
		call: { template: TextTemplateView, icon: "call" },
		pccInstruction: PccInstructionTemplateView,
		officerIntervention: OfficerInterventionTemplateView,
		mrfIntervention: MRFInterventionTemplateView,
		resume: ResumeTemplateView,
		termination: TerminationTemplateView,
		multipleLink: MultipleLinkTemplateView,
		simpleLink: SimpleLinkTemplateView,
		space: SpaceTemplateView
	};

	const loadTemplateComponent = (templateToLoad, index, subNodeType, isEndItem = false) => {
		const ComponentConfig = components[templateToLoad.type];
		let ComponentName = ComponentConfig;
		let additionalConfig = {};

		const moveSubCard = subNodeType === "yes" ? moveYesSubCard : moveNoSubCard;

		// in case the template selected has additional configuration like icon or input type
		if (ComponentConfig && ComponentConfig.template) {
			ComponentName = ComponentConfig.template;
			additionalConfig = { icon: ComponentConfig.icon };
		}

		if (ComponentConfig && ComponentConfig.hasBullet){
			const bulletPosition = getSubTemplateTypePosition(templateToLoad);
			if (Number.isInteger(bulletPosition)){
				additionalConfig.bulletPosition = bulletPosition;
			}
		}

		if (!!viewType && templateToLoad?.type === "space") {
			return null;
		}

		return (
			<div key={templateToLoad.id}>
				{React.createElement(ComponentName,
					{
						key: templateToLoad.id,
						template: templateToLoad,
						location: { id: templateToLoad.id, parentId: template.id, questionSide: subNodeType, questionEnd: isEndItem },
						currentPosition: index,
						allowInsertAfter: false,
						processCurrentTemplate,
						isTrainBinder,
						moveCard: moveSubCard,
						...additionalConfig
					}
				)}
			</div>
		);
	};

	// column : column name "yes" or "no"
	const addNewSubTemplate = (event, column) => {
		event.stopPropagation();
		const newTemplate = createTemplate();
		const newQuestionTemplate = { ...questionTemplate };
		const templateList = newQuestionTemplate.content[column] && newQuestionTemplate.content[column].items;
		if (Array.isArray(templateList)){
			templateList.push(newTemplate);
		} else {
			newQuestionTemplate.content[column].items = [ newTemplate ];
		}

		setActiveLocation({ id: newTemplate.id, parentId: template.id, questionSide: column, questionEnd: false });
	};

	const classNameReadOnly = useMemo(() => classnames(
		"question-template-view__text",
		{ "question-template-view__text--read-only": readOnly }
	), [ readOnly ]);

	const className = useMemo(() => classnames(
		"question-template-view",
		{ "question-template-view--train": isTrainBinder }
	), [ isTrainBinder ]);


	const canInsertAfter = () => {
		const { content } = template || {};
		if (content && (content?.yes || content?.no)) {
			const { yes = {}, no = {} } = template?.content || {};
			setAllowInsert(yes["end-item"]?.type === "next" || no["end-item"]?.type === "next");
		}
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(canInsertAfter, [ template?.content ]);

	const reloadView = () => {
		setQuestionTemplate(template);
	};

	useEffect(reloadView, [ text, note, template ]);

	return (
		<TemplateWrapper
			id={template.id}
			className={className}
			template={template}
			location={location}
			currentPosition={currentPosition}
			reference={allowInsert ? ref : null}
			isDragging={isDragging}
			dragRef={drag}
			allowInsertAfter={allowInsert}
			processCurrentTemplate={processCurrentTemplate}>
			<div className="question-template-view__header">
				<div className={classNameReadOnly}>
					<EditorRendering className="question-template-view__text-content" content={text}/>
				</div>
				{(note?.length > 0 || images.length > 0) && (
					<div className="question-template-view__note">
						{note?.length > 0 && <EditorRendering className="question-template-view__note__text" content={note}/>}
						{images.length > 0 && <ImageListView className="text-question-view__images" images={images}/>}
					</div>
				)}
			</div>
			<div className="question-template-view__answer">
				<div className="answer__column">
					<div className="answer__column__title">
						{t("sheet:sheet-details.template.question.yes")}
					</div>
					<div className="answer__column__content">
						<div className="content__items">
							{questionTemplate?.content?.yes?.items?.map((subTemplate, index) =>
								loadTemplateComponent(subTemplate, index, "yes", false))
							}
							{!readOnly &&
							<div className="content__add-button">
								<button type="button" onClick={(event) => addNewSubTemplate(event, "yes")}>
									<IconWrapper className="content__add-button__icon" Component={IconAdd} tooltip={t("sheet:sheet-details.template.add-tooltip")}/>
								</button>
							</div>
							}
						</div>
						<div className="content__last-item">
							{
								loadTemplateComponent(questionTemplate?.content?.yes?.["end-item"]  || { type: "next", id: 1 }, -1, "yes", true)
							}
						</div>
					</div>
				</div>
				<div className="answer__column">
					<div className="answer__column__title">
						{t("sheet:sheet-details.template.question.no")}
					</div>
					<div className="answer__column__content">
						<div className="content__items">
							{questionTemplate?.content?.no?.items?.map((subTemplate, index) => loadTemplateComponent(subTemplate, index, "no", false))}
							{!readOnly &&
							<div className="content__add-button">
								<button type="button" onClick={(event) => addNewSubTemplate(event,"no")}>
									<IconWrapper className="content__add-button__icon" Component={IconAdd}

									             tooltip={t("sheet:sheet-details.template.add-tooltip")}/>
								</button>
							</div>
							}
						</div>
						<div className="content__last-item">
							{
								loadTemplateComponent(questionTemplate?.content?.no?.["end-item"] || { type: "next", id: 2 }, -1, "no", true)
							}
						</div>
					</div>
				</div>
			</div>
		</TemplateWrapper>
	);
};

export default QuestionTemplateView;
