import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { formatJsonDate } from "../../../../../../shared";
import { EditorRendering, IconWrapper } from "../../../../../../theme";
import { useHistoryProcessContext } from "../../../../pages/process-history-view/components/context-history-process/context-history-process";
import { dateFormatTime } from "../../../../utils";
import { IconCheckboxUnchecked, IconCheckboxChecked } from "../../../../../../theme/assets/img";
import ImageListView from "../../../../../../theme/components/image-list-view/image-list-view";
import addBulletPointToText from "../../../../pages/spr-run-process/utils/add-bullet-point-to-text";
import getActionBullet from "../../../../../sheet/components/sheet-preview/components/template/template-view/text-template-view/utils/get-action-bullet";

import "./history-action-task-view.scss";
/**
 * Action task render on history process page
 * @param {{
 *		className: string,
 *		task: {content: object, infoAction: object, optionalInfos: object},
 *		passed: boolean,
 *		isTrainBinder: boolean
 *	}} props
 * @returns {JSX.Element}
 */
const HistoryActionTaskView = (props) => {
	const { className, task = {}, passed, isTrainBinder } = props;
	const { content, infoAction = {}, optionalInfos } = task;
	const { notApplicable, position = "" } = infoAction;
	const { handleHistoryActionCheckbox, isOwner, isFinish } = useHistoryProcessContext();
	const { t } = useTranslation();

	// checkbox is clickable on history page
	const handleCheckboxChange = () => {
		if (handleHistoryActionCheckbox) {
			const newTask = { ...task };
			const olfInfoAction = task.infoAction || {};
			newTask.infoAction = { ...olfInfoAction, notApplicable: !notApplicable, lastUpdate: new Date() };
			handleHistoryActionCheckbox(newTask);
		}
	};

	const renderIcon = () => {
		const icon = notApplicable ? IconCheckboxUnchecked : IconCheckboxChecked;
		const modifier = { "history-action-task-view__icon--pointer": (isOwner && !isFinish) };
		const classname = classnames("history-action-task-view__icon", modifier);
		return <IconWrapper onClick={handleCheckboxChange} className={classname} Component={icon}/>;
	};

	// multipleChoice subheader info
	const renderMultipleChoiceStep = (info) => {
		if (passed && info?.parentType === "multipleChoice" && info?.choiceTitle) {
			// change color on odd index | default color on even index

			const modifier = { "history-text--view__subheader--odd": optionalInfos?.index % 2 === 1 };
			const classname = classnames("history-text--view__subheader", modifier);
			return (
				<div className={classname}>
					{optionalInfos?.choiceTitle}
				</div>
			);
		} else {
			return false;
		}
	};

	const initClassName = () => {
		const modifier = { "history-action-task-view--passed": passed };
		return classnames("history-action-task-view", modifier, className);
	};

	const editorContent = isTrainBinder ? addBulletPointToText(content?.text, getActionBullet(task?.isSubNode, position, "-")) : content?.text;

	return (
		<div className={initClassName()}>
			{renderMultipleChoiceStep(optionalInfos)}
			<div className={"history-action-task-view__text"}>
				<div className="history-action-task-view__text__wrapper">
					{renderIcon()}
					<EditorRendering
						content={editorContent}
						className={"history-action-task-view__text__content"}
					/>
					{notApplicable && <span className="history-action-task-view__text__not-applicable">
						{`- ${t("process:history-view.action.not-applicable")}`}
					</span>}
				</div>
				{notApplicable && <span className="history-action-task-view__text__not-applicable--mobile">
					{`- ${t("process:history-view.action.not-applicable")}`}
				</span>}
				{infoAction?.lastUpdate &&
				<div className="history-action-task-view__update">
					{t("process:history-view.action.updated-at", { date: formatJsonDate(infoAction.lastUpdate, dateFormatTime) })}
				</div>
				}
				{content?.images?.length > 0 &&
				<ImageListView className="history-action-task-view__images" images={content?.images}/>}
			</div>
		</div>
	);
};

HistoryActionTaskView.propTypes = {
	task: PropTypes.shape({
		optionalInfos: PropTypes.shape({
			parentType: PropTypes.string,
			choiceTitles: PropTypes.string,
			index: PropTypes.number
		}),
		content: PropTypes.shape({
			text: PropTypes.string,
			images: PropTypes.array
		}),
		infoAction: PropTypes.shape({
			notApplicable: PropTypes.bool,
			lastUpdated: PropTypes.string,
			position: PropTypes.number
		})
	}),
	className: PropTypes.string,
	passed: PropTypes.bool
};

export default HistoryActionTaskView;
