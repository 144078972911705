const trainCompositionVoidLineB = [
	{
		element1: [
			{
				header: "",
				key: "",
				content: [
					[ "", "" ]
				],
			},
			{
				header: "",
				key: "",
				content: [
					[ "", "" ]
				],
			},
			{
				header: "",
				key: "",
				content: [
					[ "", "" ]
				],
			},
			{
				header: "",
				key: "",
				content: [
					[ "", "" ]
				],
			}
		],
	}
];

export default trainCompositionVoidLineB;
