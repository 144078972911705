import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconPlus } from "../../../../../binder/assets";
import {
	Button,
	FilterAssociatedLineLabel,
	FilterDropDown,
	FilterLabelLine,
	FilterTags,
	SearchBar,
} from "../../../../../../theme";
import { useAppSetupContext, usePopup } from "../../../../../../shared";
import { PopupAddUser } from "../../../../index";
import FilterRoleLabel from "../filter-role-label/filter-role-label";
import { setupLineScopeAllowed } from "../../../../utils/user-associated-line-scope";
import formUserRoleConfig from "../form-user/form-user-role-config";
import { formatRoleName } from "../../../../utils/format-role-name";
import { ReactComponent as excelLogo } from "./../../../../../../theme/assets/img/excel-logo.svg";

/**
 * Render the AdminUserList sub header
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const UserListSubHeader = (props) => {
	const {
		filter,
		updateFilter,
		resetFilter,
		retrievedSearchedUser,
		users,
		handleUserListGenerationClick,
		loading,
	} = props;

	const { t } = useTranslation();

	const [ associatedLineOptions, setAssociatedLineOptions ] = useState([]);
	const { user: currentUser } = useAppSetupContext();
	const { role: userRole, origin: userOrigin } = currentUser || {};
	const popupPopupAddUserControl = usePopup();

	const editableRoleForCurrentUser =
		formUserRoleConfig[userRole]?.editableRole || [];

	const filterTagRender = {
		role: (role) => (
			<span className="filter__tag">
				{t(
					`user:admin-user-list.list.user-info.role.${formatRoleName(
						role,
						userOrigin
					)}`
				)}
			</span>
		),
		associatedLine: (line) => <FilterLabelLine line={line} />,
	};

	const setupAssociatedLineAllowed = () => {
		const lineOptionsAllowed = setupLineScopeAllowed(currentUser);
		setAssociatedLineOptions(lineOptionsAllowed);
	};

	useEffect(setupAssociatedLineAllowed, [ currentUser ]);

	return (
		<>
			<PopupAddUser
				userRole={userRole}
				popupControl={popupPopupAddUserControl}
				onSubmitSuccess={retrievedSearchedUser}
			/>
			<div className="admin-page__header">
				<h1 className="admin-page__title">
					{t("user:admin-user-list.title")}
				</h1>
				<div className="admin-page__container">
					<SearchBar
						className="admin-page__search"
						name="search"
						value={filter?.search}
						onChange={(event) =>
							updateFilter("search", event?.target?.value)
						}
						placeholder={t("user:admin-user-list.search")}
					/>
					<Button
						variant="primary"
						type="button"
						onClick={popupPopupAddUserControl.show}
						className={"admin-page__cta--add-user"}
						label={t("user:admin-user-list.add-user-btn")}
						icon={IconPlus}
					/>
				</div>
			</div>
			<div className="admin-page__filter">
				<FilterTags
					className="admin-page__filter__tags"
					filter={filter}
					updateFilter={updateFilter}
					customFilterTagRender={filterTagRender}
					resetFilter={resetFilter}
				/>
				<div className="filter__form">
					<div className="filter__container">
						<FilterDropDown
							className="filter__role"
							onSelect={(value) => updateFilter("role", value)}
							options={editableRoleForCurrentUser}
							filterValue={filter.role}
							staticSelectedLabel={
								t("user:admin-user-list.filter.role") || ""
							}
							renderOptionValue={(role) => (
								<FilterRoleLabel role={role} />
							)}
						/>
						<FilterDropDown
							className="filter__line"
							onSelect={(value) =>
								updateFilter("associatedLine", value)
							}
							options={associatedLineOptions}
							filterValue={filter.associatedLine}
							staticSelectedLabel={
								t(
									"user:admin-user-list.filter.associated-line"
								) || ""
							}
							renderOptionValue={(value) => (
								<FilterAssociatedLineLabel line={value} />
							)}
						/>
					</div>
					{userRole === "administrator" && (
						<div className="excel__container">
							<Button
								type="button"
								variant="minimal"
								onClick={() => handleUserListGenerationClick()}
								disabled={loading}
								loading={loading}
								iconTooltipContent={t(
									"user:admin-user-list.list.generate-users-list.tooltip"
								)}
								icon={excelLogo}
							/>
						</div>
					)}

					<div className="admin-page__users">
						<span className="users__count">
							{t("user:admin-user-list.users.users-count", {
								usersCount: users?.length,
							})}
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

UserListSubHeader.propTypes = {
	filter: PropTypes.shape({
		search: PropTypes.string,
		role: PropTypes.arrayOf(PropTypes.string),
		associatedLine: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	updateFilter: PropTypes.func.isRequired,
	resetFilter: PropTypes.func.isRequired,
	retrievedSearchedUser: PropTypes.func.isRequired,
	handleUserListGenerationClick: PropTypes.func.isRequired,
};

export default UserListSubHeader;
