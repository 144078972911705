import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { PopupDefault } from "../../../../../theme";
import { checkFormError } from "../../../../../shared";
import { validateThemeForm, getFormThemeFieldMessageKey } from "../../../utils/validate-theme-form";
import { createBinderTheme } from "../../../binder-theme.services";
import FormTheme from "../form-theme/form-theme";
import "./popup-create-theme.scss";

/**
 * Render the PopupCreateTheme component
 * @param props.popupControl
 * @param props.binder
 * @param props.themeList
 * @param props.handleClose Callback called after closing the popup
 * @returns {JSX.Element}
 */
const PopupCreateTheme = props => {
	const { popupControl, binder, themeList, handleClose } = props;
	const { t } = useTranslation();

	const [ formData, setFormData ] = useState({});

	const formError = validateThemeForm(formData, themeList);
	const formErrorMessage = getFormThemeFieldMessageKey(formError);

	const handleChange = (event) => {
		setFormData(previousForm => {
			const { name, value } = event.target;
			return { ...previousForm, [name]: value };
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!checkFormError(formError)) {
			// Handle default binder color
			if (!formData.color) {
				formData.color = "default";
			}

			createBinderTheme(binder.tech_id, formData).then((response) => {
				setFormData({});
				handleClose();
				popupControl.hide();
			});
		}
	};

	return (
		<PopupDefault popupControl={popupControl} title={t("binder:popup.form-theme.create-title")}>
			<form className="popup-create-theme" onSubmit={handleSubmit}>
				<FormTheme
					formData={formData}
					binder={binder}
					formError={formError}
					formErrorMessage={formErrorMessage}
					handleChange={handleChange}/>
			</form>
		</PopupDefault>
	);
};

PopupCreateTheme.propTypes = {
	popupControl: PropTypes.shape({
		hide: PropTypes.func.isRequired
	}).isRequired,
	binder: PropTypes.shape({
		tech_id: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired
	}),
	themeList: PropTypes.arrayOf(PropTypes.object),
	handleClose: PropTypes.func
};

export default PopupCreateTheme;

