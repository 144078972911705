import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { IconWrapper } from "../../../../../../theme";
import { ReactComponent as IconCheck } from "../../../../../../theme/assets/img/icon-check.svg";
import { fetchSheetByBinderAndNumber } from "../../../../../sheet/sheet.services";
import { resolveSheetColor, SheetImageLoader, SheetType } from "../../../../../sheet";
import { ReactComponent as IconArrowBreakline } from "../../../../../../theme/assets/img/icon-arrow-right.svg";
import "./history-multiple-link-train-task-view.scss";

/**
 * Historic view of a multiple link task
 * @param {object} props
 * @returns {JSX.Element}
 */
const HistoryMultipleLinkTrainTaskView = (props) => {
	const { task, className, passed } = props;
	const { text, sheetNumber, id, image } = task?.choice || {};
	const { items, hasImage } = task?.content || [];

	const [ sheetLinkInfo, setSheetLinkInfo ] = useState();
	const sheetColor = resolveSheetColor(sheetLinkInfo?.color, sheetLinkInfo?.binder_color);

	// Get linked sheet info to display correct color
	const initBinderSheets = () => {
		fetchSheetByBinderAndNumber(task?.binder?.techId, sheetNumber)
			.then((response) => {
				if ( response?.data ) {
					setSheetLinkInfo(response?.data);
				}
			});
	};

	// get the link category by finding the last previous link in the list with a "category" param
	const findLinkCategory = () => {
		let category = null;
		// using "some" to break iteration when reaching the chosen link
		items.some(item => {
			if (item.category) {
				category = item.category;
			}
			return (item.id === id);
		});
		return category;
	};

	const renderCategory = () => {
		const category = findLinkCategory();
		return (
			<>
				{category &&
				<span className="history-multiple-link-task-view__category">
					{category}
				</span>
				}
			</>
		);
	};

	const getComponentClassName = () => {
		const modifier = {
			"history-multiple-link-train-task-view--passed": passed,
			"history-multiple-link-train-task-view--image": hasImage
		};
		return classnames("history-multiple-link-train-task-view", className, modifier);
	};

	// TODO Handle warnings dependencies
	useEffect(initBinderSheets, [ sheetNumber, task?.binder ]);

	return (
		<div className={getComponentClassName()}>
			{renderCategory()}
			<div className="history-multiple-link-train-task-view__link-container">
				<IconWrapper className="history-multiple-link-train-task-view__icon" Component={IconCheck}/>
				<div className="history-multiple-link-train-task-view__description" style={{ "--description-color": sheetColor }}>
					<span>{text}</span>
					{hasImage &&
					<SheetImageLoader className="image-list-view__item__image" imageId={image?.imageId} tag="light"/>
					}
				</div>
				<IconWrapper className="history-multiple-link-train-task-view__arrow" Component={IconArrowBreakline}/>
				<SheetType
					className="history-multiple-link-train-task-view__sheet-number"
					type={sheetLinkInfo?.type || "parent"}
					sheetNumber={sheetNumber}
					color={sheetColor}
					binderType={"train-auto"}
				/>
			</div>
		</div>
	);
};

HistoryMultipleLinkTrainTaskView.propTypes = {
	task: PropTypes.shape({
		choice: PropTypes.shape({
			text: PropTypes.string,
			sheetNumber: PropTypes.string,
			id: PropTypes.string
		}),
		content: PropTypes.shape({
			hasImage: PropTypes.bool,
			items: PropTypes.arrayOf(PropTypes.shape({
				category: PropTypes.string
			}))
		})
	}),
	className: PropTypes.string,
	passed: PropTypes.bool
};

export default HistoryMultipleLinkTrainTaskView;
