import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDndSheetReference } from "../../../../../../../../shared";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import "./page-break-template-view.scss";

const PageBreakTemplateView = (props) => {
	const { template, allowInsertAfter, currentPosition, moveCard, location } = props;

	const { t } = useTranslation();
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref, false);

	return (
		<TemplateWrapper
			id={template.id}
			className="page-break-template"
			template={template}
			location={location}
			reference={ref} isDragging={isDragging} dragRef={drag}
			allowInsertAfter={allowInsertAfter}
			currentPosition={currentPosition}>
			<span className="page-break-template__text">{t("sheet:sheet-details.template.pageBreak.text")}</span>
		</TemplateWrapper>
	);
};

export default PageBreakTemplateView;

