import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSheetContentContext } from "../../../../../../context/sheet-content-context";
import "./resume-template-form.scss";

const ResumeTemplateForm = (props) => {
	const { content = {} } = props;
	const currentType = content?.type;

	const { t } = useTranslation();

	const { setCurrentNodeContent } = useSheetContentContext();

	const handleChange = (event) => {
		const { value } = event.target;
		setCurrentNodeContent({ type: value }, true);
	};

	const cmTypes = [ "cm", "cm-15", "hlp", "cai", "cai-hlp", "cai-cm", "travelers", "emergency" ];

	const initTemplateContent = () => {
		if (!content?.type){
			setCurrentNodeContent({ type: "cm" }, true);
		}
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initTemplateContent, [ content?.type ]);

	const generateRadioButton = (cmType) => {
		return (
			<li key={cmType} className={`cm-type ${cmType}`}>
				<input type="radio" id={cmType} name="cm-type" value={cmType} checked={cmType === currentType}
				       className="cm-type__input" onChange={handleChange}/>
				<label htmlFor={cmType}
				       className="cm-type__label">{t(`sheet:sheet-details.template.resume.${cmType}-label`)}</label>
			</li>
		);
	};

	return (
		<div className="resume-template-wrapper">
			<form onSubmit={event => event.preventDefault()}>
				<ul className="cm-types">
					{cmTypes.map(generateRadioButton)}
				</ul>
			</form>
		</div>
	);
};

export default ResumeTemplateForm;
