import React from "react";
import classNames from "classnames";
import { IconWrapper } from "../../../../../../../../theme";
import { useSheetModeContext } from "../../../../../../context/sheet-mode-context";
import { ReactComponent as IconArrowDownFilled } from "../../../../../../../../theme/assets/img/icon-arrow-down-filled.svg";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import "./next-template-view.scss";

const NextTemplateView = (props) => {
	const { template, location } = props;
	const { readOnly } = useSheetModeContext();

	return (
		<TemplateWrapper
			id={template.id}
			location={location}
			template={template}
			className={classNames("next-template-view", { "next-template-view--edition": !readOnly })}>
			<IconWrapper className="next-template-view__arrow" Component={IconArrowDownFilled}/>
			<IconWrapper className="next-template-view__arrow" Component={IconArrowDownFilled}/>
			<IconWrapper className="next-template-view__arrow" Component={IconArrowDownFilled}/>
		</TemplateWrapper>
	);
};

export default NextTemplateView;