import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button, EditorRendering, PopupDefault } from "../../../../../../theme";
import "./popup-system-effect-list.scss";
import ImageListView from "../../../../../../theme/components/image-list-view/image-list-view";

const PopupSystemEffectList = props => {
	const { systemEffects, popupControl } = props;
	const { t } = useTranslation();

	const displaySystemEffect = (systemEffect) => {
		return (
			<Fragment key={systemEffect.content?.text}>
				<div className="popup-system-effect__separator"/>
				<div className="popup-system-effect__item">
					<EditorRendering className="popup-system-effect__item__text" content={systemEffect.content?.text}/>
					<ImageListView className="popup-system-effect__item__images" images={systemEffect.content?.images}/>
				</div>
			</Fragment>
		);
	};

	return (
		<PopupDefault
			popupControl={popupControl}
			title={t("process:popup.system-effect.title")}>
			<div className="popup-system-effect">
				<div className="popup-system-effect__list">
					{systemEffects?.length > 0 && systemEffects?.map(displaySystemEffect)}
				</div>
				<div className="popup-system-effect__confirm">
					<Button 
						variant="primary"
						type="button"
						onClick={popupControl.hide}
						label={t("process:popup.system-effect.close-button")}
					/>
				</div>
			</div>
		</PopupDefault>
	);
};

export default PopupSystemEffectList;
