import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import "./textarea.scss";

/**
 * Render a Textarea component
 * @param {object} props
 * @param {string} [props.value=""]
 * @param {string} props.label
 * @param {function} props.onChange
 * @param {function} props.onBlur
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {string} props.className
 * @param {string} props.helperText
 * @param {boolean} props.invalid
 * @param {boolean} props.disabled
 * @param {boolean} props.preventNewLine
 * @param {boolean} props.hasFocus
 */
const Textarea = (props) => {
	const { value = "", label, onChange, onBlur, name, placeholder, className, helperText, invalid, disabled, preventNewLine, hasFocus = false } = props;
	const ref = useRef();

	const initFocus = () => {
		if (hasFocus){
			handleLabelFocus();
		}
	};

	const handleLabelFocus = useCallback(() => {
		if (ref?.current) {
			ref.current.focus();
			// set focus position at the end of the field
			ref.current.setSelectionRange(value.length, value.length);
		}
	}, [ value.length ]);

	const handleKeyPress = event => {
		if (preventNewLine && event.key === "Enter"){
			event.preventDefault();
		}
	};

	useEffect(initFocus,[ handleLabelFocus, hasFocus ]);

	return (
		<div className={classnames("textarea", className, { "textarea--invalid": invalid, "textarea--disabled": disabled })}>
			<TextareaAutosize
				ref={ref}
				className={classnames("textarea__field", { "textarea__field--valid": value })}
				type="text"
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				placeholder={placeholder}
				disabled={disabled}
				onKeyPress={handleKeyPress}
			/>
			<label onClick={handleLabelFocus} className="textarea__label">{label}</label>
			{helperText && <span className="textarea__helper-text">{helperText}</span>}
		</div>
	);
};

Textarea.propTypes = {
	value: PropTypes.string.isRequired,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	helperText: PropTypes.string,
	invalid: PropTypes.bool,
	disabled: PropTypes.bool,
	preventNewLine: PropTypes.bool
};

export default Textarea;
