import React from "react";
import { useTranslation } from "react-i18next";

import "./train-trouble-form-input-textarea.scss";

const TrainTroubleFormInputTextarea = props => {
	const { step = "", substep = "", checkedValue, onChange } = props;
	const { t } = useTranslation();

	return (
		<div>
			<span className={"train-trouble-form__input-textarea__title"}>
				{t(`process:process-rer-resume.${step}.${substep}`)}
			</span>
			<label>
				<textarea
					className="train-trouble-form__input-textarea"
					placeholder={t(`process:process-rer-resume.${step}.${substep}-placeholder`)}
					name={substep}
					value={checkedValue}
					rows={3}
					maxLength={140}
					required={false}
					onChange={onChange}
				/>
			</label>
		</div>
	);
};

export default TrainTroubleFormInputTextarea;
