import { getAuthItem, removeAuthItem, setAuthItem } from "../../../config/otp/auth-storage";

const jwtSecretStorageKey = "ufsdft-uxk";

/**
 * Remove the stored offline auth jwt secret
 * @return {Promise<void>}
 */
const removeStoredJwtSecret = async () => removeAuthItem(jwtSecretStorageKey);

/**
 * The offline auth jwt storage key
 * @type {string}
 */
const jwtStorageKey = "offline-usr";

/**
 * Get stored offline auth jwt
 * @return {Promise<string|null>}
 */
const getStoredOfflineAuthJwt = async () => getAuthItem(jwtStorageKey);

/**
 * @return {Promise<boolean>}
 */
const checkOfflineAuth = async () => getStoredOfflineAuthJwt();

/**
 * Save provided offline auth jwt into storage
 * @param {string} jwtString
 * @return {Promise<*>}
 */
const setStoredOfflineAuthJwt = async (jwtString) => setAuthItem(jwtStorageKey, jwtString);

/**
 * Save provided offline auth jwt into storage
 * @return {Promise<*>}
 */
const removeStoredOfflineAuthJwt = async () => removeAuthItem(jwtStorageKey);


export {
	getStoredOfflineAuthJwt,
	setStoredOfflineAuthJwt,
	removeStoredJwtSecret,
	removeStoredOfflineAuthJwt,
	checkOfflineAuth
};
