import React from "react";
import classname from "classnames";
import PropTypes from "prop-types";
import { Dropdown } from "../../../../theme";

/**
 * Render a dropdown to select train model
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.value The selected material technical id
 * @param {string} props.name
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {boolean} props.disabled
 * @param {function} props.onChange
 * @param {{label: string, tech_id: string}[]} props.materialList
 * @returns {JSX.Element}
 */
const MaterialDropdown = props => {
	const { className, value, name, label, placeholder, onChange, materialList, disabled } = props;

	const renderOption = () => {
		return materialList.map(material => {
			const { label: materialLabel, tech_id: materialTechId } = material;
			return <option key={materialTechId} value={materialTechId}>{materialLabel}</option>;
		});
	};

	return (
		<Dropdown 
			className={classname("material-dropdown", className)} 
			value={value} 
			name={name} 
			onChange={onChange}
			label={label}
			placeholder={placeholder}
			disabled={disabled}
		>
			{renderOption()}
		</Dropdown>
	);
};

MaterialDropdown.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	materialList: PropTypes.arrayOf(PropTypes.shape({
		tech_id: PropTypes.string,
		label: PropTypes.string
	})),
	disabled: PropTypes.bool
};

export default MaterialDropdown;
