import React, { useMemo } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconWrapper, Button } from "../../../index";
import { ReactComponent as IconClose } from "../../../assets/img/close.svg";
import "./filter-tags.scss";

const defaultTagContentRender = (value) => <span>{value}</span>;

/**
 * Render the FilterTags components
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.filter
 * @param {function} props.customFilterTagRender
 * @param {function} props.resetFilter
 * @param {function} props.updateFilter
 * @example
 * // customFilterTagRender example:
 * const customFilterTagRender = {
 *     "fieldName": (fieldValue) => <JSXComponentToRender />
 * }
 */
const FilterTags = (props) => {
	const { className, filter, customFilterTagRender = {}, resetFilter, updateFilter } = props;

	const { t } = useTranslation();

	const handleDelete = (name, value) => {
		const filterValue = filter[name];
		if (Array.isArray(filterValue)) {
			updateFilter(name, filterValue.filter(itemValue => itemValue !== value));
		} else {
			updateFilter(name, undefined);
		}
	};

	const renderTag = (tag) => {
		const { name, value } = tag;
		const tagContentRender = customFilterTagRender[name] || defaultTagContentRender;

		return (
			<li className="filter-tags__item" key={`${name}${value}`}>
				{tagContentRender(value)}
				<IconWrapper
					className="filter-tags__icon"
					Component={IconClose}
					tooltip={t("theme:filter-tag.remove-tooltip")}
					onClick={() => handleDelete(name, value)}
				/>
			</li>
		);
	};

	/**
	 * Convert filter object into tag list
	 * @returns {{{name: string, value: string}[]}}
	 */
	const parseFilterIntoTag = () => {
		// Get the list of all filter value
		const filterEntries = Object.entries(filter);

		return filterEntries.reduce((previousTagList, filterValue) => {
			const [ name, value ] = filterValue;
			// Convert value into array if needed
			const tagValues = Array.isArray(value) ? value : [ value ];
			const tags = tagValues
				.filter(isTruthyValue => !!isTruthyValue) // Allow only truthy value in tag
				.map(tagValue => ({ name, value: tagValue }));

			return [ ...previousTagList, ...tags ];
		}, []);
	};

	const tagList = useMemo(parseFilterIntoTag, [ filter ]);

	if (tagList?.length === 0) {
		return false;
	}

	return (
		<ul className={classnames(className, "filter-tags__list")}>
			{tagList.map(renderTag)}
			{tagList?.length > 0 && (
				<li className="filter-tags__item filter-tags__item--reset">
					<Button
						variant="primary"
						onClick={resetFilter}
						label={t("theme:filter-tag.reset")}
						className="filter-tags__reset"
					/>
				</li>
			)}
		</ul>
	);
};

FilterTags.propTypes = {
	className: PropTypes.string,
	filter: PropTypes.shape({}).isRequired,
	customFilterTagRender: PropTypes.shape({}),
	resetFilter: PropTypes.func.isRequired,
	updateFilter: PropTypes.func.isRequired
};

export default FilterTags;
