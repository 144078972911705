import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { usePopup } from "../../../../shared";
import { Button, EditorRendering } from "../../../../theme";
import { draftJSExtractFirstBlock } from "../../../../theme/components/editor-rendering/utils";
import { IconSemiArrowLeft, IconSemiArrowRight, IconEdit } from "../../../../theme/assets/img";
import { useSheetContentContext } from "../../context/sheet-content-context";
import { useSheetReload } from "../../utils/use-sheet-reload";
import { isTrainBinderType } from "../../../binder/utils/binder-type";
import PopupEditSheetTitle from "../popup-edit-sheet-title/popup-edit-sheet-title";
import { useHistory } from "react-router-dom";

import "./sheet-details-subheader-title.scss";

/**
 * SheetDetailsSubheaderTitle component
 * @param {object} props
 * @param {object} props.sheetData
 * @param {object} props.binderData
 * @param {object} props.refreshSheetData
 * @param {boolean} props.isBinderStatusWIP
 * @param {Array} props.sheetList
 * @returns {JSX.Element}
 */
const SheetDetailsSubheaderTitle = (props) => {
	const { binderData, isBinderStatusWIP, sheetList } = props;
	const popupEditSheetTitleControl = usePopup();
	const { sheetData = {} } = useSheetContentContext();
	const { refreshSheetData } = useSheetReload();
	const [ currentNavInfo, setCurrentNavInfo ] = useState({});
	const { currPreviousSheetId: previousSheetId, currNextSheetId: nextSheetId } = currentNavInfo || {};

	const history = useHistory();

	const { t } = useTranslation();

	// take first item of draftJs block to only show one line of title and the full title on hover
	const titleEllipsis = sheetData?.title && draftJSExtractFirstBlock(sheetData?.title);

	const handleNavigation = (direction) => {
		const urlBase = `/binders/${binderData?.tech_id}/sheet`;

		if (direction === "left" && previousSheetId !== null) {
			history.push(`${urlBase}/${previousSheetId}/edit`);
		} else if (direction === "right" && nextSheetId !== null) {
			history.push(`${urlBase}/${nextSheetId}/edit`);
		}
	};

	useEffect(() => {
		const currentSheetData = sheetList?.find((sheet) => sheet.sheet_id === sheetData.sheet_id);
		const { previous_sheet_id: currPreviousSheetId, next_sheet_id: currNextSheetId } = currentSheetData || {};
		setCurrentNavInfo({ currPreviousSheetId, currNextSheetId });
	}, [ sheetData.sheet_id, sheetList ]);

	return (
		<div className="sheet-details__subheader__title">
			<PopupEditSheetTitle
				popupControl={popupEditSheetTitleControl}
				sheet={sheetData}
				binder={binderData}
				isTrainBinder={isTrainBinderType(binderData?.type)}
				onSubmitSuccess={(data) => refreshSheetData(data, false)}
			/>
			<EditorRendering editorClassName="title--ellipsis" content={titleEllipsis}/>
			<EditorRendering editorClassName="title--full" content={sheetData.title}/>
			{isBinderStatusWIP &&
				<Button
					variant="minimal"
					small
					type="button"
					className="sheet-details__subheader__title__edition-title"
			    	onClick={popupEditSheetTitleControl.show}
					icon={IconEdit}
					iconTooltipContent={t("sheet:sheet-details.sub-header.cta-modify")}
				/>
			}
			<div className="sheet-details__subheader__title__navigation__container">
				<Button
					small
					variant="secondary"
					type="button"
					className="sheet-details__subheader__title__navigation__container__button"
					onClick={() => handleNavigation("left")}
					icon={IconSemiArrowLeft}
					iconBig={true}
					disabled={!previousSheetId}
				/>
				<Button
					small
					variant="secondary"
					type="button"
					className="sheet-details__subheader__title__navigation__container__button"
					onClick={() => handleNavigation("right")}
					icon={IconSemiArrowRight}
					iconBig={true}
					disabled={!nextSheetId}
				/>
			</div>
		</div>
	);
};

SheetDetailsSubheaderTitle.propTypes = {
	sheetData: PropTypes.shape({
		title: PropTypes.object
	}),
	binderData: PropTypes.object,
	refreshSheetData: PropTypes.func,
	isBinderStatusWIP: PropTypes.bool,
};


export default SheetDetailsSubheaderTitle;
