import React, { useState, useEffect, useContext, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LoadWrapper, SprContext } from "../../../../shared";
import { SprPage } from "../../../../theme";
import { binderTrainTypeList } from "../../utils/binder-type";
import { searchBinder } from "../../binder.services";
import { sortAndGroupBinderByMaterial } from "../../utils/binder-list-utils";
import RailWaySubheader from "../../../../shared/components/railway-subheader/railway-subheader";
import CardBlockTemplate from "../../../../shared/components/card-block-template/card-block-template";
import "./binder-by-material-list.scss";

/**
 * BinderByMaterialList component
 * @returns {JSX.Element}
 */
const BinderByMaterialList = ({ disableLineSwitch }) => {
	const { t } = useTranslation();
	const { associatedLine, resetCurrentBinder, side } = useContext(SprContext);
	const [ materialsLoading, setMaterialsLoading ] = useState(false);
	const [ materialList, setMaterialList ] = useState([]);

	const searchBindersByMaterial = useCallback(async () => {
		try {
			if (associatedLine) {
				const params = {
					associatedLine,
					status: "published",
					type: binderTrainTypeList
				};
				const { data: binders = [] } = await searchBinder(params);
				setMaterialList(sortAndGroupBinderByMaterial(binders));
			}
		} catch (error) {
			console.error(error);
		} finally {
			setMaterialsLoading(false);
		}
	}, [ associatedLine ]);

	const subheader = <RailWaySubheader
		returnLabel={t("binder:material-list.head.back")}
		title={t("binder:material-list.head.material")}
		linkTo="/railway"
	/>;

	const generateMaterialCard = useCallback((material) => {
		const [ materialTechId, binderList ] = material;
		const [ binder ] = binderList;
		const { id: firstBinderId, material_label: materialLabel, color } = binder;
		const isBinderGroup = binderList.length > 1;
		const uri = isBinderGroup ? `/railway/material/${materialTechId}/binders` : `/railway/material/${materialTechId}/binder/${firstBinderId}`;

		return <CardBlockTemplate
			linkTo={uri}
			mainText={materialLabel}
			key={materialTechId}
			subTitle={firstBinderId}
			color={color}
			isBinderGroup={isBinderGroup}
		/>;
	}, [ ]);

	useEffect(resetCurrentBinder, [ resetCurrentBinder ]);
	useEffect(searchBindersByMaterial, [ searchBindersByMaterial ]);

	return (
		<LoadWrapper className="material-list__loader" loading={materialsLoading} >
			<SprPage
				className="material-list"
				binderAbbreviationNotVisible={true}
				allowLineSwitch={side === "train"}
				disabledLineSwitch={disableLineSwitch}
				subheader={subheader}
			>
				<ul className="material-cards">
					{materialList.map(generateMaterialCard)}
				</ul>
			</SprPage>
		</LoadWrapper>
	);
};

const BinderByMaterialListWrapper = (props) => <BinderByMaterialList {...props}/>;

export default BinderByMaterialListWrapper;
