import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { usePopup, useAppSetupContext, buildSprProcessUri  } from "../../../../../../shared";
import { Button } from "../../../../../../theme";
import PopupAbortProcess from "../../../../../process/pages/process-list/components/popup-abort-process/popup-abort-process";
import { IconEye, IconTrash, IconTools } from "../../../../../../theme/assets/img";
import { offlineUser } from "../../../../../../config/offline";

const sprPrefix = "spr";
const railwayPrefix = "railway";

const ProcessListItemActions = props => {
	const { process, updateProcess, side } = props;
	const { user, online } = useAppSetupContext();

	const { t } = useTranslation();
	const popupAbortProcessControl = usePopup();

	const handleAbortProcess = () => {
		const processToUpdate = { tech_id: process.tech_id, status: "aborted", history: process.history };
		updateProcess(processToUpdate);
	};

	// when offline, user can update all cache processes
	// when online, he can update only processes he own or processes attributed to offline user (processes from cache fully or partially executed offline)
	const currentUserProcessOwnership = (!online || process.owner === user.tech_id || process.owner === offlineUser.tech_id);
	const processOwner = "processOwner";
	const currentUser = "currentUser";

	const prefixUrl = side === "train" ? railwayPrefix : sprPrefix;
	const urlRunProcess = buildSprProcessUri({ processId: process.tech_id }, prefixUrl);

	const items = [
		{
			visibleBy: [ processOwner ],
			label: t("process:process-list.menu.continue-process-run"),
			href: urlRunProcess,
			icon: IconTools
		},
		{
			visibleBy: [ currentUser ],
			label: t("process:process-list.menu.show-live"),
			href: urlRunProcess,
			icon: IconEye
		},
		{
			visibleBy: [ processOwner, "administrator" ],
			label: t("process:process-list.menu.abort-process"),
			onClick: popupAbortProcessControl.show,
			icon: IconTrash
		}
	];

	const filteredItems = items.filter(item => {
		const { visibleBy } = item;
		return visibleBy.includes(currentUserProcessOwnership ? processOwner : currentUser) || (user.role === "administrator" && visibleBy.includes("administrator"));
	});

	const displayItems = (item, index) => {
		if (item.href) {
			return (
				<div className="item__in-progress__container">
					<Link key={index} to={item.href} className="item__in-progress__actions">
						<Button
							variant="minimal"
							label={item.label}
							icon={item.icon}
						/>

					</Link>
				</div>
			);
		}
		if (item.onClick) {
			return (
				<div key={index} className="item__in-progress__actions" onClick={item.onClick}>
					<Button
						variant="minimal"
						label={item.label}
						icon={item.icon}
					/>

				</div>
			);
		}
	};

	return (
		<>
			<PopupAbortProcess popupControl={popupAbortProcessControl} onConfirm={handleAbortProcess}/>
			{filteredItems.map(displayItems)}
		</>
	);
};

export default ProcessListItemActions;
