/**
 * Check if the provided binder id contains errors
 * @param {string} binderId The binder id to validate
 * @returns {string[]}
 */
const binderIdValidator = (binderId = "") => {
	const isForbiddenChar = binderId.search(/[ _]/g) !== -1;
	return isForbiddenChar ? [ "invalid" ] : [];
};

/**
 * Check if the provided binder subtitle already exist in binderList
 * @param {string} binderSubtitle
 * @param {{subtitle: string}} currentBinder The current edited binder
 * @param {{subtitle: string}[]} binderList The binder list
 * @param {string} id The new binder id
 * @returns {string[]}
 */
const binderSubtitleValidator = (binderSubtitle = "", currentBinder, binderList, id) => {
	const subtitle = binderSubtitle.trim();
	let subtitleExist;

	// don't compare with current binder subtitle if id is changed in the form because it's a new binder
	if (id) {
		subtitleExist = !!binderList.find(binder => binder.subtitle === subtitle);
	}
	else {
		subtitleExist = !!binderList.find(binder => currentBinder.subtitle !== binder.subtitle && binder.subtitle === subtitle);
	}
	return subtitleExist ? [ "invalid" ] : [];
};

/**
 * Check if the provided binder abbreviation is alpha only and a length less than 10
 * @param {string} abbreviation The binder abbreviation
 * @returns {string[]}
 */
const binderAbbreviationValidator = (abbreviation = "") => {
	const isAlphaOnly = !/^[a-z]*$/i.test(abbreviation);
	const isTooLong = abbreviation?.length > 10;

	const result = [];
	if (isAlphaOnly) result.push("format");
	if (isTooLong) result.push("length");
	return result;
};

/**
 * Check if the provided binder changed description already exist in binderList
 * @param changedDescription
 * @param {{changed_description: string}} currentBinder The current edited binder
 * @param {{changed_description: string}[]} binderList
 * @returns {string[]}
 */
const binderChangedDescriptionValidator = (changedDescription = "", currentBinder, binderList) => {
	const descriptionExists = !!binderList.find(binder => binder?.tech_id !== currentBinder?.tech_id && binder.changed_description === changedDescription);
	return descriptionExists ? [ "invalid" ] : [];
};

/**
 * Check if the provided associatedLine list contains at least 1 line
 * @param {string[]} associatedLine
 * @returns {string[]}
 */
const binderAssociatedLineListValidator = (associatedLine) => {
	return associatedLine?.length < 1 ? [ "invalid" ] : [];
};

export {
	binderIdValidator,
	binderAbbreviationValidator,
	binderChangedDescriptionValidator,
	binderSubtitleValidator,
	binderAssociatedLineListValidator
};
