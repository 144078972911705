import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import TextfieldAutocomplete from "../../../../../../../../../../theme/components/form/text-field-autocomplete/textfield-autocomplete";
import { fetchSheetByBinder } from "../../../../../../../../sheet.services";
import { addSheetsTitleRaw } from "../../../../../../../../utils/add-sheet-title-raw-utils/add-sheet-title-raw-utils";
import { Dropdown } from "../../../../../../../../../../theme";

import "./simple-link-sheet-form.scss";

/**
 * Render the SimpleLinkSheetForm component
 * @param {object} props
 * @return {JSX.Element}
 * @constructor
 */
const SimpleLinkSheetForm = (props) => {
	const { handleSelectedValue, binderType, currentSheet, isTrainBinder, content = {} } = props;
	const { binderId } = useParams();
	const { t } = useTranslation();

	const [ redirectSheet, setRedirectSheet ] = useState({});
	const [ sheetQuery, setSheetQuery ] = useState("");
	const [ binderSheetList, setBinderSheetList ] = useState();

	// get sheet from same binder for autocompletion
	const initBinderSheetsList = () => {
		if (binderType && currentSheet.sheet_id) {
			const param = {
				"-type": [ "appendix" ]
			};
			if (binderType === "man") {
				param.signalTechId = currentSheet.signal_tech_id;
				param["-sheet_id"] = currentSheet.sheet_id;
			}
			fetchSheetByBinder(binderId, param).then((response) => {
				if (response?.data) {
					const sheetWithPlainTitle = addSheetsTitleRaw(response.data);
					if (content.sheetNumber){
						const { actionId, actionPosition, sheetNumber, sheetId } = content;
						const sheetDetail = sheetWithPlainTitle?.find(sheet => sheet.number_search === sheetNumber);
						setRedirectSheet({
							action: { id: actionId, position: actionPosition },
							number_search: sheetNumber,
							content: sheetDetail?.content,
							sheet_id: sheetId
						});
						setSheetQuery(sheetNumber);
					}
					setBinderSheetList(sheetWithPlainTitle);
					return response.data;
				}
			});

		}
	};

	const handleAutoCompleteChange = (event) => {
		setSheetQuery(event.target.value);
		setRedirectSheet({});
		// clean value selected on input change
		if (content.sheetNumber) {
			handleSelectedValue({});
		}
	};

	const handleAutoCompleteSelectedValue = (suggestion) => {
		setRedirectSheet(suggestion.value);
		setSheetQuery(suggestion?.value?.number_search);
		handleSelectedValue(suggestion.value);
	};

	const getSuggestions = async (event = null) => {
		const sheets = await searchExistingSheetByNumber(event) || [];
		return sheets.map(renderLabelAutoCompletion);
	};

	const renderLabelAutoCompletion = (sheet) => {
		const { number_search, titleRaw, sheet_id } = sheet;
		const lines = [ { type: "label", text: `${number_search.toUpperCase()} - ${titleRaw}` } ];
		const label = { className: "simple-link-sheet-form__autocomplete", lines };
		return ({ sheet_id, value: sheet, label });
	};

	// getting suggestion result and pass to child
	const searchExistingSheetByNumber = async (query) => {
		if (query) {
			return binderSheetList?.filter((sheet) => (sheet.number_search.includes(query) || sheet.titleRaw?.includes(query)));
		} else {
			return [ ...binderSheetList ];
		}
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initBinderSheetsList, [ currentSheet.sheet_id ]);

	const actionDropdownOptions = useMemo(() => {
		const templateList = redirectSheet.content || [];
		return templateList.filter(template => template.type === "action").map((template, position) => {
			const { id } = template;
			return (
				<option key={id} value={JSON.stringify({ id, position })}>
					{t("sheet:sheet-details.template.simpleLink.action-option", { bullet: position + 1 })}
				</option>
			);
		});

	}, [ redirectSheet.content, t ]);

	const handleSelectAction = (event) => {
		const parsedValue = JSON.parse(event.target.value);
		setRedirectSheet(previousSheet => {
			const newSheet = { ...previousSheet, action: parsedValue };
			// Update sheet and binder and action info in parent
			handleSelectedValue(newSheet);
			return newSheet;
		});
	};

	return (
		<div className="simple-link-sheet-form">
			{binderSheetList && <>
				<label
					className="simple-link-sheet-form__label">{t("sheet:sheet-details.template.simpleLink.sheet-label")}</label>
				<TextfieldAutocomplete
					name="sheetNumber"
					className="simple-link-sheet-form__input"
					value={sheetQuery}
					handleChange={(e) => {
						handleAutoCompleteChange(e);
					}}
					getSuggestions={getSuggestions}
					selectedValue={handleAutoCompleteSelectedValue}
				/>
			</>}
			{isTrainBinder && (
				<div className="simple-link-sheet-form__dropdown-action">
					<label
						className="simple-link-sheet-form__label">{t("sheet:sheet-details.template.simpleLink.action-dropdown-label")}</label>
					<Dropdown
						className="simple-link-sheet-form__dropdown"
						onChange={handleSelectAction}
						value={JSON.stringify(redirectSheet?.action || {})}
						placeholder={t("sheet:sheet-details.template.simpleLink.action-dropdown-placeholder")}
						placeholderValue="{}"
						disabledPlaceholder={false}
						disabled={!redirectSheet?.content}>
						{actionDropdownOptions}
					</Dropdown>
				</div>
			)}
		</div>
	);
};

SimpleLinkSheetForm.propTypes = {
	handleSelectedValue: PropTypes.func.isRequired,
	redirectSheetValue: PropTypes.string.isRequired,
	binderType: PropTypes.oneOf([ "sheet", "theme", "man", "train-driver", "train-officer", "train-auto" ]),
	currentSheet: PropTypes.shape({
		sheet_id: PropTypes.string,
		signal_tech_id: PropTypes.string
	})
};

export default SimpleLinkSheetForm;
