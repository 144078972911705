import React from "react";
import { useTranslation } from "react-i18next";
import { IconWrapper } from "../../../../../../../theme";
import { ReactComponent as IconDuplicate } from "../../../../../../../theme/assets/img/icon-duplicate.svg";
import { usePopup } from "../../../../../../../shared";
import PopupCopyTemplate from "../../../../../pages/sheet-detail/components/popup-copy-template/popup-copy-template";
import { setTemplateCopiedInLocalStorage } from "../../../../../pages/sheet-detail/utils/handle-copy-paste-template";
import "./copy-template-button.scss";

const CopyTemplateButton = (props) => {
	const { template } = props;
	const popupCopyTemplateControl = usePopup();
	const { t } = useTranslation();

	const handleCopyTemplate = () => {
		setTemplateCopiedInLocalStorage(template);
		popupCopyTemplateControl.show();
	};

	return (
		<>
			<PopupCopyTemplate popupControl={popupCopyTemplateControl} template={template}/>
			<button type="button" onClick={handleCopyTemplate}>
				<IconWrapper className="copy-template-button__icon" Component={IconDuplicate}
				             tooltip={t("sheet:sheet-details.tooltip.copy")}/>
			</button>
		</>
	);
};

export default CopyTemplateButton;
