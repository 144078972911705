/**
 * Extract a DraftJS content to take the first block of the list
 * Return a DraftJS Object with the previous block with metadata
 * @param draftJSContent
 */
const draftJSExtractFirstBlock = (draftJSContent = {}) => {
	const blocks = [ draftJSContent.blocks[0] ];
	return { ...draftJSContent, blocks };
};

export default draftJSExtractFirstBlock;
