import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classnames from "classnames";
import { PopupDefault, Button, ErrorMessage } from "../../../../../theme";
import { checkFormError } from "../../../../../shared";
import FormBinder from "../form-binder/form-binder";
import { copyBinder, fetchBinderListById } from "../../../binder.services";
import {
	getFormBinderFieldMessageKey,
	validatePopupCopyBinderForm,
	validatePopupCopyBinderTrainForm
} from "../../../utils/validate-popup-binder-form";

import "./popup-copy-binder.scss";

/**
 * Render the PopupCopyBinder component
 * @param {object} props
 * @param {function} props.onSubmitSuccess Callback called after successful submit
 * @param {object} props.binder binder to copy
 * @param {object} props.popupControl
 * @param {boolean} props.train is the popup for a train binder 
 * @returns {JSX.Element}
 */
const PopupCopyBinder = props => {
	const {
		onSubmitSuccess = () => {}, binder, popupControl, train
	} = props;
	const { t } = useTranslation();

	const [ formData, setFormData ] = useState({});
	const [ existingBinderList, setExistingBinderList ] = useState([]);
	const [ networkError, setNetworkError ] = useState(false);
	const [ hasNewBinderId, setHasNewBinderId ] = useState(false);

	const reInitForm = () => {
		props.onClose();
		setFormData({});
		setExistingBinderList([]);
		setNetworkError(false);
	};

	const getCurrentBinder = useCallback((binderList) => binderList.find(({ tech_id }) => tech_id === binder.tech_id) || {}, [ binder.tech_id ]);

	const getFormError = () => {
		const currentBinder = getCurrentBinder(existingBinderList);
		if (train) {
			return validatePopupCopyBinderTrainForm(formData, currentBinder, existingBinderList);
		} else {
			return validatePopupCopyBinderForm(formData, currentBinder, existingBinderList);
		}
	};


	const formError = getFormError();
	const formErrorMessage = getFormBinderFieldMessageKey(formError);

	// Fetch existing binder by exact id
	const requestBinderById = useCallback((binderId) => {
		if (binderId) {
			fetchBinderListById(binderId).then(response => {
				setNetworkError(false);
				const binderList = response?.data || [];
				setExistingBinderList(binderList);

				const currentBinder = getCurrentBinder(binderList);
				setFormData(oldFormData => ({
					...oldFormData,
					lineNumber: currentBinder.associated_line,
					id: currentBinder.id,
					title: currentBinder.title,
					color: currentBinder.color,
					abbreviation: currentBinder.abbreviation,
					type: currentBinder.type,
					material: currentBinder.material_tech_id
				}));
			}).catch((error) => {
				setNetworkError(true);
				throw error;
			});
		}
	}, [ getCurrentBinder ]);

	const refreshBinderList =  useCallback(() => {
		const binderId = formData?.id;
		if (binderId) {
			fetchBinderListById(binderId).then(response => {
				setNetworkError(false);
				const binderList = response?.data || [];
				setExistingBinderList(binderList);
				setHasNewBinderId(binderId !== binder.id);
			}).catch((error) => {
				setNetworkError(true);
				throw error;
			});
		}
	}, [ binder.id, formData?.id ]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};
	const handleSubmit = event => {
		event.preventDefault();
		if (!checkFormError(formError)) {
			const { 
				lineNumber: associated_line, 
				material: material_tech_id, 
				...restFormData 
			} = formData;
			const newBinder = { associated_line, ...restFormData };

			// if copying train binder, then add material field
			if (train) {
				newBinder.material_tech_id = material_tech_id;
			} else if (binder?.type === "man"){
				newBinder.station_tech_id = binder?.station_tech_id;
				newBinder.station_type = binder?.station_type;
			}

			copyBinder(newBinder, binder.tech_id)
				.then(() => {
					reInitForm();
					onSubmitSuccess();
					popupControl.hide();
				});
		}
	};

	useEffect(() => {
		if (popupControl.visible && binder.id) {
			requestBinderById(binder.id);
		}
	}, [ popupControl.visible, binder.id, requestBinderById ]);


	useEffect(() => {
		refreshBinderList();
	}, [ refreshBinderList ]);

	return (
		<PopupDefault
			popupControl={popupControl}
			title={t("binder:popup.copy-binder.title")}
			onClose={reInitForm}
			className={classnames("popup-copy-binder__wrapper", { "popup-copy-binder__wrapper--train": train })}>
			<form className="popup-copy-binder" onSubmit={handleSubmit}>
				<div className="popup-copy-binder__form-wrapper">
					<FormBinder form={formData} validation={formError} errorMessage={formErrorMessage}
				            onChange={handleChange} copy train={train} hasCopyBinderId={hasNewBinderId} />
				</div>
				<div className="popup-copy-binder__controller">
					<Button 
						variant="primary" 
						className="popup-copy-binder__submit"
						type="submit"
						disabled={checkFormError(formError)}
						label={t("binder:popup.form.field.submit")}
					/>
				</div>
				{networkError &&
				<ErrorMessage className="popup-copy-binder__network-error">{t("error.default")}</ErrorMessage>}
			</form>
		</PopupDefault>
	);
};

PopupCopyBinder.propTypes = {
	onSubmitSuccess: PropTypes.func,
	binder: PropTypes.shape({
		id: PropTypes.string,
		tech_id: PropTypes.string,
	}),
	popupControl: PropTypes.shape({
		hide: PropTypes.func,
		show: PropTypes.func,
		visible: PropTypes.bool
	}),
	train: PropTypes.bool
};

export default PopupCopyBinder;
