/**
 * Sort the given array by ascending order.
 * @param {*[]} array The array to sort by asending order
 * @returns {*[]}
 * @example
 * const arrayToSort = [1, 5, 9, 6, 11];
 * const result = arraySortAscending(arrayToPad);
 * console.log(result);
 * //output: [ 1, 5, 6, 9, 11];
 */
const arraySortAscending = (array) => Array.isArray(array) ? array.sort((a, b) => a - b) : array;

export default arraySortAscending;
