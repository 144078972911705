import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { LogoDigiproc } from "../../../../theme";
import "./offline-header.scss";

/**
 * Render the offline header
 * @param props
 * @param [props.className=""]
 * @return {JSX.Element}
 * @constructor
 */
const OfflineHeader = (props) => {
	const { className = "" } = props;
	const { t } = useTranslation();
	
	return (
		<header className={classnames("offline-header", className)}>
			<Link to="/"><LogoDigiproc className="offline-header__logo" /></Link>
			<span className="offline-header__separator" />
			<h1 className="offline-header__title">{t("user:offline-auth.title")}</h1>
		</header>
	);
};

OfflineHeader.propTypes = {
	className: PropTypes.string
};

export default OfflineHeader;
