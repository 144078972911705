import React from "react";
import PropTypes from "prop-types";
import "./square-color-icon.scss";

/**
 * @param {object} props
 * @param {string} props.color
 * @returns {JSX.Element}
 */
const SquareColorIcon = (props) => {
	const { color } = props;
	return <span className="square-color-icon" style={{ backgroundColor: color }}/>;
};

SquareColorIcon.propTypes = {
	color: PropTypes.string.isRequired
};

export default SquareColorIcon;
