import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ImageListView from "../../../../../../theme/components/image-list-view/image-list-view";
import { EditorRendering, IconWrapper } from "../../../../../../theme";
import { ReactComponent as IconCheckboxChecked } from "../../../../../../theme/assets/img/checkbox-checked.svg";
import { ReactComponent as IconCall } from "../../../../../../theme/assets/img/icon-call.svg";

import "./history-text-task-view.scss";

/**
 * history text template component
 * @param {object} props
 * @param {object} props.task
 * @param {string} props.className
 * @param {boolean} props.passed
 * @returns {JSX.Element}
 */
const HistoryTextTaskView = (props) => {
	const { task = {}, className, passed } = props;
	const { type, content, optionalInfos } = task;

	const iconList = {
		call: IconCall,
		action: IconCheckboxChecked
	};

	const renderIcon = () => {
		const icon = iconList[type];
		if (icon) {
			return <IconWrapper className="history-text-task-view__icon" Component={icon}/>;
		}
		return null;
	};

	// multipleChoice subheader info
	const renderMultipleChoiceStep = (info) => {
		if (passed && info?.parentType === "multipleChoice" && info?.choiceTitle) {
			// change color on odd index | default color on even index
			const classname = classNames("history-text--view__subheader", { "odd": optionalInfos?.index % 2 === 1 });
			return (
				<div className={classname}>
					{optionalInfos?.choiceTitle}
				</div>
			);
		}
	};

	const initClassName = (initialClassName) => classNames(initialClassName, className, {
		[`${initialClassName}--call`]: type === "call",
		[`${initialClassName}--action`]: type === "action",
		[`${initialClassName}--note`]: type === "note",
		[`${initialClassName}--system-effect`]: type === "systemEffect",
		[`${initialClassName}--passed`]: passed
	});

	return (
		<div className={initClassName("history-text-task-view")}>
			{renderMultipleChoiceStep(optionalInfos)}
			<div className={initClassName("history-text-task-view__text")}>
				<div className="history-text-task-view__text__wrapper">
					{renderIcon()}
					<EditorRendering content={content?.text} className={"history-text-task-view__text__content"}/>
				</div>
				{content?.images?.length > 0 &&
				<ImageListView className="history-text-task-view__images" images={content?.images}/>}
			</div>
		</div>
	);
};

HistoryTextTaskView.propTypes = {
	task: PropTypes.shape({
		content: PropTypes.shape({
			type: PropTypes.string,
			sheetNumber: PropTypes.string,
			sheetTitle: PropTypes.string
		}),
		type: PropTypes.string,
		optionalInfos: PropTypes.shape({
			parentType: PropTypes.string,
			index: PropTypes.number,
			choiceTitle: PropTypes.string
		})
	}),
	className: PropTypes.string,
	passed: PropTypes.bool
};

export default HistoryTextTaskView;
