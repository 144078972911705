import React from "react";
import { PopupConfirm } from "../../../../../../../theme";
import { useTranslation } from "react-i18next";
import "./popup-stop-delete-step-template.scss";

const PopupStopDeleteStepTemplate = props => {
	const { popupControl } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t("sheet:popup.delete-step-not-allowed.title")}
			ctaConfirmLabel="sheet:popup.delete-step-not-allowed.cta-confirm-label"
			className="popup-stop-delete-step-template"
		>
			<span className="popup-stop-delete-step-template_text">
				{t("sheet:popup.delete-step-not-allowed.display-message")}
			</span>
		</PopupConfirm>
	);
};


export default PopupStopDeleteStepTemplate;
