import React from "react";
import classNames from "classnames";

const TabsNavPanel = props => {
	const { id, activeTab, children, className = "" } = props;

	if (id === activeTab) {
		return (
			<div className={classNames("tabs-nav-panel", className)}>{children}</div>
		);
	}
	return null;
};

export default TabsNavPanel;
