import i18next from "../../config/i18next";
import i18nError from "./i18n/fr.json";
import ErrorBoundary from "./components/error-boundary/error-boundary";
import UnauthorizedPage from "./pages/unauthorized-page/unauthorized-page";
import ErrorNetworkPage from "./pages/error-network-page/error-network-page";
import NotFoundPage from "./pages/not-found-page/not-found-page";
import ErrorDownPage from "./pages/error-down-page/error-down-page";
import ProcessUnfinishedPage from "./pages/process-unfinished-page/process-unfinished-page";

i18next.addResourceBundle("fr", "error", i18nError);

export { ErrorBoundary, UnauthorizedPage, ErrorNetworkPage, NotFoundPage, ErrorDownPage, ProcessUnfinishedPage };
