import React from "react";
import PropTypes from "prop-types";
import { CustomDropdown } from "../../../../theme";
import { binderStatusRules, BinderStatus } from "../../";

import "./status-filter.scss";

/**
 * StatusFilter component
 * @param {Props} props
 * @param {"work-in-progress" | "checking" | "verified" | "validating" | "validated" | "waiting-for-approval" | "approved" | "waiting" | "published" | "revoke" | "cancelled"} props.value - status
 * @param {object} props.name - validation of form input per field
 * @param {object} props.label - form error messages per field
 * @param {function} props.onChange - change handler
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 */
const StatusFilter = (props) => {
	const { value, name, onChange, disabled } = props;

	const handleChange = (event) => {
		const { status } = event.target.value;
		event.target.value = status;
		onChange(event);
	};

	return (
		<div className="status-filter">
			<CustomDropdown
				className="status-filter__field"
				name={name}
				options={binderStatusRules[value]}
				renderOptionValue={binderStatus =>
					<BinderStatus
						className="status-filter__option"
						status={binderStatus?.status}
						arrow={binderStatus?.arrow}
						showArrow/>
				}
				renderActiveValue={binderStatus =>
					<BinderStatus className="status-filter__option"
						status={binderStatus?.status}
						arrow={binderStatus?.arrow}
						showArrow={false}
					/>}
				defaultValue={{ status: value }}
				onChange={handleChange}
				disabled={disabled}/>
		</div>
	);
};


StatusFilter.propTypes = {
	value: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
};

export default StatusFilter;
