
/**
 * @typedef FormUserRoleDescription
 * @property {string[]} editableRole editable role list by user with the described role
 * @property {boolean} allAssociatedLine if `true`, described role have access to all line
 */

const userMetroScope = [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "0", "VA" ];
const userRerScope = [ "A", "B" ];
const userLineScope = userMetroScope.concat(userRerScope);

/**
 * The Form role config map, each field described a role config for the FormUser components
 * @type {{
 * "administrator": FormUserRoleDescription,
 * "admin-regulation": FormUserRoleDescription,
 * "regulation": FormUserRoleDescription,
 * "regulation-rer": FormUserRoleDescription,
 * "admin-line": FormUserRoleDescription,
 * "line-operator-supervisor": FormUserRoleDescription,
 * "line-operator": FormUserRoleDescription,
 * "train-agent": FormUserRoleDescription,
 * "admin-rer": FormUserRoleDescription
 * }} formUserRoleConfig
 *
 */
const formUserRoleConfig = {
	"administrator": {
		editableRole: [
			"administrator",
			"admin-regulation",
			"regulation",
			"admin-line",
			"line-operator-supervisor",
			"line-operator",
			"train-agent",
			"admin-rer",
			"regulation-rer"
		],
		allAssociatedLine: true,
		hasMainLine: false,
		linesAllowed: userLineScope
	},
	"admin-regulation": {
		editableRole: [ "regulation" ],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userMetroScope
	},
	"regulation": {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userMetroScope
	},
	"admin-line": {
		editableRole: [ "line-operator-supervisor", "line-operator", "train-agent" ],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userMetroScope
	},
	"line-operator-supervisor": {
		editableRole: [], allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userMetroScope
	},
	"line-operator": {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userLineScope
	},
	"train-agent": {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: true,
		linesAllowed: userMetroScope
	},
	"admin-rer": {
		editableRole: [
			"regulation-rer",
			"line-operator",
			"train-agent"
		],
		allAssociatedLine: true,
		hasMainLine: false,
		linesAllowed: userRerScope
	},
	"regulation-rer": {
		editableRole: [],
		allAssociatedLine: false,
		hasMainLine: false,
		linesAllowed: userRerScope
	}
};

export default formUserRoleConfig;
