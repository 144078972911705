import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./signal-destination-selector.scss";

/**
 * Render Signal title selector for binder man signal detail page
 * @param {object} props
 * @param {string} props.className
 * @param {string[]} props.signalTitleList
 * @param {string} props.value
 * @param {string} props.name
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const SignalDestinationSelector = (props) => {
	const { className, name, destinationList = [], value = {}, onChange } = props;

	const renderSignalTitleChoice = (signal) => {
		const { tech_id: techId, origin, destination } = signal;
		const key = `${techId}${origin}${destination}`;
		const checked = value.origin === origin && value.destination === destination && value.tech_id === techId;

		return (
			<li key={key} className="signal-destination-selector__choice">
				<label htmlFor={key}>

					<input id={key} name={name} type="radio" checked={checked} onChange={() => onChange(signal)}/>
					<span className="choice__label">{`${origin} -> ${destination}`}</span>
				</label>
			</li>
		);
	};

	return (
		<ul className={classnames("signal-destination-selector", className)}>
			{destinationList.map(renderSignalTitleChoice)}
		</ul>
	);
};

SignalDestinationSelector.propTypes = {
	className: PropTypes.string,
	destinationList: PropTypes.arrayOf(PropTypes.shape({
		tech_id: PropTypes.string,
		title: PropTypes.string,
		origin: PropTypes.string,
		destination: PropTypes.string,
	})).isRequired,
	value: PropTypes.shape({
		tech_id: PropTypes.string,
		title: PropTypes.string,
		origin: PropTypes.string,
		destination: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired
};

export default SignalDestinationSelector;
