const resultsSheetTableLineB = [
	{
		element1: {
			key: "first-element",
			content: [ "EMD", "EMD", "CG CP", "CG CP", "EMD", "EMD", "CG CP", "CG CP", "EMD", "EMD", "CG CP", "CG CP", "EMD", "EMD", "CG CP" ]
		}
	},
	{
		element2: {
			key: "second-element",
			content: [ "CG CP", "EMD", "EMD", "CG CP", "CG CP", "EMD", "EMD", "CG CP", "CG CP", "EMD", "EMD", "CG CP", "CG CP", "EMD", "EMD" ]
		}
	}
];

export default resultsSheetTableLineB;
