import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { requiredFieldValidator, checkFormError } from "../../../../../../shared";
import { Button, IconWrapper } from "../../../../../../theme";
import SignalTitleSelector from "../signal-title-selector/signal-title-selector";
import SignalDestinationSelector from "../signal-destination-selector/signal-destination-selector";
import { listAllBinderSignal } from "../../../../binder-signal.services";
import { ReactComponent as IconArrowRight } from "../../../../../../theme/assets/img/icon-arrow-right.svg";
import "./binder-signal-choice.scss";

/**
 * Render the Signal choice form for a binder a type 'man'
 * @param {object} props
 * @param {object} props.binder
 * @param {function} props.onConfirm
 * @returns {JSX.Element}
 * @constructor
 */
const BinderSignalChoice = (props) => {
	const { binder, onConfirm } = props;
	const [ form, setForm ] = useState({});
	const [ signalList, setSignalList ] = useState([]);

	const { t } = useTranslation();

	const validateForm = (formData) => ({
		tech_id: requiredFieldValidator(formData?.tech_id),
		origin: requiredFieldValidator(formData?.origin),
		destination: requiredFieldValidator(formData?.destination),
		title: requiredFieldValidator(formData?.title)
	});

	const formError = useMemo(() => validateForm(form), [ form ]);

	const retrieveSignalList = () => {
		if (binder?.tech_id) {
			listAllBinderSignal(binder.tech_id).then(response => setSignalList(response.data));
		}
	};

	// Get signal title list without duplicate
	const signalTitleList = useMemo(() => signalList.reduce((result, signal) => {
		if (result.length === 0) {
			return [ { title: signal.title, tech_id: signal.tech_id } ];
		}

		// Insert Signal only if title not exists
		if (!result.some(signalInfo => signalInfo.title === signal.title)) {
			result.push({ title: signal.title, tech_id: signal.tech_id });
		}

		return result;
	}, []), [ signalList ]);

	const destinationList = useMemo(() => signalList.filter(signal => signal.title === form.title), [ signalList, form.title ]);

	const handleSubmit = () => {
		if (!checkFormError(formError)) {
			onConfirm(form);
		}
	};

	const handleChange = (value) => {
		setForm(value);
	};

	useEffect(retrieveSignalList, [ binder?.tech_id ]);
	return (
		<div className="binder-signal-choice">
			<div className="binder-signal-choice__titles">
				<p className="binder-signal-choice__heading">{t("binder:spr-binder-detail.signal-choice.title.head")}</p>
				<p className="binder-signal-choice__text">{t("binder:spr-binder-detail.signal-choice.title.text")}</p>
				<SignalTitleSelector
					name="title"
					className="binder-signal-choice__titles__list"
					signalTitleList={signalTitleList}
					value={form}
					onChange={handleChange}/>
			</div>
			<IconWrapper className="binder-signal-choice__arrow" Component={IconArrowRight}/>
			<div className="binder-signal-choice__destinations">
				<p className="binder-signal-choice__heading">{t("binder:spr-binder-detail.signal-choice.destination.head")}</p>
				<p className="binder-signal-choice__text">{t("binder:spr-binder-detail.signal-choice.destination.text")}</p>
				<div className="destinations__wrapper">
					{form?.title && <span className="destinations__selected-title">{`${form.title} sélectionné`}</span>}
					<SignalDestinationSelector
						name="signal"
						className="destinations__list"
						destinationList={destinationList}
						value={form} onChange={handleChange}/>
				</div>
				<Button
					variant="primary"
					className="binder-signal-choice__submit"
					onClick={handleSubmit}
					disabled={checkFormError(formError)}
					label={t("binder:spr-binder-detail.signal-choice.validate")}
				/>
			</div>
		</div>
	);
};

BinderSignalChoice.propTypes = {
	binder: PropTypes.shape({
		tech_id: PropTypes.string
	}).isRequired,
	onConfirm: PropTypes.func.isRequired
};

export default BinderSignalChoice;
