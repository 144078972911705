import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../theme/components/form/button/button";
import { ReactComponent as IconSortAz } from "../../../../../../theme/assets/img/icon-sort-az.svg";
import { ReactComponent as IconCalendar } from "../../../../../../theme/assets/img/icon-calendar.svg";
import "./binder-filters-sort.scss";


const BinderFiltersSort = (props) => {
	const { binders, sort, updateSort } = props;
	const { t } = useTranslation();

	return (
		<div className="binder-filters-sort" onClick={(e) => e.stopPropagation()}>
			<div className="binder-filter-sort__subtitle">
				{t("binder:binder-list.filter.binder-count", { count: binders?.length })}
			</div>
			<span className="binder-filters-sort__label">{t("binder:binder-list.filter.sort")}</span>
			<Button
				className="binder-filters-sort"
				selected={sort?.sortBy === "id"}
				variant="switch"
				onClick={() => updateSort("id", "asc")}
				icon={IconSortAz}
				iconTooltipContent={t("binder:binder-list.filter.sort-alphabetic")}
			/>
			<Button
				selected={sort?.sortBy === "last_change_at"}
				variant="switch"
				onClick={() => updateSort("last_change_at", "desc")}
				icon={IconCalendar}
				iconTooltipContent={t("binder:binder-list.filter.sort-date")}
			/>
		</div>
	);
};

export default BinderFiltersSort;
