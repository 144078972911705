import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { sheetStatusMap } from "../../";
import "./sheet-status.scss";


/**
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.status
 * @param {string} props.tooltip
 * @param {boolean} props.warning
 * @returns {JSX.Element}
 */
const SheetStatus = props => {
	const { className, status, warning, tooltip } = props;

	const { t } = useTranslation();

	const initClassName = (baseClassName) => classNames(baseClassName, className, {
		[`${baseClassName}--changed`]: status === sheetStatusMap.changed,
		[`${baseClassName}--warning`]: warning
	});

	const getLabel = () => status === sheetStatusMap.changed ? t("sheet:status.changed") : "";

	return (
		<span className={initClassName("sheet-status")}>
			{getLabel()}
			{warning && tooltip && <span className="sheet-status__tooltip__wrapper"><span
				className="sheet-status__tooltip__text">{tooltip}</span></span>}
		</span>
	);
};

SheetStatus.propTypes = {
	className: PropTypes.string,
	status: PropTypes.string,
	tooltip: PropTypes.string,
	warning: PropTypes.bool
};

export default SheetStatus;