import { getEntityList } from "../entity/entity-utils";

/**
 * Get api item reference depending on entity
 * @param entity Entity name
 * @param item Entity details
 * @returns {string}
 */
const getApiItemRef = (entity, item) => {
	switch (entity) {
	case "sheet":
		return item?.data?.sheet_id;
	case "image":
		return item.ref;
	default:
		return item?.data?.tech_id;
	}
};

/**
 * Get api item last update date depending on entity
 * @param entity Entity name
 * @param item Entity details
 * @returns {Date}
 */
const getApiItemLastChangeDate = (entity, item) => {
	// Erase cacheData by returning the newest date by default
	if (entity === "train") {
		return new Date(item?.data.last_update_at || Date.now());
	}
	return new Date();
};

/**
 * Get publication pending entity from cache
 * Return only entity ready to be publish to api
 * @param {string} line The current line
 * @returns {Promise<CacheEntity[]>}
 */
const getPendingEntity = async (line) => {
	return getEntityList("process", { isPending: true, status: "closed", line });
};

/**
 * Replace all provided object 's properties by newValue
 * @param {{[p: string]: any}} [object={}]
 * @param {boolean} [newValue=true]
 * @return {{[p: string]: boolean}}
 */
const replaceAllPropertyValue = (object = {}, newValue= true) => {
	const replacedEntries = Object.keys(object).map(fieldName => [ fieldName, newValue ]);
	return Object.fromEntries(replacedEntries);
};

/**
 * Check if at least one property in provided object is true
 * @param {{[p: string]: boolean}} object
 * @return {boolean}
 */
const isSomeTruthyProperty = (object) =>  Object.values(object).some(pending => pending);

export { getApiItemRef, getApiItemLastChangeDate, getPendingEntity, replaceAllPropertyValue, isSomeTruthyProperty };
