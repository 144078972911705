import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { getImageUrl } from "../../image.services";
import { useSprContext } from "../../../../shared";
import { getEntity } from "../../../../shared/cache-access/entity/entity-utils";
import "./sheet-image-loader.scss";

const SheetImageLoader = (props) => {
	const { className, imageId, tag } = props;
	const [ loadError, setLoadError ] = useState(false);
	const [ imageSrc, setImageSrc ] = useState();
	const { associatedLine = "" } = useSprContext();

	const handleImageLoadError = () => {
		getEntity("image", imageId, associatedLine).then((blob) => {
			const src = URL.createObjectURL(blob?.data?.light);
			setImageSrc(src);
		}).catch(() => {
			setLoadError(true);
		});
	};

	const getImage = () => {
		if (imageId) {
			getImageUrl(imageId, tag).then((resp) => {
				setImageSrc(URL.createObjectURL(resp.data));
			}).catch(() => {
				handleImageLoadError();
			});
		}
	};

	// eslint-disable-next-line
	useEffect(getImage, [ imageId, tag ]);

	if (loadError) {
		// Error on image load (not found for instance)
		return (
			<span className={classNames("sheet-image-loader sheet-image-loader--unavailable", className)}>
				Image non disponible
			</span>
		);
	} else if (imageId) {
		return (
			<img
				className={classNames("sheet-image-loader", className)}
				src={imageSrc}
				onError={handleImageLoadError}
				alt=""
			/>
		);
	} else {
		return (
			<span className={classNames("sheet-image-loader sheet-image-loader--empty", className)}/>
		);
	}
};

export default SheetImageLoader;

