import { useLocation } from "react-router-dom";

/**
 * util hook to check if in read context
 * @returns {{
 * isInReadContext: boolean,
 * isInLiveReadContext: boolean
 * }} true if in a read context
 */
const useInReadContextCheck = () => {
	const { pathname } = useLocation();
	const isInReadContext = pathname.includes("/read");
	const isInLiveReadContext = isInReadContext && pathname.includes("run");
	return { isInReadContext, isInLiveReadContext };
};

export default useInReadContextCheck;
