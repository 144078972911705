import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { getElementNumberMax } from "../../../utils/get-car-element-info";
import classNames from "classnames";

const BreakTestLineBSummary = (props) => {
	const { breakTestGroup = {}, trainComposition, material, isProcessRecap = false } = props;
	const { break_test: breakTest } = breakTestGroup || {};
	const hasBreakTest = breakTest === "yes";

	const bogiesInfo = Object.entries(breakTestGroup).slice(2) || [];

	const { t } = useTranslation();

	return (
		<>
			<div className={"rer-resume-summary__substep"}>
				<span className={classNames("rer-resume-summary__substep__title", { "rer-resume-summary__substep__title--lighter": isProcessRecap })}>
					{t("process:process-rer-resume.results-sheet.break-test")}
				</span>
				<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
					{t(`process:process-rer-resume.results-sheet.break-test-form.${breakTest}`)}
				</span>
			</div>
			{hasBreakTest && <div>
				{ bogiesInfo.map(([ key, value ]) => {
					return (
						<div className={"rer-resume-summary__substep--secondary"}>
							<span className={classNames("rer-resume-summary__substep__title--secondary", { "rer-resume-summary__substep__title--secondary--lighter": isProcessRecap })}>
								{t(`process:process-rer-resume.summary.${key}`)}
							</span>
							<div className={"rer-resume-summary__substep__value"}>
								<span className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}>
									{value}
								</span>
								<Trans
									parent="span"
									className={classNames("rer-resume-summary__substep__value", { "rer-resume-summary__substep__value--lighter": isProcessRecap })}
									i18nKey="process:process-rer-resume.summary.operator"
									values={{
										name: "bogies",
										number: getElementNumberMax("bogies", trainComposition, material)
									}}>
									<strong className="damage-form-number-selector__label--bold"/>
								</Trans>
							</div>
						</div>
					);
				})}
			</div>}
		</>
	);
};

export default BreakTestLineBSummary;
