import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { formatJsonDate, useRailwayOfflineContext, useAppSetupContext } from "../../../../index";
import { IconWrapper } from "../../../../../theme";
import { dateFormatDate } from "../../../../../domains/process/utils";
import { isLastActivityRecent } from "../../../../cache-access/entity/status-utils";
import { Close as IconClose, IconCheck, IconDownload, IconSpinner } from "../../../../../theme/assets/img";
import "./sync-status.scss";

/**
 * Render SyncStatus component
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 * @constructor
 */
const SyncStatus = ({ disabled }) => {
	const { online } = useAppSetupContext();
	const { syncStatus } = useRailwayOfflineContext();
	const { t } = useTranslation();

	const { text, icon } = useMemo(() => {
		const { lastSyncDate, state } = syncStatus.data;

		if (state === "success" && online){
			// Last sync end as success and app is online
			return {
				text: t("sync-text.up-to-date"),
				icon: IconCheck
			};
		} else if (syncStatus.data.state === "success" && !online){
			// Last sync end as success and app is offline
			return {
				text: t("sync-text.verified", { date: formatJsonDate(lastSyncDate, dateFormatDate) }),
				icon: IconDownload
			};
		} else if ( syncStatus.data.state === "in-progress" && isLastActivityRecent(syncStatus) ) {
			// Last sync is currently in progress
			return {
				text: t("sync-text.download"),
				icon: IconSpinner
			};
		}

		return {
			text: t("sync-text.outdated"),
			icon: IconClose
		};
	}, [ syncStatus, online, t ]);

	const initClassName = () => classNames(
		"sync-status",
		{
			"sync-status--disabled": disabled
		}
	);

	return (
		<div className={initClassName()}>
			<IconWrapper className="sync-status__icon" Component={icon} />
			<span>{text}</span>
		</div>
	);
};

export default SyncStatus;
