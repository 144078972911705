import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import "./results-sheet-radio-button-with-input.scss";

// TODO make the component more generic
const ResultsSheetRadioButtonWithInput = (props) => {
	const { resultsSheetFormData, options, field, handleChange, currentGroup } = props;
	const { name: fieldName, key } = field;
	const { t } = useTranslation();
	const isFieldsWithPressure = fieldName === "zalidi_position" || fieldName === "kadi_position";
	const isSpeedDecreaseField = fieldName === "speed_decrease";

	const isMultipleValueFields = options.length > 2 || isFieldsWithPressure;
	const displayInputField = isSpeedDecreaseField || resultsSheetFormData?.[currentGroup][fieldName]?.choice !== "";

	const clearInput = () => {
		document.getElementById("input-field").value = "";
	};

	const onChange = async (event) => {
		const { name, value } = event.target;
		const dataToKeep = resultsSheetFormData?.[currentGroup][fieldName];
		if (name === fieldName && isSpeedDecreaseField) {
			clearInput();
			handleChange({ target: { group: currentGroup, name: fieldName, value: { choice: value } } });
		} else if (name === fieldName && isFieldsWithPressure) {
			handleChange({ target: { group: currentGroup, name: fieldName, value: { ...dataToKeep, choice: value } } });
		} else if (name === "other-restriction") {
			handleChange({ target: { group: currentGroup, name: fieldName, value: { other_restriction: value } } });
		} else if (name === "pressure") {
			handleChange({ target: { group: currentGroup, name: fieldName, value: { ...dataToKeep, pressure: value } } });
		}
	};

	const handleKeyPressPressure = (e) => {
		const regex = /^[0-9,]{0,4}$/;
		const validRegex = regex.test(e.key);
		if (!validRegex) {
			e.preventDefault();
		}
	};

	const handleKeyPressSpeedDecrease = (e) => {
		const regex = /^[a-zA-Z-0-9/]{0,15}$/;
		const validRegex = regex.test(e.key);
		if (!validRegex) {
			e.preventDefault();
		}
	};

	return (
		<div className={classNames("process-results-sheet__radio-button-input", { "process-results-sheet__radio-button-input--column": isSpeedDecreaseField })}>
			<div className="process-results-sheet__radio-button-input__title-wrapper">
				<span className="process-results-sheet__radio-button-input__title-wrapper__title">
					{t(`process:process-rer-resume.results-sheet.${key}`)}
				</span>
			</div>
			<ul className={classNames("process-results-sheet__radio-button-input__list", { "process-results-sheet__radio-button-input__list--multiple-values-field": isMultipleValueFields })}>
				{options.map((option)=> {
					const selected = resultsSheetFormData?.[currentGroup][fieldName] === option || resultsSheetFormData?.[currentGroup][fieldName]?.choice === option;
					return <li className="process-results-sheet__radio-button-input__list__item">
						<input
							className="process-results-sheet__radio-button-input__list__input-field"
							name={fieldName}
							type={"radio"}
							checked={selected}
							value={option}
							onClick={onChange}
						/>
						<label htmlFor={option} className="process-results-sheet__radio-button-input__list__item__label">
							{t(`process:process-rer-resume.results-sheet.${key}-form.${option}`)}
						</label>
					</li>;
				})}
				{displayInputField &&
					<>
						{!isSpeedDecreaseField && <div className={"damage-popup-damage-location__form-wrapper__input-divider"} />}
						<label className={"damage-popup-damage-location__form-wrapper__input-wrapper"}>
							<input
								id="input-field"
								aria-label="Enter character"
								placeholder={t(`process:process-rer-resume.results-sheet.${key}-form.other`)}
								name={isSpeedDecreaseField ? "other-restriction" : "pressure"}
								type="text"
								className="input-wrapper__text-area"
								value={isSpeedDecreaseField ? resultsSheetFormData?.[currentGroup][fieldName].other_restriction : resultsSheetFormData?.[currentGroup][fieldName].pressure}
								onChange={onChange}
								onKeyPress={isSpeedDecreaseField ? handleKeyPressSpeedDecrease : handleKeyPressPressure}
								required={false}
								maxLength={isSpeedDecreaseField ? 6 : 4}
							/>
						</label>
					</>
				}
			</ul>
		</div>
	);
};

export default ResultsSheetRadioButtonWithInput;
