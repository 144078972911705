import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import { useSheetContentContext } from "../../../../../context/sheet-content-context";
import { useDebouncedAction } from "../../../../../../../shared";
import AppendixFormRow from "./appendix-form-row/appendix-form-row";
import { fetchSheetByBinder } from "../../../../../sheet.services";
import { addSheetsTitleRaw } from "../../../../../utils/add-sheet-title-raw-utils/add-sheet-title-raw-utils";

import "./appendix-form.scss";


const AppendixForm = (props) => {
	const { content = {} } = props;
	const { t } = useTranslation();
	const { binderId } = useParams();
	const { setCurrentNodeContent, updateDelay } = useSheetContentContext();
	const debouncedSetCurrentNodeContent = useDebouncedAction(setCurrentNodeContent, updateDelay);

	const [ appendixList, setAppendixList ] = useState( content?.appendixes || []);
	const [ binderAppendixSheetList, setBinderAppendixSheetList ] = useState([]);

	const updateActiveNodeContent = (newAppendixRedirection) => {
		setAppendixList(newAppendixRedirection);
		const selectedAppendixRedirection = newAppendixRedirection?.map(({ sheetNumber, sheetIdParam, appendixId, sheetColor }) => ({ sheetNumber, sheetIdParam, appendixId, sheetColor }));
		debouncedSetCurrentNodeContent({
			...content,
			appendixes: selectedAppendixRedirection
		});
	};

	const updateAppendixContent = (position, params) => {
		const { sheetNumber, sheetId: sheetIdParam, sheetColor } = params;
		const newAppendixContent = {
			sheetNumber,
			sheetIdParam,
			sheetColor,
			appendixId: uuid()
		};
		const newAppendixContentList = [ ...appendixList ];
		newAppendixContentList[position] = newAppendixContent;
		updateActiveNodeContent(newAppendixContentList);
	};

	const addForm = () => {
		const newFormData = { sheetNumber: "", appendixId: "" };
		updateActiveNodeContent([ ...appendixList, newFormData ]);
	};

	const handleDeleteRow = position => async () => {
		const newAppendixList = appendixList.filter((elm, elmPos) => position !== elmPos);
		updateActiveNodeContent(newAppendixList);
	};

	const initBinderAppendixSheetsList = () => {
		const param = {
			"type": [ "appendix" ]
		};
		fetchSheetByBinder(binderId, param).then((response) => {
			if (response?.data) {
				const sheetWithPlainTitle = addSheetsTitleRaw(response.data);
				setBinderAppendixSheetList(sheetWithPlainTitle);
				return response.data;
			}
		});
	};

	const renderFormRow = (data, position) => {
		const { sheetIdParam } = data;
		return (
			<AppendixFormRow
				key={position + 1}
				sheetIdParam={sheetIdParam}
				onDeleteRow={handleDeleteRow(position)}
				index={position}
				updateContent={updateAppendixContent}
				binderAppendixSheetList={binderAppendixSheetList}
				appendixList={appendixList}
			/>
		);
	};

	// Clear appendixList when the type of active node has been changed by the user and therefore has no appendix yet
	const clearAppendixList = () => {
		const hasAppendixes = !!content?.appendixes;
		if (!hasAppendixes) {
			setAppendixList([]);
		}
	};

	useEffect(clearAppendixList, [ content?.appendixes ]);
	useEffect(initBinderAppendixSheetsList, [ binderId ]);

	return (
		<div className="appendix-form">
			<ul className={classNames("appendix-form__list", { "appendix-form__list--empty": !appendixList || (appendixList && appendixList.length === 0) })}>
				{appendixList && appendixList?.map(renderFormRow)}
			</ul>
			<button className="appendix-form__button" type="button" onClick={addForm}>{t("sheet:sheet-details.appendix-form.add-appendix")}</button>
		</div>
	);
};

export default AppendixForm;
