/**
 * @typedef Train
 * @prop {String} id
 * @prop {Number} line
 * @prop {String} material_tech_id
 * @prop {String} brake_system
 * @prop {boolean} brake_isolation
 * @prop {boolean} engine_system
 * @prop {Number} car_number
 * @prop {String} binder_auto
 * @prop {String} binder_driver
 * @prop {String} binder_officer
 */

/**
 * create train form object
 * @param {Train} train 
 * @param {boolean} [includeId = true] 
 * @returns {Train}
 */
export const createTrainForm = (train, includeId = true) =>( {
	id: includeId ? train.id : undefined,
	material_tech_id: train.material_tech_id,
	brake_system: train.brake_system,
	car_number: train.car_number.toString(),
	binder_auto: train.binder_auto || null,
	binder_driver: train.binder_driver || null,
	binder_officer: train.binder_officer || null,
	brake_isolation: typeof train.brake_isolation === "boolean" ? train.brake_isolation : null,
	engine_system: typeof train.engine_system === "boolean" ? train.engine_system : null,
});

/**
 * remove train binders
 * @param {Train} train 
 * @param {boolean} [includeId = true] 
 * @returns {Train}
 */
export const removeTrainBinders = (train) => {
	const { binder_auto, binder_driver, binder_officer, ...trainWithoutBinders } = train;
	return trainWithoutBinders;
};

/**
 * remove train binders
 * @param {Train} formData  
 * @param {{target: keyof Train, value: any}} event
 * @param {'create'|'update'|''} changeType
 * @returns {Train}
 */
export const createTrainFormOnChange = (formData, event, ) => {
	const binderFields = [ "binder_auto", "binder_driver", "binder_officer" ];
	const booleanFields = [ "brake_isolation","engine_system" ];
	const { name, value } = event.target;
	let newFormData = { ...formData };
	if (name === "material_tech_id") {
		newFormData = removeTrainBinders(newFormData);
	}
	// reset any binder selected when choosing placeholder in list
	if (binderFields.includes(name) && value === "") {
		newFormData[name] = null;
	} else if (booleanFields.includes(name)) {
		const booleans = { true: true, false: false };
		newFormData[name] = booleans[value];
	} else {
		newFormData[name] = value;
	}
	return newFormData; 
};