import React, { useEffect } from "react";
import classname from "classnames";
import PropTypes from "prop-types";
import { Dropdown } from "../../../../theme";

/**
 * Render a dropdown components to select a station
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.value
 * @param {string} [props.defaultValue = ""]
 * @param {string} props.name
 * @param {string} props.label
 * @param {string} props.placeholder
 * @param {function} props.onChange
 * @param {boolean} props.disabled
 * @param {[]object} [props.stationList = []]
 * @returns {JSX.Element}
 */
const StationDropdown = (props) => {
	const { className, value, defaultValue = "", name, label, placeholder, onChange, disabled, stationList = [] } = props;

	const renderOption = () => {
		return stationList.map(station =>
			<option key={station?.tech_id} value={station?.tech_id}>{station?.label}</option>);
	};

	useEffect(() => {
		// Set sheet as default value if no value provided
		if (!value) {
			onChange({ target: { name, value: defaultValue } });
		}
		// ignore onChange dependency to avoid infinite render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ name, value, defaultValue ]);

	return (
		<Dropdown className={classname("station-dropdown", className)} value={value} name={name} onChange={onChange}
		          label={label} placeholder={placeholder} disabled={disabled}>
			{renderOption()}
		</Dropdown>
	);
};

StationDropdown.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	stationList: PropTypes.arrayOf(PropTypes.shape({
		tech_id: PropTypes.string,
		label: PropTypes.string
	})).isRequired
};

export default StationDropdown;
