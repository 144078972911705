import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PopupConfirm, EditorRendering } from "../../../../theme";
import { resolveSheetColor, SheetType } from "../../";
import { deleteSheet } from "../../sheet.services";
import "./popup-delete-sheet.scss";

/**
 * Render the PopupDeleteSheet components popup
 * @param {object} props
 * @param {function} props.onConfirm
 * @param {object} props.sheet
 * @param {object} props.popupControl
 * @returns {JSX.Element}
 */
const PopupDeleteSheet = props => {
	const {
		onConfirm = () => {}, sheet = {}, defaultColor, popupControl
	} = props;
	const { t } = useTranslation();
	const {
		number: sheetNumber,
		title,
		binder_tech_id: binderTechId,
		sheet_id: sheetId,
		type,
		color
	} = sheet;

	const handleDelete = () => {
		deleteSheet(binderTechId, sheetId).then(onConfirm);
	};

	return (
		<PopupConfirm
			className="popup-delete-sheet__wrapper"
			popupControl={popupControl}
			onConfirm={handleDelete}
			title={t("sheet:popup.delete.title")}
			ctaConfirmLabel="sheet:popup.delete.cta-confirm-label"
		>
			<div className="popup-delete-sheet">
				<h1 className="popup-delete-sheet__title">{t("sheet:popup.delete.text.title")}</h1>
				<p className="popup-delete-sheet__question">{t("sheet:popup.delete.text.question")}</p>
				<div className="popup-delete-sheet__sheet">
					<SheetType className="sheet__number" type={type} sheetNumber={sheetNumber}
					           color={resolveSheetColor(color, defaultColor)}/>
					<EditorRendering className="sheet__title" content={title}/>
				</div>
				<p className="popup-delete-sheet__note">{t("sheet:popup.delete.text.note")}</p>
			</div>
		</PopupConfirm>
	);
};

PopupDeleteSheet.propTypes = {
	onConfirm: PropTypes.func,
	sheet: PropTypes.shape({
		number: PropTypes.string.isRequired,
		title: PropTypes.object.isRequired,
		binder_tech_id: PropTypes.string.isRequired,
		sheet_id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		color: PropTypes.string.isRequired
	}).isRequired,
	popupControl: PropTypes.shape({
		visible: PropTypes.bool.isRequired,
		hide: PropTypes.func.isRequired,
		show: PropTypes.func.isRequired
	}).isRequired,
	defaultColor: PropTypes.string
};

export default PopupDeleteSheet;
