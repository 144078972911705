import React from "react";
import { PopupWrapper } from "../../../../shared";
import { IconWrapper } from "../../..";
import "./popup-offline.scss";

const PopupOffline = props => {
	const {
		popupControl,
		icon,
		title,
		children,
	} = props;

	return (
		<PopupWrapper visible={popupControl.visible}>
			<div className="popup-offline">
				<div className="popup-offline__header">
					{icon && <IconWrapper className="popup-offline__icon" Component={icon}/>}
					<h1 className="popup-offline__title">{title}</h1>
				</div>
				<div className="popup-offline__body">{children}</div>
			</div>
		</PopupWrapper>
	);
};

export default PopupOffline;
