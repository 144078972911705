import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	getSprStorageContent,
	setStoredAssociatedLine,
	setStoredCurrentBinder,
	setStoredSide,
	setStoredSignalList,
	setStoredTrainBinderCount
} from "./spr-context-localstorage-utils";
import { listAllSignalByLine } from "../../../domains/binder/binder-signal.services";
import { searchBinder } from "../../../domains/binder/binder.services";
import { getProcessList } from "../../../domains/process/process.services";
import { useAppSetupContext } from "../..";
import { prepareProcessData } from "./spr-context-prepare-process-utils";

const SprContext = createContext({});

const trainBindersTypes = [ "train-driver", "train-officer", "train-auto" ];

const SprContextProvider = props => {
	const { children } = props;
	const { pathname } = useLocation();
	const { user = {} } = useAppSetupContext();
	const [ content, setContent ] = useState(getSprStorageContent() || {});
	const [ newInProgressProcess, setNewInProgressProcess ] = useState([]);
	const [ processLoading, setProcessLoading ] = useState(true);
	const [ areTrainBindersPublished, setAreTrainBindersPublished ] = useState(false);
	const hasUser = useMemo(() => Object.keys(user || {}).length > 0, [ user ]);

	const storedContent = getSprStorageContent();

	/**
	 * Load SprContext stored content
	 */
	const loadStoredContent = () => {
		const storageContent = getSprStorageContent();
		setContent(storageContent);
	};

	/**
	 * Update currentBinder in state and storage
	 * @param {object} currentBinder
	 * @param {boolean} [copyOldBinder = false] optionaly copy old properties when creating new binder
	 */
	const setCurrentBinder = useCallback((currentBinder, copyOldBinder = false) => {
		setContent(oldContent => {
			const oldBinder = oldContent?.currentBinder || {};
			const binder = copyOldBinder ? { ...oldBinder, ...currentBinder } : currentBinder;
			setStoredCurrentBinder(binder);
			return { ...oldContent, currentBinder: binder };
		});
	}, [ setContent ]);

	/**
	 * Update signalList in state and storage
	 * @param {string} line
	 */
	const setSignalList = useCallback((signalList) => {
		setContent(oldContent => {
			setStoredSignalList(signalList);
			return { ...oldContent, signalList };
		});
	}, []);

	/**
	 * reset currentBinder in state and storage
	 */
	const resetCurrentBinder = useCallback(() => {
		setContent({});
		setStoredCurrentBinder({});
	}, [ setContent ]);

	/**
	 * Update train binder count
	 * @param {number} trainBinderCount
	 */
	const setTrainBinderCount = useCallback((trainBinderCount) => {
		setContent(oldContent => {
			setStoredTrainBinderCount(trainBinderCount);
			return { ...oldContent, trainBinderCount };
		});
	}, []);

	/**
	 * Update side in state and storage
	 * @param currentBinder
	 */
	const setSide = useCallback((newSide) => {
		setStoredSide(newSide);
		setContent(oldContent => {
			return { ...oldContent, side: newSide };
		});
	}, []);

	/**
	 * Update newInProgressProcess list in state
	 * @param currentProcessList
	 */
	const setInProgressProcessList = useCallback((currentProcessList) => {
		setNewInProgressProcess(currentProcessList);
		setProcessLoading(false);
	}, [ setNewInProgressProcess ]);

	/**
	 * Process pages need a main associated line set here
	 * @param line
	 * @returns {Promise<void>}
	 */
	const setAssociatedLine = async (line) => {
		//try loading signalList to display signal info with sheets numbers in processes pages
		const response = await listAllSignalByLine(line);
		setSignalList(response?.data);
		setStoredAssociatedLine(line);
		loadStoredContent();
	};

	/**
	 * Get the different side between SL flow (railway) and SPR flow (spr)
	 */
	const initiateSide = () => {
		if (pathname.startsWith("/spr")) {
			setSide("pcc");
		} else if (pathname.startsWith("/railway")) {
			setSide("train");
		}
	};

	/**
	 * Get Binder Train published to enable Train features if exists
	 */
	const hasBinderTrainPublished = () => {
		if (hasUser) {
			const searchParams = {
				associatedLine: storedContent?.associatedLine,
				status: "published",
				type: trainBindersTypes
			};
			searchBinder(searchParams).then(({ data: responseData }) => {
				if (responseData && responseData.length > 0) {
					setAreTrainBindersPublished(true);
				}
			}).catch((e) => console.error(e));
		}
	};

	/**
	 * Get in progress Process with join data
	 * @returns {Promise<void>}
	 */
	const fetchCurrentProcesses = async () => {
		const { associatedLine } = storedContent;
		const { side } = content;
		try {
			if (hasUser && associatedLine) {
				const type = side === "train" ? "train" : null;
				const processParams = {
					extendUser: true,
					associatedLine,
					status: "in-progress",
					orderBy: "created_at",
					orderSort: "DESC",
					filterSlFlag: side === "train",
					...(type && { type }),
				};

				const response = await getProcessList(processParams, user);

				if (response) {
					const { data } = response;
					const processList = await prepareProcessData(data, side, user);
					setInProgressProcessList(processList);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};


	useEffect(loadStoredContent, []);
	useEffect(initiateSide, [ pathname, setSide ]);
	useEffect(hasBinderTrainPublished, [ storedContent?.associatedLine, hasUser ]);


	return (
		<SprContext.Provider
			value={{
				associatedLine: storedContent.associatedLine,
				currentBinder: content.currentBinder,
				setStoredAssociatedLine: setAssociatedLine,
				setTrainBinderCount,
				trainBinderCount: content.trainBinderCount,
				signalList: content.signalList,
				setCurrentBinder,
				resetCurrentBinder,
				newInProgressProcess,
				setInProgressProcessList,
				processLoading,
				setProcessLoading,
				side: content.side,
				setSide,
				trainLinksVisible: areTrainBindersPublished,
				fetchCurrentProcesses
			}}>
			{children}
		</SprContext.Provider>
	);
};

/**
 * use the spr context hook
 * @returns {{
 * 	associatedLine: number,
 * 	currentBinder: object,
 * 	setStoredAssociatedLine: function(associatedLine: number): Promise<void>,
 * 	setTrainBinderCount: function(trainBinderCount: number): void,
 * 	trainBinderCount: number,
 * 	setCurrentBinder: function(currentBinder: object, copyOldBinder: boolean): void,
 * 	newInProgressProcess: object,
 * 	setInProgressProcessList: function(currentProcessList: object[]): void,
 *  processLoading: boolean,
 *  setProcessLoading: function(boolean): void,
 * 	side: "train" | "pcc",
 * 	setSide: function(newSide: string): void,
 *	trainLinksVisible: boolean
 * }}
 */
const useSprContext = () => useContext(SprContext);


export { SprContext, SprContextProvider, useSprContext };
