import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DottedCard, EditorRendering, IconWrapper, SprPage, Button } from "../../../../theme";
import {
	filterSheetByType,
	formatJsonDate,
	getSignalInfo,
	LoadWrapper,
	unpadStartNumberInside,
	useAppSetupContext,
	useRailwayOnlineReturn,
	useSprContext
} from "../../../../shared";
import ProcessListItemActions from "./components/process-list-item-actions/process-list-item-actions";
import ProcessListSubheader from "./components/process-list-subheader/process-list-subheader";
import { getProcessList, updateProcess } from "../../process.services";
import { SheetType } from "../../../sheet";
import { dateFormatDate, dateFormatTime, mapProcessList } from "../../utils";
import { ReactComponent as IconTrain } from "../../../binder/assets/icon-train.svg";
import { ReactComponent as IconPcc } from "../../../binder/assets/icon-pcc.svg";
import { IconCheck } from "../../../../theme/assets/img";
import { IconError, IconHourglass } from "../../assets";

import "./process-list.scss";

const sprPrefix =  "/spr";
const railwayPrefix = "/railway";
const processBaseUrl = "/process/";

/**
 * Render the ProcessList component
 * @return {JSX.Element}
 * @constructor
 */
const ProcessList = () => {
	const { t } = useTranslation();
	const [ closedProcessList, setClosedProcessList ] = useState([]);
	const [ openProcessList, setOpenProcessList ] = useState([]);
	const [ pageLoading, setPageLoading ] = useState(true);
	const { user = {} } = useAppSetupContext();

	useRailwayOnlineReturn(true);

	const { associatedLine, signalList, side } = useSprContext();

	const loadOpenProcesses = async () => {
		const { data } = await getProcessList({
			associatedLine,
			status: "in-progress",
			orderBy: "started_at",
			orderSort: "DESC",
			filterSlFlag: side === "train",
			"-started_at": "null",
		}, user);
		if (Array.isArray(data)) {
			try {
				setOpenProcessList(mapProcessList(data));
			} catch (error) {
				console.error(error);
			}
		}
	};

	const loadClosedProcesses = async () => {
		const { data } = await getProcessList({
			extendUser: true,
			associatedLine,
			status: [ "closed", "aborted" ],
			orderFlag: "lastUpdated",
			orderSort: "DESC",
			filterSlFlag: side === "train",
			"-started_at": "null",
		}, user);
		if (Array.isArray(data)) {
			try {
				setClosedProcessList(mapProcessList(data));
			} catch (error) {
				console.error(error);
			}
		}
	};

	const init = () => {
		if (associatedLine) {
			setPageLoading(true);
			Promise
				.allSettled([ loadOpenProcesses(), loadClosedProcesses() ])
				.finally(()=>setPageLoading(false));
		}
	};



	const renderSheet = (sheet = {}) => {
		return (
			<li key={sheet.number}>
				<SheetType
					className={`sheet-block sheet-block--${sheet.type}`}
					color={sheet.color}
					type={sheet.type}
					sheetNumber={unpadStartNumberInside(sheet.number)}
					signalInfo={getSignalInfo(signalList, sheet.signalTechId)}
					binderType={sheet.binder_type}
				/>
			</li>
		);
	};

	const filterDisplaySheet = (sheets) => {
		if (sheets.length <= 1) {
			return sheets;
		} else {
			const firstSheetPos = sheets.findIndex(sheet => sheet?.type !== "determination" || sheet?.type !== "m");
			return firstSheetPos === -1 ? sheets : sheets.slice(firstSheetPos);
		}
	};

	const generateSheetHistory = (sheets) => {
		if (sheets.length > 1) {
			sheets = filterSheetByType(sheets, "type");
		}
		return <ul className="browsed-sheets">
			{renderSheet(sheets[0])}
			{sheets.length >= 3 && <DottedCard/>}
			{sheets.length >= 2 && renderSheet(sheets[sheets.length - 1])}
		</ul>;
	};

	const handleUpdateProcess = async (process) => {
		try {
			await updateProcess(process.tech_id, process, { line: associatedLine });
			init();
		} catch (error) {
			console.error(error);
		}
	};

	const generateIcon = (processType, trainId = "") => {
		return (
			<div className="icon__wrapper">
				<IconWrapper className="icon__image" Component={processType === "train" ? IconTrain : IconPcc} />
				{ trainId && <span className="icon__train-id">{trainId}</span>}
			</div>
		);
	};

	const generateProcessItem = (process, closed = true) => {
		const {
			tech_id: techId,
			started_at: startAt,
			ended_at: endedAt,
			train_id: trainId,
			browsedSheets = [],
			type,
			metadata
		} = process;

		const prefixUrl = side === "train" ? railwayPrefix : sprPrefix;
		const urlProcessReport = `${prefixUrl}${processBaseUrl}${techId}`;

		const displayedSheets = filterDisplaySheet(browsedSheets);
		const firstSheetTitle = displayedSheets[0]?.title || "";

		let syncStatusIcon = IconError;
		if (!metadata?.isPending) {
			syncStatusIcon = IconCheck;
		} else if (!metadata?.isSyncFailed) {
			syncStatusIcon = IconHourglass;
		}

		return (
			<li key={techId} className="process-list__list__item process-list__list__row">
				<div className="item__cell__type__label">
					<span className="item__cell__type__title">{t("process:process-list.binder-type")}</span>
					{generateIcon(type, trainId)}
				</div>
				<div className="item__cell__sheets__label">
					<span className="item__cell__sheets__process-sheets-title">{t("process:process-list.process-sheets")}</span>
				</div>
				<div className="item__cell__sheets__sheets-list">{generateSheetHistory(displayedSheets)}</div>
				<div className="item__cell__sheet__first-sheet__label">
					<span>{t("process:process-list.first-sheet")}</span>
				</div>
				{/* TODO refacto editor rendering -> no data parsing */}
				{/* TODO remove cond when no text firstSheetTitle */}
				{typeof firstSheetTitle === "string" ?
					<div className="item__cell__sheet__first-sheet__content">{firstSheetTitle}</div> :
					<EditorRendering noStyle className="item__cell__sheet__first-sheet__content" content={firstSheetTitle}/>
				}
				<div className="item__start-at item__cell__start-at__label">
					<div>
						<span className="item__start-at__content">{t("process:process-list.date-start")}</span>
						<span>{`${formatJsonDate(startAt, dateFormatDate)} - ${formatJsonDate(startAt, dateFormatTime)}`}</span>
					</div>
				</div>
				{!closed && (<ProcessListItemActions process={process} updateProcess={handleUpdateProcess} side={side} />)}
				{closed && (
					<>
						<div className={`item__cell__ended-at__label item__ended-at item--${process.status}`}>
							<span className="item__ended-at__content">{t("process:process-list.date-end")}</span>
							{process.status === "closed" && (
								<span>{`${formatJsonDate(endedAt, dateFormatDate)} - ${formatJsonDate(endedAt, dateFormatTime)}`}</span>
							)}
							{process.status === "aborted" && t("process:process-list.status-aborted")}
						</div>
						<div className="item__cell__actions item__actions">
							<Link to={urlProcessReport}>
								<Button
									variant="minimal"
									label={t("process:process-list.show-report")}
								/>
							</Link>
						</div>
					</>
				)}
				<IconWrapper className="item__cell__sync-icon" Component={syncStatusIcon} />
			</li>
		);
	};

	const generateProcessList = ({ processes = [], className = "", title = "", processesClosed = true }) => {
		const filteredProcesses = processes.filter(process => process?.history?.length > 0);

		return (
			<div className={`process-list__opened-processes ${className}`}>
				<h2 className="process-list__subtitle">{t(title)}</h2>
				<ul className="process-list__list">
					<li className="process-list__list__row process-list__list__header">
						<div className="item__cell__type__label">
							{t("process:process-list.binder-type")}
						</div>
						<div className="item__sheets">
							{t("process:process-list.process-sheets")}
						</div>
						<div className="item__first-sheet">
							{t("process:process-list.first-sheet")}
						</div>
						<div className="item__start-at">
							{t("process:process-list.date-start")}
						</div>
						<div className="item__ended-at">
							{processesClosed && t("process:process-list.date-end")}
						</div>
					</li>
					{filteredProcesses.map(process => generateProcessItem(process, processesClosed))}
				</ul>
			</div>
		);
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(init, [ associatedLine ]);

	return (
		<LoadWrapper className="process-list__loader" loading={pageLoading}>
			<SprPage
				className="process-list"
				binderAbbreviationNotVisible
				subheader={<ProcessListSubheader side={side} />}
				allowLineSwitch={side === "train"}
			>
				<div className="process-list__content">
					{
						Array.isArray(openProcessList) && openProcessList.length > 0 && generateProcessList({
							processes: openProcessList,
							className: "process-list__opened-processes",
							title: "process:process-list.process-in-progress",
							processesClosed: false
						})
					}

					{
						Array.isArray(closedProcessList) && closedProcessList.length > 0 && generateProcessList({
							processes: closedProcessList,
							className: "process-list__closed-processes",
							title: "process:process-list.process-closed",
							processesClosed: true
						})
					}
				</div>
			</SprPage>
		</LoadWrapper>
	);
};

export default ProcessList;
