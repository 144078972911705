import React from "react";
import TrainTroubleFormRadioButton from "../../../../shared/train-trouble-form-radio-button/train-trouble-form-radio-button";
import TrainTroubleFormRadioButtonWithInput
	from "../../../../shared/train-trouble-form-radio-button-with-input/train-trouble-form-radio-button-with-input";
import ValidatedSubstepCard from "../../../../shared/validated-substep-card/validated-substep-card";
import { speedRestrictionFields } from "../../../utils/resume-form-fields";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import TrainTroubleFormFooter from "../../../../shared/train-trouble-form-footer/train-trouble-form-footer";

import "../../resume-form-block.scss";

const ResumeFormStepTwoLineA = (props) => {
	const { resumeStep, handleChange, handleReset, handleSubmit, resumeForm, backToSubstep } = props;
	const { t } = useTranslation();

	const fields =  [ ...speedRestrictionFields ];
	const { has_speed_restriction: speedRestrictionYesNo, speed_restriction: speedRestriction } = resumeForm?.speed_restriction_group || {};

	const hasSpeedRestriction = resumeForm?.speed_restriction_group.has_speed_restriction === "yes";
	const isSubstepValidated = resumeForm?.speed_restriction_group.validated;

	const className = classNames({ "resume-form-block": resumeStep === 1 });

	const checkFormValidity = () => {
		if (!hasSpeedRestriction) {
			fields.pop();
		}
		const formErrorList = fields.map((field) => {
			const { name: fieldName } = field;
			return field.validator(resumeForm?.speed_restriction_group[fieldName]);
		});
		return formErrorList?.every(el => el === true);
	};

	const isFormValid = checkFormValidity();

	const handleSubmitSubstep = () => {
		let updatedResumeForm;
		if (hasSpeedRestriction) {
			updatedResumeForm = { ...resumeForm, speed_restriction_group: { ...resumeForm.speed_restriction_group, validated: true } };
		} else {
			updatedResumeForm = { ...resumeForm, speed_restriction_group: { has_speed_restriction: "no", speed_restriction: "", validated: true } };
		}
		handleSubmit(updatedResumeForm);
	};

	return (
		<div className={className}>
			{resumeStep > 1 && isSubstepValidated && <ValidatedSubstepCard
				title={t("process:process-rer-resume.resume-rer.substep.speed_restriction")}
				backToSubstep={() => backToSubstep(1)}/>}
			{resumeStep === 1 && <div className={className}>
				<TrainTroubleFormRadioButton
					title={t("process:process-rer-resume.resume-rer.has_speed_restriction")}
					step={"resume-rer"}
					substep={fields[0]?.name}
					options={fields[0]?.values}
					isFirstQuestion={fields[0]?.is_first_question}
					checkedValue={speedRestrictionYesNo}
					onChange={handleChange}/>
				{hasSpeedRestriction && <TrainTroubleFormRadioButtonWithInput
					title={t("process:process-rer-resume.resume-rer.speed_restriction")}
					step={"resume-rer"}
					substep={fields[1]?.name}
					group={"speed_restriction_group"}
					options={fields[1]?.values}
					warning={fields[1]?.warning}
					checkedValue={speedRestriction}
					resumeForm={resumeForm}
					onChange={handleChange}/>},
			</div>}
			{resumeStep === 1 && <TrainTroubleFormFooter handleReset={handleReset} handleSubmit={handleSubmitSubstep} isSubmitBlocked={!isFormValid}/>}
		</div>
	);
};

export default ResumeFormStepTwoLineA;
