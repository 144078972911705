import React from "react";
import TrainTroubleFormRadioButton from "../../../../shared/train-trouble-form-radio-button/train-trouble-form-radio-button";
import { specificRestrictionFields } from "../../../utils/resume-form-fields";
import { initLineA, initShortLineA } from "../../../utils/init-resume-form";
import ValidatedSubstepCard from "../../../../shared/validated-substep-card/validated-substep-card";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import TrainTroubleFormFooter from "../../../../shared/train-trouble-form-footer/train-trouble-form-footer";

import "../../resume-form-block.scss";

const ResumeFormStepOneLineA = (props) => {
	const { resumeStep, handleChange, handleReset, handleSubmit, resumeForm, backToSubstep } = props;
	const { t } = useTranslation();

	const fields = [ ...specificRestrictionFields ];
	const { specific_restriction: specificRestriction } = resumeForm?.specific_restriction_group || {};

	const isSubstepValidated = resumeForm?.specific_restriction_group?.validated;
	const hasSpecificRestrictionValue = resumeForm?.specific_restriction_group[fields[0].name] === "yes";
	const cardTitle = hasSpecificRestrictionValue ? "specific_restriction" : "no-specific_restriction";
	const validatedCardTitle = t(`process:process-rer-resume.resume-rer.substep.${cardTitle}`);

	const className = classNames({ "resume-form-block": resumeStep === 0 });

	const handleSubmitSubstep = () => {
		if (hasSpecificRestrictionValue) {
			const updatedResumeForm = { ...initLineA, specific_restriction_group: { ...resumeForm.specific_restriction_group, validated: true }, head_of_regulation_informed_group: { ...resumeForm.head_of_regulation_informed_group } };
			handleSubmit(updatedResumeForm);
		} else {
			const updatedResumeForm = { ...initShortLineA, specific_restriction_group: { ...initShortLineA.specific_restriction_group, validated: true }, head_of_regulation_informed_group: { ...resumeForm.head_of_regulation_informed_group }, validated: true };
			handleSubmit(updatedResumeForm);
		}
	};

	const checkFormValidity = () => {
		const formErrorList = fields.map((field) => {
			const { name: fieldName } = field;
			return field.validator(resumeForm?.specific_restriction_group[fieldName]);
		});
		return formErrorList?.every(el => el === true);
	};

	const isFormValid = checkFormValidity();

	return (
		<div className={className}>
			{resumeStep > 0 && isSubstepValidated && <ValidatedSubstepCard
				title={validatedCardTitle}
				backToSubstep={() => backToSubstep(0)}/>}
			{resumeStep === 0 && <div className={className}>
				<TrainTroubleFormRadioButton
					title={t("process:process-rer-resume.resume-rer.specific_restriction")}
					step={"resume-rer"}
					substep={fields[0].name}
					options={fields[0].values}
					isFirstQuestion={fields[0].is_first_question}
					checkedValue={specificRestriction}
					onChange={handleChange}/>
			</div>}
			{resumeStep === 0 && <TrainTroubleFormFooter
				handleReset={handleReset}
				handleSubmit={handleSubmitSubstep}
				isSubmitBlocked={!isFormValid}/>}
		</div>
	);
};

export default ResumeFormStepOneLineA;
