import { http } from "../../config";
import * as qs from "qs";
import { getEntity, getEntityList } from "../../shared/cache-access/entity/entity-utils";
import { unpadStartNumberInside } from "../../shared";

/**
 * @typedef NodeContent
 * @property {String} id
 * @property {String} type
 * @property {Object} content
 */


/**
 * @typedef Sheet
 * @prop {string} analysis_result
 * @prop {string} binder_abbreviation
 * @prop {object[]} binder_abrogated_documents
 * @prop {string} binder_changed_description
 * @prop {string} binder_correction
 * @prop {string} binder_for_line_manager
 * @prop {string} binder_is_rectification
 * @prop {string} binder_isf_version
 * @prop {string} binder_line_manager
 * @prop {string} binder_parent
 * @prop {string} binder_published_at
 * @prop {string} binder_published_temp_at
 * @prop {string} binder_station_type
 * @prop {string} binder_zone_link
 * @prop {string} page_analyse_result
 * @prop {string} page_analyse_status
 * @prop {string} published_at
 * @prop {string} rectification
 * @prop {string} signal_tech_id
 * @prop {string} theme_tech_id
 * @prop {string[]} binder_associated_line
 * @prop {string} binder_color
 * @prop {string} binder_document_manager
 * @prop {string} binder_id
 * @prop {number} binder_iteration
 * @prop {string} binder_iteration_date
 * @prop {string} binder_last_change_at
 * @prop {string} binder_material_tech_id
 * @prop {string} binder_status
 * @prop {string} binder_subtitle
 * @prop {string} binder_tech_id
 * @prop {string} binder_title
 * @prop {string} binder_type
 * @prop {string} color
 * @prop {object[]} content
 * @prop {string} edition
 * @prop {string} editor
 * @prop {string} last_change_at
 * @prop {string} last_change_content_at
 * @prop {string} number
 * @prop {string} number_search
 * @prop {string} sheet_id
 * @prop {string} status
 * @prop {{blockck: *[], entityMap: object}} title
 * @prop {string} type
 */

/**
 * Return the type sort order for a sheet
 * @param {object} item
 * @returns {number}
 */
const getSheetTypeOrder = (item) => {
	const typeOrder = [ "m", "determination" ];
	const priority = typeOrder.findIndex((type) => type === item.type);
	return priority !== -1 ? priority : typeOrder.length;
};

/**
 * Sort a sheet list based on type then number
 * @param {{ data: Sheet, metadata: any }[]} sheetList
 * @returns {{ data: Sheet, metadata: any }[]}
 */
const sortCacheSheet = (sheetList) => {
	return [ ...sheetList ].sort((cacheEntryLeft, cacheEntryRight) => {
		const left = { type: cacheEntryLeft.data.type, number: cacheEntryLeft.data.number_search };
		const right = { type: cacheEntryRight.data.type, number: cacheEntryRight.data.number_search };

		const prioLeft = getSheetTypeOrder(left);
		const prioRight = getSheetTypeOrder(right);

		if (prioLeft === prioRight){
			const isString = typeof left.number === "string" && typeof right.number === "string";
			return isString ? left.number.localeCompare(right.number) : 0;
		} else {
			return prioLeft - prioRight;
		}
	} );
};

/**
 * Get all sheet contained in the specified binder
 * @param {string} binderId The sheet's binder technical id
 * @param {object} [params]
 * @return {Promise<import("axios").AxiosResponse<Sheet>>}
 */
const fetchSheetByBinder = async (binderId, params = {}) => {
	const { line, ...otherParams } = params;
	return http.get(`/binder/${binderId}/sheet`, {
		params: otherParams,
		paramsSerializer: (queryParams) => qs.stringify(queryParams, { arrayFormat: "repeat" }),
		handleOfflineResponse: async () => {
			const cacheParams = { binderRef: binderId };

			if (params.type){
				cacheParams.type = params.type;
			}
			if (line){
				cacheParams.line = line;
			}

			const sheetCacheList = await getEntityList("sheet", cacheParams);

			return sortCacheSheet(sheetCacheList).map(cacheEntry => cacheEntry.data);
		}
	});
};

/**
 * Search sheet from in binder
 * @param {string} binderTechId
 * @param {string} search
 * @returns {Promise<import("axios").AxiosResponse<Sheet>>}
 */
const findSheetByBinderTechId = async (binderTechId, search) => http.get(`/binder/${binderTechId}/sheet`, { params: { search } });

/**
 * Search sheet
 * @param {object} query
 * @param {string} binderId
 * @param {{simpleLink: boolean}} params
 * @returns {Promise<import("axios").AxiosResponse<Sheet[]>>}
 */
const searchSheetsByBinderId = async (query, binderId, params) => {
	const formattedParams = qs.stringify({ ...params }, { arrayFormat: "repeat" });
	return http.get(`/sheet?${formattedParams}`, { params: { query, binderId } });
};

/**
 * Get a single sheet by id
 * @param {string} binderId The binder's sheet technical id
 * @param {string} sheetId The sheet's id technical
 * @returns {Promise<import("axios").AxiosResponse<Sheet>>}
 */
const fetchSheetById = async (binderId, sheetId) => http.get(`/binder/${binderId}/sheet/${sheetId}`, {
	handleOfflineResponse: async () => {
		const storedSheet = await getEntity("sheet", sheetId);
		return storedSheet.data;
	}
});

/**
 * Get a single sheet by number
 * @param {string} binderTechId The binder's sheet technical id
 * @param {string} number The sheet's number
 * @returns {Promise<import("axios").AxiosResponse<Sheet>>}
 */
const fetchSheetByBinderAndNumber = async (binderTechId, number) => {
	const response = await http.get("/sheet", {
		params: { binderTechId, number },
		handleOfflineResponse: async () => {
			const filter = { number: unpadStartNumberInside(number), binderRef: binderTechId };
			const storedSheet = await getEntityList("sheet", filter);
			return storedSheet.map(({ data }) => data);
		}
	});
	response.data = response.data[0];
	return response;
};

/**
 * Get a single sheet by business id, only sheet in published binder can be returned
 * @param {string} binderId The binder's id
 * @param {string} sheetNumber The sheet's number
 * @param {string} [binderTechId] The binder's technical id
 * @param {string} [signalTechId] The sheet's signal technical id
 * @param {{ extendThem?: boolean, extendSignal?: boolean }} [options={}]
 * @param {boolean} [options.extendTheme=false]
 * @param {boolean} [options.extendSignal=false]
 * @returns {Promise<import("axios").AxiosResponse<Sheet>>}
 */
const fetchSheetByBusinessIds = async (binderId, sheetNumber, binderTechId = "", signalTechId = "", options = {}) => {
	const { extendTheme = false, extendSignal = false } = options;
	const params = { binderId, binderTechId, number: sheetNumber, extendTheme, extendSignal };

	if (signalTechId) {
		params.signalTechId = signalTechId;
	}
	const response = await http.get("/sheet", {
		params,
		handleOfflineResponse: async () => {
			const filter = { number: unpadStartNumberInside(sheetNumber) };
			if (binderId) filter.binderId = binderId;
			if (binderTechId) filter.binderRef = binderTechId;
			const storedSheet = await getEntityList("sheet", filter);
			return storedSheet.map(({ data }) => data);
		} });
	response.data = response.data[0];
	return response;
};

/**
 * Create a new Sheet
 * @param {String} binderId The sheet's binder technical id
 * @param {Sheet} data The sheet data
 * @param {object} params
 */
const createSheet = async (binderId, data, params = {}) => http.post(`/binder/${binderId}/sheet`, data, { params });

/**
 * Delete a sheet
 * @param {string} binderId The sheet's binder technical id
 * @param {string} sheetId The sheet's technical id
 */
const deleteSheet = async (binderId, sheetId) => http.delete(`/binder/${binderId}/sheet/${sheetId}`);

/**
 * Update a sheet
 * @param {string} binderId The sheet's binder technical id
 * @param {string} sheetId The sheet's technical id
 * @param {Sheet} data The sheet data to update
 */
const updateSheet = async (binderId, sheetId, data) => http.put(`/binder/${binderId}/sheet/${sheetId}`, data);

const generateSheetPdf = async (sheetTechId) => http.post(`/sheet/${sheetTechId}/pdf`, {}, { timeout: 0 });

const runAnalysis = async (sheetId) => http.get(`/sheet/${sheetId}/analysis`);

export {
	fetchSheetByBinder,
	fetchSheetByBinderAndNumber,
	fetchSheetByBusinessIds,
	findSheetByBinderTechId,
	createSheet,
	deleteSheet,
	updateSheet,
	fetchSheetById,
	searchSheetsByBinderId,
	generateSheetPdf,
	runAnalysis
};
