import { requiredFieldValidator } from "../../../../shared";
import { sheetNumberValidator } from "../../utils/form-utils";


/**
 * Validate the edit sheet title popup's form
 * @param form
 * @param params
 * @param params.sheetNumber
 * @param params.sheetList
 * @param params.binderType
 * @return {{color: string[], title: string[], sheetSignal: string[] }}
 */
const validateEditSheetTitleForm = (form, params = {}) => {
	const { title } = form;
	const { sheetNumber, sheetList = [], binderType = "" } = params;
	return {
		title: requiredFieldValidator(title),
		color: [],
		sheetSignal: binderType === "man" ? sheetNumberValidator(sheetNumber, sheetList) : []
	};
};

export default validateEditSheetTitleForm;
