import React, { createContext, useContext, useState } from "react";

const SheetModeContext = createContext({});

/**
 * sheet mode context provider
 * @param {object} props
 * @param {boolean} props.readOnly
 * @param {"spr" | "railway"} props.viewType
 * @returns
 */
const SheetModeContextProvider = props => {
	const { readOnly: initialReadOnlyMode, viewType, children } = props;
	const [ forceReadMode, setForceReadMode ] = useState(initialReadOnlyMode);

	return <SheetModeContext.Provider value={{
		readOnly: forceReadMode,
		initialReadOnlyMode,
		setForceReadMode,
		viewType
	}}>{children}</SheetModeContext.Provider>;
};

/**
 * sheet mode context hook
 * @returns {{
 * 	readOnly: boolean,
 *	initialReadOnlyMode: boolean
 *	setForceReadMode: function
 *	viewType: string
 * }}
 */
const useSheetModeContext = () => useContext(SheetModeContext);

export { SheetModeContext, SheetModeContextProvider, useSheetModeContext };
