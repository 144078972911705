import React from "react";
import classnames from "classnames";
import "./tabs-nav.scss";

const TabsNav = props => {
	const { children, className } = props;
	return (
		<div className={classnames("tabs-nav", className)}>{children}</div>
	);
};

export default TabsNav;
