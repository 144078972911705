import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../../theme";
import BinderDetailCardTemplate from "../binder-detail-card-template/binder-detail-card-template";
import "./binder-zone-link-card.scss";

/**
 * BinderZoneLinkCard component
 * @param {object} props
 * @param {string} props.binderZoneLink
 * @param {PopupControl} props.popupControl
 * @returns {JSX.Element}
 */
const BinderZoneLinkCard = props => {
	const { popupControl = {}, binderZoneLink } = props;

	const { t } = useTranslation();

	return (
		<BinderDetailCardTemplate title={t("binder:binder-detail.specification.zone-title")} className="binder-zone-link-card">
			<div>
				<span>{t("binder:binder-detail.specification.zone-subtitle")}</span>
				{binderZoneLink && (
					<a
						className="binder-zone-link-card__link"
						href={binderZoneLink}
						target="_blank"
						rel="noreferrer noopener nofollow">
						{binderZoneLink}
					</a>
				)}
			</div>
			<Button
				variant="secondary"
				className="binder-zone-link-card__edit"
				onClick={popupControl.show}
				label={t(`binder:binder-detail.specification.${binderZoneLink ? "zone-change-button" : "zone-add-button"}`)}
			/>
		</BinderDetailCardTemplate>
	);
};

BinderZoneLinkCard.propTypes = {
	binder: PropTypes.shape({
		tech_id: PropTypes.string,
		status: PropTypes.string
	}),
	reloadBinder: PropTypes.func,
	showPopupTypeSuggestion: PropTypes.func,
	setNetworkError: PropTypes.func,
};


export default BinderZoneLinkCard;
