import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Editor as DraftJSEditor } from "react-draft-wysiwyg";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { parseJsonString, isDraftJSContentEmpty, draftJSContentToText } from "./utils";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor-rendering.scss";

/**
 * Render a read only DraftJS wysiwyg content
 * @param {object} props
 * @param {string} props.className The component custom html class
 * @param {string} props.editorClassName The component editor custom html class
 * @param {string | { blocks: { text: string }[] }} props.content The draftJS content to display as json string or js object
 * @param {string} props.label The field label
 * @param {boolean} props.noStyle If true display content as simple text list without any style
 * @returns {JSX.Element}
 */
const EditorRendering = props => {
	const { className, editorClassName, content, label, noStyle } = props;

	const [ editorState, setEditorState ] = useState(EditorState.createEmpty());

	const editorConfig = useMemo(() => ({
		editorState,
		wrapperClassName: classnames("editor-render__wrapper", className),
		editorClassName: classnames("editor editor-override-style", editorClassName),
		toolbarClassName: "editor__toolbar"
	}), [ editorState, className, editorClassName ]);

	const initEditorContent = () => {
		if (!noStyle) {
			const parsedContent = parseJsonString(content);
			const empty = !parsedContent || isDraftJSContentEmpty(parsedContent);
			if (empty) {
				setEditorState(EditorState.createEmpty());
			} else {
				setEditorState(previousEditorState => {
					const initialEditorState = convertFromRaw(parsedContent);
					const isChange = JSON.stringify(convertToRaw(previousEditorState.getCurrentContent())) !== JSON.stringify(parsedContent);

					return isChange ? EditorState.createWithContent(initialEditorState) : previousEditorState;
				});
			}
		}
	};

	const renderNoStyle = (draftJSContent) => {
		const parsedContent = parseJsonString(draftJSContent);
		const lineList = draftJSContentToText(parsedContent);
		const lineText = lineList.join(" ");
		return (
			<div className={classnames("editor-render__wrapper", "editor-render__wrapper--no-style", className)}>
				<span className="editor-render__text">{lineText}</span>
			</div>
		);
	};

	useEffect(initEditorContent, [ content, noStyle ]);

	return noStyle ? renderNoStyle(content) : <DraftJSEditor {...editorConfig} readOnly placeholder={label}/>;
};

EditorRendering.propTypes = {
	className: PropTypes.string,
	editorClassName: PropTypes.string,
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			blocks: PropTypes.arrayOf(
				PropTypes.shape({ text: PropTypes.string })
			)
		})
	]),
	label: PropTypes.string,
	noStyle: PropTypes.bool
};

export default EditorRendering;
