import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { PopupWrapper } from "../../../../shared";
import { IconWrapper, Button } from "../../..";
import { ReactComponent as Close } from "../../../assets/img/close.svg";

import "./popup-confirm.scss";

/**
 * PopupControl object
 * @typedef {object} PopupControl
 * @prop {boolean} visible - is popup visible
 * @prop {function} setVisible - set is popup visible
 * @prop {function} show - show popup
 * @prop {function} hide - hide popup
 */


/**
 * PopupZoneLink component
 * @param {object} props
 * @param {string} props.className
 * @param {PopupControl} props.popupControl
 * @param {string} props.headerClassName
 * @param {string} props.title
 * @param {function} props.onCancel
 * @param {function} props.onConfirm
 * @param {function} props.onClose
 * @param {object} props.children
 * @param {string} props.ctaConfirmLabel
 * @param {string} props.ctaConfirmDisabled
 * @param {string} props.ctaCancelLabel
 * @param {string} props.warning
 * @param {string} props.error
 * @param {boolean} [props.cancellable = true]
 * @returns {JSX.Element}
 */
const PopupConfirm = props => {
	const {
		className,
		popupControl,
		headerClassName,
		title,
		onCancel = () => {},
		onConfirm = () => {},
		onClose = () => {},
		children,
		ctaConfirmLabel = "popup.confirm.cta-label-confirm",
		ctaConfirmDisabled,
		ctaCancelLabel = "popup.confirm.cta-label-cancel",
		warning,
		error,
		cancellable = true
	} = props;

	const { t } = useTranslation();

	const handleCancel = () => {
		popupControl.hide();
		onCancel();
	};

	const handleConfirm = (e) => {
		e.stopPropagation();
		popupControl.hide();
		onConfirm();
	};

	const handleClose = () => {
		popupControl.hide();
		onClose();
	};

	const getHeaderClasses = () => classNames("popup-confirm__header", headerClassName, {
		"popup-confirm__header--warning": warning,
		"popup-confirm__header--error": error,
	});


	return (
		<PopupWrapper visible={popupControl.visible}>
			<div className={classNames("popup-confirm", { [className]: !!className })}>
				<div className={getHeaderClasses()}>
					<h1 className="popup-confirm__title">{title}</h1>
					{cancellable && <button type="button" className="popup-confirm__close" onClick={handleClose}>
						<IconWrapper Component={Close}/>
					</button>}
				</div>
				<div className="popup-confirm__body">{children}</div>
				<div className="popup-confirm__controller">
					{cancellable &&
						<Button
							type="button"
							variant="minimal"
							className="popup-confirm__cancel"
							onClick={handleCancel}
							label={t(ctaCancelLabel)}
						/>
					}
					<Button
						type="button"
						variant="primary"
						className="popup-confirm__confirm"
						onClick={handleConfirm}
						disabled={ctaConfirmDisabled}
						label={t(ctaConfirmLabel)}
					/>
				</div>
			</div>
		</PopupWrapper>
	);
};

PopupConfirm.propTypes = {
	className: PropTypes.string,
	popupControl: PropTypes.shape({
		visible: PropTypes.bool,
		setVisible: PropTypes.func,
		show: PropTypes.func,
		hide: PropTypes.func
	}),
	headerClassName: PropTypes.string,
	title: PropTypes.string,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	onClose: PropTypes.func,
	children: PropTypes.node,
	ctaConfirmLabel: PropTypes.string,
	ctaConfirmDisabled: PropTypes.bool,
	ctaCancelLabel: PropTypes.string,
	warning: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
	error: PropTypes.bool
};

export default PopupConfirm;
