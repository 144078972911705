import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { formatJsonDate, buildSprProcessUri, buildTrainProcessBinderList } from "../../../../shared";
import { dateFormatTime, dateFormatDayMonth } from "../../utils";
import { IconArrowRight, IconEye, IconProcessPcc, IconProcessTrain } from "../../../../theme/assets/img";
import { IconStep } from "../../../sheet/assets";
import { Button, IconWrapper } from "../../../../theme";

/**
 * in progress process item
 * @param {object} props
 * @param {object} props.process
 * @param {"train" | "pcc"} props.side
 * @param {function} props.handlePopupStartProcessTrainVisible
 * @param {function} props.renderUserLabel
 * @returns {JSX.Element}
 */
export const SprProcessItem = (props) => {
	const { process, side, handlePopupStartProcessTrainVisible, renderUserLabel } =props;

	const { firstname, lastname, owner, ownerId: userId, createdAt, processTechId, binderAbbreviation, isProcessOwner, type, positionInfo, trainId, materialLabel, startedAt, history, binderTitle } = process;
	const binderAbbreviationUpper = binderAbbreviation?.toUpperCase();
	const { t } = useTranslation();
	const date = formatJsonDate(startedAt || createdAt, dateFormatDayMonth);
	const time = formatJsonDate(startedAt || createdAt, dateFormatTime);

	if (type === "pcc" && isProcessOwner) {
		const ClickableItem = React.forwardRef((_props, ref) => (<li className="spr-in-progress-process-list__item" key={createdAt}>
			<a ref={ref} {..._props}>
				<div className="item__process__wrapper">
					<IconWrapper className="item__process__icon" Component={IconProcessPcc}/>
					<div className="item__process__infos">
						<span className="item__process__binder-abbreviation">{binderAbbreviationUpper}</span>
						<span className="date__text">{date}</span>
						<span className="time__text">{time}</span>
						{binderTitle && <span className="item__process__text">{binderTitle}</span>}
					</div>
					<span className="item__cta__link">
						<span className="item__cta__continue">
							{t("process:spr-in-progress-list.continue-process-run")}
						</span>
						<IconWrapper className="item__cta__icon" Component={IconArrowRight}/>
					</span>
				</div>
			</a>
		</li>));

		return <Link to={buildSprProcessUri({ processId: processTechId })} component={ClickableItem} />;
	}

	if (type === "pcc" && !isProcessOwner){
		return <li className={classnames( "spr-in-progress-process-list__item", "spr-in-progress-process-list__item--not-owner" )}
			key={createdAt}>
			<div className="item__process__wrapper">
				<IconWrapper className="item__process__icon" Component={IconProcessPcc}/>
				<Trans
					parent="span"
					className="item__process__text"
					i18nKey="process:spr-in-progress-list.text"
					values={{
						abbreviation: binderAbbreviationUpper,
						user: renderUserLabel(firstname, lastname, userId, owner),
						date,
						time
					}}
				>
					<strong className="item__process__text--highlight"/>
				</Trans>
				<div className="item__cta__wrapper">
					<Link
						className="item__cta__link"
						to={buildSprProcessUri({ processId: processTechId })}>
						<Button
							className="item__cta__icon"
							variant="secondary"
							icon={IconEye}
							label={t("process:spr-in-progress-list.show-live")}
						/>
					</Link>
					<Link className="item__cta__link" to={`/spr/process/${processTechId}`}>
						<Button
							className="item__cta__icon"
							variant="secondary"
							icon={IconStep}
							label={t("process:spr-in-progress-list.access-step-done")}
						/>
					</Link>
				</div>
			</div>
		</li>;
	}

	if (type === "train") {
		const hasHistory = history?.length > 0;
		const isTrainSide = side === "train";

		const renderPointer = () => {
			if (!isTrainSide) {
				return <span className="item__cta__link">
					<span className="item__cta__continue-train">{t("process:spr-in-progress-list.get-process-binder")}</span>
					<IconWrapper className="item__cta__icon-train" Component={IconArrowRight}/>
				</span>;
			}
			if (isTrainSide){
				if (!startedAt) {
					return <button className="item__cta__link" onClick={(e) => {handlePopupStartProcessTrainVisible(e, process);}}>
						<span className="item__cta__continue-train">{t("process:spr-in-progress-list.get-process-binder-train")}</span>
						<IconWrapper className="item__cta__icon-train" Component={IconArrowRight}/>
					</button>;
				}
				if (startedAt) {
					return <span className="item__cta__link">
						<span className="item__cta__continue-train--owner">{t("process:spr-in-progress-list.continue-process-run")}</span>
						<IconWrapper className="item__cta__icon-train--owner" Component={IconArrowRight}/>
					</span>;
				}
			}
			return null;
		};

		const getLink = ()=> {
			if (!isTrainSide) {
				return buildTrainProcessBinderList({ processId: processTechId }, "spr", true);
			}
			if (isTrainSide && startedAt && hasHistory){
				return buildSprProcessUri({ processId: processTechId }, "railway");
			}
			if (isTrainSide && startedAt && !hasHistory){
				return  buildTrainProcessBinderList({ processId: processTechId }, "railway");
			}
			return "#";
		};

		const notTakenUpDisplay = !startedAt && side === "pcc";
		const ownerLabel = notTakenUpDisplay ? t("process:spr-in-progress-list.not-started") : `${renderUserLabel(firstname, lastname, userId, owner)} ${t("process:spr-in-progress-list.started")}`;

		const ClickableItem = React.forwardRef((_props, ref) => (<li className="spr-in-progress-process-list__item" key={createdAt}>
			<a ref={ref} {..._props}>
				<div className="item__process__wrapper">
					<IconWrapper className="item__process__icon-train" Component={IconProcessTrain}/>
					<div className={classnames("item__process__infos-train", { "item__process__infos-train--owner": isProcessOwner && startedAt })}>
						<div>
							{positionInfo && <span className="item__process__binder-abbreviation">{positionInfo}</span>}
							<span className="material__text">{
								`${trainId ?? t("process:spr-in-progress-list.no-train") }
							(${materialLabel ?? t("process:spr-in-progress-list.no-material")})`}
							</span>
							<span className="date__text">{date}</span>
							<span className="time__text">{time}</span>
						</div>
						{!isTrainSide && <div>{ownerLabel}</div>}
					</div>
					{renderPointer()}
				</div>
			</a>
		</li>));


		return <Link to={getLink()} component={ClickableItem} />;
	}

	return null;
};

SprProcessItem.propTypes = {
	process: PropTypes.object,
	side: PropTypes.string,
	handlePopupStartProcessTrainVisible: PropTypes.func,
	renderUserLabel: PropTypes.func
};
