import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import "./dynamic-background-text.scss";

const DynamicBackgroundText = props => {
	const { className, text, color } = props;

	const container = useRef("");

	useEffect(() => {
		if (container?.current?.style && color) {
			container.current.style.setProperty("--bg-color", color);
		}
	}, [ color ]);

	return <span ref={container}
	             className={classNames("dynamic-background-text", className)}>{text}</span>;
};

export default DynamicBackgroundText;
