import React from "react";
import classNames from "classnames";
import CopyTemplateButton from "../../copy-template-button/copy-template-button";
import DeleteTemplateButton from "../../delete-template-button/delete-template-button";
import "./template-actions.scss";

const TemplateActions = (props) => {
	const { className, readOnly, viewType, template, location } = props;

	const showCopyCta = [ "resume", "note", "action", "call", "systemEffect", "question", "multipleChoice", "table", "officerIntervention", "pccInstruction" ].includes(template?.type);
	const showDeleteCta = template?.type !== "next";

	return (
		<div className={classNames("template-actions", `template-actions--${template?.type}`, className)}>
			{!viewType && showCopyCta && <CopyTemplateButton template={template}/>}
			{!readOnly && showDeleteCta && <DeleteTemplateButton template={template} location={location}/>}
		</div>
	);
};

export default TemplateActions;
