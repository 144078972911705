import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ErrorMessage, IconWrapper } from "../../../../../../../theme";
import { dateFormatDate } from "../../../../../../process/utils";
import { formatJsonDate, usePopup, useAppSetupContext } from "../../../../../../../shared";
import { updateComment } from "../../../../../comment.services";
import { IconEdit, IconTrash } from "../../../../../../../theme/assets/img";
import EditorRendering from "../../../../../../../theme/components/editor-rendering/editor-rendering";
import PopupDeleteComment from "./popup-delete-comment/popup-delete-comment";
import PopupEditComment from "./popup-edit-comment/popup-edit-comment";
import UserNameLabel from "../../../../../../../shared/components/user-name-label/user-name-label";
import "./comment.scss";

/**
 * @param {object} props
 * @param {object} props.comment
 * @param {function} props.retrieveCommentsList
 * @param {object} props.toolbarConfig
 * @param {number} props.binderIteration
 * @returns {JSX.Element}
 */
const Comment = (props) => {
	const { t } = useTranslation();
	const { comment, retrieveCommentsList, toolbarConfig, binderIteration } = props;
	const popupDeleteCommentControl = usePopup();
	const popupEditCommentControl = usePopup();
	const { user: currentUser } = useAppSetupContext();
	const [ integrationStatus, setIntegrationStatus ] = useState(comment.status);
	const [ networkError, setNetworkError ] = useState(false);

	const integrated = "integrated";
	const notIntegrated = "not-integrated";
	const pending = "pending";

	const updateStatus = (newStatus) => {
		const updatedComment = {
			status: newStatus,
		};
		updateComment(comment.id, updatedComment).catch(error => {
			setNetworkError(true);
			throw (error);
		});
	};

	const handleIntegration = (selectedStatus) => {
		let newStatus;

		// check which status is selected and select the other if one is already selected
		if (integrationStatus === pending && selectedStatus === integrated) {
			newStatus = integrated;
		} else if (integrationStatus === pending && selectedStatus === notIntegrated) {
			newStatus = notIntegrated;
		} else if (integrationStatus === selectedStatus) {
			newStatus = pending;
		} else {
			newStatus = selectedStatus;
		}

		updateStatus(newStatus);
		setIntegrationStatus(newStatus);
	};

	const initClassName = (selectedStatus) => classNames( "actions__button__inner", { "actions__button__inner--selected": integrationStatus === selectedStatus });

	return (
		<div className="comment-wrapper">
			<div className="comment-wrapper__infos">
				<span className="comment-wrapper__infos__user">
					<UserNameLabel user={{
						firstname: comment.owner_firstname,
						lastname: comment.owner_lastname,
						id: comment.owner_id
					}}/>
				</span>
				<span
					className="comment-wrapper__infos__date">{formatJsonDate(comment.last_change_at, dateFormatDate)}</span>
			</div>
			<div className="comment-wrapper__content">
				<div className="comment-wrapper__content__data">
					<span>
						<EditorRendering content={comment.content}/>
					</span>
					{comment.owner_tech_id === currentUser.tech_id &&
					<div className="data__edition">
						<PopupEditComment
							popupControl={popupEditCommentControl}
							commentId={comment.id}
							content={comment.content}
							binderIteration={binderIteration}
							retrieveCommentsList={retrieveCommentsList}
							toolbarConfig={toolbarConfig}
						/>
						<button type="button" onClick={popupEditCommentControl.show}>
							<IconWrapper
								className="data__edition__button edit"
								Component={IconEdit}
								tooltip={t("sheet:sheet-details.comments.tooltip.edit")}/>
						</button>
						<PopupDeleteComment
							popupControl={popupDeleteCommentControl}
							commentId={comment.id}
							retrieveCommentsList={retrieveCommentsList}
						/>
						<button type="button" onClick={popupDeleteCommentControl.show}>
							<IconWrapper
								className="data__edition__button delete"
								Component={IconTrash}
								tooltip={t("sheet:sheet-details.comments.tooltip.delete")}/>
						</button>
					</div>
					}
				</div>
				<div className="comment-wrapper__content__actions">
					<div className="actions__button" onClick={() => handleIntegration(integrated)}>
						<div className={initClassName(integrated)}>
							{t("sheet:sheet-details.comments.published-comment.taking-account")}
						</div>
					</div>
					<div className="actions__button" onClick={() => handleIntegration(notIntegrated)}>
						<div className={initClassName(notIntegrated)}>
							{t("sheet:sheet-details.comments.published-comment.not-integrated")}
						</div>
					</div>
				</div>
				{networkError && <ErrorMessage>{t("error.default")}</ErrorMessage>}
			</div>
		</div>
	);
};

Comment.propTypes = {
	comment: PropTypes.object,
	retrieveCommentsList: PropTypes.func,
	toolbarConfig: PropTypes.object,
	binderIteration: PropTypes.number
};

export default Comment;
