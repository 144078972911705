import React from "react";
import TrainTroubleFormNumberSelector from "../../../../shared/train-trouble-form-number-selector/train-trouble-form-number-selector";
import  { rescuedBogieFields } from "../../../utils/resume-form-fields";
import { useTranslation } from "react-i18next";
import { useSprContext } from "../../../../../../../../shared";
import classNames from "classnames";
import TrainTroubleFormFooter from "../../../../shared/train-trouble-form-footer/train-trouble-form-footer";

import "../../resume-form-block.scss";

const ResumeFormStepOneLineB = (props) => {
	const { resumeStep, handleChange, handleReset, handleSubmit, resumeForm, handleBackClick,
		stepNumber, trainComposition } = props;
	const { t } = useTranslation();

	const fields = [ ...rescuedBogieFields ];
	const rescuedBogiesUnits = resumeForm?.rescued_bogie_group.rescued_bogies_units;

	const { currentBinder } = useSprContext();
	const { material_label, materialLabel } = currentBinder || {};
	const material = materialLabel ?? material_label;

	const className = classNames({ "resume-form-block": resumeStep === 0 });

	const handleSubmitSubstep = () => {
		const updatedResumeForm = { ...resumeForm, rescued_bogie_group: { ...resumeForm.rescued_bogie_group, validated: true } };
		handleSubmit(updatedResumeForm);
	};

	const checkFormValidity = () => {
		const formErrorList = fields.map((field) => {
			const { name: fieldName } = field;
			return field.validator(resumeForm?.rescued_bogie_group[fieldName], fieldName, trainComposition, material );
		});
		return formErrorList?.every(el => el === true);
	};

	const isFormValid = checkFormValidity();

	return (
		<div className={className}>
			{resumeStep === 0 && <div className={className}>
				<TrainTroubleFormNumberSelector
					title={t("process:process-rer-resume.resume-rer.substep.rescued_bogies_units")}
					label={t("process:process-rer-resume.resume-rer.rescued_bogies_units")}
					fieldName={"rescued_bogies_units"}
					value={rescuedBogiesUnits}
					trainComposition={trainComposition}
					material={material}
					handleChange={handleChange}/>
			</div>}
			{resumeStep === 0 && <TrainTroubleFormFooter
				line={"B"}
				stepNumber={stepNumber}
				handleReset={handleReset}
				handleSubmit={handleSubmitSubstep}
				handleBackClick={handleBackClick}
				isSubmitBlocked={!isFormValid}/>}
		</div>
	);
};

export default ResumeFormStepOneLineB;
