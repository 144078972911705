import React from "react";
import classNames from "classnames";
import { IconWrapper } from "../../";
import { ReactComponent as IconSpinner } from "../../assets/img/icon-spinner.svg";
import "./spinner.scss";

const Spinner = (props) => {
	const { className } = props;

	return <IconWrapper className={classNames("spinner", className)} Component={IconSpinner}/>;
};

export default Spinner;
