import React from "react";
import { useTranslation } from "react-i18next";
import PopupConfirm from "../../../../../../theme/components/popup/popup-confirm/popup-confirm";
import "./popup-purge-user.scss";

const PopupPurgeUser = props => {
	const { user = {}, popupControl, onConfirm } = props;
	const { t } = useTranslation();

	return (
		<PopupConfirm
			popupControl={popupControl}
			title={t("user:popup.purge-user.title")}
			ctaConfirmLabel={t("user:popup.purge-user.submit")}
			onConfirm={onConfirm}
			error
		>
			<div className="popup-purge-user">
				<div className="popup-purge-user__information">
					{t("user:popup.purge-user.info-user")}
					<div className="popup-purge-user__user-info">
						<span>{user.id}</span>
						<span>{user.firstname}</span>
						<span>{user.lastname}</span>
					</div>
				</div>
				<div className="popup-purge-user__content">
					{t("user:popup.purge-user.content")}
				</div>
				<div className="popup-purge-user__warning">
					{t("user:popup.purge-user.content-warning")}
				</div>
			</div>
		</PopupConfirm>
	);
};

export default PopupPurgeUser;
