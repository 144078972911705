import React from "react";
import classnames from "classnames";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import { IconWrapper } from "../../../../../../../../theme";
import { ReactComponent as IconArrowBreakline } from "../../../../../../../../theme/assets/img/icon-arrow-breakline.svg";
import { SheetType } from "../../../../../../index";
import resolveSheetColor from "../../../../../../utils/resolve-sheet-color";

import "./multiple-link-template-view.scss";

const MultipleLinkTemplateView = (props) => {
	const { template, currentPosition, processCurrentTemplate, location } = props;
	const redirectBlocks = template?.content?.items;

	const generateRedirectBlock = (redirectBlock, index) => {
		const { category, text, sheetColor: color, sheetNumberSelected, sheetNumber, sheetType, binderColor } = redirectBlock || {};
		// if type is not defined, then we have a specific sheet view
		const isOldSheetVersion = !sheetType;

		// find the current color to use for the sheet
		const sheetColor = resolveSheetColor(color, binderColor);


		return (
			<div className={classnames("item", { "item--category": !!category })} key={index}>
				<div className="item__category">{category}</div>
				<div className="item__description">{text}</div>
				<div className="item__sheet-link">
					<IconWrapper className="item__arrow" Component={IconArrowBreakline}/>
					{sheetNumberSelected && !isOldSheetVersion && <SheetType
						className="item__sheet-number"
						type={sheetType}
						sheetNumber={sheetNumber}
						color={sheetColor}
					/> }
					{sheetNumber && isOldSheetVersion &&
					<span className="item__sheet-number__old-version">
						{sheetNumber}
					</span>
					}
				</div>
			</div>
		);
	};

	return (
		<TemplateWrapper
			id={template.id}
			location={location}
			className="multiple-link-template-view"
			template={template}
			currentPosition={currentPosition}
			processCurrentTemplate={processCurrentTemplate}>
			{Array.isArray(redirectBlocks) && redirectBlocks.length > 0 && redirectBlocks.map(generateRedirectBlock)}
		</TemplateWrapper>
	);
};

export default MultipleLinkTemplateView;
