import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedAction } from "../../../../../../../../shared";
import { useSheetContentContext } from "../../../../../../context/sheet-content-context";
import { Editor, IconWrapper, Textarea } from "../../../../../../../../theme";
import { IconCheck, IconTrash } from "../../../../../../../../theme/assets/img";
import { defaultEditorToolbarConfig } from "../../../../../..";
import "./multiple-choice-template-form.scss";

/**
 * @param {object} props
 * @param {object} props.content
 * @param {object} props.type
 * @returns {JSX.Element}
 */
const MultipleChoiceTemplateForm = (props) => {
	const { content, type } = props;
	const { t } = useTranslation();

	const {
		setCurrentNodeContent,
		additionalInformation,
		setAdditionalInformation,
		isAtEndHistory,
		updateDelay
	} = useSheetContentContext();
	const [ initTitle, setInitTitle ] = useState("");
	const [ currentTitle, setCurrentTitle ] = useState(content?.title || null);
	const [ choiceBlocks, setChoiceBlocks ] = useState(content?.choices || [ {}, {} ]);

	const titleInputRef = useRef();

	const initAdditionalInformation = () => {
		setAdditionalInformation({ multipleChoiceInvalid: false });
	};

	const updatedContent = () => {
		if (content?.title) {
			reloadForm();
		}
		if (content?.choices) {
			setChoiceBlocks(content.choices);
		}
	};

	const reloadForm = () => {
		const newText = content.title;
		setCurrentTitle(newText);

		// initialize editor or update when selecting another text template
		if (!initTitle) {
			setInitTitle(JSON.parse(content?.title || null));
		}
		if (titleInputRef.current) {
			titleInputRef.current.focus();
		}
	};

	// send data to context
	const setNodeContent = useDebouncedAction((newContent, setHistory) => {
		const mergeContent = { ...content, ...newContent };
		const { title, subNodes, choices } = mergeContent;
		setCurrentNodeContent({ title, subNodes, choices }, setHistory);
	}, updateDelay);

	const handleChange = (event) => {
		let { value } = event.target;
		if (value) {
			value = JSON.stringify(value);
		}
		setNodeContent({ title: value }, false);
	};

	const handleDeleteBlock = (index) => {
		const updatedChoiceBlocks = [ ...choiceBlocks ];
		updatedChoiceBlocks.splice(index, 1);
		setNodeContent({ choices: updatedChoiceBlocks }, true);
	};

	const addNewChoiceBlock = () => {
		const updatedChoiceBlocks = [ ...choiceBlocks ];
		updatedChoiceBlocks.push({});
		setNodeContent({ choices: updatedChoiceBlocks }, true);
	};

	const handleChangeChoiceBlock = (event, index, setHistory) => {
		const { value, name } = event.target;
		const updatedChoiceBlocks = [ ...choiceBlocks ];
		updatedChoiceBlocks[index][name] = value;
		setNodeContent({ choices: updatedChoiceBlocks }, setHistory);
	};

	const generateChoiceBlock = (choiceBlock, index) => {
		return (
			<div className="multiple-choice-template-form__list" key={index}>
				<div className="multiple-choice-template-form__head">
					<span className="multiple-choice-template-form__head__badge">{index + 1}</span>
					{choiceBlocks.length > 2 &&
					<button className=" multiple-choice-template-form-field__delete" type="button"
					        onClick={() => handleDeleteBlock(index)}>
						<IconWrapper className={"multiple-choice-template-form-field__icon"} Component={IconTrash}
						             tooltip={t("sheet:sheet-details.tooltip.delete")}/>
					</button>
					}
				</div>
				<div className="multiple-choice-template-form-field">
					<div className="multiple-choice-template-form-field__head">
						<label className="multiple-choice-template-form__label">
							{t("sheet:sheet-details.template.multipleChoice.label-title", { number: index + 1 })}
						</label>
					</div>
					<div className="multiple-choice-template-form-field__content">
						<div className="multiple-choice--template-form__icon-head">
							<IconWrapper className="multiple-choice--template-form__icon--check" Component={IconCheck}/>
						</div>
						<Textarea
							name="title"
							className="multiple-choice-template-form__input"
							value={choiceBlock.title}
							onChange={(e) => handleChangeChoiceBlock(e, index, false)}
							onBlur={(e) => handleChangeChoiceBlock(e, index, true)}
						/>
					</div>
				</div>
				<div className="multiple-choice-template-form-field">
					<div className="multiple-choice-template-form-field__head">
						<label className="multiple-choice-template-form__label">
							{t("sheet:sheet-details.template.multipleChoice.label-subtitle", { number: index + 1 })}
						</label>
					</div>
					<div className="multiple-choice-template-form-field__content">
						<Textarea
							name="subtitle"
							className="multiple-choice-template-form__input multiple-choice-template-form__input--subtitle"
							value={choiceBlock.subtitle}
							onChange={(e) => handleChangeChoiceBlock(e, index, false)}
							onBlur={(e) => handleChangeChoiceBlock(e, index, true)}
						/>
					</div>
				</div>
			</div>
		);
	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(updatedContent, [ content ]);
	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initAdditionalInformation, []);

	return (
		<div>
			<form className="multiple-choice-template-form" onSubmit={event => event.preventDefault()}>

				{additionalInformation.multipleChoiceInvalid && <div className="multiple-choice-template-form__warning">
					{t("sheet:sheet-details.template.multipleChoice.form-warning")}
				</div>}

				<div className="multiple-choice-template-form__title">
					<label
						className="multiple-choice-template-form__label">{t(`sheet:sheet-details.template.${type}.label`)}</label>
					<Editor
						className="multiple-choice-template-form__editor"
						name="text"
						focus={type && isAtEndHistory} //disable auto focus is history is not at the end to avoid blur when click next button
						value={initTitle || null}
						toolbarConfig={defaultEditorToolbarConfig}
						onChange={handleChange}
						onBlur={(e) => setNodeContent({ title: currentTitle }, true)}/>
				</div>

				<div className="multiple-choice-template-form__choice-title">
					{t("sheet:sheet-details.template.multipleChoice.choice-title")}
				</div>

				{Array.isArray(choiceBlocks) && choiceBlocks.length > 0 && choiceBlocks.map(generateChoiceBlock)}

				<button className="multiple-choice-add-node" onClick={addNewChoiceBlock}>
					{t("sheet:sheet-details.template.multipleChoice.add-choice")}
				</button>

				{additionalInformation.multipleChoiceInvalid && <div className="multiple-choice-template-form__warning">
					{t("sheet:sheet-details.template.multipleChoice.form-warning")}
				</div>}
			</form>
		</div>
	);
};

export default MultipleChoiceTemplateForm;
