import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./train-binder.scss";

/**
 * TrainBinder component
 * @param {object} props
 * @param {string} props.className
 * @param {string} props.label
 * @param {"auto" | "driver" | "officer"} props.type
 * @returns {JSX.Element}
 */
const TrainBinder = props => {
	const { className, label = "", type = "" } = props;

	return (
		<span className={classNames("binder-related", className ,`binder-related--${type}`)}>
			{label}
		</span>
	);
};

TrainBinder.propTypes = {
	label: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string
};

export default TrainBinder;
