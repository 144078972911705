import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./filter-material-option.scss";

/**
 * Render the FilterMaterialDropdownOption components
 * @param {object} props
 * @param {string} props.className
 * @param {object[]} props.materialList
 * @returns {JSX.Element}
 */
const FilterMaterialOption = props => {
	const { className, materialList } = props;

	return (
		<label className={classnames(className, "filter-material-option-label")} htmlFor={materialList}>
			<span className="filter-material-option__label">{materialList}</span>
		</label>
	);
};

FilterMaterialOption.propTypes = {
	className: PropTypes.string,
	materialList: PropTypes.string.isRequired
};

export default FilterMaterialOption;
