import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSheetContentContext } from "../../../../context/sheet-content-context";
import { isTrainBinderType } from "../../../../../binder/utils/binder-type";
import getComponentConfig from "../../utils/get-component-config";
import DropdownGenerator from "./dropdown-generator/dropdown-generator";
import "./sheet-edition-panel.scss";

/**
 * @param {{binderData: {
 * 		type: 'sheet' | 'theme' | 'man'  | 'train-driver' | 'train-officer' | 'train-auto'
 * }}} props
 * @returns {JSX.Element}
 */
const SheetEditionPanel = (props) => {
	const { binderData } = props;
	const isTrainBinder = useMemo(() => isTrainBinderType(binderData?.type), [ binderData?.type ]);
	const { getCurrentActive, activeLocation } = useSheetContentContext();

	const activeNode = useMemo(getCurrentActive, [ getCurrentActive ]);
	const components = useMemo(() => getComponentConfig(isTrainBinder ? "train" : "pcc"), [ isTrainBinder ]);

	/**
	 * Render the form associated with the provided template
	 * @param {object}template
	 */
	const loadTemplateComponent = (template) => {
		const ComponentName = components[template.type];
		if (ComponentName){
			const { content, type, id, location } = template;
			return React.createElement(ComponentName, {
				key: id, id, content, type, binderData, location, isTrainBinder, activeLocation
			});
		} else {
			return false;
		}
	};

	return (
		<aside className="sheet-edition-panel">
			{activeNode && <DropdownGenerator scope={isTrainBinder ? "train" : "pcc"}/>}
			{activeNode && loadTemplateComponent(activeNode)}
		</aside>
	);
};

SheetEditionPanel.propTypes = {
	binderData: PropTypes.shape({
		type: PropTypes.oneOf([
			"sheet", "theme", "man" , "train-driver", "train-officer", "train-auto"
		])
	})
};

export default SheetEditionPanel;
