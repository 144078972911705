import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./toggle-switch.scss";

/**
 * @typedef Props
 * @prop {string} className
 * @prop {string} sliderClassName
 * @prop {boolean} isChecked
 * @prop {function} handleOnChange
 * @prop {boolean} disabled
 */

/**
 * Render the SubwayLineNumber components
 * @param {Props} props
 * @returns {JSX.Element}
 */
const ToggleSwitch = (props) => {
	const { className, isChecked, handleOnChange, disabled } = props;

	const classNameModifier = useMemo(() => ({
		"toggle-switch--checked": isChecked,
		"toggle-switch--disable": disabled
	}), [ isChecked, disabled ]);


	return (
		<label className={classnames("toggle-switch", classNameModifier, className )}>
			<input className="toggle-switch__input" type="checkbox" checked={isChecked} onChange={handleOnChange} disabled={disabled}/>
			<span className="toggle-switch__slider"/>
		</label>
	);
};

ToggleSwitch.propTypes = {
	className: PropTypes.string,
	isChecked: PropTypes.bool,
	handleOnChange: PropTypes.func,
	disabled: PropTypes.bool,
};


export default ToggleSwitch;
