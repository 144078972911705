import React, { useRef } from "react";
import classNames from "classnames";
import { IconWrapper } from "../../../";

import { ReactComponent as ArrowDown } from "../../../assets/img/arrow-down.svg";

import "./dropdown.scss";

const Dropdown = (props) => {
	const {
		children,
		className,
		value = "",
		name,
		onChange,
		label,
		placeholder = "",
		placeholderValue = "",
		disabledPlaceholder = true,
		disabled,
	} = props;

	const selectRef = useRef();

	const initClassName = (baseClassName) => classNames(baseClassName , className, { [`${baseClassName}--disabled`] : disabled });

	const handleLabelClick = () => {
		selectRef.current.focus();
	};

	return (
		<div className={initClassName("dropdown")}>
			<label className="dropdown__label" onClick={handleLabelClick}>{label}</label>
			<select
				ref={selectRef}
				className="dropdown__select"
				value={value || placeholderValue}
				name={name}
				onChange={onChange}
				disabled={disabled}>
				{ placeholder &&
				<option value={placeholderValue} disabled={value !== placeholderValue && disabledPlaceholder}>
					{placeholder}
				</option>
				}
				{children}
			</select>
			<IconWrapper className="dropdown__arrow" Component={ArrowDown}/>
		</div>
	);
};

export default Dropdown;
