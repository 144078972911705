import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDndSheetReference } from "../../../../../../../../shared";
import TemplateWrapper from "../../template-wrapper/template-wrapper";
import "./space-template-view.scss";

const SpaceTemplateView = (props) => {
	const { template, allowInsertAfter, currentPosition, moveCard, location } = props;

	const { t } = useTranslation();
	const ref = useRef(null);
	const { drag, isDragging } = useDndSheetReference(template, moveCard, ref, location?.parentId);

	return (
		<TemplateWrapper
			id={template.id}
			location={location}
			className="space-template"
			reference={ref}
			isDragging={isDragging}
			dragRef={drag}
			template={template}
			allowInsertAfter={allowInsertAfter}
			currentPosition={currentPosition}>
			<span className="space-template__text">{t("sheet:sheet-details.template.space.text")}</span>
		</TemplateWrapper>
	);
};

export default SpaceTemplateView;

