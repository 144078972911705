import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { buildSprProcessUri } from "../../index";
import { Button } from "../../../theme";
import { ReactComponent as IconArrowLeft } from "../../../domains/process/assets/icon-arrow-left.svg";
import "./back-to-current-step.scss";

const BackToCurrentStep = (props) => {
	const { className, processId } = props;
	const location = useLocation();
	const { t } = useTranslation();

	const prefixLink = location.pathname.startsWith("/spr") ? "spr" : "railway";

	return (
		<Link to={buildSprProcessUri({ processId }, prefixLink)}
		      className={classNames("back-to-current-step", className)}>
			<Button 
				variant="primary"
				small
				icon={IconArrowLeft}
			/>
			<span className="back-to-current-step__text">{t("process:history-view.back-to-current-step")}</span>
		</Link>
	);
};

export default BackToCurrentStep;
