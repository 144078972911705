import React, { useEffect, useState } from "react";
import { useDragLayer } from "react-dnd";
import { ItemTypes } from "../../../../../../shared/utils/drag-and-drop-item-types";
import "./sheet-edition-drag-layer.scss";

// update style for div to follow the mouse
const getItemStyles = (initialOffset, currentOffset) => {
	if (!initialOffset || !currentOffset) {
		return {
			display: "none",
		};
	}
	let { x, y } = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
};

const SheetEditionDragLayer = ({ loadTemplateComponent }) => {
	const {
		itemType,
		isDragging,
		item,
		initialOffset,
		currentOffset,
	} = useDragLayer((monitor) => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	const [ previewNode, setPreviewNode ] = useState();
	const [ customClass, setCustomClass ] = useState("");

	// generate template css from item nodeType
	const loadNewItem = () => {
		if (itemType === ItemTypes.NODE_TASK || itemType === ItemTypes.SUB_NODE_TASK) {
			setPreviewNode(loadTemplateComponent(item.template));
		}

		if (itemType === ItemTypes.SUB_NODE_TASK) {
			setCustomClass("--sub-node");
		} else {
			setCustomClass("");
		}

	};

	// TODO remove eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(loadNewItem, [ item ]);

	if (!isDragging) {
		return null;
	}

	return (
		<div className="sheet-edition-drag-layer">
			<div className={`sheet-edition-drag-layer__template${customClass}`}
			     style={getItemStyles(initialOffset, currentOffset)}>
				{previewNode}
			</div>
		</div>
	);
};

export default SheetEditionDragLayer;
