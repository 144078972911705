import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { formatJsonDate, usePopup, useAppSetupContext } from "../../../../shared";
import { IconWrapper } from "../../../../theme";
import HistoryResumeTaskView from "./view/history-resume-task-view/history-resume-task-view";
import HistoryMultipleChoiceTaskView from "./view/history-multiple-choice-task-view/history-multiple-choice-task-view";
import HistoryQuestionTaskView from "./view/history-question-task-view/history-question-task-view";
import HistoryMultipleLinkTaskView from "./view/history-multiple-link-task-view/history-multiple-link-task-view";
import HistoryMultipleLinkTrainTaskView from "./view/history-multiple-link-train-task-view/history-multiple-link-train-task-view";
import HistorySimpleLinkTaskView from "./view/history-simple-link-task-view/history-simple-link-task-view";
import HistoryTerminationTaskView from "./view/history-termination-task-view/history-termination-task-view";
import HistoryChangeOwnerView from "./view/history-change-owner-view/history-change-owner-view";
import HistoryTextTaskView from "./view/history-text-task-view/history-text-task-view";
import HistoryMultipleChoiceStepTaskView from "./view/history-multiple-choice-task-view/history-multiple-choice-step-task-view/history-multiple-choice-step-task-view";
import HistoryStepTaskView from "./view/history-step-task-view/history-step-task-view";
import HistoryTableTaskView from "./view/history-table-task-view/history-table-task-view";
import PopupWarningReturn from "../popup-warning-return/popup-warning-return";
import HistoryActionTaskView from "./view/history-action-task-view/history-action-task-view";
import HistoryTrainInterventionTaskView from "./view/history-train-intervention-task-view/history-train-intervention-task-view";
import { dateFormatTime } from "../../utils";
import { IconReturn, IconLockOpen, IconLockClose } from "../../assets";
import "./history-task-loader.scss";

/**
 * history task loader
 * @param {object} props.task
 * @param {string} props.className
 * @param {boolean} props.passed
 * @param {number} props.lastCanceledTaskPosition
 * @param {function} props.handleJumpBack
 * @param {object} props.processInfo
 * @param {boolean} props.isTrainBinder
 * @returns {JSX.Element}
 */
const HistoryTaskLoader = (props) => {
	const {
		task, className, passed, lastCanceledTaskPosition, handleJumpBack = () => {}, processInfo, isTrainBinder
	} = props;
	const { user: currentUser } = useAppSetupContext();

	const popupWarningReturnControl = usePopup();
	const isProcessOwner = processInfo?.owner === currentUser?.tech_id;

	const components = {
		note: HistoryTextTaskView,
		action: HistoryActionTaskView,
		call: HistoryTextTaskView,
		systemEffect: HistoryTextTaskView,
		step: HistoryStepTaskView,
		question: HistoryQuestionTaskView,
		resume: HistoryResumeTaskView,
		multipleLink: isTrainBinder ? HistoryMultipleLinkTrainTaskView : HistoryMultipleLinkTaskView,
		simpleLink: HistorySimpleLinkTaskView,
		termination: HistoryTerminationTaskView,
		multipleChoice: HistoryMultipleChoiceTaskView,
		multipleChoiceStep: HistoryMultipleChoiceStepTaskView,
		table: HistoryTableTaskView,
		"change-owner": HistoryChangeOwnerView,
		mrfIntervention: HistoryTrainInterventionTaskView,
		pccInstruction: HistoryTrainInterventionTaskView,
		officerIntervention: HistoryTrainInterventionTaskView,
	};

	const buildHistoryTaskLoaderClasses = () => classNames("history-task-loader", className, { "history-task-loader--passed": passed, "history-task-loader--canceled": task?.canceled });

	/**
	 * Check if the provided task should be rendered
	 * @param taskToDisplay The task to check
	 * @return {boolean} `true` if the task should be displayed, else `false`
	 */
	const isTaskRendered = (taskToDisplay) => {
		// No render for history-start, space and pageBreak template
		const skippedTaskTypes = [ "history-start", "pageBreak", "space" ];
		return !skippedTaskTypes.includes(taskToDisplay?.type);
	};

	const handleLockClick = () => {
		if (isProcessOwner) {
			popupWarningReturnControl.show();
		}
	};

	const renderTask = () => {
		if (isTaskRendered(task) && task?.type) {
			const ComponentName = components[task?.type];
			const displayOpenLock = task?.returnable && task?.canceled && task?.isFirstCanceled;
			const displayReturnButton = !displayOpenLock && task?.returnable && (task?.historyPosition > lastCanceledTaskPosition) && !task?.canceled;

			if (ComponentName) {
				return (
					<li key={task.id} className={buildHistoryTaskLoaderClasses()}>
						<PopupWarningReturn popupControl={popupWarningReturnControl} onConfirm={handleJumpBack}/>
						{!passed && task?.canceled && task?.isFirstCanceled &&
						<IconWrapper className="history-task-loader__icon-canceled" Component={IconReturn}/>}
						{!passed && task?.type !== "step" && <span
							className="history-task-loader__time">{formatJsonDate(task?.finishedAt, dateFormatTime)}</span>}
						<ComponentName className="history-task-loader__component" task={task} passed={passed} isTrainBinder={isTrainBinder}/>
						{!passed && processInfo?.status === "in-progress" && displayReturnButton && (
							<button type="button"
							        className={classNames( "history-task-loader__return", { "history-task-loader__return--owner": isProcessOwner })}
							        onClick={handleLockClick}>
								<IconWrapper className="history-task-loader__return__icon" Component={IconLockClose}/>
							</button>
						)}
						{!passed && displayOpenLock &&
						<IconWrapper className="history-task-loader__icon-lock" Component={IconLockOpen}/>}
					</li>
				);
			}
		}
		return false;
	};

	return renderTask();
};

HistoryTaskLoader.propTypes = {
	task: PropTypes.shape({
		canceled: PropTypes.bool,
		isFirstCanceled: PropTypes.bool,
		returnable: PropTypes.bool,
		type: PropTypes.string,
		finishedAt: PropTypes.string
	}),
	className: PropTypes.string,
	passed: PropTypes.bool,
	lastCanceledTaskPosition: PropTypes.number,
	handleJumpBack: PropTypes.func,
	processInfo: PropTypes.shape({
		owner: PropTypes.string,
		status: PropTypes.string
	}),
	isTrainBinder: PropTypes.bool
};

export default HistoryTaskLoader;
