/**
 * group items by properties
 * @param {object[]} templates 
 * @param {string} key1 key to group by 
 * @param {string} [key2] nested key to group by 
 * @returns {object[]} item list
 */
const groupBy = (items = [], key1, key2 = null) => {
	const getId = key2 ? (item) => item?.[key1]?.[key2] : (item) => item?.[key1];
	return items.reduce((groups, item) => {
		const id = getId(item);
		const value = groups?.[id];
		if (!value){
			groups[id] = []; 
		}
		groups[id].push(item);
	  return groups;
	}, {});
};

/**
 * Group neighbor by id
 * @param items
 * @param {string} key1
 * @param {string} key2
 * @return {*}
 * @example
 * const list1 = [
      { a: 1, id: 1 },
      { a: 1, id: 2 },
      { a: 2, id: 3 },
      { a: 1, id: 4 }
    ]

   const list2 = [
      { a: { b: 1 }, id: 1 },
      { a: { b: 1 }, id: 2 },
      { a: { b: 2 }, id: 3 },
      { a: 2, id: 4 }
    ]

    groupNeighborBy(list, "a")
    [
	  [ { a: 1, id: 1 }, { a: 1, id: 2 } ],
	  [ { a: 2, id: 3 } ],
	  [ { a: 1, id: 4 } ]
    ]

    groupNeighborBy(list2, "a", "b")
    [
  	  [ { a: { b: 1 }, id: 1 }, { a: { b: 1 }, id: 2 } ],
	  [ { a: { b: 2 }, id: 3 } ],
	  [ { a: 2, id : 4 } ]
    ]
 */
const groupNeighborBy = (items, key1, key2) => {
	const getId = key2 ? (item) => item?.[key1]?.[key2] : (item) => item?.[key1];
	return items.reduce((groupList, item) => {
		const [ lastGroup = [] ] = groupList.slice(-1);
		const [ lastGroupItem ] = lastGroup;

		if (lastGroup.length === 0){
			return [ ...groupList, [ item ] ];
		}

		if (getId(item) === getId(lastGroupItem)){
			return [ ...groupList.slice(0, -1), [ ...lastGroup, item ] ];
		} else {
			return [ ...groupList, [ item ] ];
		}
	}, []);
};

export { groupNeighborBy, groupBy };
