import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ErrorMessage, IconWrapper, TextField } from "../../../../../../../../theme";
import { IconArrowRight } from "../../../../../../../../theme/assets/img";
import "./form-signal.scss";

/**
 * Render the FormSignal component
 * @param {object} props
 * @param {string} props.className
 * @param {object} props.form
 * @param {object} props.validation
 * @param {function} props.onChange
 * @returns {JSX.Element}
 */
const FormSignal = (props) => {
	const { className, form, validation, onChange } = props;

	const { t } = useTranslation();

	return (
		<form className={classnames("form-signal", className)}>
			<TextField label={t("binder:popup.form-signal.field-label.title")} name="title" value={form?.title}
			           onChange={onChange}/>
			<div className="form-signal__wrapper">
				<span className="form-signal__label">{t("binder:popup.form-signal.field-label.signal-path")}</span>
				<TextField label={t("binder:popup.form-signal.field-label.origin")} name="origin" value={form?.origin}
				           onChange={onChange}/>
				<IconWrapper className="form-signal__icon" Component={IconArrowRight}/>
				<TextField label={t("binder:popup.form-signal.field-label.destination")} name="destination"
				           value={form?.destination} onChange={onChange}/>
			</div>
			{validation?.global?.length > 0 &&
			<ErrorMessage className="form-signal__error">
				{t("binder:popup.form-signal.error.signal-exist")}
			</ErrorMessage>
			}
		</form>
	);
};

FormSignal.propTypes = {
	className: PropTypes.string,
	form: PropTypes.shape({
		title: PropTypes.string,
		origin: PropTypes.string,
		destination: PropTypes.string,
	}).isRequired,
	validation: PropTypes.shape({
		title: PropTypes.arrayOf(PropTypes.string).isRequired,
		origin: PropTypes.arrayOf(PropTypes.string).isRequired,
		destination: PropTypes.arrayOf(PropTypes.string).isRequired,
		global: PropTypes.arrayOf(PropTypes.string).isRequired,
	}).isRequired,
	onChange: PropTypes.func.isRequired
};

export default FormSignal;
