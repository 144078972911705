import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../theme";
import { ReactComponent as IconBack } from "../../../../../../theme/assets/img/icon-back.svg";
import "./process-list-subheader.scss";

const sprHomeUrl = "/spr/binders";
const railwayHomeUrl = "/railway";

const ProcessListSubheader = (props) => {
	const { t } = useTranslation();
	const { side } = props;

	const url = side === "train" ? railwayHomeUrl : sprHomeUrl;
	const ctaTextContent = side === "train" ? t("process:process-list.search-train-list") : t("process:process-list.return-binder-list");

	const displayProcessListSubHeaderOptions = () => (
		<Link className="process-list__content__return-binders" to={url}>
			<div className="return-binders__text-wrapper">
				<Button 
					variant="primary"
					small
					icon={IconBack}
				/>
				<span className="return-binders__text">{ctaTextContent}</span>
			</div>
		</Link>
	);

	return (
		<div className="process-list__subheader">
			<h1 className="process-list__subheader__title">{t("process:process-list.title")}</h1>
			{displayProcessListSubHeaderOptions()}
		</div>
	);
};

export default ProcessListSubheader;
