import React from "react";
import PropTypes from "prop-types";
import CustomDropdown from "../custom-dropdown/custom-dropdown";

/**
 * Render the FilterDropdown component
 * This component add value in list of value for filter
 * @param {object} props
 * @returns {JSX.Element}
 * @constructor
 */
const FilterDropDown = props => {
	const { onSelect, filterValue, className, options = [], staticSelectedLabel, renderOptionValue } = props;

	const handleDropdownSelect = (event) => {
		const dataToUpdate = filterValue ? [ ...filterValue ] : [];
		const { value } = event.target;
		if (value && !dataToUpdate.includes(value)) {
			dataToUpdate.push(value);
			onSelect(dataToUpdate);
		}
	};

	return (
		<CustomDropdown
			className={className}
			onChange={handleDropdownSelect}
			options={options}
			renderStaticDefaultValue={staticSelectedLabel}
			renderOptionValue={renderOptionValue}
		/>
	);
};

FilterDropDown.propTypes = {
	onSelect: PropTypes.func.isRequired,
	filterValue: PropTypes.arrayOf(PropTypes.string),
	className: PropTypes.string,
	options: PropTypes.array.isRequired,
	staticSelectedLabel: PropTypes.string.isRequired,
	renderOptionValue: PropTypes.func.isRequired
};

export default FilterDropDown;
