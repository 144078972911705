import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { IconWrapper } from "../../../../../../theme";
import { ReactComponent as IconCheck } from "../../../../../../theme/assets/img/icon-check.svg";
import resolveSheetColor from "../../../../../sheet/utils/resolve-sheet-color";
import { SheetType } from "../../../../../sheet";
import "./history-multiple-link-task-view.scss";

/**
 * Historic view of a multiple link task
 * @param {object} props
 * @returns {JSX.Element}
 */
const HistoryMultipleLinkTaskView = (props) => {
	const { task, className, passed } = props;
	const { text, sheetNumber, id, sheetColor: color, binderColor, sheetType } = task?.choice || {};
	const { items } = task?.content || [];
	const sheetColor = resolveSheetColor(color, binderColor);

	// get the link category by finding the last previous link in the list with a "category" param
	const findLinkCategory = () => {
		let category = null;
		// using "some" to break iteration when reaching the chosen link
		items.some(item => {
			if (item.category) {
				category = item.category;
			}
			return (item.id === id);
		});
		return category;
	};

	const renderCategory = () => {
		const category = findLinkCategory();
		return (
			<>
				{category &&
				<span className="history-multiple-link-task-view__category">
					{category}
				</span>
				}
			</>
		);
	};

	const getComponentClassName = () => {
		const modifier = { "history-multiple-link-task-view--passed": passed };
		return classnames("history-multiple-link-task-view", className, modifier);
	};

	return (
		<div className={getComponentClassName()}>
			{renderCategory()}
			<div className="history-multiple-link-task-view__link-container">
				<IconWrapper className="history-multiple-link-task-view__icon" Component={IconCheck}/>
				<span className="history-multiple-link-task-view__text">{text}</span>
				<SheetType
					className="history-multiple-link-train-task-view__sheet-number"
					type={sheetType || "no-type"}
					sheetNumber={sheetNumber}
					color={sheetColor}
				/>
			</div>
		</div>
	);
};

HistoryMultipleLinkTaskView.propTypes = {
	task: PropTypes.shape({
		choice: PropTypes.shape({
			text: PropTypes.string,
			sheetNumber: PropTypes.string,
			id: PropTypes.string,
			sheetColor: PropTypes.string,
			binderColor: PropTypes.string,
			sheetType: PropTypes.string
		}),
		content: PropTypes.shape({
			items: PropTypes.arrayOf(PropTypes.shape({
				category: PropTypes.string
			}))
		})
	}),
	className: PropTypes.string,
	passed: PropTypes.bool
};

export default HistoryMultipleLinkTaskView;
