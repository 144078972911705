import { v4 as uuid } from "uuid";
import { groupNeighborBy } from "../../../../../shared";
/**
 * @typedef Task
 * @property {string} id
 * @property {Object} content
 * @property {string} type
 * @property {("yes" | "no")|Object} [choice]
 * */

/**
 * @typedef HistoryEntry
 * @property {Object} id
 * @property {Object} content
 * @property {string} type
 * @property {Object} binder
 * @property {string} binder.id
 * @property {string} binder.techId
 * @property {string} binder.color
 * @property {string} binder.abbreviation
 * @property {string} binder.subtitle
 * @property {number} binder.associatedLine
 * @property {Object} sheet
 * @property {string} sheet.number
 * @property {string} sheet.status
 * @property {string} sheet.techId
 * @property {string} sheet.title
 * @property {string} sheet.type
 * @property {Date} finishedAt
 * @property {Boolean} returnable
 * @property {Boolean} canceled
 * @property {Boolean} isFirstCanceled
 * @property {("yes" | "no")|Object} [choice]
 * @property {Task} [parent]
 */

/**
 * @typedef SheetData
 * @property {string} binder_abbreviation
 * @property {number} binder_associated_line
 * @property {string} binder_color
 * @property {string} binder_id
 * @property {string} binder_status
 * @property {string} binder_subtitle
 * @property {string} binder_tech_id
 * @property {string} binder_type
 * @property {string} last_change_at
 * @property {string} number
 * @property {string} number_search
 * @property {string} sheet_id
 * @property {string} status
 * @property {string} title
 * @property {string} type
 */

const branchTaskTypes = [ "question", "multipleChoice", "multipleLink" ];

/**
 * Convert a task to an history entry
 * @param {SheetData} sheetData
 * @param {object} taskInfo
 * @param {Task} taskInfo.task
 * @param {[Task]} taskInfo.parentTask
 * @param {object} taskInfo.additionalData
 * @param {object} user
 * @returns {HistoryEntry}
 */
const buildTaskLog = (sheetData, taskInfo = {}, user) => {
	let content = undefined;
	const { task, parentTask = {}, additionalData = {} } = taskInfo;

	const binder = {
		techId: sheetData?.binder_tech_id,
		type: sheetData?.binder_type,
		abbreviation: sheetData?.binder_abbreviation,
		associatedLine: sheetData?.binder_associated_line,
		color: sheetData?.binder_color,
		id: sheetData?.binder_id,
		subtitle: sheetData?.binder_subtitle
	};

	const sheet = {
		techId: sheetData?.sheet_id,
		number: sheetData?.number,
		status: sheetData?.status,
		title: sheetData?.title,
		type: sheetData?.type,
		color: sheetData?.color,
		signalTechId: sheetData?.signal_tech_id
	};

	const theme = {
		theme_color: sheetData?.theme_color ?? null,
		theme_title: sheetData?.theme_title ?? ""
	};

	// save the choices to subNode in MultipleChoice
	if (parentTask?.type === "multipleChoice") {
		// deepCopy
		const choices = JSON.parse(JSON.stringify(parentTask.content.choices));
		content = { choices };
	}

	return {
		...additionalData,
		...task,
		binder,
		sheet,
		theme,
		finishedAt: new Date(),
		finishedBy: user?.tech_id,
		parent: parentTask && { ...parentTask, content },
		returnable: branchTaskTypes.includes(task?.type),
		canceled: false,
		isFirstCanceled: false,
		active: undefined,
		userInfo: undefined // remove usersInfo before saved
	};
};

/**
 *
 * @param {HistoryEntry[]} [history=[]]
 * @return {HistoryEntry[]}
 */
const setupHistoryBeforeSave = (history = []) => {
	const result = JSON.parse(JSON.stringify(history));
	return result.map(task => {
		delete task.userInfo;

		// Support for old task
		task.returnable = branchTaskTypes.includes(task?.type);
		// change owner can't be canceled
		task.canceled = (task.canceled && task?.type !== "change-owner") || false;
		task.isFirstCanceled = task.isFirstCanceled || false;

		return task;
	});
};


/**
 * Push the provided task into the given history
 * @param {HistoryEntry[]} history The history to push the task in
 * @param {SheetData} sheet The sheet data relative to the task
 * @param taskInfo
 * @param {Task} taskInfo.task The task to push into history
 * @param {[Task]} taskInfo.parentTask The parent task of the task to push in the history
 * @param taskInfo.additionalData data needs for certains task ( Ex: {infoAction: {notApplicable: true }} )
 * @param user
 * @returns {HistoryEntry[]} The new history
 */
const pushHistoryEntry = (history, sheet, taskInfo = {}, user) => {
	if (Object.keys(sheet).length > 0) {
		const taskHistoryEntry = buildTaskLog(sheet, taskInfo, user);
		return [ ...history, taskHistoryEntry ];
	}
	return history;
};

/**
 * Get the list of the first task of each sheet part of the process's history except determination sheet
 * @param {HistoryEntry[]} history
 */
const getSheetsFromHistory = (history) => {
	return  groupNeighborBy(history, "sheet", "techId")
		// Get first task of each group
		.map(([ firstTask ]) => firstTask)
		// Remove task from determination sheet
		.filter(task => task?.sheet?.type !== "determination");
};

/**
 * Get the process list status from history
 * @param {HistoryEntry[]} history
 * @returns {"in-progress"|"closed"}
 */
const getProcessStatus = (history) => {
	if (history.length > 0) {
		const [ lastEntry ] = history.slice(-1);
		return lastEntry.type === "termination" ? "closed" : "in-progress";
	}
	return "in-progress";
};

/**
 * Create the first entry of the history
 * @returns {{id: string, type: string}}
 */
const createStartHistoryEntry = () => ({
	type: "history-start",
	id: uuid(),
});

export {
	pushHistoryEntry,
	getSheetsFromHistory,
	buildTaskLog,
	getProcessStatus,
	createStartHistoryEntry,
	setupHistoryBeforeSave
};
